import { Box, ModalContent, Text } from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import { useCheckPremium } from 'hooks';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewDispatch, NewRootState } from 'store';
import { isElectronEnv } from 'tools/env';
import ApplePricePlan from './apple-price-plan.component';
import BenefitItem from './benefit-item.component';
import Plans from './price-plan.component';
import AppleSaleCampaign from './sale-campaign/apple-sale-campaign.component';
import SaleCampaign from './sale-campaign/sale-campaign.component';
import SubBenefit from './sub-benefit-item.component';

const benefits = [
  {
    iconName: 'ic-infinity',
    folderName: 'journal',
    color: '#2AA8D0',
    title: 'newupgrade.promptHabitTitle',
    subTitle: 'newupgrade.unlimitedHabitSubtitle',
  },
  {
    iconName: 'ic-app-settings',
    folderName: 'sidebar',
    color: '#E0861D',
    title: 'newupgrade.benefitsUnlimitedReminderTitle',
    subTitle: 'newupgrade.smartReminderSubtitle',
  },
  {
    iconName: 'ic_lock',
    folderName: '',
    color: '#000000',
    title: 'upgrade.upgradeUnlimitedUsageTitle',
    subTitle: 'upgrade.upgradeUnlimitedUsageSubtitle',
  },
];
const UpgradeModal: React.FC = (): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  const dispatch = useDispatch<NewDispatch>();

  const { t } = useTranslation();
  const [isApplePayments, setIsApplePayments] = useState<boolean>(false);

  const canMakePayments = useSelector<NewRootState, boolean>((state) => state.appModel.isCanMakeMacAppStorePayments);
  const isPremium = useCheckPremium();
  const isOpenPaymentModal = useSelector((state: NewRootState) => state.paymentModel.isOpenPaymentModal);
  const currentTabInSetting = useSelector((state: NewRootState) => state.appSettingModel.currentTab);

  useEffect(() => {
    if (isElectronEnv() && canMakePayments) {
      setIsApplePayments(true);
    } else {
      setIsApplePayments(false);
    }
  }, [canMakePayments]);

  const closePayment = useCallback(() => {
    if (isOpenPaymentModal) {
      dispatch.paymentModel.openPaymentModal(false);
    }
    if (currentTabInSetting === 'payment') {
      dispatch.appSettingModel.changeTab('general');
    }
  }, [currentTabInSetting, dispatch.appSettingModel, dispatch.paymentModel, isOpenPaymentModal]);

  useEffect(() => {
    if (isPremium) {
      closePayment();
    }
  }, [closePayment, isPremium]);

  return (
    <ModalContent>
      <Box display="flex" w="674px" minH="482px">
        <Box w="342px" p="25px 25px 26px" bgColor={colorScheme.platform.control.background} borderLeftRadius="10px">
          <Text {...typography.emphasized.title[2]}>{t('newupgrade.title')}</Text>
          <Box m="2px 0 3px 0">
            {benefits.map((benefit, index) => (
              <BenefitItem
                key={`${benefit.title}-${index}`}
                iconName={benefit.iconName}
                folderName={benefit.folderName}
                color={benefit.color}
                title={t(benefit.title)}
                subTitle={t(benefit.subTitle)}
              />
            ))}
          </Box>
          <Box pl="6px">
            <SubBenefit title={t('authentication.syncTitle')} />
            <SubBenefit title={t('settings.privacyLock')} />
            <SubBenefit title={t('upgrade.upgradeCancelSubscription')} />
          </Box>
        </Box>
        <Box w="332px" borderRightRadius="10px" bgColor={colorScheme.background.primary[2]}>
          {/* <Box minH="78px"></Box> */}
          {isApplePayments ? <AppleSaleCampaign /> : <SaleCampaign />}
          <Box p="15px 16px 65px">
            <Box display="flex" justifyContent="space-between">
              <Box mb="11px" pr="5.5px">
                <Text {...typography.emphasized.title[5]} mb="4px">
                  {t('upgrade.chooseplanTitle')}
                </Text>
                <Text {...typography.normal.body} color={colorScheme.label.secondary}>
                  {t('newupgrade.macSubtitle')}
                </Text>
              </Box>
              <Box className='btn-close-payment' w="16px" h="16px" onClick={closePayment}>
                <Icon name={'ic_close'} width={16} height={16} fill={colorScheme.label.secondary} />
              </Box>
            </Box>
            {isApplePayments ? <ApplePricePlan /> : <Plans />}
          </Box>
        </Box>
      </Box>
    </ModalContent>
  );
};
export default UpgradeModal;
