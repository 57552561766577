import { Box, Flex, Text } from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import { useThemeData } from 'hooks/useThemeData';
import React from 'react';
import { ReactElement, useCallback } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { NewDispatch } from 'store';
import keyMap from 'utils/keyMapShortcut';
import { updateHabitFolderFilterAction } from '__archived__/store/ducks/app/app.action';
import { TIME_OF_DAY_BITWISE } from '__archived__/types/enum';

interface HabitMenuItemProps {
  payload: {
    timeOfDayBitwise: number;
    title: string;
    iconName: string;
    iconFolder: string;
    link: {
      pathName: string;
      pageName: 'allHabit' | 'timeOfDay';
      param?: string | number;
    };
  };
}

const shortcut: { [key: string]: string | undefined } = {
  allHabit: keyMap.sidebar.habit.combine,
  timeOfDay: keyMap.sidebar.timeOfDay.combine,
};

const HabitMenuItem: React.FC<HabitMenuItemProps> = ({ payload }: HabitMenuItemProps): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch<NewDispatch>();
  const oldDispatch = useDispatch();

  const keyShortcut = shortcut[payload.link.pageName] || '';
  useHotkeys(keyShortcut, (e) => {
    e.preventDefault();
    history.push(payload.link.pathName);
  });

  const updateTimeOfDay = useCallback(
    (timeOfDayBitwise: TIME_OF_DAY_BITWISE) => {
      switch (timeOfDayBitwise) {
        case TIME_OF_DAY_BITWISE.MORNING:
          oldDispatch(updateHabitFolderFilterAction(''));
          dispatch.appModel.setTimeOfDay('morning');
          break;
        case TIME_OF_DAY_BITWISE.AFTERNOON:
          oldDispatch(updateHabitFolderFilterAction(''));
          dispatch.appModel.setTimeOfDay('afternoon');
          break;
        case TIME_OF_DAY_BITWISE.EVENING:
          oldDispatch(updateHabitFolderFilterAction(''));
          dispatch.appModel.setTimeOfDay('evening');
          break;
        default:
          oldDispatch(updateHabitFolderFilterAction(''));
          dispatch.appModel.setTimeOfDay('all');
          break;
      }
    },
    [dispatch.appModel, oldDispatch],
  );

  return (
    <NavLink
      to={{
        pathname: payload.link.pathName,
        state: { pageName: payload.link.pageName },
      }}
      style={{
        position: 'relative',
        display: location.pathname === payload.link.pathName ? 'block' : 'unset',
      }}
      className="sidebar-item"
      activeClassName="active-nav-link"
      onClick={() => updateTimeOfDay(payload.timeOfDayBitwise)}
    >
      <Flex
        p="4.3px 8px"
        alignItems="center"
        cursor="default"
        borderRadius="5px"
        _hover={{ background: colorScheme.sidebar.item.hover }}
      >
        <Box display="flex" w="20px" h="20px" mr="5px" className="sidebar-item-icon--color">
          <Icon folderName={payload.iconFolder} name={payload.iconName} fill={'#7B7C7C'} />
        </Box>
        <Text
          width="100%"
          p="6px 7px"
          textTransform="capitalize"
          className="sidebar-item-text--color"
          {...typography.normal.title[4]}
          color={colorScheme.label.secondary}
          _focusVisible={{
            outline: '1.5px solid #2A67F4',
            borderRadius: '5px',
            caretColor: '#2A67F4',
          }}
        >
          {payload.title}
        </Text>
      </Flex>
    </NavLink>
  );
};

export default React.memo(HabitMenuItem);
