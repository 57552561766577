import { Flex, Box, Text } from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import { useThemeData } from 'hooks/useThemeData';
import { OffMode, OffModeSection } from 'models/off-mode';
import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { _dayjs } from 'tools/extended-dayjs';
interface Props {
  offMode: OffMode;
  section: OffModeSection;
  onClick: () => void;
}
const OffModeItem: React.FC<Props> = ({ offMode, section, onClick }: Props): ReactElement => {
  const { t } = useTranslation();
  const { typography, colorScheme } = useThemeData();

  const renderDateRange = useCallback((offMode: OffMode) => {
    const { startDate, endDate } = offMode;
    if (offMode.stopDate) {
      return `${_dayjs(startDate).format('MMM D, YYYY')} - ${_dayjs(offMode.stopDate)
        .subtract(1)
        .format('MMM D, YYYY')}`;
    }
    return `${_dayjs(startDate).format('MMM D, YYYY')} - ${_dayjs(endDate).format('MMM D, YYYY')}`;
  }, []);

  const renderOffModeAction = useCallback(() => {
    if (_dayjs(offMode.startDate).isToday()) {
      return t('common.delete');
    }
    switch (section) {
      case 'current':
        return t('common.stop');
      case 'upcoming':
        return t('common.delete');
      default:
        break;
    }
  }, [offMode.startDate, section, t]);

  return (
    <Flex key={offMode.id} alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Icon
          folderName="off-mode"
          name={offMode.iconNamed}
          width={24}
          height={24}
          fill={colorScheme.label.secondary}
        />
        <Box ml="12px">
          <Text
            {...typography.normal.title[4]}
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            width="200px"
          >
            {offMode.reason}
          </Text>
          <Text {...typography.normal.callout} color={colorScheme.label.secondary} mt="4px">
            {renderDateRange(offMode)}
          </Text>
        </Box>
      </Flex>
      {(section === 'current' || section === 'upcoming') && (
        <Flex
          alignItems="center"
          border={`1px solid ${colorScheme.other.border}`}
          borderRadius="3px"
          p="7.5px 8px"
          onClick={onClick}
        >
          <Text {...typography.emphasized.callout} color={colorScheme.supporting.error}>
            {renderOffModeAction()}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
export default OffModeItem;
