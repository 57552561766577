import { Box, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';

interface WrapperSectionsProps {
    title?: string,
    children: React.ReactNode
}

const HabitMenuItem: React.FC<WrapperSectionsProps> = ({
    title,
    children
}: WrapperSectionsProps): ReactElement => {
    const { colorScheme, typography } = useThemeData();

    return (
        <Box mb="20px">
            {!!title && <Text mb="6px" textTransform="uppercase" {...typography.emphasized.caption[2]} color={colorScheme.label.secondary}>{title}</Text>}
            {children}
        </Box>
    );
};

export default React.memo(HabitMenuItem);
