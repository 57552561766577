import {
    ColorMode,
    InputGroup,
    Popover,
    PopoverTrigger,
    useColorMode,
} from "@chakra-ui/react";
import InputField from "components/common/input-field/input-field.component";
import MenuItemLayout from "components/common/menu/MenuItem";
import MenuListLayout from "components/common/menu/MenuList";
import { useThemeData } from "hooks/useThemeData";
import { NewDispatch, NewRootState } from "store";
import React, { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralSettingItem from "../general-setting-item.component";
import { PREFERENCES_LOCAL } from "models/common";
import { useTranslation } from "react-i18next";

interface ThemeColorMode {
    color: ColorMode;
    title: string;
}

const ThemeMode: React.FC = (): ReactElement => {
    const { t } = useTranslation();
    const { setColorMode, colorMode } = useColorMode();
    const { colorScheme, typography } = useThemeData();
    const dispatch = useDispatch<NewDispatch>();
    const isOpen = useSelector(
        (state: NewRootState) => state.appSettingModel.isOpenThemeMode
    );

    const themes: ThemeColorMode[] = [
        {
            color: "light",
            title: t("settings.themeLight"),
        },
        {
            color: "dark",
            title: t("settings.themeDark"),
        },
    ];

    const handleOpenUnit = useCallback(() => {
        // setOpenUnit(!openUnit);
        dispatch.appSettingModel.openThemeMode(!isOpen);
    }, [dispatch.appSettingModel, isOpen]);

    const closeUnit = () => {
        dispatch.appSettingModel.openThemeMode(false);
    };

    const handleThemeMode = (mode: ColorMode) => {
        setColorMode(mode);
        localStorage.setItem(PREFERENCES_LOCAL.themeMode, mode);
        handleOpenUnit();
    };

    const getTextTheme = (theme:string) => {
      return theme === 'light' ? t('settings.themeLight') : t('settings.themeDark')
    }

    return (
        <GeneralSettingItem
            title={t("settings.themeTitle")}
            subTitle={t("settings.themeSubtitle")}
        >
            <Popover
                matchWidth
                placement="bottom-start"
                isOpen={isOpen}
                onClose={closeUnit}
                closeOnBlur
            >
                <PopoverTrigger>
                    <InputGroup maxW="118px">
                        <InputField
                            isReadOnly
                            cursor="default"
                            inputType="select"
                            leftIconName={
                                colorMode === "light" ? "ic_sun" : "ic_moon"
                            }
                            textTransform="capitalize"
                            value={getTextTheme(colorMode)}
                            isOpen={isOpen}
                            {...typography.emphasized.callout}
                            paddingLeft="28px"
                            h="30px"
                            onClick={handleOpenUnit}
                        />
                    </InputGroup>
                </PopoverTrigger>
                <MenuListLayout style={{ width: "auto" }}>
                    {themes.map((theme, index) => (
                        <MenuItemLayout
                            title={theme.title}
                            hasIconLeft={false}
                            key={`${theme.title}-${index}`}
                            bgColor={
                                colorMode === theme.color
                                    ? colorScheme.accent.primary
                                    : "none"
                            }
                            isSelected={colorMode === theme.color}
                            // color={
                            //   colorMode === theme.color
                            //     ? colorScheme.universal.white
                            //     : colorScheme.other.menuItem
                            // }
                            borderTopRadius={index === 0 ? "3px" : "unset"}
                            borderBottomRadius={
                                index === themes.length - 1 ? "3px" : "unset"
                            }
                            onClick={() => handleThemeMode(theme.color)}
                        />
                    ))}
                </MenuListLayout>
            </Popover>
        </GeneralSettingItem>
    );
};
export default ThemeMode;
