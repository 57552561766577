import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ChangeEvent, ReactElement, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface IEmailInputProps {
  defaultValue?: string;
  handleInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit?: () => void;
}

const EmailInput: React.FC<IEmailInputProps> = ({
  defaultValue,
  handleInput,
  handleSubmit,
}: IEmailInputProps): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  const { t } = useTranslation();
  const handleSubmitWithEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (handleSubmit) {
        handleSubmit();
      }
    }
  };
  return (
    <FormControl id="email" isRequired mb="4px" autoComplete="off">
      <FormLabel
        {...typography.emphasized.caption[2]}
        color={colorScheme.label.secondary}
        p="8px 0px 4px 6px"
        m="0px 0px 3px 6px"
        width="286px"
      >
        {t('common.email').toUpperCase()}
      </FormLabel>
      <Input
        cursor="default"
        placeholder="Email"
        name="email"
        borderRadius="3px"
        w="282px"
        height="32px"
        p="7.5px 10px 7.5px 10px"
        {...typography.emphasized.callout}
        border={`1px solid`}
        borderColor={colorScheme.other.border}
        onChange={handleInput}
        defaultValue={defaultValue}
        onKeyUp={handleSubmitWithEnterKey}
        // _autofill={{boxShadow: 'unset !important'}}
        autoComplete="off"
        _hover={{ borderColor: colorScheme.other.border }}
        _focus={{ outline: 'none' }}
        _placeholder={{ color: colorScheme.label.primary, opacity: 0.3 }}
      />
    </FormControl>
  );
};

export default EmailInput;
