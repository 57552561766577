import { ApplePlan, PricingApple } from 'models/payment';

export const pricingInfoAppleParser = (rawValue: any): PricingApple | undefined => {
  if (!rawValue) return undefined;
  return {
    preSelectedProductId: rawValue['preSelectedProductId'],
    plans: listPlanParser(rawValue['plans']),
  };
};

const listPlanParser = (listPlanRawValue: any): ApplePlan[] | undefined => {
  if (!listPlanRawValue) return undefined;
  if (Array.isArray(listPlanRawValue) && listPlanRawValue.length) {
    return listPlanRawValue.map((rawValue) => {
      return {
        productId: rawValue['productId'],
        desktopNameLocalizationKey: rawValue['desktopNameLocalizationKey'],
      };
    });
  }
  return undefined;
};
