import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import { ReactElement, useEffect, useState } from 'react';
import EmailBox from 'assets/images/email-box.svg';
import { getAuth, UserInfo } from 'firebase/auth';

import { PROVIDER_ID } from 'models/single-progress/profile';
import SingUpEmailModal from './sing-up-modal';
import ChangeMailModal from './change-mail-modal';
import { useTranslation } from 'react-i18next';

const SigInEmailAccount: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const { typography, colorScheme } = useThemeData();
  const auth = getAuth();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isOpenSigUpModal, setIsOpenSigUpModal] = useState(false);
  const [isOpenChangeMailModal, setIsOpenChangeMailModal] = useState(false);

  useEffect(() => {
    if (auth.currentUser) {
      const providerData = auth.currentUser.providerData.find(
        (val) => val.providerId === PROVIDER_ID.EMAIL_PROVIDER_ID,
      );
      setUserInfo(providerData);
    }
  }, [auth.currentUser]);

  const handleLinkEmail = () => {
    setIsOpenSigUpModal(true);
  };

  const handleChangeEmail = () => {
    setIsOpenChangeMailModal(true);
  };

  return (
    <Box>
      <Flex justify="flex-start" align="center" p="0px 0 10px 16px" borderRadius="10px">
        <Image src={EmailBox} w="24px" h="24px" mr="12px" />
        <Flex justify="space-between" align="center" w="100%">
          <Box>
            <Text {...typography.normal.title[4]} color={colorScheme.label.primary} lineHeight="15.73px" mb="4px">
              {t('common.email')}
            </Text>
            {userInfo?.email && (
              <Text {...typography.normal.callout} color={colorScheme.label.secondary} lineHeight="14.52px">
                {userInfo.email}
              </Text>
            )}
          </Box>
          {userInfo?.providerId ? (
            <Flex
              as="button"
              p="8px"
              mr="16px"
              borderRadius="3px"
              border={`1px solid ${colorScheme.platform.border}`}
              onClick={() => handleChangeEmail()}
            >
              <Text {...typography.emphasized.callout} color={colorScheme.label.primary} lineHeight="14.52px">
                {t('common.change')}
              </Text>
            </Flex>
          ) : (
            <Flex
              as="button"
              p="8px"
              mr="16px"
              borderRadius="3px"
              border={`1px solid ${colorScheme.platform.border}`}
              onClick={() => handleLinkEmail()}
            >
              <Text {...typography.emphasized.callout} color={colorScheme.label.primary} lineHeight="14.52px">
                {t('settings.signinMethodLink')}
              </Text>
            </Flex>
          )}
        </Flex>
      </Flex>
      {isOpenSigUpModal && (
        <SingUpEmailModal
          updateUserInfo={(userInfo) => setUserInfo(userInfo)}
          closeModal={() => setIsOpenSigUpModal(false)}
        />
      )}
      {isOpenChangeMailModal && (
        <ChangeMailModal
          updateUserInfo={(userInfo) => setUserInfo(userInfo)}
          closeModal={() => setIsOpenChangeMailModal(false)}
        />
      )}
    </Box>
  );
};

export default SigInEmailAccount;
