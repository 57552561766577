import { createModel } from '@rematch/core';
import { database } from 'firebase-v9';
import { child, onValue, push, ref, Unsubscribe, update, remove } from 'firebase/database';
import { HabitFolders } from 'models/habit-folders';
import { habitFoldersModelMapper } from 'tools/habit-folders';
import { IHabitFolder } from '__archived__/types/states/habit';

import { RootModel } from '../../root.model';

type HabitFoldersModelState = {
  habitFolders: HabitFolders;
  syncedHabitFoldersSuccess: boolean;
  lastSyncedAction: 'child-added' | 'child-changed' | 'child-removed' | 'get' | 'none';
};

const initialState: HabitFoldersModelState = {
  habitFolders: {},
  syncedHabitFoldersSuccess: false,
  lastSyncedAction: 'none',
};

export const habitFoldersModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    onDataSynced(state, payload: HabitFolders) {
      return {
        ...state,
        habitFolders: payload,
        syncedHabitFoldersSuccess: true,
        lastSyncedAction: 'get',
      };
    },
    setHabitFolders(state, payload: HabitFolders) {
      return {
        ...state,
        habitFolders: payload,
      };
    },
  },
  effects: (dispatch) => ({
    watchOnHabitFoldersValue({
      uid,
      setUnsubscribe,
    }: {
      uid: string;
      setUnsubscribe?: (unsubscribe: Unsubscribe) => void;
    }) {
      const habitFoldersRef = ref(database, `habitFolders/${uid}`);
      const unsubscribe = onValue(habitFoldersRef, (snapshot) => {
        const habitFolders: HabitFolders = {};
        snapshot.forEach((child) => {
          const habitFolderId = child.key;
          if (habitFolderId) {
            const habitFolder = habitFoldersModelMapper({
              key: habitFolderId,
              rawValue: child.val(),
            });
            if (habitFolder) {
              habitFolders[habitFolderId] = habitFolder;
            }
          }
        });
        dispatch.habitFoldersModel.onDataSynced(habitFolders);
      });
      if (setUnsubscribe) setUnsubscribe(unsubscribe);
    },

    updateFolderName(payload: { uid: string | undefined; folderId: string; folderName: string }) {
      const { uid, folderId, folderName } = payload;
      if (uid && folderId && folderName) {
        const habitFolderRef = ref(database, `habitFolders/${uid}/${folderId}`);
        return update(habitFolderRef, { name: folderName });
      }
    },

    updateFolderIconKey(payload: { uid: string | undefined; folderId: string; iconKey: string }) {
      const { uid, folderId, iconKey } = payload;
      if (uid && folderId && iconKey) {
        const habitFolderRef = ref(database, `habitFolders/${uid}/${folderId}`);
        return update(habitFolderRef, { iconKey });
      }
    },

    updateFolderIconColor(payload: { uid: string | undefined; folderId: string; iconColor: string }) {
      const { uid, folderId, iconColor } = payload;
      if (uid && folderId && iconColor) {
        const habitFolderRef = ref(database, `habitFolders/${uid}/${folderId}`);
        return update(habitFolderRef, { color: iconColor });
      }
    },

    addNewFolder(payload: { uid: string | undefined; newFolder: IHabitFolder }) {
      const { uid, newFolder } = payload;
      if (uid) {
        const newFolderKey = push(child(ref(database), `habitFolders/${uid}`)).key;
        const habitFolderRef = ref(database, `habitFolders/${uid}/${newFolderKey}`);
        if (newFolderKey) {
          return update(habitFolderRef, newFolder);
        }
      }
    },

    deleteFolder(payload: { uid: string | undefined; folderId: string }) {
      const { uid, folderId } = payload;
      if (uid && folderId) {
        const habitFolderRef = ref(database, `habitFolders/${uid}/${folderId}`);
        remove(habitFolderRef);
      }
    },

    resetHabitFolder(payload: HabitFolders) {
      dispatch.habitFoldersModel.setHabitFolders({});
    },
  }),
});
