import { Box, Text } from '@chakra-ui/react';
import { Dayjs } from 'dayjs';
import { useThemeData } from 'hooks/useThemeData';
import { SaleCampaign } from 'models/payment';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';
import { _dayjs } from 'tools/extended-dayjs';

const SaleCampaignSection: React.FC = (): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  const [isSale, setIsSale] = useState(false);
  const [isPromoForUser, setIsPromoForUser] = useState(false);
  const [countdown, setCountDown] = useState('');
  const [promo, setPromo] = useState<SaleCampaign>();

  const saleCampaign = useSelector((state: NewRootState) => state.paymentModel.remoteSaleCampaign);
  const userSaleCampaign = useSelector((state: NewRootState) => state.userRemoteConfigModel.remoteSaleCampaign);

  useEffect(() => {
    if (userSaleCampaign) {
      setPromo(userSaleCampaign);
    } else if (saleCampaign) {
      setPromo(saleCampaign);
    }
  }, [saleCampaign, userSaleCampaign]);

  const startTimer = useCallback(() => {
    let endDate: Dayjs = _dayjs(promo?.end);

    setInterval(() => {
      const duration = _dayjs.duration(endDate.diff(_dayjs()));
      const numOfDayLeft = endDate.diff(_dayjs(), 'days');

      const hourLeft = duration.hours();
      const minutesLeft = duration.minutes();
      const secondsLeft = duration.seconds();
      if (numOfDayLeft <= 0 && hourLeft <= 0 && minutesLeft <= 0 && secondsLeft === 0) {
        setIsSale(false);
        setIsPromoForUser(false);
        clearInterval();
      }
      if (numOfDayLeft || hourLeft || minutesLeft || secondsLeft) {
        setCountDown(`${numOfDayLeft}d ${hourLeft}h ${minutesLeft}m ${secondsLeft}s`);
      }
    }, 1000);
  }, [promo?.end]);

  useEffect(() => {
    const startDate = _dayjs(promo?.start);
    const endDate = _dayjs(promo?.end);
    const isBetweenSale = _dayjs().isBetween(startDate, endDate);
    const startDateUser = _dayjs(promo?.start);
    const endDateUser = _dayjs(promo?.end);
    const isBetweenSaleUser = _dayjs().isBetween(startDateUser, endDateUser);
    if (isBetweenSale) {
      setIsSale(true);
      startTimer();
    } else if (isBetweenSaleUser) {
      setIsSale(false);
      setIsPromoForUser(true);
      startTimer();
    } else {
      setIsPromoForUser(false);
      setIsSale(false);
    }
  }, [promo?.end, promo?.start, startTimer]);

  return (
    <>
      {(isSale || isPromoForUser) && (
        <Box display="flex" p="14px" bgColor={promo?.backgroundColorHex} borderTopRightRadius="10px">
          <Box w="212px" mr="16px">
            <Text {...typography.normal.title[4]} color={colorScheme.universal.white} mb="4px">
              {promo?.title}
            </Text>
            <Text {...typography.normal.callout} color={colorScheme.universal.white} opacity="0.5">
              {promo?.subtitle}
            </Text>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <Text
                {...typography.emphasized.caption[1]}
                textTransform="uppercase"
                color={colorScheme.universal.white}
                opacity="0.5"
                textAlign="right"
              >
                valid til
              </Text>
              <Text {...typography.emphasized.callout} color={colorScheme.universal.white} whiteSpace="pre">
                {countdown}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default SaleCampaignSection;
