import { _dayjs } from '__archived__/constants/app';
import { useEffect, useState } from 'react';

interface INumberOverdue {
  isOverdue: boolean;
}

const remindFormat = `YYYY-MM-DDTHH:mm`;

export const useCheckOverdue = (remindAt?: string, status?: number): INumberOverdue => {
  const [isOverdue, setIsOverdue] = useState(false);
  const [timeNow, setTimeNow] = useState(_dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeNow(_dayjs());
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [setTimeNow]);

  useEffect(() => {
    if (timeNow.diff(_dayjs(remindAt).format(remindFormat)) > 0 && !status) {
      setIsOverdue(true);
    } else {
      setIsOverdue(false);
    }
  }, [remindAt, status, timeNow]);

  return { isOverdue };
};
