type IKeyBoard = {
  osx?: string;
  windows?: string;
  linux?: string;
  combine?: string;
  common?: string;
};

type ISidebar = {
  habit: IKeyBoard;
  folder: IKeyBoard;
  timeOfDay: IKeyBoard;
};

type IJournal = {
  activeSearchBox: IKeyBoard;
  addNewHabit: IKeyBoard;
  addNewBadHabit: IKeyBoard;
};

type IModal = {
  selectPrimaryAction: IKeyBoard;
  selectCancelAction: IKeyBoard;
};

type IMenu = {
  selectSubItem: IKeyBoard;
};

type IKeyMap = {
  sidebar: ISidebar;
  journal: IJournal;
  modal: IModal;
  menu: IMenu;
};
const keyMap: IKeyMap = {
  sidebar: {
    habit: {
      osx: 'command+1',
      windows: 'ctrl+1',
      combine: 'command+1, ctrl+1',
    },
    folder: {
      // keyMap + number 1->9 same stt folder
      osx: 'command+shift+',
      windows: 'ctrl+shift+',
    },
    timeOfDay: {
      osx: 'command+2',
      windows: 'ctrl+2',
      combine: 'command+2, ctrl+2',
    },
  },
  journal: {
    activeSearchBox: {
      osx: 'command+f',
      windows: 'ctrl+f',
      combine: 'command+f, ctrl+f',
    },
    addNewHabit: {
      osx: 'command+N',
      windows: 'ctrl+N ',
      combine: 'command+N, ctrl+N',
    },
    addNewBadHabit: {
      osx: 'command+shift+n',
      windows: 'ctrl+shift+n',
      combine: 'command+shift+n, ctrl+shift+n',
    },
  },
  modal: {
    selectPrimaryAction: {
      common: 'Enter',
    },
    selectCancelAction: {
      common: 'Esc ',
    },
  },
  menu: {
    selectSubItem: {
      osx: 'command+k',
      windows: 'ctrl+k',
      combine: 'command+k, ctrl+k',
    },
  },
};
export default keyMap;
