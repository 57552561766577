import { IconButton } from '@chakra-ui/button';
import { Box, Grid, GridItem, Text } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/react';
import { Dayjs } from 'dayjs';
import { useThemeData } from 'hooks/useThemeData';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { _dayjs } from 'tools/extended-dayjs';
import Icon from '../Icon';
import { NUMBER_OF_WEEK_DAYS } from './constants';
import DateCell from './date-cell.component';
import DateItem from './date-item.component';
import { BeforeOrAfter } from './date-picker.type';
import DayUtils from './day-utils';
import { useStartWith } from './use-start-with';
import { useWeekDays } from './use-week-days';

type DatePickerType = 'journal' | 'create' | 'log-value';
type ButtonType = 'decrease' | 'increase';
interface Props {
  type: DatePickerType;
  startWith?: number;
  onChange?: (value: Dayjs) => void;
  defaultSelectedDate?: Dayjs;
  disableDays?: BeforeOrAfter;
}

export default function DatePicker({ startWith = 1, onChange, defaultSelectedDate, disableDays, type }: Props) {
  const { i18n } = useTranslation();
  const [dayUtils, setDayUtils] = useState<DayUtils>(new DayUtils(_dayjs().startOf('day')));
  const [beforeMonthCells, setBeforeMonthCells] = useState<any>([]);
  const [afterMonthCells, setAfterMonthCells] = useState<any>([]);
  const [dayInMonthCells, setDayInMonthCells] = useState<any>([]);
  const [resultCells, setResultCells] = useState<any>([]);
  const [selectedDated, setSelectedDated] = useState(_dayjs());

  const { typography, colorScheme } = useThemeData();
  const { startWithValue } = useStartWith(startWith);
  const weekDaysColor = useColorModeValue('rgb(0,0,0,0.3)', colorScheme.label.secondary);

  const defaultFirstWeekDayOfMonth = dayUtils.firstWeekDayOfMonth;

  const defaultLastWeekDayOfMonth = dayUtils.lastWeekDayOfMonth;

  const daysInMonth = dayUtils.daysInMonth;

  const { displayWeekDays, firstWeekDayOfMonth, lastWeekDayOfMonth } = useWeekDays(
    startWithValue,
    defaultFirstWeekDayOfMonth,
    defaultLastWeekDayOfMonth,
    i18n.language,
  );

  const dateCellHoveredColor = useColorModeValue(
    colorScheme.background.primary[2],
    colorScheme.platform.control.background,
  );

  useEffect(() => {
    if (defaultSelectedDate) {
      setSelectedDated(defaultSelectedDate);
      setDayUtils(new DayUtils(defaultSelectedDate.startOf('day')));
    } else {
      setSelectedDated(_dayjs());
      setDayUtils(new DayUtils(_dayjs().startOf('day')));
    }
  }, [defaultSelectedDate]);

  const selectDate = useCallback(
    (date: number, month: number, year: number) => {
      const newDayObj = _dayjs().set({ year, month, date });
      setSelectedDated(newDayObj);
      if (onChange) {
        onChange(newDayObj);
      }
    },
    [onChange],
  );

  useEffect(() => {
    const beforeMonthCells = [];
    const previousDayUtils = new DayUtils(_dayjs(dayUtils.date).subtract(1, 'month').startOf('day'));

    if (firstWeekDayOfMonth < 0) {
      for (let i = 0; i < NUMBER_OF_WEEK_DAYS; i++) {
        const date = previousDayUtils.daysInMonth + i - NUMBER_OF_WEEK_DAYS + 1;
        const month = dayUtils.date.month() - 1;
        const year = dayUtils.date.year();
        const dayInPreviousMonth = _dayjs().set({ date, month, year });
        const isSelected =
          selectedDated.date() === date && selectedDated.month() === month && selectedDated.year() === year;
        if (dayInPreviousMonth.isBetween(disableDays?.before, disableDays?.after)) {
          if (type === 'journal' || type === 'log-value') {
            beforeMonthCells.push(
              <Box key={`before-month=${i}`} display="flex" justifyContent="center">
                <DateItem date={date} isSelected={isSelected} selectDate={() => selectDate(date, month, year)} />
              </Box>,
            );
          }
        } else {
          beforeMonthCells.push(
            <DateCell key={`before-month=${i}`} colStart={i + 1} cursor="not-allowed">
              <Text color={weekDaysColor} {...typography.normal.callout}>
                {previousDayUtils.daysInMonth + i - NUMBER_OF_WEEK_DAYS + 1}
              </Text>
            </DateCell>,
          );
        }
      }
    }
    for (let i = 0; i < firstWeekDayOfMonth; i++) {
      const date = previousDayUtils.daysInMonth + i - firstWeekDayOfMonth + 1;
      const month = dayUtils.date.month() - 1;
      const year = dayUtils.date.year();
      const isSelected =
        selectedDated.date() === date && selectedDated.month() === month && selectedDated.year() === year;

      if (dayUtils.date.date(1).isBetween(disableDays?.before, disableDays?.after)) {
        if (type === 'log-value') {
          if (dayUtils.date.date(1).add(-1, 'day').isBetween(disableDays?.before, disableDays?.after)) {
            beforeMonthCells.push(
              <Box key={`before-month=${i}`} display="flex" justifyContent="center">
                <DateItem date={date} isSelected={isSelected} selectDate={() => selectDate(date, month, year)} />
              </Box>,
            );
          } else {
            beforeMonthCells.push(
              <DateCell key={`before-month=${i}`} colStart={i + 1} cursor="not-allowed">
                <Text color={weekDaysColor} {...typography.normal.callout}>
                  {previousDayUtils.daysInMonth + i - firstWeekDayOfMonth + 1}
                </Text>
              </DateCell>,
            );
          }
        }

        if (type === 'journal') {
          beforeMonthCells.push(
            <Box key={`before-month=${i}`} display="flex" justifyContent="center">
              <DateItem date={date} isSelected={isSelected} selectDate={() => selectDate(date, month, year)} />
            </Box>,
          );
        }
      } else {
        beforeMonthCells.push(
          <DateCell key={`before-month=${i}`} colStart={i + 1} cursor="not-allowed">
            <Text color={weekDaysColor} {...typography.normal.callout}>
              {previousDayUtils.daysInMonth + i - firstWeekDayOfMonth + 1}
            </Text>
          </DateCell>,
        );
      }
    }
    setBeforeMonthCells(beforeMonthCells);
  }, [
    colorScheme.accent.primary,
    colorScheme.label.primary,
    colorScheme.universal.white,
    dateCellHoveredColor,
    dayUtils.date,
    disableDays?.after,
    disableDays?.before,
    firstWeekDayOfMonth,
    selectDate,
    selectedDated,
    type,
    typography.emphasized.callout,
    typography.normal.callout,
    weekDaysColor,
  ]);

  useEffect(() => {
    const afterMonthCells = [];

    if (lastWeekDayOfMonth > -1) {
      let nextDay = 1;

      for (let i = lastWeekDayOfMonth; i < NUMBER_OF_WEEK_DAYS; i++) {
        const date = nextDay;
        const month = dayUtils.date.month() + 1;
        let year = dayUtils.date.year();
        if (_dayjs().isAfter(dayUtils.date, 'year')) {
          year += 1;
        }
        const dayInMonthAfter = _dayjs().set({ date, month, year });
        const isSelected =
          selectedDated.date() === date && selectedDated.month() === month && selectedDated.year() === year;
        if (dayInMonthAfter.isBetween(disableDays?.before, disableDays?.after)) {
          if (type === 'journal' || type === 'log-value') {
            afterMonthCells.push(
              <Box key={`after-month=${i}`} display="flex" justifyContent="center">
                <DateItem date={date} isSelected={isSelected} selectDate={() => selectDate(date, month, year)} />
              </Box>,
            );
          }
        } else {
          afterMonthCells.push(
            <DateCell key={`after-month=${i}`} colStart={i + 2} cursor="not-allowed">
              <Text color={weekDaysColor} {...typography.normal.callout}>
                {nextDay}
              </Text>
            </DateCell>,
          );
        }
        nextDay += 1;
      }
    }
    setAfterMonthCells(afterMonthCells);
  }, [
    dayUtils.date,
    disableDays?.after,
    disableDays?.before,
    lastWeekDayOfMonth,
    selectDate,
    selectedDated,
    type,
    typography.normal.callout,
    weekDaysColor,
  ]);

  useEffect(() => {
    const dayInMonthCells = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const date = i;
      const month = dayUtils.date.month();
      const year = dayUtils.date.year();

      const isSelected =
        selectedDated.date() === date && selectedDated.month() === month && selectedDated.year() === year;

      let isDisabled = false;
      const newDayObj = _dayjs().set({ year, month, date }).startOf('day');
      if (disableDays) {
        if (disableDays.before && disableDays.after) {
          const before = disableDays.before;
          const after = disableDays.after;
          isDisabled = before.isAfter(newDayObj) || after.isBefore(newDayObj);
        } else if (disableDays.before && !disableDays.after) {
          const before = disableDays.before;
          isDisabled = newDayObj.isBefore(before);
        } else if (!disableDays.before && disableDays.after) {
          const after = disableDays.after;
          isDisabled = newDayObj.isAfter(after);
        } else {
          isDisabled = false;
        }
      }

      dayInMonthCells.push(
        <DateCell key={`in-month-${date}`} colStart={date === 1 ? firstWeekDayOfMonth + 1 : 0} cursor="default">
          <DateItem
            isSelected={isSelected}
            date={date}
            selectDate={() => selectDate(date, month, year)}
            isDisable={isDisabled}
          />
        </DateCell>,
      );
    }
    setDayInMonthCells(dayInMonthCells);
  }, [
    colorScheme.accent.primary,
    colorScheme.label.primary,
    colorScheme.universal.white,
    dateCellHoveredColor,
    dayUtils.date,
    daysInMonth,
    disableDays,
    firstWeekDayOfMonth,
    selectDate,
    selectedDated,
    typography.emphasized.callout,
    weekDaysColor,
  ]);

  useEffect(() => {
    const renderCells = [...beforeMonthCells, ...dayInMonthCells, ...afterMonthCells];
    const resultCells: any[] = [];

    let rows = [];

    for (let i = 0; i <= renderCells.length; i++) {
      if (i % 7 === 0) {
        resultCells.push(rows);
        rows = [];
        rows.push(renderCells[i]);
      } else {
        rows.push(renderCells[i]);
      }
    }

    setResultCells(renderCells);
  }, [beforeMonthCells, dayInMonthCells, afterMonthCells]);

  const increaseMonth = () => {
    setDayUtils(new DayUtils(_dayjs(dayUtils.date).add(1, 'month').startOf('day')));
  };

  const decreaseMonth = () => {
    setDayUtils(new DayUtils(_dayjs(dayUtils.date).subtract(1, 'month').startOf('day')));
  };

  const isDisable = (buttonType: ButtonType) => {
    if (type === 'journal' || type === 'log-value') {
      switch (buttonType) {
        case 'decrease':
          return !_dayjs(dayUtils.date).date(1).subtract(1, 'month').isSameOrAfter(disableDays?.before, 'month');
        case 'increase':
          return !_dayjs(dayUtils.date).add(1, 'month').isSameOrBefore(_dayjs(), 'month');
        default:
          break;
      }
    } else {
      return false;
    }
  };

  return (
    <Box position="relative">
      <IconButton
        aria-label="prev-button"
        icon={<Icon name="ic_date_picker_previous" folderName="journal" fill={colorScheme.label.primary} />}
        cursor="default"
        _focus={{ outline: 'none' }}
        onClick={decreaseMonth}
        size="xs"
        isRound
        variant="ghost"
        position="absolute"
        top="6px"
        right="25px"
        disabled={isDisable('decrease')}
      />
      <IconButton
        aria-label="prev-button"
        icon={<Icon name="ic_date_picker_next" folderName="journal" fill={colorScheme.label.primary} />}
        onClick={increaseMonth}
        cursor="default"
        _focus={{ outline: 'none' }}
        size="xs"
        isRound
        variant="ghost"
        position="absolute"
        top="6px"
        right="0"
        disabled={isDisable('increase')}
      />
      <Text {...typography.emphasized.body} color={colorScheme.label.primary} padding="12px">
        {dayUtils.date.locale(i18n.language).format('MMMM YYYY')}
      </Text>
      <Grid gridTemplateColumns="repeat(7, 1fr)">
        {displayWeekDays.map((weekDay, i) => (
          <GridItem
            key={`week-day-${weekDay}-${i}`}
            {...typography.normal.caption[2]}
            color={weekDaysColor}
            width="31.29px"
            height="28px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {weekDay}
          </GridItem>
        ))}
      </Grid>
      <Grid gridTemplateColumns="repeat(7, 1fr)">{resultCells}</Grid>
    </Box>
  );
}
