import { useColorModeValue } from '@chakra-ui/color-mode';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  updateContextMenuPropsActionSingleProgress,
  updateDetailHabitAction,
} from '__archived__/store/ducks/app/app.action';
import { UpdateDetailHabit } from '__archived__/store/ducks/app/app.type';
import { darkMode, lightMode } from '__archived__/utils/colorPreferences';

type Return = {
  boxColor: string;
  borderColor: string;
  updateDetailHabit: (habitId: string) => UpdateDetailHabit;
  numberOverdue: number;
  handleContextMenuOnClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  handleLongPressUpMobile: (e: React.TouchEvent<HTMLDivElement>) => void;
  handleLongPressDownMobile: (e: React.TouchEvent<HTMLDivElement>) => boolean;
};

export const useSingleProgress = (habitId: string): Return => {
  const [pressTimer, setPressTimer] = useState<number>(0);

  const boxColor = useColorModeValue(lightMode.BACKGROUND, darkMode.INPUT_BG_COLOR);
  const borderColor = useColorModeValue(lightMode.TRACK_COLOR, darkMode.BORDER_BOX_COLOR);
  const numberOverdue = 0;

  const dispatch = useDispatch();
  const updateContextMenuProps = useCallback(
    (habitId: string, xPos: number, yPos: number, showMenu: boolean) =>
      dispatch(updateContextMenuPropsActionSingleProgress(habitId, xPos, yPos, showMenu)),
    [dispatch],
  );
  const updateDetailHabit = useCallback((habitId: string) => dispatch(updateDetailHabitAction(habitId)), [dispatch]);

  const handleContextMenuOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      updateContextMenuProps(habitId, e.pageX, e.pageY, true);
    },
    [updateContextMenuProps, habitId],
  );

  const handleContextMenuOnTouch = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (e.cancelable) {
        e.preventDefault();
      }

      const touches = e.changedTouches;

      for (let i = 0; i < touches.length; i++) {
        const touch = touches[i];
        updateContextMenuProps(habitId, touch.pageX, touch.pageY, true);
      }
    },
    [habitId, updateContextMenuProps],
  );

  // const handleClick = useCallback(() => {
  //   showMenu && updateContextMenuProps('', 0, 0, false);
  // }, [showMenu, updateContextMenuProps]);

  const handleLongPressUpMobile = () => {
    clearTimeout(pressTimer);
    return false;
  };

  const handleLongPressDownMobile = (e: React.TouchEvent<HTMLDivElement>) => {
    setPressTimer(window.setTimeout(() => handleContextMenuOnTouch(e), 500));
    return false;
  };

  // useEffect(() => {
  //   document.addEventListener('click', handleClick);
  //   return () => {
  //     document.removeEventListener('click', handleClick);
  //   };
  // });

  return {
    boxColor,
    borderColor,
    numberOverdue,
    updateDetailHabit,
    handleContextMenuOnClick,
    handleLongPressUpMobile,
    handleLongPressDownMobile,
  };
};
