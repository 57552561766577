import keys from 'lodash/keys';
import memoizeOne from 'memoize-one';
import { Habit, HabitRemind } from 'models/habits';
const MAX_TIME = 1500; // max time of remind

const numberOfMinuteConvert = memoizeOne((times: string) => {
  const arr = times.split(':');
  const hour = Number(arr[0]);
  const minute = Number(arr[1]);
  return hour * 60 + minute;
});

const numberOfMinute = memoizeOne((remind: HabitRemind | null | undefined): number => {
  let timestampsInDay = MAX_TIME;
  if (remind) {
    const { timeTriggers } = remind;
    if (timeTriggers) {
      if (keys(timeTriggers).length > 1) {
        const times = keys(timeTriggers).sort((time1, time2) => {
          const num1 = timeTriggers[time1] ? numberOfMinuteConvert(time1) : MAX_TIME;
          const num2 = timeTriggers[time2] ? numberOfMinuteConvert(time2) : MAX_TIME;
          return num1 - num2;
        });
        timestampsInDay = timeTriggers[times[0]] ? numberOfMinuteConvert(times[0]) : MAX_TIME;
      } else {
        timestampsInDay = timeTriggers[keys(timeTriggers)[0]] ? numberOfMinuteConvert(keys(timeTriggers)[0]) : MAX_TIME;
      }
    }
  }

  return timestampsInDay;
});

const _sort = memoizeOne(
  ({ remindA, remindB }: { remindA: HabitRemind | null | undefined; remindB: HabitRemind | null | undefined }) => {
    const num1 = numberOfMinute(remindA);
    const num2 = numberOfMinute(remindB);
    if (num1 === num2) {
      return 1;
    }
    return num1 - num2;
  },
);

export const sortHabitByRemindTime = memoizeOne(({ habits }: { habits: Habit[] }): Promise<Habit[]> => {
  return new Promise((resolve, reject) => {
    try {
      resolve(habits.sort((a, b) => _sort({ remindA: a.remind, remindB: b.remind })));
    } catch (e) {
      reject(e);
    }
  });
});
