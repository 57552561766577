import memoizeOne from 'memoize-one';
import { Habit } from 'models/habits';

export const sortHabitsByAreaPriority = memoizeOne(({ habits }: { habits: Habit[] }): Promise<Habit[]> => {
  return new Promise((resolve, reject) => {
    try {
      resolve(
        habits.sort((a, b) => sortHabitByAreaPriority({ priorityA: a.priorityByArea, priorityB: b.priorityByArea })),
      );
    } catch (e) {
      reject(e);
    }
  });
});

export const sortHabitByAreaPriority = ({
  priorityA,
  priorityB,
}: {
  priorityA: string | null | undefined;
  priorityB: string | null | undefined;
}) => {
  if (priorityA !== undefined && priorityA !== null && priorityB !== undefined && priorityB !== null) {
    return Number(priorityA < priorityB) - Number(priorityA > priorityB);
  } else if ((priorityA === undefined || priorityA === null) && priorityB !== undefined && priorityB !== null) {
    return -1;
  } else if (priorityA !== undefined && priorityA !== null && (priorityB === undefined || priorityB === null)) {
    return 1;
  }
  return 1;
};
