import dayjs from 'dayjs';
import { TimeOfDay } from 'models/common';
import { getTimeOfDayTextByString } from '__archived__/utils/getTimeOfDay';

const titleOther: { [key: string]: string } = {
  'progress': 'Overall Progress',
  'upgrade': 'Payment Info',
  'email-preferences': 'Email Preferences',
  'signin': 'Sign In',
  'signin-desktop': 'Sign In',
  'desktop-entry': 'Sign In',
  'signup': 'Sign Up',
  'reset-password': 'Reset Password',
};

const pathNameCondition = (pathName: string, name: string) => {
  return pathName.includes(name);
};

const getAppTitle = (
  pathName: string,
  timeOfDay?: TimeOfDay,
  folderName?: string,
  habitName?: string,
  chooseDate?: string,
): string => {
  const isJournal = pathNameCondition(pathName, 'journal');
  const isSetting = pathNameCondition(pathName, 'profile/setting');
  const isManage = pathNameCondition(pathName, 'habits/manage');
  let title = '';
  let day = dayjs(chooseDate).format('MMMM D');
  if (dayjs(chooseDate).isToday()) day = 'Today';
  if (dayjs(chooseDate).isTomorrow()) day = 'Tomorrow';
  if (dayjs(chooseDate).isYesterday()) day = 'Yesterday';

  if (isJournal) {
    if (!!folderName && !!!habitName) title = folderName + ', ' + day;
    else if (pathName === '/journal/all-habits' && !!!habitName) title = `All Habits, ${day}`;
    else if (habitName) title = habitName;
    else
      title =
        getTimeOfDayTextByString(timeOfDay?.toString() || '')?.charAt(0).toUpperCase() +
        getTimeOfDayTextByString(timeOfDay?.toString() || '')?.slice(1) +
        ', ' +
        day;
    return title;
  }
  if (habitName) return 'Log History';
  if (isSetting) return 'App Setting';
  if (isManage) return 'Mange Habits';

  const routerName = pathName.slice(1, pathName.length);
  return titleOther[routerName] || '';
};

export default getAppTitle;
