import { Box, Text, Button, useMediaQuery } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { getAuth } from 'firebase/auth';

import logoHabitify from 'assets/images/new-habitify-logo.png';
import logoIfttt from 'assets/images/logo-ifttt.png';
import logoZapier from 'assets/images/logo-zapier.png';

interface Props {
  queries: { clientId: string; redirectUrl: string; state: string };
}

const clientIds: { [key: string]: { logo: string; text: string } } = {
  'habitify-ifttt': {
    logo: logoIfttt,
    text: 'IFTTT',
  },
  'habitify-zapier': {
    logo: logoZapier,
    text: 'Zapier',
  },
};

const IFTTTAndZapierAuthentication: React.FC<Props> = ({ queries }: Props): ReactElement => {
  const { clientId, redirectUrl, state } = queries;
  const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

  const handleAccept = async () => {
    const token = await getAuth().currentUser?.getIdToken();
    token ? (window.location.href = `${redirectUrl}?code=${token}&state=${state}`) : handleCancel();
  };

  const handleCancel = () => {
    window.location.href = `${redirectUrl}?error=access_denied`;
  };

  return (
    <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center" background="#E5E5E5">
      <Box
        width={isLargerThan600 ? '417px' : '90vw'}
        margin="0 auto"
        textAlign="center"
        background="white"
        border="1px solid #E5E4E5"
        borderRadius="12px"
        padding="20px"
      >
        <Box display="flex" justifyContent="center" alignItems="center" padding="24px 0">
          <img src={logoHabitify} width="80px" height="80px" alt="logo habitify" />
          <Box
            background="#7B7C7C"
            width="33.33px"
            height="33.33px"
            fontWeight="200"
            fontSize="35px"
            textAlign="center"
            lineHeight="29px"
            borderRadius="50%"
            color="white"
            m="0 23.33px"
          >
            +
          </Box>
          <img
            src={clientIds[clientId].logo}
            width="80px"
            height="80px"
            style={{ borderRadius: '22px' }}
            alt="logo authorize"
          />
        </Box>
        <Text fontSize="22px" fontWeight="600" lineHeight="27px" color="black">
          {`Authorize ${clientIds[clientId].text}`}
        </Text>
        <Text fontSize="15px" mt="6px" color="#7B7C7C">
          {`Do you want to continue using Habitify with ${clientIds[clientId].text}?`}
        </Text>
        <Box mt="36px">
          <Button
            onClick={handleCancel}
            width={isLargerThan600 ? '140px' : '106px'}
            height={isLargerThan600 ? '50px' : '40px'}
            lineHeight={isLargerThan600 ? '19.36px' : '1.2'}
            fontSize="1rem"
            color="#7B7C7C"
            fontWeight="500"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAccept}
            width={isLargerThan600 ? '140px' : '106px'}
            height={isLargerThan600 ? '50px' : '40px'}
            lineHeight={isLargerThan600 ? '19.36px' : '1.2'}
            background="#2A67F4"
            fontSize="1rem"
            fontWeight="500"
            _hover={{ background: '#2A67F4' }}
          >
            Authorize
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default IFTTTAndZapierAuthentication;
