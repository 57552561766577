import { database } from 'firebase-v9';
import { ref, update } from 'firebase/database';
import { _dayjs } from 'tools/extended-dayjs';
import { deviceUUID } from './device-uuid';

export const updateUserEndpoint = ({
  uid,
  pushToken,
  deviceType,
}: {
  uid: string;
  pushToken: string;
  deviceType: 'Web' | 'Desktop';
}) => {
  let deviceId = deviceUUID();
  const timeZone = _dayjs.tz.guess();

  if (deviceType === 'Desktop') {
    deviceId = `desktop-${deviceUUID()}`;
  }

  const endPoints = {
    [deviceId]: {
      deviceType: 'Web',
      timeZone,
      pushToken,
    },
  };

  const userEnpointRef = ref(database, `users/${uid}/endPoints`);

  update(userEnpointRef, endPoints);
};
