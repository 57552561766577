import { Box, BoxProps } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement, ReactNode } from 'react';
interface Props extends BoxProps {
  children: ReactNode;
  selected: string;
  value: string;
}
const RadioButton: React.FC<Props> = ({ children, selected, value, ...rest }: Props): ReactElement => {
  const { colorScheme } = useThemeData();
  return (
    <Box display="flex" alignItems="center" {...rest}>
      <Box
        w="16px"
        h="16px"
        border={
          value === selected ? `1px solid ${colorScheme.accent.primary}` : `1px solid ${colorScheme.other.border}`
        }
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mr="8px"
      >
        <Box
          w="10px"
          h="10px"
          bgColor={value === selected ? colorScheme.accent.primary : 'unset'}
          borderRadius="50%"
        ></Box>
      </Box>
      {children}
    </Box>
  );
};
export default RadioButton;
