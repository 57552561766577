import { get, ref, runTransaction } from '@firebase/database';
import { createModel } from '@rematch/core';
import { database } from 'firebase-v9';
import { DataSnapshot } from 'firebase/database';
import update from 'immutability-helper';
import { HabitLog, HabitLogs } from 'models/habit-logs';
import { DateRange } from 'models/off-mode';
import { _dayjs } from 'tools/extended-dayjs';
import { habitLogsMapper } from 'tools/habit-logs';
import { calculateHabitProgress } from 'tools/habit-progress';
import { RootModel } from '../../root.model';

type HabitLogsState = {
  habitLogs: HabitLogs;
  syncedHabitLogsSuccess: boolean;
  lastSyncedAction: 'child-added' | 'child-changed' | 'child-removed' | 'get' | 'none';
};

const initialState: HabitLogsState = {
  habitLogs: {},
  syncedHabitLogsSuccess: false,
  lastSyncedAction: 'none',
};

export const habitLogsModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    onDataSynced(state, payload: HabitLogs) {
      return {
        ...state,
        habitLogs: payload,
        syncedHabitLogsSuccess: true,
        lastSyncedAction: 'get',
      };
    },
    onAddedChildSynced(state, { habitId, addedHabitLogs }: { habitId: string; addedHabitLogs: HabitLog[] }) {
      return update(state, {
        habitLogs: {
          [habitId]: { $set: addedHabitLogs },
        },
      });
    },
    onChangedChildSynced(state, { habitId, changedHabitLogs }: { habitId: string; changedHabitLogs: HabitLog[] }) {
      return update(state, {
        habitLogs: {
          [habitId]: { $set: changedHabitLogs },
        },
      });
    },
    onRemovedChildSynced(state, habitId: string) {
      return update(state, {
        habitLogs: {
          $unset: [habitId],
        },
      });
    },
    resetState() {
      return initialState;
    },
  },
  effects: (dispatch) => ({
    getAllHabitLogs({ uid }: { uid: string }) {
      const habitLogsRef = ref(database, `habitLogs/${uid}`);
      const habitLogs: HabitLogs = {};
      get(habitLogsRef).then((snapshot) => {
        snapshot.forEach((childSnapshot) => {
          const habitId = childSnapshot.key;
          if (habitId) {
            const habitLogDict = parseHabitLogs(habitId, childSnapshot.val());
            if (habitLogDict) {
              habitLogs[habitId] = habitLogDict;
            }
          }
        });
        this.onDataSynced(habitLogs);
      });
    },
    async watchOnChildAdded(
      {
        snapshot,
        chooseDate,
        offModeDateRanges,
      }: { snapshot: DataSnapshot; chooseDate: string; offModeDateRanges: DateRange[] },
      currentState,
    ) {
      const habitId = snapshot.key;
      if (habitId) {
        const habitLogDict = parseHabitLogs(habitId, snapshot.val());
        const currentHabitLog = currentState.habitLogsModel.habitLogs;
        const isExisted = habitId in currentHabitLog;
        if (!isExisted && habitLogDict) {
          this.onAddedChildSynced({
            habitId,
            addedHabitLogs: habitLogDict,
          });
          const habit = currentState.habitsModel.habits[habitId];
          const firstDayOfWeek = currentState.appSettingModel.preferences?.firstWeekDay;
          if (firstDayOfWeek) {
            const habitProgress = await calculateHabitProgress({
              habit,
              habitLogs: habitLogDict,
              date: _dayjs(chooseDate),
              firstDayOfWeek,
              dateRangesOffMode: offModeDateRanges,
            });
            dispatch.habitProgressModel.updateHabitProgress({
              habitId,
              habitProgress,
            });
          }
        }
      }
    },
    async watchOnChildChanged(
      {
        snapshot,
        chooseDate,
        offModeDateRanges,
      }: { snapshot: DataSnapshot; chooseDate: string; offModeDateRanges: DateRange[] },
      currentState,
    ) {
      const habitId = snapshot.key;
      if (habitId) {
        const habitLogDict = parseHabitLogs(habitId, snapshot.val());
        if (habitLogDict) {
          this.onChangedChildSynced({
            habitId,
            changedHabitLogs: habitLogDict,
          });
          const habit = currentState.habitsModel.habits[habitId];
          const firstDayOfWeek = currentState.appSettingModel.preferences?.firstWeekDay;
          if (firstDayOfWeek) {
            const habitProgress = await calculateHabitProgress({
              habit,
              habitLogs: habitLogDict,
              date: _dayjs(chooseDate),
              firstDayOfWeek,
              dateRangesOffMode: offModeDateRanges,
            });
            dispatch.habitProgressModel.updateHabitProgress({
              habitId,
              habitProgress,
            });
          }
        }
      }
    },
    async watchOnChildRemoved(
      {
        snapshot,
        chooseDate,
        offModeDateRanges,
      }: { snapshot: DataSnapshot; chooseDate: string; offModeDateRanges: DateRange[] },
      currentState,
    ) {
      const habitId = snapshot.key;
      if (habitId) {
        this.onRemovedChildSynced(habitId);
        const habit = currentState.habitsModel.habits[habitId];
        const firstDayOfWeek = currentState.appSettingModel.preferences?.firstWeekDay;
        if (firstDayOfWeek) {
          const habitProgress = await calculateHabitProgress({
            habit,
            habitLogs: [],
            date: _dayjs(chooseDate),
            firstDayOfWeek,
            dateRangesOffMode: offModeDateRanges,
          });
          dispatch.habitProgressModel.updateHabitProgress({
            habitId,
            habitProgress,
          });
        }
      }
    },

    habitLogsDelete({ uid, habitId, logIds }: { uid: string | undefined; habitId: string; logIds: string[] }) {
      if (uid && habitId && logIds?.length) {
        const habitLogs: { [key: string]: null } = {};
        logIds.map((item) => (habitLogs[item] = null));
        const logsRef = ref(database, `habitLogs/${uid}/${habitId}`);
        runTransaction(logsRef, (logs) => {
          if (logs) {
            return {
              ...logs,
              ...habitLogs,
            };
          } else return;
        });
      }
    },
  }),
});

function parseHabitLogs(habitId: string, rawValue: any): HabitLog[] | undefined {
  return habitLogsMapper({ habitId, rawValue });
}
