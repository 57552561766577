import { createModel } from '@rematch/core';
import { auth } from 'firebase-v9';
import { signInWithCustomToken } from 'firebase/auth';
import { RootModel } from '../../root.model';
import { authenticationMethodReducers, initialState } from './authentication.type';

export const appleAuthenticationModel = createModel<RootModel>()({
  state: initialState,
  reducers: authenticationMethodReducers,
  effects: (dispatch) => ({
    signInWithApple(customToken: string) {
      return signInWithCustomToken(auth, customToken);
    },
  }),
});
