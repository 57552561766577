/* eslint-disable prefer-rest-params */
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import App from 'App';
import 'localization/i18n';
import React from 'react';
import 'react-day-picker/lib/style.css';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as rudderanalytics from 'rudder-sdk-js';
import { newStore } from 'store';
import { chakraTheme } from 'theme';
import ThemeProvider from 'theme/theme.provider';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './styles/main.css';

declare global {
  interface Window {
    ElectronBridge: {
      startNotificationService: (senderId: string) => void;
      getToken: (callback: (pushToken: string) => void) => void;
      notifyPushMessage: (callback: (habitId: string) => void) => void;
      checkCanMakePayments: (callback: (canMakePayments: boolean) => void) => void;
      getProducts: (productIds: string[], callback: (products: any) => void) => void;
      onTransactionUpdate: (callback: (state: any) => void) => void;
      purchase: (productIdentifier: string, currencyCode: string, price: number) => void;
      openAuthenticationPageInExternals: () => void;
      onAuthenticationUpdate: (callback: (credential: string) => void) => void;
      onDoubleClickTitleBar: () => void;
      signInWithAppleOnNativeWindow: () => void;
      onAppleAuthenticationUpdate: (onSuccess: (data: any) => void, onError: (err: any) => void) => void;
    };
  }
}

rudderanalytics.load(process.env.REACT_APP_RUDDER_STACK_KEY, process.env.REACT_APP_RUDDER_STACK_PLAN_URL);

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={chakraTheme}>
        <ColorModeScript initialColorMode={chakraTheme.config.initialColorMode} key="chakra-ui-no-flash" />
        <Provider store={newStore}>
          <ThemeProvider>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </Provider>
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
