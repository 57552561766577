import { get, onChildAdded, onChildChanged, ref, remove } from '@firebase/database';
import { createModel } from '@rematch/core';
import dayjs from 'dayjs';
import { database } from 'firebase-v9';
import { onChildRemoved } from 'firebase/database';
import { RootModel } from '../../root.model';
import { InAppMessage } from './../../../models/in-app-message/index';

type InAppMessageState = {
  inAppMessages: InAppMessage[];
  habitId: string;
  isOpenLogValue: boolean;
  isOpenCreateGoodHabit?: boolean;
  isOpenCreateBadHabit?: boolean;
};

const initialState: InAppMessageState = {
  inAppMessages: [],
  habitId: '',
  isOpenLogValue: false,
};

export const inAppMessageModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    initInAppMessage(state, payload: InAppMessage[]) {
      return {
        ...state,
        inAppMessages: payload,
      };
    },

    enqueueMessage(state, payload: InAppMessage) {
      return {
        ...state,
        inAppMessages: [...state.inAppMessages, payload],
      };
    },

    updateMessage(state, payload: InAppMessage) {
      const message = state.inAppMessages;
      const index = message.findIndex((val) => val.key === payload.key);
      message[index] = payload;
      return {
        ...state,
        inAppMessages: [...message],
      };
    },

    dequeueMessage(state, payload: InAppMessage) {
      const message = state.inAppMessages.filter((val) => val.key !== payload.key);
      return {
        ...state,
        inAppMessages: message,
      };
    },

    setHabitId(state, payload: string) {
      return {
        ...state,
        habitId: payload,
      };
    },

    setOpenLogValue(state, payload: boolean) {
      return {
        ...state,
        isOpenLogValue: payload,
      };
    },
  },
  effects: (dispatch) => ({
    getInAppMessage({ uid }: { uid: string }) {
      const messageRef = ref(database, `inAppMessages/${uid}`);
      get(messageRef)
        .then((snapshot) => {
          let inAppMessageMap = new Map();
          if (snapshot.exists()) {
            let result: InAppMessage[] = [];
            const snapshotVal = snapshot.val();
            const inAppMessageArray = Object.entries(snapshotVal) as [string, InAppMessage][];
            inAppMessageMap = new Map(Object.entries(snapshotVal));
            inAppMessageArray.forEach(([key, value]) => {
              result.push({ ...{ key }, ...value });
            });
            result = result.sort((item1, item2) => dayjs(item1.createdAt).valueOf() - dayjs(item2.createdAt).valueOf());
            dispatch.inAppMessageModel.initInAppMessage(result);
          } else {
            // no data available
            console.warn('no message available');
          }

          onChildAdded(messageRef, (subSnapshot) => {
            const isInAppMessageAlreadyExist = inAppMessageMap.has(subSnapshot.key || '');
            if (!isInAppMessageAlreadyExist) {
              dispatch.inAppMessageModel.enqueueMessage({ ...{ key: subSnapshot.key }, ...subSnapshot.val() });
            }
          });

          onChildChanged(messageRef, (subSnapshot) => {
            dispatch.inAppMessageModel.updateMessage({ ...{ key: subSnapshot.key }, ...subSnapshot.val() });
          });

          onChildRemoved(messageRef, (subSnapshot) => {
            dispatch.inAppMessageModel.dequeueMessage({ ...{ key: subSnapshot.key }, ...subSnapshot.val() });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    deleteMessage({ uid, message }: { uid: string; message: InAppMessage }) {
      if (message.key) {
        const messageRef = ref(database, `inAppMessages/${uid}/${message.key}`);
        dispatch.inAppMessageModel.dequeueMessage(message);
        remove(messageRef);
      } else {
        console.warn('no message key available');
      }
    },

    getHabitIdFromDeepLink({ habitId }: { habitId: string }) {
      dispatch.inAppMessageModel.setHabitId(habitId);
    },

    openLogValue({ open }: { open: boolean }) {
      dispatch.inAppMessageModel.setOpenLogValue(open);
    },
  }),
});
