import { HabitLogType } from 'models/habit-logs';
import { HabitLogInfo } from 'models/habits';
import { useEffect, useState } from 'react';
import { HABIT_LOG_TYPE, LINK_SOURCES, LINK_SOURCES_TEXT } from '__archived__/types/enum/habit';

type LogInfoType = {
  type: HabitLogType;
  source: string;
};

export const useLogInfoType = (logInfo: HabitLogInfo | null | undefined): LogInfoType => {
  const [type, setType] = useState<HabitLogType>(HABIT_LOG_TYPE.MANUAL);
  const [source, setSource] = useState<string>('');

  useEffect(() => {
    if (logInfo) {
      setType(logInfo.type);
      if (logInfo.links) {
        if (logInfo.links.source === LINK_SOURCES.APPLE_HEALTH) {
          setSource(LINK_SOURCES_TEXT.APPLE_HEALTH);
        } else {
          setSource(LINK_SOURCES_TEXT.GOOGLE_FIT);
        }
      }
    }
  }, [logInfo]);

  return {
    type,
    source,
  };
};
