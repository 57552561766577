import { IUsageLimit } from 'models/common';
import { useEffect, useState } from 'react';

export const useGetUsageLimit = (userUsageConfig: IUsageLimit | null, usageConfig?: IUsageLimit): IUsageLimit => {
  const [usageLimit, setUsageLimit] = useState<IUsageLimit>({});

  useEffect(() => {
    if (userUsageConfig) setUsageLimit(userUsageConfig);
    else if (usageConfig) setUsageLimit(usageConfig);
  }, [usageConfig, userUsageConfig]);

  return usageLimit;
};
