import { Box, Text } from "@chakra-ui/react";
import Icon from "components/common/Icon";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement } from "react";
interface Props {
  title: string;
}
const SubBenefit: React.FC<Props> = ({ title }: Props): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  return (
    <Box mt="10px" display="flex">
      <Icon
        name="ic_completed"
        width={15}
        height={15}
        fill={colorScheme.label.secondary}
      />
      <Text
        {...typography.normal.body}
        color={colorScheme.label.secondary}
        ml="11px"
      >
        {title}
      </Text>
    </Box>
  );
};
export default SubBenefit;
