import {
    Box,
    InputGroup,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Text,
} from "@chakra-ui/react";
import InputField from "components/common/input-field/input-field.component";
import MenuItemLayout from "components/common/menu/MenuItem";
import MenuListLayout from "components/common/menu/MenuList";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFormatTime } from "tools/profile-settings/format-time";
import { ITimeBound } from "__archived__/types/states";

interface TimeOptionProps {
    setTimeOfDayOption: (time: number) => void;
    morningMinuteRange: ITimeBound;
}

const timeOptions = () => {
    const times: number[] = [];
    const TIME_RANGE = 30;
    const MAX_TIME = 1440;
    const numberOfTimeline = MAX_TIME / TIME_RANGE;
    for (let i = 0; i < numberOfTimeline; i++) {
        times.push(i * TIME_RANGE);
    }
    return times;
};

const TimeOfDayOptions: React.FC<TimeOptionProps> = ({
    setTimeOfDayOption,
    morningMinuteRange,
}: TimeOptionProps): ReactElement => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { colorScheme, typography } = useThemeData();

    const [timeline, setTimeline] = useState(0);

    useEffect(() => {
        setTimeline(morningMinuteRange.lowerbound || 0);
    }, [morningMinuteRange]);

    const onPickedTimeline = (time: number) => {
        setTimeOfDayOption(time);
        setIsOpen(false);
        setTimeline(time);
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pr="16px"
        >
            <Box width="60%" maxW="230px">
                <Text
                    mb="6px"
                    color={colorScheme.label.primary}
                    {...typography.emphasized.title[4]}
                >
                    {t("common.startOfDay")}
                </Text>
                <Text
                    color={colorScheme.label.secondary}
                    {...typography.normal.callout}
                >
                    {t("common.startOfDayDescription")}
                </Text>
            </Box>
            <Box width="30%" maxW="118px">
                <Popover
                    matchWidth
                    placement="bottom-start"
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    closeOnBlur
                >
                    <PopoverTrigger>
                        <InputGroup>
                            <InputField
                                isReadOnly
                                cursor="default"
                                inputType="select"
                                textTransform="capitalize"
                                value={getFormatTime(timeline)}
                                isOpen={isOpen}
                                {...typography.emphasized.callout}
                                h="30px"
                                onClick={() => setIsOpen(true)}
                            />
                        </InputGroup>
                    </PopoverTrigger>
                    <PopoverContent
                        height="389px"
                        overflow="overlay"
                        w="full"
                        border="unset"
                        _focus={{ outline: "none" }}
                    >
                        <MenuListLayout style={{ width: "auto" }}>
                            {timeOptions().map((time, index) => (
                                <MenuItemLayout
                                    title={getFormatTime(time)}
                                    key={index}
                                    onClick={() => onPickedTimeline(time)}
                                    bgColor={
                                        time === timeline
                                            ? colorScheme.accent.primary
                                            : "unset"
                                    }
                                    isSelected={time === timeline}
                                    hasIconLeft={false}
                                />
                            ))}
                        </MenuListLayout>
                    </PopoverContent>
                </Popover>
            </Box>
        </Box>
    );
};

export default TimeOfDayOptions;
