export enum AUTHENTICATION_ACTION_TYPES {
  SIGN_OUT = '@authentication/signOut',
  SIGN_OUT_FAILED = '@@authentication/signOutFailed',
}

export type SignOutType = {
  readonly type: AUTHENTICATION_ACTION_TYPES.SIGN_OUT;
};

export type SignOutFailedType = {
  readonly type: AUTHENTICATION_ACTION_TYPES.SIGN_OUT_FAILED;
  readonly payload: boolean;
};

export type SignOutActionType = SignOutType | SignOutFailedType;
export {}