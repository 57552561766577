import { HabitFolder } from 'models/habit-folders';
import memoizeOne from 'memoize-one';

export const habitFoldersModelMapper = memoizeOne(
  ({ key, rawValue }: { key: string; rawValue: any }): HabitFolder | undefined => {
    if (!rawValue) return undefined;
    return {
      id: key,
      name: rawStringValueMapper(rawValue['name']),
      color: rawStringValueMapper(rawValue['color']),
      createdAt: rawStringValueMapper(rawValue['createdAt']),
      iconKey: rawStringValueMapper(rawValue['iconKey']),
      priority: rawStringValueMapper(rawValue['priority']),
    };
  },
);

const rawStringValueMapper = memoizeOne((rawValue: any): string | undefined => {
  if (!rawValue) return undefined;
  return rawValue;
});
