export const iconOptions = [
  'ic_area_addmission_ticket',
  'ic_area_alarm',
  'ic_area_alien',
  'ic_area_anchor',
  'ic_area_apple',
  'ic_area_app_stack',

  'ic_area_archive',
  'ic_area_atlas',
  'ic_area_baby_bottle',
  'ic_area_baby_carriage',
  'ic_area_backpack',
  'ic_area_badge',

  'ic_area_badge_alt',
  'ic_area_bag',
  'ic_area_ball',
  'ic_area_balloon',
  'ic_area_band_aid',
  'ic_area_baseball',
  'ic_area_basket',
  'ic_area_basketball',
  'ic_area_bathing',
  'ic_area_bbq',
  'ic_area_beaker',
  'ic_area_bed',
  'ic_area_binoculars',
  'ic_area_birthday_cake',
  'ic_area_blocks',
  'ic_area_bone',
  'ic_area_book',
  'ic_area_bookmark',

  'ic_area_boombox',
  'ic_area_bowling',
  'ic_area_bowling_ball',
  'ic_area_bowtie',
  'ic_area_bow_and_arrow',
  'ic_area_boxing',

  'ic_area_briefcase_1',
  'ic_area_broken_heart',
  'ic_area_bug',
  'ic_area_building_block',
  'ic_area_calendar',
  'ic_area_camera',

  'ic_area_candle',
  'ic_area_candy',
  'ic_area_car',
  'ic_area_cards',
  'ic_area_cart',
  'ic_area_cash_dollar',

  'ic_area_cash_euro',
  'ic_area_cash_pound',
  'ic_area_cash_stack',
  'ic_area_cash_yen',
  'ic_area_cauldron',
  'ic_area_chair',

  'ic_area_charts',
  'ic_area_checkered_flag',
  'ic_area_chefs_hat',
  'ic_area_chess',
  'ic_area_clock',
  'ic_area_clothes_hanger',

  'ic_area_cloudy',
  'ic_area_cloud_and_moon',
  'ic_area_cloud_and_sun',
  'ic_area_coffee_pot',
  'ic_area_coffee_to_go',
  'ic_area_coin_dollar',

  'ic_area_coin_euro',
  'ic_area_coin_pound',
  'ic_area_coin_yen',
  'ic_area_compass',
  'ic_area_couch',
  'ic_area_cowboy_hat',

  'ic_area_credit_card_front',
  'ic_area_crown',
  'ic_area_cursor',
  'ic_area_cut',
  'ic_area_database',
  'ic_area_diamond',

  'ic_area_drum',
  'ic_area_egg',
  'ic_area_egg_timer',
  'ic_area_emoji',
  'ic_area_emoji_happy',
  'ic_area_emoji_neutral',

  'ic_area_emoji_sad',
  'ic_area_explosive',
  'ic_area_firework',
  'ic_area_flame',
  'ic_area_flower',
  'ic_area_flowerpot',

  'ic_area_folders',
  'ic_area_football',
  'ic_area_footprints',
  'ic_area_fragile',
  'ic_area_game_controller',
  'ic_area_garage',

  'ic_area_gavel',
  'ic_area_ghost',
  'ic_area_gift',
  'ic_area_glass_with_ice',
  'ic_area_glass_with_straw',
  'ic_area_graduation',

  'ic_area_grapes',
  'ic_area_guitars',
  'ic_area_handbag',
  'ic_area_hard_hat',
  'ic_area_headphones_2',
  'ic_area_helicopter',

  'ic_area_highlight',
  'ic_area_home',
  'ic_area_ice_cream_cone',
  'ic_area_inbox',
  'ic_area_infinity',
  'ic_area_key',
  'ic_area_lamp',
  'ic_area_leaf',
  'ic_area_library',
  'ic_area_life_preserver',
  'ic_area_light_on',
  'ic_area_like',
  'ic_area_lipstick',
  'ic_area_locked',
  'ic_area_magnet',
  'ic_area_mail',
  'ic_area_mallet',
  'ic_area_martini',

  'ic_area_medal',
  'ic_area_medical_bag',
  'ic_area_megaphone_1',
  'ic_area_megaphone_2',
  'ic_area_message',
  'ic_area_microphone',

  'ic_area_mixing_bowl',
  'ic_area_moon_phase',
  'ic_area_motorcycle',
  'ic_area_motorcycle_helmet',
  'ic_area_mountains',
  'ic_area_music',

  'ic_area_news',
  'ic_area_oven_mitt',
  'ic_area_package',
  'ic_area_paintbrush_and_pencil',
  'ic_area_paint_roller',
  'ic_area_palette',

  'ic_area_palm_tree',
  'ic_area_paw_print',
  'ic_area_photo',
  'ic_area_photos_flower',
  'ic_area_piggy_bank',
  'ic_area_pill',

  'ic_area_pill_bottle',
  'ic_area_pizza',
  'ic_area_planet',
  'ic_area_podcast',
  'ic_area_poop',
  'ic_area_popsicle',

  'ic_area_potted_bush',
  'ic_area_preview',
  'ic_area_radiation',
  'ic_area_rain_storm',
  'ic_area_recycle',
  'ic_area_robot',

  'ic_area_rocking_horse',
  'ic_area_rolling_pin',
  'ic_area_running_shoe',
  'ic_area_sandals',
  'ic_area_scale',
  'ic_area_scales_of_justice',

  'ic_area_secure',
  'ic_area_send',
  'ic_area_service_bell',
  'ic_area_shield_star',
  'ic_area_shower',
  'ic_area_sketch',

  'ic_area_snooker',
  'ic_area_snowflake',
  'ic_area_soccer',
  'ic_area_solar_system',
  'ic_area_spatula',
  'ic_area_stack_open',

  'ic_area_star',
  'ic_area_stopwatch',
  'ic_area_storefront',
  'ic_area_tags',
  'ic_area_takeoff',
  'ic_area_target',
  'ic_area_thumbtack',
  'ic_area_timer',
  'ic_area_tooth',
  'ic_area_top_hat',
  'ic_area_tree',
  'ic_area_tricycle',
  'ic_area_trophy',
  'ic_area_tv',
  'ic_area_t_shirt',
  'ic_area_ufo',
  'ic_area_umbrella',
  'ic_area_utensils',

  'ic_area_van',
  'ic_area_video_on',
  'ic_area_watermelon',
  'ic_area_water_droplet',
  'ic_area_weights',
  'ic_area_wheelchair',

  'ic_area_whisk',
  'ic_area_whistle',
  'ic_area_wine_bottle',
  'ic_area_wish_list',
  'ic_area_ying_and_yang',
];
