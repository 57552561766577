import { getDayKey } from './common';
import dayjs, { Dayjs } from 'dayjs';
import { IHabitGoal, IHabitNewGoal } from '__archived__/types/states/habit';
import { HabitProgressMapInfo } from '__archived__/types/states/singleProgress';
import { UnitSymbol } from 'models/common';
import { Habit, HabitGoal, HabitGoals } from 'models/habits';
import { getCurrentHabitGoal } from 'tools/habit-progress';
import { convert, getBaseUnitFromType, getType as newGetType } from 'tools/si-unit/si-unit-utils';
import {
  CheckInsStatus,
  getMonthKey,
  getWeekKey,
  HabitProgressInfo,
  IHabitLogFilter,
  isToday,
  IStreaks,
  SingleProgressDataCalculator,
  StreakProperties,
} from '.';
import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';
import { _dayjs } from 'tools/extended-dayjs';
import { DateRange } from 'models/off-mode';

const WEEK_DAYS = 'weekDays';
const MONTH_DAYS = 'monthDays';
const DAY_INTERVAL = 'dayInterval';
const DAILY = 'daily';

const getListDayByRegularly = (habit: Habit): Map<string, number | string> => {
  const days: Map<string, number | string> = new Map();
  const regularly = habit?.regularly?.split('-') as string[];
  const dayByRegularly: string[] = regularly[1]?.split(',');
  dayByRegularly.forEach((element: string | number) => {
    days.set(String(element), element);
  });
  return days;
};

const isValidCheckInSkip = (checkIn: number | null): boolean => {
  if (checkIn === CheckInsStatus.OFF || checkIn === CheckInsStatus.SKIP) {
    return true;
  }
  return false;
};

const isValidHabitByDayInterval = (
  dayInterval: number,
  habitStartDate: number | null | undefined,
  currentDate: dayjs.Dayjs,
): boolean => {
  if (!dayInterval) return false;
  const diff = currentDate.diff(dayjs(habitStartDate).format('YYYY-MM-DD'), 'days');
  return diff % dayInterval === 0;
};

const isValidHabitByMonth = (currentDate: dayjs.Dayjs, monthDays: Map<string, string | number>): boolean => {
  if (!monthDays) return false;
  const day: string = currentDate.format('D');
  return monthDays.get(day) ? true : false;
};

const isValidHabitByWeek = (currentDate: dayjs.Dayjs, weekDays: Map<string, string | number>): boolean => {
  if (!weekDays) return false;
  const day: string = currentDate.locale('en').format('ddd').toLocaleLowerCase();
  return weekDays.get(day) ? true : false;
};

const isValidStreak = (subHabitProgressInfo: HabitProgressInfo): boolean => {
  const { checkInStatus } = subHabitProgressInfo;
  return (
    checkInStatus === CheckInsStatus.COMPLETE ||
    checkInStatus === CheckInsStatus.SKIP ||
    checkInStatus === CheckInsStatus.OFF
  );
};

const isValidStreakByRegularly = (
  habit: Habit,
  currentDate: dayjs.Dayjs,
  regularlyCurrent: string,
  daysByRegularly: Map<string, string | number>,
): boolean => {
  const habitStartDate: number | null | undefined = habit.startDate;
  const arrHabitRegularly: string[] = habit?.regularly?.split('-') as string[];
  let isPass = false;
  if (arrHabitRegularly?.length) {
    switch (regularlyCurrent) {
      case DAY_INTERVAL:
        isPass = isValidHabitByDayInterval(Number(arrHabitRegularly[1]), habitStartDate, currentDate);
        break;
      case MONTH_DAYS:
        isPass = isValidHabitByMonth(currentDate, daysByRegularly);
        break;
      case WEEK_DAYS:
        isPass = isValidHabitByWeek(currentDate, daysByRegularly);
        break;
      default:
        break;
    }
  }
  return isPass;
};

const getStatusGoodHabitDailyByHabitLogMap = (
  habitLogValue: number,
  habitGoalValue: number,
  date: Dayjs,
  dateRangesOffMode: DateRange[],
): number => {
  if (habitLogValue >= habitGoalValue) {
    return CheckInsStatus.COMPLETE;
  }
  if (habitLogValue < habitGoalValue && habitLogValue > 0) {
    return CheckInsStatus.PROGRESS;
  }

  const status = statusInDateRangeOffMode(date, dateRangesOffMode);
  if (status) return status;

  return CheckInsStatus.NONE;
};

const statusInDateRangeOffMode = (date: Dayjs, dateRangesOffMode: DateRange[], offModeId?: string) => {
  for (let i = 0; i < dateRangesOffMode.length; i++) {
    const range = dateRangesOffMode[i];
    const { startDate, endDate } = range;
    if (range.stopDate) {
      if (date.isBetween(startDate, endDate.subtract(1), 'day', '[]')) {
        return CheckInsStatus.OFF;
      }
    } else if (date.isBetween(startDate, endDate, 'day', '[]')) {
      return CheckInsStatus.OFF;
    }
  }
};

const getStatusBadHabitByHabitLogMap = (parameter: {
  habitLogValue: number;
  habitGoalValue: number;
  chooseDate: dayjs.Dayjs;
  habitGoalCurrent: HabitGoal | null | undefined;
  habitLogsMap: Map<string, IHabitLogFilter>;
  firstDayOfWeek: FirstWeekDay;
  dateRangesOffMode: DateRange[];
}) => {
  const {
    habitLogValue,
    habitGoalValue,
    chooseDate,
    habitGoalCurrent,
    habitLogsMap,
    firstDayOfWeek,
    dateRangesOffMode,
  } = parameter;
  const habitGoalPeriodicity = habitGoalCurrent?.periodicity;

  if (habitGoalPeriodicity === 'daily') {
    if (habitLogValue > habitGoalValue) {
      return CheckInsStatus.FAIL;
    }
    if (isToday(chooseDate) && habitLogValue && habitLogValue <= habitGoalValue) {
      return CheckInsStatus.PROGRESS;
    }
    if (isToday(chooseDate) && !habitLogValue) {
      return CheckInsStatus.NONE;
    }
    if (!isToday(chooseDate) && habitLogValue && habitLogValue <= habitGoalValue) {
      return CheckInsStatus.COMPLETE;
    }

    const status = statusInDateRangeOffMode(chooseDate, dateRangesOffMode);
    if (status) return status;

    return CheckInsStatus.COMPLETE;
  }

  if (habitGoalPeriodicity === 'weekly' || habitGoalPeriodicity === 'monthly') {
    let fistDayOfPeriodicity = _dayjs();
    if (habitGoalPeriodicity === 'weekly') {
      if (firstDayOfWeek === 'monday') {
        fistDayOfPeriodicity = _dayjs(chooseDate).add(-1, 'day').startOf('week').add(1, 'day');
      } else {
        fistDayOfPeriodicity = _dayjs(chooseDate).startOf('week');
      }
    } else {
      fistDayOfPeriodicity = _dayjs(chooseDate).startOf('month');
    }

    const checkInStatus = getBadHabitCheckInStatusByWeekAndMonth({
      fistDayOfPeriodicity,
      chooseDate,
      habitLogsMap,
      habitGoalValue,
      dateRangesOffMode,
    });
    if (checkInStatus === CheckInsStatus.FAIL) return CheckInsStatus.FAIL;
    if (checkInStatus === CheckInsStatus.COMPLETE) return CheckInsStatus.COMPLETE;
    if (checkInStatus === CheckInsStatus.NONE) return CheckInsStatus.NONE;
    if (checkInStatus === CheckInsStatus.OFF) return CheckInsStatus.OFF;
  }
  return dayjs().format('DDMMYYYY') === chooseDate.format('DDMMYYYY')
    ? CheckInsStatus.PROGRESS
    : CheckInsStatus.COMPLETE;
};

const getBadHabitCheckInStatusByWeekAndMonth = (parameters: {
  fistDayOfPeriodicity: dayjs.Dayjs;
  chooseDate: dayjs.Dayjs;
  habitLogsMap: Map<string, IHabitLogFilter>;
  habitGoalValue: number;
  dateRangesOffMode: DateRange[];
}) => {
  const { fistDayOfPeriodicity, chooseDate, habitLogsMap, habitGoalValue, dateRangesOffMode } = parameters;
  let currentDate = chooseDate;
  let logValue = 0;
  let checkInStatus = isToday(chooseDate) ? CheckInsStatus.NONE : CheckInsStatus.COMPLETE;
  let hasLogValue = false;

  while (currentDate.valueOf() >= fistDayOfPeriodicity.valueOf()) {
    logValue += habitLogsMap.get(currentDate.format('DDMMYYYY'))?.logValue || 0;

    if (habitLogsMap.get(currentDate.format('DDMMYYYY'))?.logValue && currentDate.isSame(chooseDate)) {
      if (logValue <= habitGoalValue) {
        hasLogValue = true;
      }
    }

    if (logValue > habitGoalValue) {
      checkInStatus = CheckInsStatus.FAIL;
      break;
    }

    currentDate = currentDate.subtract(1, 'days');
  }

  if (checkInStatus !== CheckInsStatus.FAIL && !hasLogValue) {
    const status = statusInDateRangeOffMode(chooseDate, dateRangesOffMode);
    if (status) return status;
  }

  return checkInStatus;
};

const getHabitProgressInfo = (
  habit: Habit,
  habitLogsMap: Map<string, IHabitLogFilter>,
  dateSelected: Dayjs,
  habitGoalCurrent: HabitGoal | null | undefined,
  firstDayOfWeek: FirstWeekDay,
  dateRangesOffMode: DateRange[],
): HabitProgressInfo => {
  const dateSelectedConvert: string = dateSelected.format('DDMMYYYY');
  const dateId: string = dateSelected.format('YYYY-MM-DD');
  let checkInStatus: number = CheckInsStatus.NONE;
  let actualGoalValue: number | null = null;
  let actualSymbol: string | null = null;
  let goalValue: number | null = null;
  let actualCheckInStatus: number | null = null;
  let actualNoGoalValue: number | null = null;
  let offModeId: string | null = null;
  const habitType = habit.habitType?.habitType;

  if (dateRangesOffMode.length > 0) {
    for (let i = 0; i < dateRangesOffMode.length; i++) {
      const range = dateRangesOffMode[i];
      if (range.stopDate) {
        if (dateSelected.isBetween(range.startDate, range.endDate.subtract(1), 'day', '[]')) {
          checkInStatus = CheckInsStatus.OFF;
          offModeId = range.id;
          break;
        }
      } else if (dateSelected.isBetween(range.startDate, range.endDate, 'day', '[]')) {
        checkInStatus = CheckInsStatus.OFF;
        offModeId = range.id;
        break;
      }
    }
  }

  if (habitLogsMap && (habit.goal || habit.goals) && habitGoalCurrent) {
    const habitPeriodicity = habitGoalCurrent?.periodicity || '';
    const habitSymbolCurrent = habitGoalCurrent?.unit?.symbol as UnitSymbol;
    const goalValueCurrent: number = habitGoalCurrent?.value || 0;
    const listHabitLog: Map<string, IHabitLogFilter> = habitLogsMap;
    const unitSymbol = listHabitLog.get(dateSelectedConvert)?.habitLog?.unitSymbol;
    const habitLogValue = listHabitLog.get(dateSelectedConvert)?.logValue || 0;
    const baseUnitSymbolOfHabit = getBaseUnitFromType({
      type: newGetType({ unitSymbol: habitSymbolCurrent }),
    });
    const habitGoalValue: number = convert({
      source: habitSymbolCurrent,
      target: baseUnitSymbolOfHabit,
      value: goalValueCurrent,
    });
    actualGoalValue = habitLogValue;
    goalValue = habitGoalValue;
    actualSymbol = unitSymbol as string;
    if (unitSymbol && habitType !== 'bad') {
      if (habitPeriodicity === DAILY) {
        checkInStatus = getStatusGoodHabitDailyByHabitLogMap(
          habitLogValue,
          habitGoalValue,
          dateSelected,
          dateRangesOffMode,
        );
      }
      if (habitPeriodicity !== DAILY && listHabitLog.get(dateSelectedConvert)?.logValue) {
        checkInStatus = CheckInsStatus.COMPLETE;
      }
    }

    if (habitType === 'bad' && habitGoalCurrent) {
      const habitGoalValue: number = convert({
        source: habitSymbolCurrent,
        target: baseUnitSymbolOfHabit,
        value: goalValueCurrent,
      });
      checkInStatus = getStatusBadHabitByHabitLogMap({
        habitLogValue,
        habitGoalValue,
        chooseDate: dateSelected,
        habitGoalCurrent,
        habitLogsMap,
        firstDayOfWeek,
        dateRangesOffMode,
      });
    }
  }

  if (habitLogsMap && !habitGoalCurrent) {
    const habitLog = habitLogsMap.get(dateSelectedConvert);
    if (habitLog && habitLog.isNoGoal) {
      actualNoGoalValue = habitLog.logValue;
    }
  }

  // handle habit have checkIns
  if (habit.checkins && habitType !== 'bad') {
    const checkIn = habit.checkins[dateSelectedConvert];
    if (checkIn) {
      actualCheckInStatus = checkIn.rawValue;
    }
    if (checkIn && checkIn !== CheckInsStatus.NONE) {
      checkInStatus = habit.checkins[dateSelectedConvert].rawValue;
    }
  }

  if (habitType === 'bad') {
    if (habit.checkins && habit.checkins[dateSelectedConvert]) {
      checkInStatus = habit.checkins[dateSelectedConvert].rawValue;
    }
    if (checkInStatus === CheckInsStatus.NONE && !isToday(dateSelected)) {
      checkInStatus = CheckInsStatus.COMPLETE;
    }

    if (checkInStatus === CheckInsStatus.NONE && isToday(dateSelected)) {
      checkInStatus = CheckInsStatus.NONE;
    }
  }

  return {
    dateId,
    checkInStatus,
    actualGoalValue,
    goalValue,
    habitGoalCurrent,
    actualSymbol,
    actualCheckInStatus,
    actualNoGoalValue,
    offModeId,
  };
};

const setHabitProgressInfo = (
  subHabitProgressInfo: HabitProgressInfo,
  habitProgressInfo: HabitProgressMapInfo,
  currentDate: dayjs.Dayjs,
  habit: Habit,
  habitLogsMap: Map<string, IHabitLogFilter>,
  firstDayOfWeek: FirstWeekDay,
) => {
  const {
    dateId,
    checkInStatus,
    actualGoalValue,
    goalValue,
    habitGoalCurrent,
    actualSymbol,
    actualCheckInStatus,
    actualNoGoalValue,
    offModeId,
  } = subHabitProgressInfo;
  const weekKey: string = getWeekKey(currentDate, firstDayOfWeek);
  const monthKey: string = getMonthKey(currentDate);
  // set symbol current by goal current
  if (!habitProgressInfo?.symbolDefault) habitProgressInfo.symbolDefault = habitGoalCurrent?.unit?.symbol as UnitSymbol;

  // set data for card overview
  if (habit?.habitType && habit?.habitType?.habitType === 'bad' && !actualGoalValue && !isToday(currentDate)) {
    if (habitGoalCurrent?.value) {
      const numberOfZeroDay = (habitProgressInfo.dateToBadHabitZeroDayMap?.get(monthKey) || 0) + 1;
      habitProgressInfo.dateToBadHabitZeroDayMap?.set(monthKey, numberOfZeroDay);
    }
  }
  setHabitNumberOfCompletedMap({
    habitGoalCurrent,
    habitLogsMap,
    chooseDate: currentDate,
    checkInStatus,
    monthKey,
    habitProgressInfo,
    habit,
    firstDayOfWeek,
  });
  setHabitNumberLogValueByMonthMap({
    habit,
    actualGoalValue,
    habitGoalCurrent,
    checkInStatus,
    habitProgressInfo,
    dateKey: { monthKey, dateId },
  });
  if (checkInStatus === CheckInsStatus.SKIP) {
    const numberOfSkipped = (habitProgressInfo.dateToNumberOfSkippedMonthMap?.get(monthKey) || 0) + 1;
    habitProgressInfo.dateToNumberOfSkippedMonthMap?.set(monthKey, numberOfSkipped);
  }
  if (checkInStatus === CheckInsStatus.FAIL) {
    const numberOfFailed = (habitProgressInfo.dateToNumberOfFailedMonthMap?.get(monthKey) || 0) + 1;
    habitProgressInfo.dateToNumberOfFailedMonthMap?.set(monthKey, numberOfFailed);
  }

  // set symbol -> daily | weekly | monthly
  setHabitActualSymbolMap({
    habitProgressInfo,
    actualSymbol,
    dateKey: { dayKey: dateId, weekKey, monthKey },
  });

  // set status check-in
  habitProgressInfo.dateToCheckInStatusMap?.set(dateId, checkInStatus);
  habitProgressInfo.dateToActualCheckInStatus?.set(dateId, actualCheckInStatus);
  setHabitNumberOfCheckInStatusByWeekAndMonthMap({
    habitProgressInfo,
    actualCheckInStatus,
    dateKey: { weekKey, monthKey },
  });
  setHabitActualNoGoalValueMap({
    habitProgressInfo,
    dateKey: { dayKey: dateId, weekKey, monthKey },
    actualNoGoalValue,
  });
  setHabitLogValueByWeekAndMonthMap({
    habitProgressInfo,
    actualGoalValue,
    dateKey: { weekKey, monthKey },
  });
  setHabitOffModeId({
    habitProgressInfo,
    offModeId,
    dateKey: { dayKey: dateId },
  });
  // set goal -> daily | weekly | monthly
  habitProgressInfo.dateToActualGoalValueMap?.set(dateId, actualGoalValue);
  habitProgressInfo.dateToHabitGoalCurrentMap?.set(dateId, habitGoalCurrent);
  habitProgressInfo.dateToGoalValueMap?.set(dateId, goalValue);
  if (habitGoalCurrent?.periodicity === 'weekly') habitProgressInfo.dateToGoalWeekMap?.set(weekKey, habitGoalCurrent);
  if (habitGoalCurrent?.periodicity === 'monthly')
    habitProgressInfo.dateToGoalMonthMap?.set(monthKey, habitGoalCurrent);
};

const setHabitNumberLogValueByMonthMap = (parameters: {
  habit: Habit;
  actualGoalValue: number | null;
  habitGoalCurrent: HabitGoal | null | undefined;
  checkInStatus: number;
  habitProgressInfo: HabitProgressMapInfo;
  dateKey: { monthKey: string; dateId: string };
}) => {
  const {
    habit,
    actualGoalValue,
    habitGoalCurrent,
    checkInStatus,
    habitProgressInfo,
    dateKey: { monthKey },
  } = parameters;
  if (actualGoalValue || (!habitGoalCurrent && checkInStatus === CheckInsStatus.COMPLETE)) {
    const isBabHabit = habit?.habitType?.habitType !== 'bad';
    const nowSymbol = habitProgressInfo.symbolDefault;
    const _actualLogValue =
      (isBabHabit && nowSymbol === 'rep') || (!habitGoalCurrent && !nowSymbol)
        ? actualGoalValue || 1
        : actualGoalValue || 0;
    const logValueFromMap: number | null | undefined = habitProgressInfo.dateToNumberLogValueMonthMap?.get(monthKey);
    const logValue = logValueFromMap ? logValueFromMap + _actualLogValue : _actualLogValue || 0;
    habitProgressInfo.dateToNumberLogValueMonthMap?.set(monthKey, logValue);
  }
};

const setHabitNumberOfCompletedMap = (parameters: {
  habitGoalCurrent: HabitGoal | null | undefined;
  habitLogsMap: Map<string, IHabitLogFilter>;
  chooseDate: dayjs.Dayjs;
  checkInStatus: number;
  monthKey: string;
  habitProgressInfo: HabitProgressMapInfo;
  habit: Habit;
  firstDayOfWeek: FirstWeekDay;
}) => {
  const {
    habitLogsMap,
    checkInStatus,
    monthKey,
    habitProgressInfo,
    habit,
    habitGoalCurrent,
    chooseDate,
    firstDayOfWeek,
  } = parameters;
  if (habit?.habitType?.habitType === 'bad' && checkInStatus === CheckInsStatus.COMPLETE) {
    const numberOfCompleted = (habitProgressInfo.dateToNumberOfCompletedMonthMap?.get(monthKey) || 0) + 1;
    habitProgressInfo.dateToNumberOfCompletedMonthMap?.set(monthKey, numberOfCompleted);
  }
  if (habit?.habitType?.habitType !== 'bad') {
    // let numberOfCompleted = 0;
    const habitGoalPer = habitGoalCurrent?.periodicity;
    const isGoalPer = habitGoalPer === 'weekly' || habitGoalPer === 'monthly';
    if (isGoalPer) {
      const habitSymbolCurrent = habitGoalCurrent?.unit?.symbol as UnitSymbol;
      const baseUnitSymbolOfHabit = getBaseUnitFromType({
        type: newGetType({ unitSymbol: habitSymbolCurrent }),
      });

      const goalValue = convert({
        source: habitSymbolCurrent,
        target: baseUnitSymbolOfHabit,
        value: habitGoalCurrent?.value || 0,
      });
      const logValue =
        habitGoalPer === 'weekly'
          ? habitLogsMap?.get(getWeekKey(chooseDate, firstDayOfWeek))?.logValue || 0
          : habitLogsMap?.get(getMonthKey(chooseDate))?.logValue || 0;

      if (logValue >= goalValue && checkInStatus !== CheckInsStatus.FAIL && checkInStatus !== CheckInsStatus.SKIP) {
        const numberOfCompleted = (habitProgressInfo.dateToNumberOfCompletedMonthMap?.get(monthKey) || 0) + 1;
        habitProgressInfo.dateToNumberOfCompletedMonthMap?.set(monthKey, numberOfCompleted);
      }
    }

    if (!isGoalPer && checkInStatus === CheckInsStatus.COMPLETE) {
      const numberOfCompleted = (habitProgressInfo.dateToNumberOfCompletedMonthMap?.get(monthKey) || 0) + 1;
      habitProgressInfo.dateToNumberOfCompletedMonthMap?.set(monthKey, numberOfCompleted);
    }
  }
};

const setHabitActualNoGoalValueMap = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  dateKey: {
    dayKey: string;
    weekKey: string;
    monthKey: string;
  };
  actualNoGoalValue: number | null;
}) => {
  const {
    habitProgressInfo,
    dateKey: { dayKey, weekKey, monthKey },
    actualNoGoalValue,
  } = parameters;
  if (actualNoGoalValue) {
    habitProgressInfo.dateToActualNoGoalValueMap?.set(dayKey, actualNoGoalValue);
    const weekValue: number | null | undefined = habitProgressInfo.dateToActualNoGoalValueMap?.get(weekKey);
    weekValue
      ? habitProgressInfo.dateToActualNoGoalValueMap?.set(weekKey, actualNoGoalValue + weekValue)
      : habitProgressInfo.dateToActualNoGoalValueMap?.set(weekKey, actualNoGoalValue);
    const monthValue: number | null | undefined = habitProgressInfo.dateToActualNoGoalValueMap?.get(monthKey);
    monthValue
      ? habitProgressInfo.dateToActualNoGoalValueMap?.set(monthKey, actualNoGoalValue + monthValue)
      : habitProgressInfo.dateToActualNoGoalValueMap?.set(monthKey, actualNoGoalValue);
  }
};

const setHabitLogValueByWeekAndMonthMap = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  actualGoalValue: number | null;
  dateKey: {
    weekKey: string;
    monthKey: string;
  };
}) => {
  const {
    habitProgressInfo,
    actualGoalValue,
    dateKey: { weekKey, monthKey },
  } = parameters;
  if (actualGoalValue) {
    const logValueWeek: number | null | undefined = habitProgressInfo.dateToLogValueWeekMap?.get(weekKey);
    const logValueWeekCurrent = logValueWeek ? logValueWeek + actualGoalValue : actualGoalValue || 0;
    const logValueMonth: number | null | undefined = habitProgressInfo.dateToLogValueMonthMap?.get(monthKey);
    const logValueMonthCurrent = logValueMonth ? logValueMonth + actualGoalValue : actualGoalValue || 0;
    habitProgressInfo.dateToLogValueWeekMap?.set(weekKey, logValueWeekCurrent);
    habitProgressInfo.dateToLogValueMonthMap?.set(monthKey, logValueMonthCurrent);
  }
};

const setHabitNumberOfCheckInStatusByWeekAndMonthMap = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  actualCheckInStatus: number | null;
  dateKey: {
    weekKey: string;
    monthKey: string;
  };
}) => {
  const {
    habitProgressInfo,
    actualCheckInStatus,
    dateKey: { weekKey, monthKey },
  } = parameters;
  if (actualCheckInStatus && actualCheckInStatus === CheckInsStatus.COMPLETE) {
    const numberOfCheckInWeek: number = (habitProgressInfo.dateToNumberOfCheckInStatusWeek?.get(weekKey) || 0) + 1;
    const numberOfCheckInMonth: number = (habitProgressInfo.dateToNumberOfCheckInStatusMonth?.get(monthKey) || 0) + 1;
    habitProgressInfo.dateToNumberOfCheckInStatusWeek?.set(weekKey, numberOfCheckInWeek);
    habitProgressInfo.dateToNumberOfCheckInStatusMonth?.set(monthKey, numberOfCheckInMonth);
  }
};

const setHabitActualSymbolMap = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  actualSymbol: string | null;
  dateKey: {
    dayKey: string;
    weekKey: string;
    monthKey: string;
  };
}) => {
  const {
    habitProgressInfo,
    actualSymbol,
    dateKey: { dayKey, weekKey, monthKey },
  } = parameters;
  habitProgressInfo.dateToActualSymbolMap?.set(dayKey, actualSymbol);
  if (!habitProgressInfo.dateToActualSymbolMap?.has(weekKey) && actualSymbol) {
    habitProgressInfo.dateToActualSymbolMap?.set(weekKey, actualSymbol);
  }
  if (!habitProgressInfo.dateToActualSymbolMap?.has(monthKey) && actualSymbol) {
    habitProgressInfo.dateToActualSymbolMap?.set(monthKey, actualSymbol);
  }
};

const setConditionToCurrentStreakByRegularly = (
  isRegularly: boolean,
  chooseDate: dayjs.Dayjs,
  habitProgressInfo: HabitProgressMapInfo,
) => {
  if (
    !habitProgressInfo.dateToConditionIsCurrentStreakMap ||
    habitProgressInfo.dateToConditionIsCurrentStreakMap?.size > 1
  ) {
    return;
  }
  if (isToday(chooseDate)) {
    const isFail =
      habitProgressInfo.dateToCheckInStatusMap?.get(chooseDate.format('YYYY-MM-DD')) === CheckInsStatus.FAIL;
    habitProgressInfo.dateToConditionIsCurrentStreakMap.set(
      chooseDate.format('YYYY-MM-DD'),
      isRegularly && !isFail ? true : false,
    );
  }
  if (!isToday(chooseDate) && isRegularly) {
    habitProgressInfo.dateToConditionIsCurrentStreakMap.set(chooseDate.format('YYYY-MM-DD'), true);
  }
};

const setConditionToCurrentStreakNormal = (chooseDate: dayjs.Dayjs, habitProgressInfo: HabitProgressMapInfo) => {
  if (
    !habitProgressInfo.dateToConditionIsCurrentStreakMap ||
    habitProgressInfo.dateToConditionIsCurrentStreakMap.size > 1
  ) {
    return;
  }
  const isFail = habitProgressInfo.dateToCheckInStatusMap?.get(chooseDate.format('YYYY-MM-DD')) === CheckInsStatus.FAIL;
  habitProgressInfo.dateToConditionIsCurrentStreakMap.set(
    chooseDate.format('YYYY-MM-DD'),
    isFail && isToday(chooseDate) ? false : true,
  );
};

const calculationStreakProperties = (
  habit: Habit,
  currentDate: dayjs.Dayjs,
  isValidStreak: boolean,
  isValidStreakByRegularly: boolean,
  subHabitProgressInfo: HabitProgressInfo,
): StreakProperties | null => {
  if (!habit) return null;
  const { checkInStatus: progressStatus } = subHabitProgressInfo;
  const checkInStatus =
    habit.checkins && habit.checkins[currentDate.format('DDMMYYYY')]
      ? habit.checkins[currentDate.format('DDMMYYYY')].rawValue
      : null;
  const isSkipOrOff: boolean = isValidCheckInSkip(checkInStatus) || isValidCheckInSkip(progressStatus);
  let subStartDate: string | null = null;
  let subEndDate: string | null = null;
  let subCount = 0;
  if (habit.regularly === DAILY || !habit.regularly) {
    if (!subEndDate) subEndDate = currentDate.format('YYYY/MM/DD');
    if (!isSkipOrOff) {
      subCount = 1;
    }
    subStartDate = currentDate.format('YYYY/MM/DD');
    return { subStartDate, subCount, subEndDate };
  }
  if (!subEndDate && isValidStreak && isValidStreakByRegularly) subEndDate = currentDate.format('YYYY/MM/DD');
  if (!isSkipOrOff && isValidStreak && isValidStreakByRegularly) subCount = 1;
  if (isValidStreak && isValidStreakByRegularly) subStartDate = currentDate.format('YYYY/MM/DD');
  return { subStartDate, subCount, subEndDate };
};

const setHabitOffModeId = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  offModeId: string | null;
  dateKey: {
    dayKey: string;
  };
}) => {
  const {
    habitProgressInfo,
    offModeId,
    dateKey: { dayKey },
  } = parameters;
  habitProgressInfo.dateToOffModeId?.set(dayKey, offModeId);
};

export const getSingleProgressData = (
  habit: Habit,
  habitLogsMap: Map<string, IHabitLogFilter>,
  listHabitGoal: HabitGoals,
  isCurrentStreak: boolean,
  firstDayOfWeek: FirstWeekDay,
  dateRangesOffMode: DateRange[],
): SingleProgressDataCalculator => {
  try {
    const habitStartDate: number | null | undefined = habit.startDate,
      habitRegularly: string | null | undefined = habit.regularly,
      resultHabitStreaks: IStreaks[] = [];
    const dateToCheckInStatusMap: Map<string, number> = new Map();
    const dateToActualGoalValueMap: Map<string, number> = new Map();
    const dateToActualNoGoalValueMap: Map<string, number> = new Map();
    const dateToGoalValueMap: Map<string, number> = new Map();
    const dateToHabitGoalCurrentMap: Map<string, IHabitNewGoal | IHabitGoal> = new Map();
    const dateToActualSymbolMap: Map<string, string> = new Map();
    const dateToActualCheckInStatus: Map<string, number> = new Map();
    const dateToLogValueWeekMap: Map<string, number> = new Map();
    const dateToLogValueMonthMap: Map<string, number> = new Map();
    const dateToNumberOfCheckInStatusWeek: Map<string, number> = new Map();
    const dateToNumberOfCheckInStatusMonth: Map<string, number> = new Map();
    const dateToGoalWeekMap: Map<string, IHabitNewGoal> = new Map();
    const dateToGoalMonthMap: Map<string, IHabitNewGoal> = new Map();
    const symbolDefault = '';
    const dateToNumberOfCompletedMonthMap = new Map();
    const dateToNumberOfSkippedMonthMap = new Map();
    const dateToNumberOfFailedMonthMap = new Map();
    const dateToLogValueYearMap = new Map();
    const dateToConditionIsCurrentStreakMap = new Map();
    const dateToBadHabitZeroDayMap = new Map();
    const dateToNumberLogValueMonthMap = new Map();
    const dateToConditionDisplayHabitBuRegularly = new Map();
    const dateToOffModeId = new Map();

    const habitProgressInfo: HabitProgressMapInfo = {
      habitStartDate: habit?.startDate,
      logInfoType: habit.logInfo?.type,
      dateToCheckInStatusMap,
      dateToActualGoalValueMap,
      dateToActualNoGoalValueMap,
      dateToGoalValueMap,
      dateToHabitGoalCurrentMap,
      dateToActualSymbolMap,
      dateToLogValueWeekMap,
      dateToLogValueMonthMap,
      dateToActualCheckInStatus,
      dateToNumberOfCheckInStatusWeek,
      dateToNumberOfCheckInStatusMonth,
      dateToGoalWeekMap,
      dateToGoalMonthMap,
      symbolDefault,
      dateToNumberOfCompletedMonthMap,
      dateToNumberOfSkippedMonthMap,
      dateToNumberOfFailedMonthMap,
      dateToLogValueYearMap,
      dateToConditionIsCurrentStreakMap,
      dateToBadHabitZeroDayMap,
      dateToNumberLogValueMonthMap,
      dateToConditionDisplayHabitBuRegularly,
      dateToOffModeId,
    };

    let currentDate: dayjs.Dayjs = dayjs(),
      habitRegularlyCurrent = '',
      count = 0,
      startDate: string | null = null,
      endDate: string | null = null,
      isStreaks = false,
      isPerfectStreak = false,
      _isValidStreak = false,
      daysByRegularly: Map<string, string | number> = new Map();

    if (habitRegularly?.includes(MONTH_DAYS)) daysByRegularly = getListDayByRegularly(habit);
    if (habitRegularly?.includes(WEEK_DAYS)) daysByRegularly = getListDayByRegularly(habit);
    let streakProperties: StreakProperties | null = null;
    const formatHabitStartDate = dayjs(dayjs(habitStartDate).format('YYYY-MM-DD'));
    while (true) {
      if (formatHabitStartDate.isAfter(currentDate) || (isCurrentStreak && resultHabitStreaks.length > 1)) break;
      const _habitGoalCurrent: HabitGoal | null | undefined = Object.keys(listHabitGoal).length
        ? getCurrentHabitGoal({ goals: listHabitGoal, date: currentDate, firstDayOfWeek })
        : null;
      const subHabitProgressInfo: HabitProgressInfo = getHabitProgressInfo(
        habit,
        habitLogsMap,
        currentDate,
        _habitGoalCurrent,
        firstDayOfWeek,
        dateRangesOffMode,
      );

      setHabitProgressInfo(subHabitProgressInfo, habitProgressInfo, currentDate, habit, habitLogsMap, firstDayOfWeek);
      _isValidStreak = isValidStreak(subHabitProgressInfo);

      switch (true) {
        case habitRegularly?.includes(WEEK_DAYS):
          habitRegularlyCurrent = WEEK_DAYS;
          const _isValidStreakByRegularlyWeekDay = isValidStreakByRegularly(
            habit,
            currentDate,
            habitRegularlyCurrent,
            daysByRegularly,
          );
          isStreaks = !(!_isValidStreak && _isValidStreakByRegularlyWeekDay);

          if (isStreaks)
            streakProperties = calculationStreakProperties(
              habit,
              currentDate,
              _isValidStreak,
              _isValidStreakByRegularlyWeekDay,
              subHabitProgressInfo,
            );
          dateToConditionDisplayHabitBuRegularly.set(getDayKey(currentDate), _isValidStreakByRegularlyWeekDay);
          setConditionToCurrentStreakByRegularly(_isValidStreakByRegularlyWeekDay, currentDate, habitProgressInfo);
          break;
        case habitRegularly?.includes(MONTH_DAYS):
          habitRegularlyCurrent = MONTH_DAYS;
          const _isValidStreakByRegularlyMonth = isValidStreakByRegularly(
            habit,
            currentDate,
            habitRegularlyCurrent,
            daysByRegularly,
          );
          isStreaks = !(!_isValidStreak && _isValidStreakByRegularlyMonth);

          if (isStreaks)
            streakProperties = calculationStreakProperties(
              habit,
              currentDate,
              _isValidStreak,
              _isValidStreakByRegularlyMonth,
              subHabitProgressInfo,
            );
          dateToConditionDisplayHabitBuRegularly.set(getDayKey(currentDate), _isValidStreakByRegularlyMonth);
          setConditionToCurrentStreakByRegularly(_isValidStreakByRegularlyMonth, currentDate, habitProgressInfo);
          break;
        case habitRegularly?.includes(DAY_INTERVAL):
          habitRegularlyCurrent = DAY_INTERVAL;
          const _isValidStreakByRegularlyDayInterVal = isValidStreakByRegularly(
            habit,
            currentDate,
            habitRegularlyCurrent,
            new Map(),
          );
          isStreaks = !(!_isValidStreak && _isValidStreakByRegularlyDayInterVal);
          if (isStreaks)
            streakProperties = calculationStreakProperties(
              habit,
              currentDate,
              _isValidStreak,
              _isValidStreakByRegularlyDayInterVal,
              subHabitProgressInfo,
            );
          dateToConditionDisplayHabitBuRegularly.set(getDayKey(currentDate), _isValidStreakByRegularlyDayInterVal);
          setConditionToCurrentStreakByRegularly(_isValidStreakByRegularlyDayInterVal, currentDate, habitProgressInfo);
          break;
        default:
          isStreaks = _isValidStreak;
          if (isStreaks)
            streakProperties = calculationStreakProperties(
              habit,
              currentDate,
              _isValidStreak,
              false,
              subHabitProgressInfo,
            );
          dateToConditionDisplayHabitBuRegularly.set(getDayKey(currentDate), true);
          setConditionToCurrentStreakNormal(currentDate, habitProgressInfo);
          break;
      }

      if (isStreaks || currentDate.format('DDMMYYYY') === dayjs().format('DDMMYYYY')) {
        if (isStreaks && streakProperties) {
          const { subStartDate, subCount, subEndDate } = streakProperties;
          if (!endDate && subEndDate) endDate = subEndDate;
          count += subCount;
          if (subStartDate) startDate = subStartDate;
        } else {
          const checkInStatus = subHabitProgressInfo.checkInStatus;
          endDate =
            isToday(currentDate) && checkInStatus === CheckInsStatus.FAIL
              ? dayjs().subtract(1, 'day').format('YYYY/MM/DD')
              : dayjs().format('YYYY/MM/DD');
          count = 0;
          startDate = endDate;
        }
        isPerfectStreak = true;
      } else {
        if (count > 0) resultHabitStreaks.push({ count, startDate, endDate });
        count = 0;
        startDate = null;
        endDate = null;
        isPerfectStreak = false;
      }
      currentDate = currentDate.subtract(1, 'days');
    }

    if (isPerfectStreak && count > 0) resultHabitStreaks.push({ count, startDate, endDate });
    return {
      streaks: resultHabitStreaks,
      habitProgressInfo,
    };
  } catch (error) {
    throw new Error(error as string | undefined);
  }
};
