import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { StartWeekDay } from './date-picker.type';

type ReturnType = {
  weekDays: string[];
  displayWeekDays: string[];
  firstWeekDayOfMonth: number;
  lastWeekDayOfMonth: number;
};

export const useWeekDays = (
  startWith: StartWeekDay,
  defaultFirstWeekDayOfMonth: number,
  defaultLastWeekDayOfMonth: number,
  lang: string,
): ReturnType => {
  const defaultWeekDays = useMemo(() => ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'], []);
  const [defaultDisplayWeekDays, setDefaultDisplayWeekDays] = useState<string[]>([]);
  const [weekDays, setWeekDays] = useState<string[]>([]);
  const [displayWeekDays, setDisplayWeekDays] = useState<string[]>([]);
  const [firstWeekDayOfMonth, setFirstWeekDayOfMonth] = useState<number>(0);
  const [lastWeekDayOfMonth, setLastWeekDayOfMonth] = useState<number>(0);

  useEffect(() => {
    import(`dayjs/locale/${lang}.js`).then((res) => {
      dayjs.locale({
        ...res,
        weekStart: 0,
      });
      setDefaultDisplayWeekDays(dayjs().localeData().weekdaysMin());
    });
  }, [lang]);

  useEffect(() => {
    const indexOfStartWeekDay = defaultWeekDays.map((weekDay) => weekDay.toLowerCase()).indexOf(startWith);

    const newWeekDays = [
      ...defaultWeekDays.slice(indexOfStartWeekDay, defaultWeekDays.length),
      ...defaultWeekDays.slice(0, indexOfStartWeekDay),
    ];

    const newDisplayWeekDays = [
      ...defaultDisplayWeekDays.slice(indexOfStartWeekDay, defaultDisplayWeekDays.length),
      ...defaultDisplayWeekDays.slice(0, indexOfStartWeekDay),
    ];

    const defaultFirstWeekDay = defaultWeekDays[defaultFirstWeekDayOfMonth];
    const newFirstWeekDayOfMonth = newWeekDays.indexOf(defaultFirstWeekDay);

    const defaultLastWeekDay = defaultWeekDays[defaultLastWeekDayOfMonth];
    const newLastWeekDayOfMonth = newWeekDays.indexOf(defaultLastWeekDay);

    setFirstWeekDayOfMonth(newFirstWeekDayOfMonth);
    setLastWeekDayOfMonth(newLastWeekDayOfMonth);
    setWeekDays(newWeekDays);
    setDisplayWeekDays(newDisplayWeekDays);
  }, [defaultDisplayWeekDays, defaultFirstWeekDayOfMonth, defaultLastWeekDayOfMonth, defaultWeekDays, startWith]);

  return { weekDays, displayWeekDays, firstWeekDayOfMonth, lastWeekDayOfMonth };
};
