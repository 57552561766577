import firebase from '.';
import { getRemoteConfig } from 'firebase/remote-config';

const remoteConfig = getRemoteConfig(firebase);

remoteConfig.settings = {
  fetchTimeoutMillis: 60000,
  minimumFetchIntervalMillis: 60000, //60000 - 1minute
  // minimumFetchIntervalMillis: 3600000, //3600000 - 1hour
};

export default remoteConfig;
