import { TIME_OF_DAY_BITWISE } from '__archived__/types/enum';

export function combineTimeOfDay(timeOfDayBitwise: number): TIME_OF_DAY_BITWISE[] {
  switch (timeOfDayBitwise) {
    case TIME_OF_DAY_BITWISE.MORNING | TIME_OF_DAY_BITWISE.AFTERNOON:
      return [TIME_OF_DAY_BITWISE.MORNING, TIME_OF_DAY_BITWISE.AFTERNOON];
    case TIME_OF_DAY_BITWISE.MORNING | TIME_OF_DAY_BITWISE.EVENING:
      return [TIME_OF_DAY_BITWISE.MORNING, TIME_OF_DAY_BITWISE.EVENING];
    case TIME_OF_DAY_BITWISE.AFTERNOON | TIME_OF_DAY_BITWISE.EVENING:
      return [TIME_OF_DAY_BITWISE.AFTERNOON, TIME_OF_DAY_BITWISE.EVENING];
    case TIME_OF_DAY_BITWISE.MORNING:
      return [TIME_OF_DAY_BITWISE.MORNING];
    case TIME_OF_DAY_BITWISE.AFTERNOON:
      return [TIME_OF_DAY_BITWISE.AFTERNOON];
    case TIME_OF_DAY_BITWISE.EVENING:
      return [TIME_OF_DAY_BITWISE.EVENING];
    default:
      return [TIME_OF_DAY_BITWISE.ALL];
  }
}
