import memoizeOne from 'memoize-one';
import { Habit } from 'models/habits';

export const sortHabitsByHabitPriority = memoizeOne(({ habits }: { habits: Habit[] }): Promise<Habit[]> => {
  return new Promise((resolve, reject) => {
    try {
      resolve(habits.sort((a, b) => sortHabitByHabitPriority({ priorityA: a.priority, priorityB: b.priority })));
    } catch (e) {
      reject(e);
    }
  });
});

export const sortHabitByHabitPriority = ({
  priorityA,
  priorityB,
}: {
  priorityA: number | null | undefined;
  priorityB: number | null | undefined;
}): number => {
  if (priorityA !== undefined && priorityA !== null && priorityB !== undefined && priorityB !== null) {
    return priorityB - priorityA;
  } else if ((priorityA === undefined || priorityA === null) && priorityB !== undefined && priorityB !== null) {
    return -1;
  } else if (priorityA !== undefined && priorityA !== null && (priorityB === undefined || priorityB === null)) {
    return 1;
  }
  return 1;
};
