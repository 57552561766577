import { HABIT_PROGRESS_FILTER } from '__archived__/types/enum/app';
import {
  IActionModal,
  IAppState,
  IContextMenu,
  IEventCount,
  IEvents,
  IMood,
  IMoodContextMenu,
  IMoodSelected,
  IRemoteConfig,
  ITimeBound,
  ITimerOverlay,
} from '__archived__/types/states/app';
import { IUserInfo } from '__archived__/types/states/habit';

export enum APP_TYPES {
  UPDATE_TIME_OF_DAY = '@@app/updateTimeOfDay',
  UPDATE_HABIT_PROGRESS_FILTER = '@@app/updateHabitProgressFilter',
  UPDATE_HABIT_FOLDER_FILTER = '@@app/updateHabitFolderFilter',
  UPDATE_CONTEXT_MENU_PROPS = '@@app/updateContextMenuProps',
  UPDATE_CONTEXT_MENU_PROPS_SINGLE_PROGRESS = '@@app/updateContextMenuPropsSingleProgress',
  UPDATE_LOG_ACTION_MODAL_PROPS = '@@app/updateLogActionModalProps',
  UPDATE_MANUAL_LOG_MODAL_PROPS = '@@app/updateManualLogModalProps',
  UPDATE_DATE_PICKER_MODAL_STATE = '@@app/updateDatePickerModalState',
  UPDATE_DATE = '@@app/updateDate',
  UPDATE_DATE_SINGLE_PROGRESS = '@@app/updateDateSingleProgress',
  UPDATE_TIMER_MODAL_PROPS = '@@app/updateTimerModalProps',
  UPDATE_TIMER_OVERLAY_PROPS = '@@app/updateTimerOverlayProps',
  GET_USER_INFO = '@@app/getUserInfo',
  GET_USER_INFO_SUCCESS = '@@app/getUserInfoSuccess',
  GET_USER_INFO_FAILED = '@@app/getUserInfoFailed',
  GET_USAGE_CONFIG = '@@app/getUsageConfig',
  OPEN_LIMIT_MODAL = '@@app/openLimitModal',
  UPDATE_USER_INFO_CHILD_ADDED = '@@app/updateUserInfoChildAdded',
  UPDATE_USER_INFO_CHILD_CHANGED = '@@app/updateUserInfoChildChanged',
  UPDATE_USER_INFO_CHILD_REMOVED = '@@app/updateUserInfoChildRemove',
  ADD_EVENT = '@@app/addEvent',
  GET_EVENT = '@@app/getEvent',
  UPDATE_EVENT_CHILD_REMOVED = '@app/updateEventChildRemoved',
  FILTER_EVENT = '@@app/filterEvent',
  ADD_EVENT_COUNT = '@@app/addEventCount',
  UPDATE_EVENT_TYPE = '@@app/updateEventType',
  UPDATE_LIMIT_TYPE_CURRENT = '@@app/updateLimitTypeCurrent',
  GET_USER_PREFERENCES = '@@app/getUserPreferences',
  GET_USER_PREFERENCES_SUCCESS = '@@app/getUserPreferencesSuccess',
  GET_USER_PREFERENCES_FAILED = '@@app/getUserPreferencesFailed',
  UPDATE_ON_USER_PREFERENCES_CHILD_ADDED = '@@app/updateOnUserPreferencesChildAdded',
  UPDATE_ON_USER_PREFERENCES_CHILD_CHANGED = '@@app/updateOnUserPreferencesChildChanged',
  UPDATE_ON_USER_PREFERENCES_CHILD_REMOVED = '@@app/updateOnUserPreferencesChildRemoved',
  UPDATE_SIGN_OUT_ALERT_STATE = '@@app/updateSignOutAlertState',
  GET_MOOD = '@@app/getMood',
  GET_MOOD_SUCCESS = '@@app/getMoodSuccess',
  GET_MOOD_FAILED = '@@app/getMoodFailed',
  UPDATE_MOOD_LOG_CHILD_ADDED = '@@app/updateMoodLogChildAdded',
  UPDATE_MOOD_LOG_CHILD_REMOVE = '@@app/updateMoodLogChildRemove',
  UPDATE_MOOD_LOG_CHILD_CHANGED = '@@app/updateMoodLogChildChanged',
  FILTERING_MOOD = '@@app/filteringMood',
  FILTERING_MOOD_SUCCESS = '@@app/filteredMoodSuccess',
  FILTERING_MOOD_FAILED = '@@app/filteredMoodFailed',
  UPDATE_MOOD_CONTEXT_MENU_PROPS = '@@app/updateMoodContextMenuProps',
  UPDATE_DETAIL_HABIT = '@@app/updateDetailHabit',
  UPDATE_USER_ENDPOINT = '@@app/updateUserEndpoint',
  REMOVE_USER_ENDPOINT = '@@app/removeUserEndpoint',
  UPDATE_FIST_WEEK_DAY_CHILD_ADDED = '@@app/updateFirstWeekDayChildAdded',
  UPDATE_FIST_WEEK_DAY_CHILD_CHANGED = '@@app/updateFirstWeekDayChildChanged',
  UPDATE_FIST_WEEK_DAY_CHILD_REMOVED = '@@app/updateFirstWeekDayChildRemoved',
  UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_ADDED = '@@app/updateJournalLayoutTypeChildAdded',
  UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_CHANGED = '@@app/updateJournalLayoutTypeChildChanged',
  UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_REMOVED = '@@app/updateJournalLayoutTypeChildRemoved',
  OPEN_MOOD_NOTES_MODAL = '@@app/openMoodNoteModal',
  SELECT_MOOD = '@@app/selectMood',
  UPDATE_DETAIL_MOOD_ID = '@@app/updateMoodId',
  SHOW_JOURNAL_ANNOUNCEMENT = '@app/showJournalAnnouncement',
  SORT_JOURNAL_TYPE = '@@app/sortJournalType',
  PREFERENCES_LOADED = '@@app/preferencesLoaded',
  RESET_DATA = '@@app/resetData',
}

export type UpdateTimeOfDayType = {
  readonly type: APP_TYPES.UPDATE_TIME_OF_DAY;
  readonly payload: number;
};

export type UpdateHabitProgressFilter = {
  readonly type: APP_TYPES.UPDATE_HABIT_PROGRESS_FILTER;
  readonly payload: HABIT_PROGRESS_FILTER;
};

export type UpdateHabitFolderFilter = {
  readonly type: APP_TYPES.UPDATE_HABIT_FOLDER_FILTER;
  readonly payload: string;
};

export type UpdateContextMenuProps = {
  readonly type: APP_TYPES.UPDATE_CONTEXT_MENU_PROPS;
  readonly payload: IContextMenu;
};

export type UpdateContextMenuPropsSingleProgress = {
  readonly type: APP_TYPES.UPDATE_CONTEXT_MENU_PROPS_SINGLE_PROGRESS;
  readonly payload: IContextMenu;
};

export type UpdateLogActionModal = {
  readonly type: APP_TYPES.UPDATE_LOG_ACTION_MODAL_PROPS;
  readonly payload: IActionModal;
};

export type UpdateDatePickerModalState = {
  readonly type: APP_TYPES.UPDATE_DATE_PICKER_MODAL_STATE;
  readonly payload: boolean;
};

export type UpdateDate = {
  readonly type: APP_TYPES.UPDATE_DATE;
  readonly payload: string;
};

export type UpdateDateSingleProgress = {
  readonly type: APP_TYPES.UPDATE_DATE_SINGLE_PROGRESS;
  readonly payload: string;
};

export type UpdateManualLogModalProps = {
  readonly type: APP_TYPES.UPDATE_MANUAL_LOG_MODAL_PROPS;
  readonly payload: IActionModal;
};

export type UpdateTimerModalProps = {
  readonly type: APP_TYPES.UPDATE_TIMER_MODAL_PROPS;
  readonly payload: IActionModal;
};

export type UpdateTimerOverlayProps = {
  readonly type: APP_TYPES.UPDATE_TIMER_OVERLAY_PROPS;
  readonly payload: ITimerOverlay;
};

export type GetUserInfoType = {
  readonly type: APP_TYPES.GET_USER_INFO;
  readonly payload: boolean;
};

export type GetUserInfoSuccessType = {
  readonly type: APP_TYPES.GET_USER_INFO_SUCCESS;
  readonly payload: IUserInfo;
};

export type GetUserInfoFailedType = {
  readonly type: APP_TYPES.GET_USER_INFO_FAILED;
  readonly payload?: boolean;
};

export type GetAllUserInfoType =
  | GetUserInfoType
  | GetUserInfoSuccessType
  | GetUserInfoFailedType
  | UpdateUserInfoChildAddedType
  | UpdateUserInfoChildChangedType
  | UpdateUserInfoChildRemoveType;

export type GetUsageConfigType = {
  readonly type: APP_TYPES.GET_USAGE_CONFIG;
  readonly payload: IRemoteConfig;
};

export type OpenLimitModalType = {
  readonly type: APP_TYPES.OPEN_LIMIT_MODAL;
  readonly payload: boolean;
};

export type UpdateUserInfoChildAddedType = {
  readonly type: APP_TYPES.UPDATE_USER_INFO_CHILD_ADDED;
  readonly payload: { key: string; value: string | number };
};

export type UpdateUserInfoChildChangedType = {
  readonly type: APP_TYPES.UPDATE_USER_INFO_CHILD_CHANGED;
  readonly payload: { key: string; value: string | number };
};

export type UpdateUserInfoChildRemoveType = {
  readonly type: APP_TYPES.UPDATE_USER_INFO_CHILD_REMOVED;
  readonly payload: { key: string; value: string | number };
};

export type AddEventType = {
  readonly type: APP_TYPES.ADD_EVENT;
  readonly payload: { id: string; event: IEvents };
};

export type UpdateEventChildRemoveType = {
  readonly type: APP_TYPES.UPDATE_EVENT_CHILD_REMOVED;
  readonly payload: string;
};

export type GetEventType = {
  readonly type: APP_TYPES.GET_EVENT;
  readonly payload: Map<string, IEvents>;
};

export type FilterEventType = {
  readonly type: APP_TYPES.FILTER_EVENT;
  readonly payload: IEvents[];
};

export type AddEventCount = {
  readonly type: APP_TYPES.ADD_EVENT_COUNT;
  readonly payload: IEventCount;
};

export type UpdateEventType = {
  readonly type: APP_TYPES.UPDATE_EVENT_TYPE;
  readonly payload: string;
};

export type UpdateLimitTypeCurrent = {
  readonly type: APP_TYPES.UPDATE_LIMIT_TYPE_CURRENT;
  readonly payload: string;
};

export type EventType =
  | AddEventType
  | GetEventType
  | FilterEventType
  | AddEventCount
  | UpdateEventType
  | UpdateEventChildRemoveType;

export type GetUserPreferences = {
  readonly type: APP_TYPES.GET_USER_PREFERENCES;
  readonly payload: boolean;
};

export type UserPreferences = {
  timeOfDaySettings: {
    morningMinuteRange: ITimeBound;
    afternoonMinuteRange: ITimeBound;
    eveningMinuteRange: ITimeBound;
  };
};

export type GetUserPreferencesSuccess = {
  readonly type: APP_TYPES.GET_USER_PREFERENCES_SUCCESS;
  readonly payload: UserPreferences;
};

export type GetUserPreferencesFailed = {
  readonly type: APP_TYPES.GET_USER_PREFERENCES_FAILED;
  readonly payload?: boolean;
};

export type UpdateOnUserPreferencesChildAdded = {
  readonly type: APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_ADDED;
  readonly payload: UserPreferences;
};

export type UpdateOnUserPreferencesChildChanged = {
  readonly type: APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_CHANGED;
  readonly payload: UserPreferences;
};

export type UpdateOnUserPreferencesChildRemoved = {
  readonly type: APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_REMOVED;
};

export type UpdateFirstWeekDayChildAdded = {
  readonly type: APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_ADDED;
  readonly payload: number;
};

export type UpdateFirstWeekDayChildChanged = {
  readonly type: APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_CHANGED;
  readonly payload: number;
};

export type UpdateFirstWeekDayChildRemoved = {
  readonly type: APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_REMOVED;
};

export type UpdateJournalLayoutTypeChildAdded = {
  readonly type: APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_ADDED;
  readonly payload: number;
};

export type UpdateJournalLayoutTypeChildChanged = {
  readonly type: APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_CHANGED;
  readonly payload: number;
};

export type UpdateJournalLayoutTypeChildRemoved = {
  readonly type: APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_REMOVED;
};

export type GetUserPreferencesAction =
  | GetUserPreferences
  | GetUserPreferencesSuccess
  | GetUserPreferencesFailed
  | UpdateOnUserPreferencesChildAdded
  | UpdateOnUserPreferencesChildChanged
  | UpdateOnUserPreferencesChildRemoved
  | UpdateFirstWeekDayChildAdded
  | UpdateFirstWeekDayChildChanged
  | UpdateFirstWeekDayChildRemoved
  | UpdateJournalLayoutTypeChildAdded
  | UpdateJournalLayoutTypeChildChanged
  | UpdateJournalLayoutTypeChildRemoved
  | SortJournalType
  | PreferencesLoaded;

export type UpdateSignOutAlertState = {
  readonly type: APP_TYPES.UPDATE_SIGN_OUT_ALERT_STATE;
  readonly payload: boolean;
};

export type GetMoodType = {
  readonly type: APP_TYPES.GET_MOOD;
  readonly payload: boolean;
};

export type GetMoodSuccessType = {
  readonly type: APP_TYPES.GET_MOOD_SUCCESS;
  readonly payload: Map<string, IMood>;
};

export type GetMoodFailedType = {
  readonly type: APP_TYPES.GET_MOOD_FAILED;
  readonly payload?: boolean;
};

export type MoodLogWithId = {
  moodId: string;
  mood: IMood;
  chooseDate: string;
};

export type UpdateMoodLogChildAddedType = {
  readonly type: APP_TYPES.UPDATE_MOOD_LOG_CHILD_ADDED;
  readonly payload: MoodLogWithId;
};

export type UpdateMoodLogChildChangedType = {
  readonly type: APP_TYPES.UPDATE_MOOD_LOG_CHILD_CHANGED;
  readonly payload: MoodLogWithId;
};

export type UpdateMoodLogChildRemoveType = {
  readonly type: APP_TYPES.UPDATE_MOOD_LOG_CHILD_REMOVE;
  readonly payload: string;
};

export type UpdateMoodIdType = {
  readonly type: APP_TYPES.UPDATE_DETAIL_MOOD_ID;
  readonly payload: string;
};

export type GetAllMoodType =
  | GetMoodType
  | GetMoodSuccessType
  | GetMoodFailedType
  | UpdateMoodLogChildAddedType
  | UpdateMoodLogChildChangedType
  | UpdateMoodLogChildRemoveType
  | UpdateMoodIdType;

export type FilteredMood = {
  readonly type: APP_TYPES.FILTERING_MOOD;
  readonly payload: boolean;
};

export type FilteredMoodWithDate = {
  filteredMood: Map<string, IMood>;
  chooseDate: string;
};

export type FilteredMoodSuccess = {
  readonly type: APP_TYPES.FILTERING_MOOD_SUCCESS;
  readonly payload: FilteredMoodWithDate;
};

export type FilteredMoodFailed = {
  readonly type: APP_TYPES.FILTERING_MOOD_FAILED;
  readonly payload?: boolean;
};

export type UpdateMoodContextMenuProps = {
  readonly type: APP_TYPES.UPDATE_MOOD_CONTEXT_MENU_PROPS;
  readonly payload: IMoodContextMenu;
};

export type FilteredMoodType = FilteredMood | FilteredMoodSuccess | FilteredMoodFailed;
export type UpdateDetailHabit = {
  readonly type: APP_TYPES.UPDATE_DETAIL_HABIT;
  readonly payload: string;
};

export type NotificationInfo = {
  token: string;
  uid: string;
};

export type UpdateUserEndpoint = {
  readonly type: APP_TYPES.UPDATE_USER_ENDPOINT;
};

export type RemoveUserEndpoint = {
  readonly type: APP_TYPES.REMOVE_USER_ENDPOINT;
};

export type OpenMoodNoteModalType = {
  readonly type: APP_TYPES.OPEN_MOOD_NOTES_MODAL;
  readonly payload: boolean;
};

export type SelectMoodType = {
  readonly type: APP_TYPES.SELECT_MOOD;
  readonly payload: IMoodSelected;
};

export type ShowJournalAnnouncementType = {
  readonly type: APP_TYPES.SHOW_JOURNAL_ANNOUNCEMENT;
  readonly payload: boolean;
};

export type SortJournalType = {
  readonly type: APP_TYPES.SORT_JOURNAL_TYPE;
  readonly payload: string;
};

export type PreferencesLoaded = {
  readonly type: APP_TYPES.PREFERENCES_LOADED;
  readonly payload: boolean;
};

export type ResetDataType = {
  readonly type: APP_TYPES.RESET_DATA;
  readonly payload?: IAppState;
};
