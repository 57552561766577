import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';
import Icon from '../Icon';
type InputType = 'default' | 'search' | 'select';
interface InputFieldProps extends InputProps {
  inputType: InputType;
  isOpen?: boolean;
  leftIconName?: string;
  iconSize?: number;
  isStateDisabled?: boolean;
}

const defaultProps: InputFieldProps = {
  iconSize: 12,
  inputType: 'default',
  paddingLeft: '10px',
};

const InputField: React.FC<InputFieldProps> = ({
  inputType,
  isOpen,
  leftIconName,
  iconSize,
  paddingLeft,
  isStateDisabled,
  ...rest
}: InputFieldProps): ReactElement => {
  const { colorScheme } = useThemeData();
  const focusBorderColor = useColorModeValue(colorScheme.accent.primary, '#3662CC');

  return (
    <Box w="full">
      <InputGroup>
        {leftIconName && (
          <InputLeftElement
            h="30px"
            w="12px"
            ml="10px"
            children={
              <Icon name={leftIconName} width={iconSize} height={iconSize} fill={colorScheme.label.secondary} />
            }
          />
        )}
        <Input
          {...rest}
          cursor="default"
          pl={paddingLeft}
          pr="17px"
          textOverflow="ellipsis"
          // border={
          //   isOpen
          //     ? `1.5px solid ${colorScheme.accent.primary}`
          //     : `1px solid ${colorScheme.other.border}`
          // }
          borderRadius="3px"
          borderWidth="1px"
          borderStyle="solid"
          borderColor={isOpen ? colorScheme.accent.primary : colorScheme.other.border}
          _hover={{
            borderColor: isStateDisabled ? colorScheme.other.border : `${focusBorderColor}`,
            borderWidth: '1px',
          }}
          _focus={{ outline: 'none' }}
        />
        {inputType === 'select' && (
          <InputRightElement
            h="30px"
            w="7px"
            mr="12px"
            pointerEvents="none"
            children={
              <Box width="12px" height="12px">
                <Icon name="ic_triangle" fill={isOpen ? colorScheme.accent.primary : colorScheme.label.secondary} />
              </Box>
            }
          />
        )}
      </InputGroup>
    </Box>
  );
};

InputField.defaultProps = defaultProps;

export default InputField;
