import { createModel } from '@rematch/core';
import update from 'immutability-helper';
import { HabitCurrentProgress, HabitsCurrentProgress } from 'models/habit-progress';
import { RootModel } from '../../root.model';

type HabitProgressState = {
  habitsCurrentProgress: HabitsCurrentProgress;
  currentProgressCalculated: boolean;
};

const initialState: HabitProgressState = {
  habitsCurrentProgress: {},
  currentProgressCalculated: false,
};

export const habitProgressModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    onDataSynced(state, payload: HabitsCurrentProgress) {
      return {
        ...state,
        habitsCurrentProgress: payload,
        currentProgressCalculated: true,
      };
    },
    addHabitProgress(state, { habitId, habitProgress }: { habitId: string; habitProgress: HabitCurrentProgress }) {
      return update(state, {
        habitsCurrentProgress: {
          [habitId]: { $set: habitProgress },
        },
      });
    },
    updateHabitProgress(state, { habitId, habitProgress }: { habitId: string; habitProgress: HabitCurrentProgress }) {
      return update(state, {
        habitsCurrentProgress: {
          [habitId]: { $set: habitProgress },
        },
      });
    },
    removeHabitProgress(state, habitId: string) {
      return update(state, {
        habitsCurrentProgress: { $unset: [habitId] },
      });
    },
    resetState(state) {
      return {
        ...state,
        currentProgressCalculated: false,
      };
    },
  },
  effects: (dispatch) => ({
    updateHabitsProgress(habitsCurrentProgress: HabitsCurrentProgress) {
      this.onDataSynced(habitsCurrentProgress);
    },
  }),
});
