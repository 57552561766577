import { Box, Divider, Text } from '@chakra-ui/react';
import React, { ReactElement, useEffect, useState } from 'react';
import TimeOfDaySetting from './time_of_day.settings.app_settings.modal';
import TimeOfDayOptions from './time_of_day_options.app_settings.modal';
import CloseButton from 'components/common/button/close-button.component';
import { useThemeData } from 'hooks/useThemeData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '__archived__/store/roots';
import { ITimeBound } from '__archived__/types/states';
import { NewDispatch, NewRootState } from 'store';
import isNumber from 'lodash/isNumber';
import { useTranslation } from 'react-i18next';

const SUM_TIME = 1440;
interface TimeOfDayProps {
  closeSetting: () => void;
}

const TimeOfDay: React.FC<TimeOfDayProps> = ({ closeSetting }: TimeOfDayProps): ReactElement => {
  const { t } = useTranslation();
  const { colorScheme, typography } = useThemeData();
  const newDispatch = useDispatch<NewDispatch>();
  const uid = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const morningMinuteRange: ITimeBound = useSelector((state: RootState) => state.app.morningMinuteRange);
  const afternoonMinuteRange: ITimeBound = useSelector((state: RootState) => state.app.afternoonMinuteRange);
  const eveningMinuteRange: ITimeBound = useSelector((state: RootState) => state.app.eveningMinuteRange);

  const [timeOfDayOption, setTimeOfDayOption] = useState(-1);

  const getTimeRange = (start: number, end: number) => {
    return end > start ? end - start : SUM_TIME - start + end;
  };

  useEffect(() => {
    const morningTime = getTimeRange(morningMinuteRange.lowerbound, morningMinuteRange.upperbound);
    const afternoonTime = getTimeRange(afternoonMinuteRange.lowerbound, afternoonMinuteRange.upperbound);
    const eveningTime = getTimeRange(eveningMinuteRange.lowerbound, eveningMinuteRange.upperbound);
    const sum = morningTime + afternoonTime + eveningTime;
    if (!isNumber(sum) || Math.abs(sum) > SUM_TIME) {
      const timeOfDaySettings = {
        morningMinuteRange: {
          lowerbound: 0,
          upperbound: 720,
        },
        afternoonMinuteRange: {
          lowerbound: 720,
          upperbound: 1080,
        },
        eveningMinuteRange: {
          lowerbound: 1080,
          upperbound: 0,
        },
      };
      newDispatch.preferencesModels.updateTimeOfDaySettings({
        uid,
        timeOfDaySettings,
      });
    }
  }, [
    afternoonMinuteRange.lowerbound,
    afternoonMinuteRange.upperbound,
    eveningMinuteRange.lowerbound,
    eveningMinuteRange.upperbound,
    morningMinuteRange.lowerbound,
    morningMinuteRange.upperbound,
    newDispatch.preferencesModels,
    uid,
  ]);

  return (
    <Box display="grid" gridTemplateColumns="1fr" gridGap="14px">
      <Box display="flex" justifyContent="space-between" alignItems="center" pr="8px">
        <Text {...typography.emphasized.title[2]} color={colorScheme.label.primary}>
          {t('edithabit.timeOfDay')}
        </Text>
        <CloseButton onClose={closeSetting} />
      </Box>
      <TimeOfDayOptions setTimeOfDayOption={setTimeOfDayOption} morningMinuteRange={morningMinuteRange} />
      <Divider borderColor={colorScheme.platform.border} opacity="1" />
      <TimeOfDaySetting
        timeOfDayOption={timeOfDayOption}
        userPreferences={{
          timeOfDaySettings: {
            morningMinuteRange,
            afternoonMinuteRange,
            eveningMinuteRange,
          },
        }}
      />
    </Box>
  );
};

export default TimeOfDay;
