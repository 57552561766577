import { Grid } from '@chakra-ui/layout';
import React, { ReactElement, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
}

const Main: React.FC<Props> = ({ children }: Props): ReactElement => {
  return (
    <Grid
      height="100vh"
      templateColumns="270px 9fr auto"
      templateRows="auto"
      templateAreas={`"sidebar single-progress notes"`}
    >
      {children}
    </Grid>
  );
};

export default Main;
