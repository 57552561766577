import { Box, Text } from "@chakra-ui/react";
import CloseButton from "components/common/button/close-button.component";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import AppLanguage from "./general-settings-list/app-language.component";
import FirstDayOfWeek from "./general-settings-list/first-day-of-week.component";
import JournalLayout from "./general-settings-list/journal-layout.component";
import JournalName from "./general-settings-list/journal-name.component";
import ThemeMode from "./general-settings-list/theme-mode.component";
interface GeneralSettingProps {
    closeSetting: () => void;
}
const GeneralSetting: React.FC<GeneralSettingProps> = ({
    closeSetting,
}: GeneralSettingProps): ReactElement => {
    const { t } = useTranslation();
    const { typography, colorScheme } = useThemeData();
    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pr="8px"
            >
                <Text
                    {...typography.emphasized.title[2]}
                    color={colorScheme.label.primary}
                >
                    {t("common.general")}
                </Text>
                <CloseButton onClose={closeSetting} />
            </Box>
            <ThemeMode />
            <FirstDayOfWeek />
            <AppLanguage />
            <JournalName />
            <JournalLayout />
        </Box>
    );
};
export default React.memo(GeneralSetting);
