import { useEffect, useState } from 'react';

export const useRemainingLogValue = (
  habitGoalValue: number | undefined,
  habitActualLogValue: number | undefined,
): number => {
  const [remainingLogValue, setRemainingLogValue] = useState<number>(0);

  useEffect(() => {
    let remainingLogValue = 0;
    if (habitGoalValue && !habitActualLogValue) {
      remainingLogValue = habitGoalValue;
    }
    if (habitGoalValue && habitActualLogValue) {
      remainingLogValue = habitGoalValue - habitActualLogValue;
    }
    setRemainingLogValue(remainingLogValue);
  }, [habitGoalValue, habitActualLogValue]);

  return remainingLogValue;
};
