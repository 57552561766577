import Excellent from 'assets/emotion/excellent.json';
import Good from 'assets/emotion/good.json';
import Okay from 'assets/emotion/okay.json';
import Bad from 'assets/emotion/bad.json';
import Terrible from 'assets/emotion/terrible.json';
import { _dayjs } from '__archived__/constants/app';
import { IMood } from '__archived__/types/states/app';
import { MoodType } from '__archived__/types/enum/habit';

export interface IEmotion {
  animation: string;
  title: string;
  type: string;
  value: number;
  icon: string;
}

export const emotions: IEmotion[] = [
  {
    animation: JSON.stringify(Excellent),
    title: 'moodExcellent',
    type: MoodType.EXCELLENT,
    value: 5,
    icon: 'ic-mood-excellent',
  },
  {
    animation: JSON.stringify(Good),
    title: 'moodGood',
    type: MoodType.GOOD,
    value: 4,
    icon: 'ic-mood-good',
  },
  {
    animation: JSON.stringify(Okay),
    title: 'moodOkay',
    type: MoodType.OKAY,
    value: 3,
    icon: 'ic-mood-okay',
  },
  {
    animation: JSON.stringify(Bad),
    title: 'moodBad',
    type: MoodType.BAD,
    value: 2,
    icon: 'ic-mood-bad',
  },
  {
    animation: JSON.stringify(Terrible),
    title: 'moodTerrible',
    type: MoodType.TERRIBLE,
    value: 1,
    icon: 'ic-mood-terrible',
  },
];

export const getEmotion = (moodValue: number): IEmotion => {
  const filteredEmotion = emotions.filter((emotion) => emotion.value === moodValue);
  return filteredEmotion[0];
};

export function filterMoodByDate(mood: IMood | undefined, chooseDate: string): boolean {
  const chooseDateConverted = _dayjs(chooseDate);
  if (mood) {
    const { createdAt } = mood;
    const createdAtFormatted = _dayjs(createdAt).format('YYYY-MM-DD');
    if (chooseDateConverted.diff(_dayjs(createdAtFormatted), 'days') === 0) {
      return true;
    }
    return false;
  }
  return false;
}
