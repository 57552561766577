import { Modal, ModalOverlay, ModalProps } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement, ReactNode } from 'react';
interface Props extends ModalProps {
  children: ReactNode;
}
const PreferenceModalLayout: React.FC<Props> = ({ children, isOpen, onClose, ...rest }: Props): ReactElement => {
  const { colorScheme } = useThemeData();
  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose} {...rest}>
      <ModalOverlay bgColor={colorScheme.platform.backdrop} />
      {children}
    </Modal>
  );
};
export default PreferenceModalLayout;
