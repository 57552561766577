import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';
import { UnitSymbol } from 'models/common';
import { HabitLog } from 'models/habit-logs';
import { GoalPeriodicity } from 'models/habits';
import { useEffect, useState } from 'react';
import { getType } from 'tools/si-unit/si-unit-utils';
import { _dayjs } from '__archived__/constants/app';
import { getDaysByRangeDate } from '__archived__/utils/habitUtils';

export interface FilteredItems {
  [key: string]: any;
}

export const useFilterTodayLog = (
  habitLogs: HabitLog[],
  periodicity: GoalPeriodicity | null | undefined,
  symbol: UnitSymbol | null | undefined,
  chooseDate: string,
  firstDayOfWeek: FirstWeekDay | undefined,
): FilteredItems => {
  const [logItems, setLogItems] = useState<FilteredItems>({});
  useEffect(() => {
    if (habitLogs && habitLogs.length && periodicity && symbol) {
      const formattedLogDate = _dayjs(chooseDate).format('YYYY-MM-DD');
      const logItems: FilteredItems = {};
      if (firstDayOfWeek) {
        const days = getDaysByRangeDate(periodicity, _dayjs(formattedLogDate), firstDayOfWeek);
        const goalType = getType({ unitSymbol: symbol });
        const _habitLog = habitLogs.filter((habitLog) => {
          const startAtFormatted = _dayjs(habitLog.startAt).format('DDMMYYYY');
          if (habitLog.unitSymbol) {
            const habitLogUnitSymbol = habitLog.unitSymbol;
            return (
              startAtFormatted === days[startAtFormatted] && goalType === getType({ unitSymbol: habitLogUnitSymbol })
            );
          }
          return false;
        });
        if (_habitLog && _habitLog.length > 0) {
          for (let i = 0; i < _habitLog.length; i++) {
            const habitLogId = _habitLog[i].id;
            if (habitLogId) {
              logItems[habitLogId] = null;
            }
          }
        }
        setLogItems(logItems);
      }
    } else {
      setLogItems({});
    }
  }, [chooseDate, firstDayOfWeek, habitLogs, periodicity, symbol]);

  return logItems;
};
