import { Dayjs } from 'dayjs';
import { HabitLog } from 'models/habit-logs';
import { GoalPeriodicity, HabitGoal } from 'models/habits';
import memoizeOne from 'memoize-one';
import { _dayjs } from '../extended-dayjs';
import { convert, getBaseUnitFromType, getType } from '../si-unit/si-unit-utils';
import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';

type Days = {
  [key: string]: string;
};

const NUMBER_OF_WEEK_DAYS = 6;

export const computeProgressFromHabitLog = memoizeOne(
  ({
    goal,
    habitLogs,
    startDate,
    date,
    isBadHabit,
    firstDayOfWeek,
  }: {
    goal: HabitGoal;
    habitLogs: HabitLog[];
    date: Dayjs;
    startDate: number;
    isBadHabit: boolean | number | undefined | null;
    firstDayOfWeek: FirstWeekDay;
  }): number => {
    const { periodicity, value, unit } = goal;
    const symbol = unit?.symbol;

    if (periodicity && value && symbol) {
      const days = getDaysByPeriodicity({ periodicity: periodicity, date: date, firstDayOfWeek });
      const unitType = getType({ unitSymbol: symbol });
      const _habitLogs = habitLogs.filter((habitLog) => {
        const { startAt, unitSymbol } = habitLog;
        if (startAt && unitSymbol) {
          const startAtFormatted = _dayjs(startAt).format('DDMMYYYY');
          const startDateFormatted = _dayjs(startDate).format('YYYY-MM-DD');
          const isPassDateRange = _dayjs(startAt).valueOf() >= _dayjs(startDateFormatted).valueOf();
          return (
            startAtFormatted === days[startAtFormatted] &&
            unitType === getType({ unitSymbol: unitSymbol }) &&
            isPassDateRange
          );
        }
        return false;
      });

      const baseUnitSymbol = getBaseUnitFromType({ type: unitType });
      const convertedGoalValue = convert({ source: symbol, target: baseUnitSymbol, value });

      if (_habitLogs && _habitLogs.length > 0) {
        let logValue = 0;
        for (let i = 0; i < _habitLogs.length; i++) {
          const { value, unitSymbol, startAt } = _habitLogs[i];
          if (value && unitSymbol && startAt) {
            if (unitSymbol === baseUnitSymbol) {
              const timestampOfStartAtLog = _dayjs(startAt).valueOf();
              const timestampEndDayOfDateSelected = date.add(23, 'hour').add(59, 'minute').add(59, 'second').valueOf();
              if (isBadHabit && timestampOfStartAtLog <= timestampEndDayOfDateSelected) {
                logValue += value;
              }
              if (!isBadHabit) {
                logValue += value;
              }
            }
          }
        }
        let progress = 0;
        if (convertedGoalValue !== 0) {
          progress = (logValue / convertedGoalValue) * 100;
        }
        if (!convertedGoalValue && logValue > 0) {
          progress = 100;
        }
        return progress;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  },
);

const getDaysByPeriodicity = memoizeOne(
  ({
    periodicity,
    date,
    firstDayOfWeek,
  }: {
    periodicity: GoalPeriodicity;
    date: Dayjs;
    firstDayOfWeek: FirstWeekDay;
  }): Days => {
    let days: Days = {};
    let day: Dayjs;
    const clonedDate = date.clone();

    switch (periodicity) {
      case 'weekly':
        day = clonedDate.startOf('week');
        if (firstDayOfWeek === 'monday') {
          day = clonedDate.add(-1, 'day').startOf('week').add(1, 'day');
        }
        for (let i = 0; i <= NUMBER_OF_WEEK_DAYS; i++) {
          days = {
            ...days,
            [`${_dayjs(day.toDate()).format('DDMMYYYY')}`]: _dayjs(day.toDate()).format('DDMMYYYY'),
          };
          day = day.clone().add(1, 'day');
        }
        break;
      case 'monthly':
        day = date.startOf('month');
        const daysInMonth = date.daysInMonth();
        for (let i = 1; i <= daysInMonth; i++) {
          days = {
            ...days,
            [`${_dayjs(day.toDate()).format('DDMMYYYY')}`]: _dayjs(day.toDate()).format('DDMMYYYY'),
          };
          day = day.clone().add(1, 'day');
        }
        break;
      case 'daily':
        days = {
          [`${_dayjs(date).format('DDMMYYYY')}`]: _dayjs(date).format('DDMMYYYY'),
        };
        break;
      default:
        days = {};
    }
    return days;
  },
);
