import { RootState } from '__archived__/store/roots';
import { JOURNAL_LAYOUT_TYPE } from '__archived__/types/enum/app';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const useCheckNewJournalLayout = (): boolean => {
  const journalLayoutType = useSelector((state: RootState) => state.app.journalLayoutType);
  const [isNewJournal, setIsNewJournal] = useState(false);

  useEffect(() => {
    if (journalLayoutType === JOURNAL_LAYOUT_TYPE.NEW_LAYOUT) {
      setIsNewJournal(true);
    } else {
      setIsNewJournal(false);
    }
  }, [journalLayoutType]);

  return isNewJournal;
};
