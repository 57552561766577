type Return = {
  handleOnKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnInput: (e: React.FormEvent<HTMLInputElement>) => string;
};

export const useHandleNumberInput = (): Return => {
  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === '.' || e.key === '-') {
      e.preventDefault();
    }
  };

  const handleOnInput = (e: React.FormEvent<HTMLInputElement>): string => {
    let { value } = e.currentTarget;
    value = value.replace(/[^0-9]*/g, '');
    return value;
  };

  return {
    handleOnKeyDown,
    handleOnInput,
  };
};
