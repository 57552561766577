import firebase from '__archived__/firebase-compat/index';
import { UnitSymbol } from 'models/common';
import { HabitLog } from 'models/habit-logs';
import { HABIT_LOG_TYPE, HABIT_PROGRESS } from '__archived__/types/enum/habit';

const db = firebase.database();

export const createNewHabitLog = (
  uid: string,
  habitId: string,
  datetime: string,
  unitSymbol: UnitSymbol,
  value: number,
): void => {
  const habitLogsRef = db.ref(`habitLogs/${uid}/${habitId}`);
  const newHabitLog: HabitLog = {
    startAt: datetime,
    endAt: datetime,
    type: HABIT_LOG_TYPE.MANUAL,
    unitSymbol: unitSymbol,
    value: value,
  };
  habitLogsRef.push().set(newHabitLog);
};

export const checkinNoGoalHabit = (uid: string, habitId: string, date: string): void => {
  const habitsRef = db.ref(`habits/${uid}/${habitId}/checkins`);
  habitsRef.child(date).set(HABIT_PROGRESS.COMPLETE);
};

export const skipHabit = (uid: string, habitId: string, date: string): void => {
  const habitsRef = db.ref(`habits/${uid}/${habitId}/checkins`);
  habitsRef.child(date).set(HABIT_PROGRESS.SKIP);
};

export const failHabit = (uid: string, habitId: string, date: string): void => {
  const habitsRef = db.ref(`habits/${uid}/${habitId}/checkins`);
  habitsRef.child(date).set(HABIT_PROGRESS.FAILED);
};

export const removeCheckinHabit = (uid: string, habitId: string, date: string): void => {
  const habitsRef = db.ref(`habits/${uid}/${habitId}/checkins`);
  habitsRef.child(date).remove();
};
