import { Box } from '@chakra-ui/react';
import SEO from 'components/common/SEO';
import IFTTTAndZapierAuthentication from 'components/authentication/integrate/ifttt_and_zapier.authentication';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import { NewRootState } from 'store';
import getAppTitle from '__archived__/utils/appTitle';

interface IAuthenticationProps {
  children?: ReactNode;
  isRemoteAuthentication?: boolean;
}

const Authentication: React.FC<IAuthenticationProps> = ({
  children,
  isRemoteAuthentication,
}: IAuthenticationProps): ReactElement => {
  const { colorScheme } = useThemeData();
  const isLoggedIn: boolean = useSelector((state: NewRootState) => state.authenticationModel.isAuthenticated);

  const location = useLocation();
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    const title: string = getAppTitle(location.pathname);
    setTitle(title);
  }, [location]);

  if (isLoggedIn && !isRemoteAuthentication) {
    const baseUrlIFTTTAndZapierConfig = {
      clientId: 'client_id',
      redirectUrl: 'redirect_uri',
      responseType: 'response_type',
      scope: 'scope',
      state: 'state',
    };
    
    const clientIds: { [key: string]: string } = {
      'habitify-ifttt': 'habitify-ifttt',
      'habitify-zapier': 'habitify-zapier',
    }

    const queries = new URLSearchParams(location.search);
    const clientId = queries.get(baseUrlIFTTTAndZapierConfig.clientId);
    const redirectUrl = queries.get(baseUrlIFTTTAndZapierConfig.redirectUrl);
    const state = queries.get(baseUrlIFTTTAndZapierConfig.state);
    if (clientId && clientIds[clientId] && redirectUrl && state) {
      return <IFTTTAndZapierAuthentication queries={{ clientId, redirectUrl, state }} />;
    }

    return <Redirect to="/journal" />;
  }

  return (
    <main id="authentication-container">
      <SEO title={title} />
      <Box
        height="70px"
        w="full"
        pos="absolute"
        top="0"
        right="0"
        className="drag-top-area"
      ></Box>
      <Box className="authentication-content" bg={colorScheme.background.grouped[1]}>
        {children}
      </Box>
    </main>
  );
};

export default Authentication;
