import { createModel } from '@rematch/core';
import { database } from 'firebase-v9';
import { onValue, ref, update } from 'firebase/database';
import { RootModel } from 'store/root.model';
import { AppSetting, CurrentTab, FirstWeekDay, JournalLayoutType, Preferences } from './app-setting.type';

const initialState: AppSetting = {
  loadingPreference: false,
  isOpenThemeMode: false,
  isOpenFirstDayOfWeek: false,
  isOpenJournalLayout: false,
  isOpenAppLanguage: false,
  isOpenAppSetting: false,
  currentTab: 'general',
  preferences: {
    firstWeekDay: 'sunday',
    journalLayoutType: 'withIcon',
    journalTitle: '',
  },
  appLanguage: 'English',
};

export const appSettingModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    loadPreference(state) {
      return {
        ...state,
        loadingPreference: true,
      };
    },
    preferenceLoaded(state) {
      return {
        ...state,
        loadingPreference: false,
      };
    },
    setCurrentTab(state, payload: { tab: CurrentTab }) {
      return {
        ...state,
        currentTab: payload.tab,
      };
    },
    setIsOpenThemeMode(state, payload: { open: boolean }) {
      return {
        ...state,
        isOpenThemeMode: payload.open,
      };
    },
    setIsOpenFirstDayOfWeek(state, payload: { open: boolean }) {
      return {
        ...state,
        isOpenFirstDayOfWeek: payload.open,
      };
    },
    setIsOpenJournalLayout(state, payload: { open: boolean }) {
      return {
        ...state,
        isOpenJournalLayout: payload.open,
      };
    },
    setIsOpenAppLanguage(state, payload: { open: boolean }) {
      return {
        ...state,
        isOpenAppLanguage: payload.open,
      };
    },
    setPreferences(state, payload: { preferences: Preferences }) {
      return {
        ...state,
        preferences: payload.preferences,
      };
    },
    setAppLanguage(state, payload: { language: string }) {
      return {
        ...state,
        appLanguage: payload.language,
      };
    },
    setIsOpenAppSetting(state, payload: { open: boolean }) {
      return {
        ...state,
        isOpenAppSetting: payload.open,
      };
    },
  },
  effects: (dispatch) => ({
    changeTab(tab: CurrentTab) {
      dispatch.appSettingModel.setCurrentTab({ tab });
    },
    openThemeMode(open: boolean) {
      dispatch.appSettingModel.setIsOpenThemeMode({ open });
    },
    openFirstDayOfWeek(open: boolean) {
      dispatch.appSettingModel.setIsOpenFirstDayOfWeek({ open });
    },
    openJournalLayout(open: boolean) {
      dispatch.appSettingModel.setIsOpenJournalLayout({ open });
    },
    openAppLanguage(open: boolean) {
      dispatch.appSettingModel.setIsOpenAppLanguage({ open });
    },
    openAppSetting(open: boolean) {
      dispatch.appSettingModel.setIsOpenAppSetting({ open });
    },
    getPreferences(uid: string) {
      dispatch.appSettingModel.loadPreference();
      const dbRef = ref(database, `preferences/${uid}`);
      onValue(dbRef, (snapshot) => {
        if (snapshot.exists()) {
          let layoutType: JournalLayoutType = 'noIcon';
          let firstWeekDay: FirstWeekDay = 'sunday';
          let journalTitle = '';
          if (snapshot.val()?.journalLayoutType) {
            switch (snapshot.val()?.journalLayoutType) {
              case 2:
                layoutType = 'withIcon';
                break;
              default:
                layoutType = 'noIcon';
                break;
            }
          }
          if (snapshot.val()?.firstWeekday) {
            switch (snapshot.val()?.firstWeekday) {
              case 1:
                firstWeekDay = 'sunday';
                break;
              case 2:
                firstWeekDay = 'monday';
                break;
              default:
                firstWeekDay = 'sunday';
                break;
            }
          }
          if (snapshot.val()?.journalTitle) {
            journalTitle = snapshot.val()?.journalTitle;
          }
          const preferences: Preferences = {
            journalLayoutType: layoutType,
            firstWeekDay,
            journalTitle,
          };
          dispatch.appSettingModel.setPreferences({ preferences });
        }
        dispatch.appSettingModel.preferenceLoaded();
      });
    },
    updateFirstDayOfWeek({ uid, firstWeekDay }: { uid: string; firstWeekDay: number }) {
      const updates = {
        [`/preferences/${uid}/firstWeekday`]: firstWeekDay,
      };
      update(ref(database), updates);
    },
    updateJournalLayout({ uid, layoutType }: { uid: string; layoutType: number }) {
      const updates = {
        [`/preferences/${uid}/journalLayoutType`]: layoutType,
      };

      update(ref(database), updates);
    },
    updateJournalTitle({ uid, journalTitle }: { uid: string; journalTitle: string }) {
      const updates = {
        [`/preferences/${uid}/journalTitle`]: journalTitle,
      };
      update(ref(database), updates);
    },
    updateAppLanguage(language: string) {
      dispatch.appSettingModel.setAppLanguage({ language });
    },
    updateAppLanguageToFirebase(payload: { uid: string | undefined; codeLanguage: string }) {
      const { uid, codeLanguage } = payload;
      if (uid) {
        const updates = {
          [`/users/${uid}/language`]: codeLanguage,
        };
        update(ref(database), updates);
      }
    },
  }),
});
