import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import 'firebase/compat/messaging';
import 'firebase/compat/analytics';
import 'firebase/compat/app-check';
import React from 'react';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

export const getToken = (
  setTokenFcm: React.Dispatch<React.SetStateAction<string | null>>,
): Promise<void> | undefined => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    return messaging
      .getToken({ vapidKey: process.env.REACT_APP_FIREBASE_FCM_KEY })
      .then((currentToken) => {
        if (currentToken) {
          setTokenFcm(currentToken);
        } else {
          console.warn('No registration token available. Request permission to generate one.');
          setTokenFcm(null);
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token. ', err);
      });
  } else console.warn(`This browser doesn't support the API's required to use the firebase SDK`);
};

export default firebase;
