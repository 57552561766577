interface IEventType {
  CHECK_IN: string;
  NOTE: string;
  MOOD: string;
  SKIP: string;
  FAILED: string;
  TIMER: string;
  UPLOAD_NOTE_IMAGE: string;
}

const eventType: IEventType = {
  CHECK_IN: 'checkin',
  NOTE: 'note',
  MOOD: 'mood',
  SKIP: 'skip',
  FAILED: 'failed',
  TIMER: 'timer',
  UPLOAD_NOTE_IMAGE: 'upload_note_image',
};

export { eventType };
