import { Dayjs } from 'dayjs';
import { database } from 'firebase-v9';
import { ref, update } from 'firebase/database';

export const updateAffiliates = async (
  search: string,
  uid: string | undefined,
  currentDate: Dayjs,
  userCreateTime: Dayjs,
) => {
  if (search) {
    const query = getQueryStringParams(search);
    const references = query?.ref;
    const idClick = query?.id_click;
    const isNewUser = userCreateTime.valueOf() >= currentDate.valueOf();
    
    if (references && idClick && uid && isNewUser) {
      const userRef = ref(database, `users/${uid}`);
      update(userRef, {
        affiliates: {
          ref: references,
          id_click: idClick,
        },
      });
    }
  }
};

const getQueryStringParams = (query: string) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params: any, param) => {
        const [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};
