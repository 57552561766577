import firebase from '.';
import 'firebase/compat/remote-config';
const remoteConfig = firebase.remoteConfig();

remoteConfig.settings = {
  fetchTimeoutMillis: 60000,
  minimumFetchIntervalMillis: 60000, //60000 - 1minute
  // minimumFetchIntervalMillis: 3600000, //3600000 - 1hour
};

export default remoteConfig;
