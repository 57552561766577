export enum REGULARLY {
  DAILY = 'daily',
  WEEKDAYS = 'weekDays-',
  MONTHDAYS = 'monthDays-',
  INTERVAL = 'dayInterval-',
}

export enum TIME_OF_DAY {
  MORNING = 'morning',
  MORNING_TEXT = 'morning',
  AFTERNOON = 'afternoon',
  AFTERNOON_TEXT = 'afternoon',
  EVENING = 'evening',
  EVENING_TEXT = 'evening',
  ALL = 'all-habits',
  ALL_TEXT = 'all-habits',
}

export enum TIME_OF_DAY_BITWISE {
  MORNING = 1,
  AFTERNOON = MORNING << MORNING,
  EVENING = AFTERNOON << MORNING,
  ALL = MORNING + AFTERNOON + EVENING,
}

export enum HABIT_PROGRESS {
  NONE,
  SKIP,
  COMPLETE,
  FAILED,
  IN_PROGRESS,
  OFF,
}

export enum HABIT_PROGRESS_PRIORITY {
  NONE,
  FAILED,
  SKIP,
  COMPLETE,
}

export enum HABIT_LOG_TYPE {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum LINK_SOURCES {
  APPLE_HEALTH = 'APPLE_HEALTH',
  GOOGLE_FIT = 'GOOGLE_FIT',
  SAMSUNG_HEALTH = 'SAMSUNG_HEALTH',
}

export enum LINK_SOURCES_TEXT {
  APPLE_HEALTH = 'Apple Health',
  GOOGLE_FIT = 'Google Fit',
}

export enum NoteType {
  TEXT = 1,
  IMAGE = 2,
}

export enum MoodType {
  TERRIBLE = 'terrible',
  BAD = 'bad',
  OKAY = 'okay',
  GOOD = 'good',
  EXCELLENT = 'excellent',
}

export enum HABIT_TYPE {
  GOOD = 1,
  BAD = 2,
}

export enum BAD_HABIT_GOAL_TYPE {
  QUIT = 1,
  NO_MORE_THAN = 2,
}

export enum BAD_HABIT_GOAL_VALUE {
  QUIT = 0,
  NO_MORE_THAN = 1,
}

export enum JOURNAL_COMPONENT_TYPE {
  PENDING_DAILY_HABIT_SECTION = 'PendingDailyHabitsSection',
  PENDING_MONTHLY_HABIT_SECTION = 'PendingMonthlyHabitsSection',
  PENDING_WEEKLY_HABIT_SECTION = 'PendingWeeklyHabitsSection',
  PENDING_BAD_HABIT_SECTION = 'PendingBadHabitsSection',
  COMPLETE_HABIT_SECTION = 'CompleteHabitsSection',
  FAILED_HABIT_SECTION = 'FailedHabitsSection',
  SKIP_HABIT_SECTION = 'SkipHabitsSection',
}
