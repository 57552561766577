import { UnitSymbol } from 'models/common';
import { GoalPeriodicity, Habit, HabitGoal } from 'models/habits';
import { convert, getBaseUnitFromType, getType } from 'tools/si-unit/si-unit-utils';
import { _dayjs } from '__archived__/constants/app';
import { HABIT_GOAL_PERIODICITY } from '__archived__/types/enum/habitGoal';
import { UNIT_SYMBOL } from '__archived__/types/enum/SIBaseUnit';
import { IHabitNewGoal } from '__archived__/types/states/habit';
import { DataChartTrend, HabitProgressMapInfo } from '__archived__/types/states/singleProgress';
import { getMonthKey, getWeekKey } from '.';
import { FirstWeekDay } from './../../components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';

enum CheckInStatus {
    COMPLETE = 2,
}


const calculatorDataChartByRange = (
    habit: Habit,
    dateKey: string,
    habitProgressInfo: HabitProgressMapInfo,
    habitGoalPeriodicity: GoalPeriodicity | null | undefined,
    symbolDefault: UnitSymbol | null | undefined,
    filter: string,
) => {
    const unitType = symbolDefault && getType({ unitSymbol: symbolDefault });
    const baseUnitSymbol = unitType && getBaseUnitFromType({ type: unitType });

    let logValue: number | null = habitProgressInfo.dateToActualGoalValueMap?.get(dateKey) || 0;
    let goal: HabitGoal | IHabitNewGoal | null = habitProgressInfo.dateToHabitGoalCurrentMap?.get(dateKey) || null;

    if (filter === HABIT_GOAL_PERIODICITY.MONTHLY) {
        logValue = habitProgressInfo.dateToLogValueMonthMap?.get(dateKey) || 0;
        goal = habitProgressInfo.dateToGoalMonthMap?.get(dateKey) || null;
    }

    if (filter === HABIT_GOAL_PERIODICITY.WEEKLY) {
        logValue = habitProgressInfo.dateToLogValueWeekMap?.get(dateKey) || 0;
        goal = habitProgressInfo.dateToGoalWeekMap?.get(dateKey) || null;
    }

    const actualLogValueNoGoal = habitProgressInfo.dateToActualNoGoalValueMap?.get(dateKey) || 0;
    if (actualLogValueNoGoal) logValue += actualLogValueNoGoal;
    if (logValue && baseUnitSymbol && symbolDefault) {
        logValue = convert({ source: baseUnitSymbol, target: symbolDefault, value: logValue });
    }

    if (symbolDefault === UNIT_SYMBOL.REP || symbolDefault === UNIT_SYMBOL.STEP || !(habit.goal || habit.goals)) {
        if (filter === HABIT_GOAL_PERIODICITY.DAILY) {
            const numberCheckIn: number = habitProgressInfo?.dateToActualCheckInStatus?.get(dateKey) || 0;
            if (numberCheckIn === CheckInStatus.COMPLETE) logValue = logValue + 1;
        }

        if (filter === HABIT_GOAL_PERIODICITY.WEEKLY || filter === HABIT_GOAL_PERIODICITY.MONTHLY) {
            const numberCheckIn: number =
                filter === HABIT_GOAL_PERIODICITY.WEEKLY
                    ? habitProgressInfo?.dateToNumberOfCheckInStatusWeek?.get(dateKey) || 0
                    : habitProgressInfo?.dateToNumberOfCheckInStatusMonth?.get(dateKey) || 0;
            logValue = logValue + numberCheckIn;
        }
    }

    const goalValue: number | null =
        habitGoalPeriodicity !== filter || goal?.periodicity !== filter ? null : goal?.value || null;
    const isDisplay: boolean = logValue > 0 || (goalValue && goalValue > 0) ? true : false;

    return {
        logValue,
        goalValue,
        isDisplay,
    };
};

export const getDataChartTrend = (
    habit: Habit,
    filter: string,
    habitProgressInfo: HabitProgressMapInfo,
    habitGoalCurrent: HabitGoal | null | undefined,
    firstDayOfWeek: FirstWeekDay
): DataChartTrend => {
    if (habit?.startDate) {
        const habitStartDate = habit?.startDate;
        const dataBarChart: any[] = [];
        const dataLineChart: any[] = [];
        const dateCategories: string[] = [];
        let day = _dayjs(habitStartDate);
        const symbolDefault = habitProgressInfo?.symbolDefault ?? '';
        let textRangeStart = '';
        let textRangeEnd = '';
        let isChartDisplay = false;
        let sumLogValue = 0;
        switch (filter) {
            case 'M':
                textRangeEnd = _dayjs().format('MMM YYYY');
                textRangeStart = day.format('MMM YYYY');
                while (!day.isAfter(_dayjs(), 'months')) {
                    const monthKey: string = getMonthKey(day);
                    const { logValue, goalValue, isDisplay } = calculatorDataChartByRange(
                        habit,
                        monthKey,
                        habitProgressInfo,
                        habitGoalCurrent?.periodicity,
                        symbolDefault,
                        HABIT_GOAL_PERIODICITY.MONTHLY,
                    );
                    dateCategories.push(day.format('MMM YYYY').toUpperCase());
                    dataBarChart.push(logValue || null);
                    sumLogValue += logValue;
                    dataLineChart.push(goalValue || null);
                    isChartDisplay = isDisplay;
                    day = day.add(1, 'months');
                }
                break;
            case 'W':
                textRangeEnd = 'W' + _dayjs().week() + ' ' + _dayjs().format('YYYY');
                textRangeStart = 'W' + day.week() + ' ' + day.format('YYYY');
                while (!day.isAfter(_dayjs(), 'weeks')) {
                    const weekKey: string = getWeekKey(day, firstDayOfWeek);
                    const { logValue, goalValue, isDisplay } = calculatorDataChartByRange(
                        habit,
                        weekKey,
                        habitProgressInfo,
                        habitGoalCurrent?.periodicity,
                        symbolDefault,
                        HABIT_GOAL_PERIODICITY.WEEKLY,
                    );
                    dateCategories.push('W' + day.week() + ' ' + day.format('YYYY'));
                    dataBarChart.push(logValue || null);
                    sumLogValue += logValue;
                    dataLineChart.push(goalValue || null);
                    isChartDisplay = isDisplay;
                    day = day.add(1, 'weeks');
                }
                break;
            case 'D':
                textRangeEnd = _dayjs().format('MMM D YYYY');
                textRangeStart = day.format('MMM D YYYY');
                while (!day.isAfter(_dayjs(), 'date')) {
                    const dailyKey: string = day.format('YYYY-MM-DD');
                    const { logValue, goalValue, isDisplay } = calculatorDataChartByRange(
                        habit,
                        dailyKey,
                        habitProgressInfo,
                        habitGoalCurrent?.periodicity,
                        symbolDefault,
                        HABIT_GOAL_PERIODICITY.DAILY,
                    );
                    dateCategories.push(day.format('MMM D YYYY').toUpperCase());
                    dataBarChart.push(logValue || null);
                    sumLogValue += logValue;
                    dataLineChart.push(goalValue || null);
                    isChartDisplay = isDisplay;
                    day = day.add(1, 'days');
                }
                break;

            default:
                break;
        }
        return {
            dataBarChart,
            dataLineChart,
            dateCategories,
            symbolDefault,
            textRange: `${textRangeStart} - ${textRangeEnd}`,
            isChartDisplay,
            sumLogValue,
        };
    } else return {};
};
