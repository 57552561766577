import { Habit } from 'models/habits';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  sortHabitByAlphabetical,
  sortHabitByRemindTime,
  sortHabitsByAreaPriority,
  sortHabitsByHabitPriority,
} from 'tools/sortings';
import { RootState } from '__archived__/store/roots';
import { SortJournalType } from '__archived__/types/enum/app';

export const useSortHabitByPriority = (habits: Habit[], mountRef: MutableRefObject<boolean>): Habit[] => {
  const [sortedTimeOfDayHabits, setSortedTimeOfDayHabits] = useState<Habit[]>([]);
  const habitFolderId = useSelector((state: RootState) => state.app.habitFolderId);
  const sortJournalType = useSelector((state: RootState) => state.app.sortJournalType);
  const journalType = useSelector((state: RootState) => state.app.journalLayoutType);
  const sortHabits = useCallback(
    async (journalType: number, sortJournalType: string) => {
      let sortedHabits: Habit[] = [];
      // if (journalType === JOURNAL_LAYOUT_TYPE.NEW_LAYOUT) {
      switch (sortJournalType) {
        case SortJournalType.ALPHABETICAL_ASC:
          sortedHabits = await sortHabitByAlphabetical({ habits, order: 'asc' });
          break;
        case SortJournalType.ALPHABETICAL_DESC:
          sortedHabits = await sortHabitByAlphabetical({ habits, order: 'desc' });
          break;
        case SortJournalType.REMIND_TIME:
          sortedHabits = await sortHabitByRemindTime({ habits });
          break;
        default:
          sortedHabits = habitFolderId ? await sortHabitsByAreaPriority({ habits }) : await sortHabitsByHabitPriority({ habits })
          break;
      }
      // } else {
      //   if(habitFolderId){
      //     sortedHabits = await sortHabitsByAreaPriority({ habits })
      //   }else {
      //     sortedHabits = await sortHabitsByHabitPriority({ habits })
      //   }
      // }

      if (mountRef.current) {
        setSortedTimeOfDayHabits([...sortedHabits]);
      }
    },
    [habitFolderId, habits, mountRef],
  );

  useEffect(() => {
    sortHabits(journalType, sortJournalType);
  }, [journalType, sortHabits, sortJournalType]);

  return sortedTimeOfDayHabits;
};
