import { ColorOption } from "./colorOptions";

export const colorHabitIconOptions: ColorOption[] = [
  {
    code: "2AA8D0",
    name: "areaColor2",
    hex: "#2AA8D0",
  },
  {
    code: "2A67F4",
    name: "areaColor3",
    hex: "#2A67F4",
  },
  {
    code: "E0861D",
    name: "areaColor4",
    hex: "#E0861D",
  },
  {
    code: "D32C2C",
    name: "areaColor5",
    hex: "#D32C2C",
  },
  {
    code: "992AC0",
    name: "blue_custom",
    hex: "#992AC0",
  },
  {
    code: "394D54",
    name: "areaColor7",
    hex: "#394D54",
  },
  {
    code: "D41A89",
    name: "custom_color",
    hex: "#D41A89",
  },
  {
    code: "A1A1A1",
    name: "custom_color",
    hex: "#A1A1A1",
  },
  {
    code: "42BD64",
    name: "custom_color",
    hex: "#42BD64",
  },
  {
    code: "3827FF",
    name: "custom_color",
    hex: "#3827FF",
  },
  {
    code: "D8BA1B",
    name: "custom_color",
    hex: "#D8BA1B",
  },
  {
    code: "CB1CDA",
    name: "custom_color",
    hex: "#CB1CDA",
  },
  {
    code: "A78989",
    name: "custom_color",
    hex: "#A78989",
  },
  {
    code: "15D1C6",
    name: "custom_color",
    hex: "#15D1C6",
  },
];

//   "#2AA8D0",
//     "#2A67F4",
//     "#299240",
//     "#E0861D",
//     "#D32C2C",
//     "#992AC0",
//     "#394D54",
//     "#D41A89",
//     "#A1A1A1",
//     "#42BD64",
//     "#3827FF",
//     "#D8BA1B",
//     "#CB1CDA",
//     "#A78989",
//     "#15D1C6",
