import { RootState } from '__archived__/store/roots';
import { useSelector } from 'react-redux';
import { useFolderColor } from './useFolderColor';
import { NewRootState } from 'store';
import { HabitFolder } from 'models/habit-folders';

type Return = {
  selectedHabitFolder: string;
  color: string;
};

export const useHabitFolderTag = (targetFolderId: string): Return => {
  const selectedHabitFolder = useSelector((state: RootState) => state.app.habitFolderId);
  const folder = useSelector<NewRootState, HabitFolder | undefined>(
    (state) => state.habitFoldersModel.habitFolders[targetFolderId],
  );
  const color = useFolderColor(folder);

  return {
    selectedHabitFolder,
    color,
  };
};
