import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';
import { Dayjs } from 'dayjs';
import memoizeOne from 'memoize-one';
import { UnitSymbol } from 'models/common';
import { HabitCurrentProgress, HabitProgress } from 'models/habit-progress';
import { HabitCheckins, HabitGoal, HabitGoals } from 'models/habits';
import { DateRange } from 'models/off-mode';
import { _dayjs } from 'tools/extended-dayjs';
import { getBaseUnitFromType, getType as newGetType, convert } from 'tools/si-unit/si-unit-utils';
import { isToday } from 'tools/single-progress';
import { getCurrentHabitGoal } from './habit-goal-utils';

const dateStatusInDateRangeOffMode = (date: Dayjs, dateRangesOffMode: DateRange[]): HabitProgress | undefined => {
  for (let i = 0; i < dateRangesOffMode.length; i++) {
    const range = dateRangesOffMode[i];
    const { startDate, endDate } = range;
    if (range.stopDate) {
      if (date.isBetween(startDate, endDate.subtract(1), 'day', '[]')) {
        return 'off';
      }
    } else if (date.isBetween(startDate, endDate, 'day', '[]')) {
      return 'off';
    }
  }
};

export const calculatorBadHabitCurrentStreak = memoizeOne(
  ({
    habitCurrentProgress,
    checkins,
    startDate,
    startDateFormatted,
    date,
    dateToBadHabitCompleteByLogValueMap,
    goals,
    badHabitLogValueMap,
    firstDayOfWeek,
    dateRangesOffMode,
  }: {
    habitCurrentProgress: HabitCurrentProgress;
    checkins: HabitCheckins | null | undefined;
    startDate: number;
    startDateFormatted: string;
    date: Dayjs;
    dateToBadHabitCompleteByLogValueMap: { [key: string]: HabitProgress };
    goals: HabitGoals;
    badHabitLogValueMap: { [key: string]: number };
    firstDayOfWeek: FirstWeekDay;
    dateRangesOffMode?: DateRange[];
  }) => {
    // const todayKey = _dayjs().format('DDMMYYYY');
    // const chooseDateKey = date.format('DDMMYYYY');
    const currentDate = new Date();
    let numberOfSkip = 0;
    currentDate.setDate(currentDate.getDate());

    habitCurrentProgress.currentStreak = 0;
    habitCurrentProgress.latestDayFailBadHabit = _dayjs(startDate).toISOString();
    let streakComplete = 0;
    let streakFail = 0;
    let isBlockCurrentStreak = false;

    const todayGoal = getCurrentHabitGoal({ goals, date: _dayjs(), firstDayOfWeek });
    // if (todayKey === chooseDateKey) chosenDate = _dayjs();
    const badHabitStatusByChooseDate = isValidationStatusBadHabit({
      date,
      checkins,
      dateToBadHabitCompleteByLogValueMap,
      currentGoal: todayGoal,
      badHabitLogValueMap,
      firstDayOfWeek,
    });

    if (badHabitStatusByChooseDate === 'completed') streakComplete = 1;
    if (badHabitStatusByChooseDate === 'failed') {
      streakFail = 1;
      habitCurrentProgress.currentStreak = 0;
    }

    /** 
      @todo: refactor calculate streak 
    **/

    if (habitCurrentProgress.badHabitGoalType === 'no-more-than') {
      while (_dayjs(currentDate).isSameOrAfter(_dayjs(startDateFormatted), 'day')) {
        const dateKey = _dayjs(currentDate).format('DDMMYYYY');
        const isChooseDateStatusInProgressAndSkip =
          badHabitStatusByChooseDate === 'in_progress' ||
          badHabitStatusByChooseDate === 'skipped' ||
          habitCurrentProgress.badHabitGoalType === 'no-more-than';

        const currentGoal = getCurrentHabitGoal({ goals: goals, date: _dayjs(currentDate), firstDayOfWeek });

        const badHabitStatusByCurrentDate = isValidationStatusBadHabit({
          date: _dayjs(currentDate),
          checkins,
          dateToBadHabitCompleteByLogValueMap,
          currentGoal,
          badHabitLogValueMap,
          firstDayOfWeek,
          dateRangesOffMode,
        });
        // Calculator current streak
        if (badHabitStatusByCurrentDate === 'failed') {
          isBlockCurrentStreak = true;
          habitCurrentProgress.latestDayFailBadHabit = _dayjs(dateKey, 'DDMMYYYY').add(1, 'days').format('YYYY-MM-DD');
          if (isChooseDateStatusInProgressAndSkip) {
            break;
          }
        }
        if (badHabitStatusByCurrentDate === 'skipped' || badHabitStatusByCurrentDate === 'off') {
          if (!isBlockCurrentStreak) {
            numberOfSkip += 1;
          }
        }

        if (
          (badHabitStatusByCurrentDate === 'completed' || badHabitStatusByCurrentDate === 'none') &&
          !isBlockCurrentStreak
        ) {
          habitCurrentProgress.currentStreak += 1;
        }

        currentDate.setDate(currentDate.getDate() - 1);
      }
    } else if (habitCurrentProgress.badHabitGoalType === 'quit') {
      while (currentDate > new Date(startDateFormatted)) {
        const dateKey = _dayjs(currentDate).format('DDMMYYYY');
        const timestampsChooseDate = date.valueOf();
        const timestampsCurrentDate = _dayjs(currentDate).valueOf();
        const isChooseDateStatusInProgressAndSkip =
          badHabitStatusByChooseDate === 'in_progress' || badHabitStatusByChooseDate === 'skipped';

        const currentGoal = getCurrentHabitGoal({ goals: goals, date: _dayjs(currentDate), firstDayOfWeek });
        const badHabitStatusByCurrentDate = isValidationStatusBadHabit({
          date: _dayjs(currentDate),
          checkins,
          dateToBadHabitCompleteByLogValueMap,
          currentGoal,
          badHabitLogValueMap,
          firstDayOfWeek,
          dateRangesOffMode,
        });

        if (badHabitStatusByCurrentDate === 'failed') {
          isBlockCurrentStreak = true;
          habitCurrentProgress.latestDayFailBadHabit = _dayjs(dateKey, 'DDMMYYYY').add(1, 'days').format('YYYY-MM-DD');
          if (isChooseDateStatusInProgressAndSkip) {
            break;
          }
        }
        if (badHabitStatusByCurrentDate === 'skipped' || badHabitStatusByCurrentDate === 'off') {
          if (!isBlockCurrentStreak) {
            numberOfSkip += 1;
          }
        }

        if (
          (badHabitStatusByCurrentDate === 'completed' || badHabitStatusByCurrentDate === 'none') &&
          !isBlockCurrentStreak
        ) {
          habitCurrentProgress.currentStreak += 1;
        }

        // Calculator description
        if (timestampsCurrentDate <= timestampsChooseDate && habitCurrentProgress.badHabitGoalType === 'quit') {
          if (badHabitStatusByChooseDate === 'completed') {
            if (badHabitStatusByCurrentDate === 'completed') streakComplete += 1;
            if (badHabitStatusByCurrentDate === 'failed') {
              break;
            }
          }

          if (isChooseDateStatusInProgressAndSkip) {
            streakFail = 0;
            streakComplete = 0;
          }

          if (badHabitStatusByChooseDate === 'failed') {
            if (badHabitStatusByCurrentDate === 'failed') {
              if (streakComplete) {
                break;
              }
              streakFail += 1;
            }
            if (badHabitStatusByCurrentDate === 'completed') {
              if (streakFail > 1) {
                break;
              }
              streakComplete += 1;
            }
          }
        }

        currentDate.setDate(currentDate.getDate() - 1);
      }
    }

    if (numberOfSkip) {
      const latestDate = _dayjs(habitCurrentProgress.latestDayFailBadHabit);
      const isSkipInStartDate = checkins && checkins[latestDate.format('DDMMYYYY')]?.value === 'skipped';
      const isDurationCurrent = latestDate.hour() || latestDate.minute() || latestDate.second();
      if (isDurationCurrent && isSkipInStartDate) {
        habitCurrentProgress.latestDayFailBadHabit = latestDate
          .add(numberOfSkip - 1, 'days')
          .add(23 - latestDate.hour(), 'hour')
          .add(59 - latestDate.minute(), 'minute')
          .add(60 - latestDate.second(), 'second')
          .toISOString();
      } else {
        habitCurrentProgress.latestDayFailBadHabit = latestDate.add(numberOfSkip, 'days').toISOString();
      }
    }

    // Check chooseDate is tomorrow
    if (date.format('DDMMYYYY') === _dayjs().add(1, 'days').format('DDMMYYYY')) {
      const badHabitStatusByToday = isValidationStatusBadHabit({
        date: _dayjs(),
        checkins,
        dateToBadHabitCompleteByLogValueMap,
        currentGoal: todayGoal,
        badHabitLogValueMap,
        firstDayOfWeek,
      });
      if (badHabitStatusByChooseDate === 'completed') {
        if (badHabitStatusByToday === 'in_progress' || badHabitStatusByToday === 'failed') {
          streakComplete = 1;
          streakFail = 0;
        } else {
          if (badHabitStatusByToday !== 'skipped') streakComplete += 1;
        }
      }
      if (badHabitStatusByChooseDate === 'failed') {
        if (badHabitStatusByToday === 'failed') {
          streakFail += 1;
          streakComplete = 0;
        }
        if (badHabitStatusByToday === 'in_progress' && !streakComplete) {
          streakFail = 1;
        }
        if (badHabitStatusByToday === 'completed') {
          streakComplete += 1;
        }
      }
    }

    habitCurrentProgress.badHabitPastDaysStreak = {
      streakComplete,
      streakFail,
    };
  },
);

const isValidationStatusBadHabit = memoizeOne(
  ({
    date,
    checkins,
    badHabitLogValueMap,
    currentGoal,
    dateToBadHabitCompleteByLogValueMap,
    firstDayOfWeek,
    dateRangesOffMode,
  }: {
    date: Dayjs;
    checkins: HabitCheckins | null | undefined;
    dateToBadHabitCompleteByLogValueMap: { [key: string]: HabitProgress };
    currentGoal: HabitGoal | null | undefined;
    badHabitLogValueMap: { [key: string]: number };
    firstDayOfWeek: FirstWeekDay;
    dateRangesOffMode?: DateRange[];
  }): HabitProgress => {
    const periodicity = currentGoal?.periodicity;
    const chooseDateKey = date.format('DDMMYYYY');
    if (checkins && checkins[chooseDateKey]) {
      const chooseDateCheckin = checkins[chooseDateKey];
      if (chooseDateCheckin.value === 'failed') return 'failed';
      if (chooseDateCheckin.value === 'skipped') return 'skipped';
      if (chooseDateCheckin.value === 'completed') return 'completed';
      if (chooseDateCheckin.value === 'none') return 'none';
    }

    if (periodicity === 'daily') {
      if (dateToBadHabitCompleteByLogValueMap && dateToBadHabitCompleteByLogValueMap[chooseDateKey]) {
        const status = dateToBadHabitCompleteByLogValueMap[chooseDateKey];
        if (status === 'failed') return 'failed';
        if (status === 'completed') return 'completed';
        if (status === 'in_progress') return 'in_progress';
      }

      if (dateRangesOffMode) {
        const status = dateStatusInDateRangeOffMode(date, dateRangesOffMode);
        if (status) return status;
      }
    }

    if (periodicity === 'weekly' || periodicity === 'monthly') {
      let startDateOfPer = _dayjs();
      if (periodicity === 'weekly') {
        if (firstDayOfWeek === 'monday') {
          startDateOfPer = date.add(-1, 'day').startOf('week').add(1, 'day');
        } else {
          startDateOfPer = date.startOf('week');
        }
      } else {
        startDateOfPer = date.startOf('month');
      }

      const status = getHabitStatusByPerWeekAndMonth({
        startDateOfPer,
        date,
        badHabitLogValueMap,
        currentGoal,
        dateRangesOffMode,
      });
      if (status === 'failed') return 'failed';
      if (status === 'completed') return 'completed';
      if (status === 'off') return 'off';
    }
    return chooseDateKey === _dayjs().format('DDMMYYYY') ? 'in_progress' : 'completed';
  },
);

const getHabitStatusByPerWeekAndMonth = memoizeOne(
  ({
    startDateOfPer,
    date,
    badHabitLogValueMap,
    currentGoal,
    dateRangesOffMode,
  }: {
    startDateOfPer: Dayjs;
    date: Dayjs;
    badHabitLogValueMap: { [key: string]: number };
    currentGoal: HabitGoal | null | undefined;
    dateRangesOffMode?: DateRange[];
  }): HabitProgress => {
    let currentDate = date;
    let logValue = 0;
    let habitProgress: HabitProgress = isToday(date) ? 'none' : 'completed';
    let hasLogValue = false;

    const habitSymbolCurrent = currentGoal?.unit?.symbol as UnitSymbol;
    const baseUnitSymbolOfHabit = getBaseUnitFromType({
      type: newGetType({ unitSymbol: habitSymbolCurrent }),
    });
    const goalValueCurrent: number = currentGoal?.value || 0;
    const habitGoalValue: number = convert({
      source: habitSymbolCurrent,
      target: baseUnitSymbolOfHabit,
      value: goalValueCurrent,
    });

    while (currentDate.valueOf() >= startDateOfPer.valueOf()) {
      logValue += badHabitLogValueMap[currentDate.format('DDMMYYYY')] || 0;

      if (badHabitLogValueMap[currentDate.format('DDMMYYYY')] && currentDate.isSame(date)) {
        if (logValue <= habitGoalValue) {
          hasLogValue = true;
          break;
        }
      }

      if (logValue > habitGoalValue) {
        habitProgress = 'failed';
        break;
      }
      currentDate = currentDate.subtract(1, 'days');
    }

    if (dateRangesOffMode) {
      if (habitProgress !== 'failed' && !hasLogValue) {
        const status = dateStatusInDateRangeOffMode(date, dateRangesOffMode);
        if (status) return status;
      }
    }
    return habitProgress;
  },
);
