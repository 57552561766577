import { Box, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import RadioButton from 'components/common/button/radio-button.component';
import Icon from 'components/common/Icon';
import { useGetPromo } from 'hooks/useGetPromo';
import { useThemeData } from 'hooks/useThemeData';
import { PaddleProduct, Plan } from 'models/payment';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewDispatch, NewRootState } from 'store';
import { _dayjs } from '__archived__/constants/app';
import { pushAnalyticEvent } from '__archived__/utils/pushAnalyticEvent';

declare const window: any;

const Plans: React.FC = (): ReactElement => {
  const Paddle = window.Paddle;
  const dispatch = useDispatch<NewDispatch>();
  const { colorScheme, typography } = useThemeData();
  const { t } = useTranslation();
  const [selectedPlan, setSelectedPlan] = useState('');
  const [plans, setPlans] = useState<Plan[]>([]);
  // const [sale, setSale] = useState(0);
  const pricingInfo = useSelector((state: NewRootState) => state.paymentModel.pricingInfo);
  const userPricingInfo = useSelector((state: NewRootState) => state.userRemoteConfigModel.pricingInfo);
  const userId = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const paddleProducts = useSelector((state: NewRootState) => state.paymentModel.paddleProducts);

  const promo = useGetPromo();

  const saleTextColor = useColorModeValue(colorScheme.universal.white, colorScheme.universal.black);
  const isBetweenSale = _dayjs().isBetween(_dayjs(promo?.start), _dayjs(promo?.end));

  useEffect(() => {
    if (userPricingInfo) {
      setPlans(userPricingInfo.plans);
    } else if (pricingInfo) {
      setPlans(pricingInfo.plans);
    }
  }, [pricingInfo, userPricingInfo]);

  useEffect(() => {
    if (userPricingInfo?.preselectedProductId) {
      setSelectedPlan(userPricingInfo.preselectedProductId);
    } else if (pricingInfo?.preselectedProductId) {
      setSelectedPlan(pricingInfo.preselectedProductId);
    }
  }, [pricingInfo?.preselectedProductId, userPricingInfo?.preselectedProductId]);

  const handleClickPlan = (planId: string) => {
    setSelectedPlan(planId);
    pushAnalyticEvent('UpgradeChangePlan');
  };

  const handleCheckout = useCallback(() => {
    const promoCode = isBetweenSale ? promo?.promotionalCode : null;
    pushAnalyticEvent('UpgradeCTATapped');
    Paddle.Checkout.open({
      product: parseInt(selectedPlan),
      passthrough: userId,
      coupon: promoCode,
      successCallback: (data: any) => {
        if (data) {
          dispatch.paymentModel.openProcessingModal(true);
          pushAnalyticEvent('UpgradeCTASuccess', {
            Source: 'settings',
            ProductID: selectedPlan,
          });
        }
      },
    });
  }, [Paddle.Checkout, dispatch.paymentModel, isBetweenSale, promo?.promotionalCode, selectedPlan, userId]);

  const getCurrency = (price: number, currency: string) => {
    return Number(price).toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
    });
  };

  const calculatePriceAfterSale = useCallback(
    (currentPrice: number) => {
      if (promo && promo.discount && isBetweenSale) {
        const { discount } = promo;
        return currentPrice - (currentPrice * discount) / 100;
      }
      return currentPrice;
    },
    [isBetweenSale, promo],
  );

  const renderPrice = useCallback(
    (price: number, currency: string) => {
      if (isBetweenSale) {
        return (
          <>
            <Text textDecorationLine="line-through" mr="5px">
              {getCurrency(price, currency)}
            </Text>
            {t('upgrade.monthlyPriceWith', { args1: getCurrency(calculatePriceAfterSale(price), currency) })}
          </>
        );
      }
      return <>{t('upgrade.monthlyPriceWith', { args1: getCurrency(price, currency) })}</>;
    },
    [calculatePriceAfterSale, isBetweenSale, t],
  );

  const getPrice = useCallback(
    (productId: string) => {
      // calculatePriceAfterSale();
      if (paddleProducts && paddleProducts.length > 0) {
        const productFromPaddle = paddleProducts.find(
          (product: PaddleProduct) => product.productId.toString() === productId,
        );
        let price: number = 0;
        if (productFromPaddle) {
          const priceFromPaddle = productFromPaddle.price.gross;
          const currency = productFromPaddle.currency;
          switch (productId) {
            case '640639':
              price = Math.round((priceFromPaddle / 3) * 100) / 100;
              return <Flex>{renderPrice(price, currency)}</Flex>;
            case '640640':
              price = Math.round((priceFromPaddle / 6) * 100) / 100;
              return <Flex>{renderPrice(price, currency)}</Flex>;
            case '555464':
              price = Math.round((priceFromPaddle / 12) * 100) / 100;
              return <Flex>{renderPrice(price, currency)}</Flex>;
            case '555465':
              return <Flex>{renderPrice(priceFromPaddle, currency)}</Flex>;
            default:
              return <Flex>{renderPrice(priceFromPaddle, currency)}</Flex>;
          }
        }
      }
    },
    [paddleProducts, renderPrice],
  );

  const renderSubtitle = useCallback(
    (productId: string) => {
      if (paddleProducts && paddleProducts.length > 0) {
        const productFromPaddle = paddleProducts.find((product) => product.productId.toString() === productId);
        if (productFromPaddle) {
          const priceFromPaddle = productFromPaddle.price.gross;
          const currency = productFromPaddle.currency;
          switch (productId) {
            case '555464':
              return (
                <Text {...typography.normal.footnote} color={colorScheme.label.secondary}>
                  {getCurrency(calculatePriceAfterSale(priceFromPaddle), currency)}{' '}
                  {t('newupgrade.billingPeriodYearly')}
                </Text>
              );
            case '555465':
              return (
                <Text {...typography.normal.footnote} color={colorScheme.label.secondary}>
                  {t('newupgrade.packageBillOnce')}
                </Text>
              );
            default:
              return '';
          }
        }
      }
    },
    [calculatePriceAfterSale, colorScheme.label.secondary, paddleProducts, t, typography.normal.footnote],
  );

  const showSaleBadge = (productId: string) => {
    if (isBetweenSale) {
      if (productId === '555464') {
        return (
          <Box
            p="4px 8px"
            w="67px"
            bgColor={promo?.backgroundColorHex}
            borderRadius="45px"
            h="20px"
            my="auto"
            whiteSpace="nowrap"
            textAlign="center"
          >
            <Text {...typography.emphasized.caption[2]} color={saleTextColor} textOverflow="ellipsis" overflow="hidden">
              {t('newupgrade.packageSaving', { args1: `${promo?.discount}%` })}
            </Text>
          </Box>
        );
      }

      if (productId === '555465') {
        return (
          <Box p="4px 8px" bgColor={colorScheme.label.secondary} w="77px" borderRadius="45px" h="20px" my="auto">
            <Text
              {...typography.emphasized.caption[2]}
              color={saleTextColor}
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              textAlign="center"
            >
              {t('newupgrade.packageBestValue')}
            </Text>
          </Box>
        );
      }

      return null;
    }

    return null;
  };

  return (
    <>
      <Box m="11px 0 16px 0">
        <Stack>
          {plans
            ? plans.map((plan) => (
                <RadioButton
                  key={plan.productId}
                  value={plan.productId}
                  selected={selectedPlan}
                  p="10px"
                  bgColor={plan.productId === selectedPlan ? colorScheme.background.primary[1] : 'unset'}
                  borderRadius="5px"
                  boxShadow={plan.productId === selectedPlan ? `0 0 6px rgba(0, 0, 0, 0.08)` : 'none'}
                  onClick={() => handleClickPlan(plan.productId)}
                >
                  <Box display="flex" justifyContent="space-between" w="full">
                    <Box>
                      <Box display="flex" alignItems="center">
                        <Text {...typography.emphasized.title[4]}>{t(`newupgrade.${plan.nameLocalizationKey}`)} -</Text>
                        &nbsp;
                        <Box {...typography.normal.callout}>{getPrice(plan.productId)}</Box>
                      </Box>
                      {renderSubtitle(plan.productId)}
                    </Box>
                    {showSaleBadge(plan.productId)}
                  </Box>
                </RadioButton>
              ))
            : null}
        </Stack>
      </Box>
      <Box p="0 16px 0 17px">
        <Box
          w="100%"
          h="30px"
          bgColor={colorScheme.accent.primary}
          borderRadius="3px"
          display="flex"
          alignItems="center"
          onClick={handleCheckout}
        >
          <Text m="auto" {...typography.emphasized.callout} color={colorScheme.universal.white}>
            {t('upgrade.cta')}
          </Text>
          <Box></Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt="10px">
          <Icon name="ic_lock" fill={colorScheme.label.secondary} />
          <Text {...typography.normal.footnote} color={colorScheme.label.secondary} ml="7.6px">
            {t('upgrade.paddlePayment')}
          </Text>
        </Box>
      </Box>
    </>
  );
};
export default Plans;
