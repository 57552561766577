export enum USAGE_LIMIT {
  CHECK_INS = 15,
  SKIP = 3,
  CREATE_HABIT = 3,
  NOTE = 10,
  TIMER = 2,
  MOOD = 1,
  UPLOAD_NOTE_IMAGE = 1,
}

export interface IEventCount {
  [key: string]: number;
}

export interface IUsageConfig {
  periodicity: string;
  value: number;
}

export interface IUsageLimit {
  [key: string]: IUsageConfig;
}

export type EventType = 'checkin' | 'skip' | 'note' | 'timer' | 'upload_note_image' | 'mood';
