import { Box, Text } from "@chakra-ui/react";
import CloseButton from "components/common/button/close-button.component";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import BasicInformation from "./basic-infomation.component";
import CautionZone from "./caution-zone.component";
import SignInMethod from "./sign-in-method.component";

interface ProfileProps {
    closeSetting: () => void;
}
const ProfileSetting: React.FC<ProfileProps> = ({
    closeSetting,
}: ProfileProps): ReactElement => {
    const { t } = useTranslation();
    const { typography, colorScheme } = useThemeData();
    return (
        <Box>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pr="8px"
            >
                <Text
                    {...typography.emphasized.title[2]}
                    color={colorScheme.label.primary}
                >
                    {t("common.profile")}
                </Text>
                <CloseButton onClose={closeSetting} />
            </Box>
            <BasicInformation />
            <SignInMethod />
            <CautionZone />
        </Box>
    );
};
export default ProfileSetting;
