import { GridItem } from '@chakra-ui/react';
import { FC, ReactElement, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  colStart?: number;
  cursor?: 'default' | 'not-allowed';
}

const DateCell: FC<Props> = ({ children, colStart, cursor }: Props): ReactElement => {
  return (
    <GridItem
      colStart={colStart}
      cursor={cursor}
      width="31.29px"
      height="28px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </GridItem>
  );
};

export default DateCell;
