import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  Popover,
  PopoverTrigger,
  Portal,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import IconPicker from 'components/common/icon-picker/new_icon_picker';
import { useThemeData } from 'hooks/useThemeData';
import { HabitFolder } from 'models/habit-folders';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { NewDispatch, NewRootState } from 'store';
import { darkColorScheme } from 'theme/data/colorscheme';
import keyMap from 'utils/keyMapShortcut';
import { FOLDER_NAME_ICON_COLLECTION } from '__archived__/types/enum/app';
import { colorHabitIconOptions as colorOptions } from '__archived__/constants/habit_color_options';
import { updateHabitFolderFilterAction } from '__archived__/store/ducks/app/app.action';
import { useTranslation } from 'react-i18next';
import isSafari from 'tools/common/checkSafari';
import { useEscKeyboardEvent } from 'hooks/useEscKeyboardEvent';

interface AreaItemProps {
  folderId: string;
  folderIdPicked: string;
  updateFolderIdPicked: (folderId: string) => void;
  folderIndex: number;
}

type stateFolderItemComponent = 'selected' | 'highlighted' | 'editable-focus' | 'hovered' | 'normal';

const stateComponentClass: { [key: string]: string } = {
  selected: 'folder-selected',
  highlighted: 'folder-highlighted',
  'editable-focus': 'folder-editable-focus',
  hovered: 'folder-hovered',
  normal: 'folder-normal',
};

const ICON_FOLDER_DEFAULT = 'ic-habit-folder';

const Area: React.FC<AreaItemProps> = ({
  folderId,
  folderIdPicked,
  updateFolderIdPicked,
  folderIndex,
}: AreaItemProps): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const oldDispatch = useDispatch();
  const dispatch = useDispatch<NewDispatch>();
  const userAuth = useSelector((state: NewRootState) => state.authenticationModel.user);
  const folder = useSelector<NewRootState, HabitFolder | undefined>(
    (state) => state.habitFoldersModel.habitFolders[folderId],
  );

  const [stateComponent, setSateComponent] = useState<stateFolderItemComponent>('normal');
  const [isShowIconList, setIsShowIconList] = useState<boolean>(false);
  const [folderIcon, setFolderIcon] = useState(folder?.iconKey || ICON_FOLDER_DEFAULT);
  const [folderName, setFolderName] = useState(folder?.name || '');

  const updateFolderId = useCallback(
    (folderId: string) => oldDispatch(updateHabitFolderFilterAction(folderId)),
    [oldDispatch],
  );

  // const [folderColor, setFolderColor] = useState(folder?.color || '2AA8D0')
  const borderOrOutline = isSafari ? 'border' : 'outline';

  const refFolderItem = useRef(null);
  const refEditable = useRef(null);

  const isFolderSelected = location.pathname.includes(folderId);
  const isEditable = stateComponent === 'editable-focus';
  const isShowEditFolderOptions = stateComponent === 'highlighted';
  const isDarkMode = colorScheme.sidebar.item.selected === darkColorScheme.sidebar.item.selected;

  const cssFolderByStateComponent: { [key: string]: React.CSSProperties } = {
    selected: {
      background: folder?.color ? `#${folder?.color}` : colorScheme.sidebar.item.selected,
    },
    highlighted: isDarkMode
      ? {
          background: folder?.color ? `#${folder?.color}` : colorScheme.sidebar.item.backgroundHighlight,
        }
      : {
          [borderOrOutline]: `1.5px solid ${
            folder?.color ? `#${folder?.color}` : colorScheme.sidebar.item.borderColorHighlight
          }`,
        },
    hovered: {
      background: folder?.color ? `#${folder?.color}` : colorScheme.sidebar.item.hover,
    },
  };

  const cssFolderEditableByStateComponent: {
    [key: string]: React.CSSProperties;
  } = {
    'editable-focus': {
      background: colorScheme.sidebar.item.backgroundFocus,
      [borderOrOutline]: `1.5px solid ${
        folder?.color ? `#${folder?.color}` : colorScheme.sidebar.item.borderColorFocus
      }`,
    },
  };

  const keyShortcutOsx = keyMap.sidebar.folder?.osx || '';
  const keyShortcutWin = keyMap.sidebar.folder?.windows || '';

  useHotkeys(`${keyShortcutOsx + folderIndex}, ${keyShortcutWin + folderIndex}`, (e) => {
    e.preventDefault();
    if (folderIndex > 9) return;
    history.push(`/journal/${folderId}`);
  });

  useEffect(() => {
    isFolderSelected ? setSateComponent('selected') : setSateComponent('normal');
  }, [isFolderSelected]);

  useEffect(() => {
    if (folderId !== folderIdPicked) {
      isFolderSelected ? setSateComponent('selected') : setSateComponent('normal');
    }
  }, [folderId, folderIdPicked, isFolderSelected]);

  const toggleEditable = () => {
    if (stateComponent === 'editable-focus' && !isShowIconList) {
      isFolderSelected ? setSateComponent('selected') : setSateComponent('normal');
    }
  };

  useOutsideClick({
    ref: refFolderItem,
    handler: toggleEditable,
  });

  useEscKeyboardEvent(toggleEditable);

  const editableFocus = () => {
    setTimeout(() => {
      const elEditable = refEditable.current as HTMLElement | null;
      const elInput = elEditable?.querySelector('input');
      elInput?.focus();
    }, 0);
  };

  const onClickQuitEditable = () => {
    setIsShowIconList(false);

    if (isFolderSelected) {
      setSateComponent('editable-focus');
      setIsShowIconList(false);
      editableFocus();
    }
  };

  const onContextMenuFolder = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    updateFolderIdPicked(folderId);
    setSateComponent('highlighted');
    setIsShowIconList(false);
  };

  const onClickFolderRename = () => {
    setSateComponent('editable-focus');
    editableFocus();
  };

  const onClickFolderDelete = () => {
    setSateComponent('normal');
    setTimeout(() => {
      dispatch.habitFoldersModel.deleteFolder({
        uid: userAuth?.uid,
        folderId,
      });
    }, 0);

    if (location?.pathname?.includes(folderId)) {
      history.push('/journal/all-habits');
    }
  };

  const onMouseEnterFolder = () => {
    if (stateComponent === 'normal') {
      setSateComponent('hovered');
    }
  };

  const onMouseLeaveFolder = () => {
    if (stateComponent === 'hovered') {
      setSateComponent('normal');
    }
    setIsShowIconList(false);
  };

  const handleOnBlur = () => {
    if (!isShowIconList) {
      isFolderSelected ? setSateComponent('selected') : setSateComponent('normal');
      updateFolderName();
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLParagraphElement>) => {
    if (e?.which === 13) {
      e.preventDefault();
      isFolderSelected ? setSateComponent('selected') : setSateComponent('normal');
      updateFolderName();
    }
  };

  const updateFolderName = () => {
    if (!!folderName) {
      dispatch.habitFoldersModel.updateFolderName({
        uid: userAuth?.uid,
        folderId,
        folderName,
      });
    }
  };

  const focusEditable = () => {
    const elEditable = refEditable.current as HTMLElement | null;
    const elInput = elEditable?.querySelector('span');
    elInput?.focus();
  };

  const handleUpdateIcon = (iconKey: string) => {
    // setIsShowIconList(false);
    setFolderIcon(iconKey);
    setTimeout(() => {
      // const elEditable = refEditable.current as HTMLElement | null;
      // const elInput = elEditable?.querySelector("span");
      // elInput?.focus();
      dispatch.habitFoldersModel.updateFolderIconKey({
        uid: userAuth?.uid,
        folderId,
        iconKey,
      });
    }, 0);
  };

  const handleUpdateColor = (iconColor: string) => {
    if (!!iconColor) {
      setTimeout(() => {
        dispatch.habitFoldersModel.updateFolderIconColor({
          uid: userAuth?.uid,
          folderId,
          iconColor: iconColor.includes('#') ? iconColor.replace('#', '').trim() : iconColor,
        });
      }, 0);
    }
  };

  const blockGotoLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isShowIconList) {
      e.preventDefault();
    }
    if (!isShowIconList && stateComponent !== 'highlighted') {
      updateFolderId(folderId);
    }
  };

  return (
    <Box
      ref={refFolderItem}
      position="relative"
      height="36px"
      borderRadius="5px"
      p="0 8px"
      style={cssFolderByStateComponent[stateComponent] || {}}
      className={stateComponentClass[stateComponent]}
      onContextMenu={(e) => onContextMenuFolder(e)}
      onMouseEnter={onMouseEnterFolder}
      onMouseLeave={onMouseLeaveFolder}
      // onKeyDown={}
    >
      <NavLink
        to={{
          pathname: `/journal/${folderId}`,
          state: { pageName: folder?.name },
        }}
        onClick={(e) => blockGotoLink(e)}
      >
        <Box height="100%" display="grid" gridTemplateColumns="20px auto" gridColumnGap="5px" alignItems="center">
          <Popover placement="bottom-end" offset={[-8, 6]} strategy="fixed" isOpen={isShowIconList}>
            <PopoverTrigger>
              <Box width="20px" className="folder-wrapper-icon" onMouseEnter={() => setIsShowIconList(true)}>
                <Icon
                  folderName={FOLDER_NAME_ICON_COLLECTION.AREA}
                  name={folderIcon}
                  fill="#7B7C7C"
                  width={20}
                  height={20}
                />
              </Box>
            </PopoverTrigger>
            <Portal>
              <IconPicker
                type="area"
                folderIcons="area"
                defaultIcon={folder?.iconKey || ''}
                color={folder?.color ? '#' + folder.color : colorOptions[0].hex}
                updateIcon={(iconKey) => handleUpdateIcon(iconKey)}
                updateColor={(iconColor) => handleUpdateColor(iconColor)}
              ></IconPicker>
            </Portal>
          </Popover>
          <Text
            p="6px 7px"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            {...typography.normal.title[4]}
            color={colorScheme.label.secondary}
            className="folder-name"
            onClick={onClickQuitEditable}
          >
            {folder?.name}
          </Text>
          {isShowEditFolderOptions && (
            <Box
              position="absolute"
              width="100%"
              top="100%"
              left="0"
              borderRadius="5px"
              zIndex="9"
              overflow="hidden"
              boxShadow="0px 0px 6px rgba(0, 0, 0, 0.08)"
              mt="2px"
              background={colorScheme.sidebar.item.backgroundOptions}
              onClick={(e) => e.preventDefault()}
            >
              <Text
                padding="7.5px 10px"
                height="30px"
                opacity="0.7"
                _hover={{
                  background: colorScheme.accent.primary,
                  color: 'white',
                  opacity: 1,
                }}
                {...typography.normal.callout}
                color={colorScheme.label.primary}
                onClick={onClickFolderRename}
              >
                {t('common.rename')}
              </Text>
              <Text
                height="30px"
                padding="7.5px 10px"
                opacity="0.7"
                _hover={{
                  background: colorScheme.accent.primary,
                  color: 'white',
                  opacity: 1,
                }}
                {...typography.normal.callout}
                color={colorScheme.label.primary}
                onClick={onClickFolderDelete}
              >
                {t('common.delete')}
              </Text>
            </Box>
          )}
        </Box>
      </NavLink>
      {isEditable && (
        <Editable
          ref={refEditable}
          display="flex"
          alignItems="center  "
          position="absolute"
          width="calc(100% - 33px)"
          height="28px"
          top="50%"
          right="0"
          transform="translateY(-50%)"
          p="6px 7px"
          borderRadius="5px"
          zIndex="9"
          style={cssFolderEditableByStateComponent[stateComponent] || {}}
          {...typography.normal.title[4]}
          color={colorScheme.label.secondary}
          className="sidebar-editable"
          selectAllOnFocus={false}
          startWithEditView={true}
          value={folderName}
          onChange={(val) => setFolderName(val)}
          onBlur={() => handleOnBlur()}
          onKeyDown={(e) => handleOnKeyDown(e)}
          onClick={() => {
            setIsShowIconList(false);
            focusEditable();
          }}
        >
          <EditablePreview />
          <EditableInput outline="none" outlineOffset="unset" _focus={{ boxShadow: 'unset' }} />
        </Editable>
      )}
      {isShowIconList && (
        <Box
          position="absolute"
          width="100%"
          height="5px"
          background="transparent"
          zIndex="9"
          top="100%"
          left="0"
        ></Box>
      )}
    </Box>
  );
};

export default React.memo(Area);
