import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';
import { Dayjs } from 'dayjs';
import keys from 'lodash/keys';
import memoizeOne from 'memoize-one';
import { HabitLog, HabitLogs } from 'models/habit-logs';
import {
  BAD_HABIT_GOAL_VALUE,
  HabitCurrentProgress,
  HabitProgress,
  HabitsCurrentProgress,
} from 'models/habit-progress';
import { GoalPeriodicity, Habit, HabitCheckins, HabitGoals } from 'models/habits';
import { DateRange } from 'models/off-mode';
import { today, _dayjs } from '../extended-dayjs';
import { convert, getBaseUnitFromType, getType } from '../si-unit/si-unit-utils';
import { calculatorBadHabitCurrentStreak } from './habit-bad-progress-utils';
import { getCurrentHabitGoal, getListHabitGoal, isOneTimePerDayHabit } from './habit-goal-utils';
import { computeProgressFromHabitLog } from './habit-log-utils';
import { getHabitRegularly } from './habit-regularly-utils';

export const bulkComputeHabitsProgress = memoizeOne(
  ({
    habits,
    habitLogs,
    chooseDate,
    firstDayOfWeek,
    dateRangesOffMode,
  }: {
    habits: Habit[];
    habitLogs: HabitLogs;
    chooseDate: string;
    firstDayOfWeek: FirstWeekDay;
    dateRangesOffMode: DateRange[];
  }): Promise<HabitsCurrentProgress> => {
    return new Promise((resolve, reject) => {
      try {
        const habitsProgress: HabitsCurrentProgress = {};
        if (habits.length) {
          habits.forEach(async (habit) => {
            if (habit) {
              const habitId = habit.id;
              if (habitId) {
                const habitLogsOfOneHabit = habitLogs[habitId];
                habitsProgress[habitId] = await calculateHabitProgress({
                  habit,
                  habitLogs: habitLogsOfOneHabit,
                  date: _dayjs(chooseDate),
                  firstDayOfWeek,
                  dateRangesOffMode,
                });
              }
            }
          });
        }
        resolve(habitsProgress);
      } catch (e) {
        reject(e);
      }
    });
  },
);

export const calculateHabitProgress = memoizeOne(
  ({
    habit,
    habitLogs,
    date,
    firstDayOfWeek,
    dateRangesOffMode,
  }: {
    habit: Habit;
    habitLogs: HabitLog[];
    date: Dayjs;
    firstDayOfWeek: FirstWeekDay;
    dateRangesOffMode?: DateRange[];
  }): Promise<HabitCurrentProgress> => {
    return new Promise((resolve, reject) => {
      try {
        if (habit) {
          const { id, goal, goals, checkins, regularly, startDate, habitType } = habit;
          if (id) {
            const habitCurrentProgress: HabitCurrentProgress = {
              habitId: id,
              isNoGoalHabit: false,
              isOneTimePerDayHabit: false,
              habitActualLogValue: 0,
              habitGoalValue: 0,
              currentStreak: 0,
              habitGoalPeriodicity: '',
              habitGoalSymbol: '',
              habitProgress: 'none',
            };

            if (startDate) {
              const startDateFormatted = _dayjs(startDate).format('YYYY-MM-DD');

              let habitGoalList: HabitGoals = {};
              const allCheckins = checkins ? getHabitCheckins(checkins) : [];
              const allLogValuesComplete: string[] = [];
              const allLogValuesInProgress: string[] = [];
              let isBadHabit = false;
              if (habitType && habitType.habitType === 'bad') {
                isBadHabit = true;
              }
              const dateToBadHabitCompleteByLogValueMap: { [key: string]: HabitProgress } = {};
              const badHabitLogValueMap: { [key: string]: number } = {};

              if (!goal && !goals) {
                habitCurrentProgress.isNoGoalHabit = true;
                habitCurrentProgress.habitGoalPeriodicity = 'daily';
                habitCurrentProgress.habitGoalSymbol = 'rep';
                habitCurrentProgress.habitGoalValue = 1;
                if (isBadHabit) {
                  habitCurrentProgress.badHabitGoalType = 'quit';
                }
              }
              if (goal || goals) {
                habitGoalList = getListHabitGoal({ goal, goals, startDate, firstDayOfWeek });
                const latestHabitGoal = getCurrentHabitGoal({ goals: habitGoalList, date: date, firstDayOfWeek });

                if (!latestHabitGoal) {
                  habitCurrentProgress.isNoGoalHabit = true;
                  habitCurrentProgress.habitGoalPeriodicity = 'daily';
                  habitCurrentProgress.habitGoalSymbol = 'rep';
                  habitCurrentProgress.habitGoalValue = 1;
                  if (isBadHabit) {
                    habitCurrentProgress.badHabitGoalType = 'quit';
                  }
                }

                if (latestHabitGoal && latestHabitGoal.periodicity && latestHabitGoal.unit) {
                  const goalValue = latestHabitGoal.value || 0;
                  const periodicity = latestHabitGoal.periodicity;
                  const symbol = latestHabitGoal.unit.symbol;

                  if (symbol) {
                    habitCurrentProgress.habitGoalValue = goalValue;
                    habitCurrentProgress.habitGoalPeriodicity = periodicity;
                    habitCurrentProgress.habitGoalSymbol = symbol;
                    if (isBadHabit) {
                      habitCurrentProgress.badHabitGoalType =
                        goalValue < BAD_HABIT_GOAL_VALUE.NO_MORE_THAN ? 'quit' : 'no-more-than';
                    }

                    if (isOneTimePerDayHabit({ periodicity, symbol, value: goalValue })) {
                      habitCurrentProgress.isOneTimePerDayHabit = true;
                    }
                    if (habitLogs) {
                      const currentProgress = computeProgressFromHabitLog({
                        goal: latestHabitGoal,
                        habitLogs,
                        startDate,
                        date,
                        isBadHabit,
                        firstDayOfWeek,
                      });
                      let currentValue = 0;
                      if (isBadHabit && currentProgress && !goalValue) {
                        currentValue = 100;
                      } else {
                        currentValue = (currentProgress * goalValue) / 100;
                      }
                      habitCurrentProgress.habitActualLogValue = Math.round(currentValue * 100) / 100;

                      let logValue = 0;
                      const habitLogFilterChecking: { [dateKey: string]: number } = {};
                      let startAtFormatted = '';

                      for (const log of habitLogs) {
                        const { startAt, value, unitSymbol } = log;
                        if (startAt && unitSymbol && value) {
                          const timestampsHabitLog = _dayjs(startAt).valueOf();
                          if (timestampsHabitLog < _dayjs(startDateFormatted).valueOf()) {
                            continue;
                          }
                          startAtFormatted = _dayjs(startAt).format('YYYY-MM-DD');
                          if (_dayjs(startAtFormatted).valueOf() >= _dayjs(startDateFormatted).valueOf()) {
                            const startAtDate = _dayjs(startAt).format('DDMMYYYY');
                            const habitGoal = getCurrentHabitGoal({
                              date: _dayjs(startAt),
                              goals: habitGoalList,
                              firstDayOfWeek,
                            });
                            if (habitGoal && habitGoal.periodicity && habitGoal.unit) {
                              const localGoalValue = habitGoal?.value || 0;
                              const localPeriodicity = habitGoal?.periodicity;
                              const localSymbol = habitGoal?.unit?.symbol;
                              const baseUnitSymbol = getBaseUnitFromType({
                                type: getType({ unitSymbol }),
                              });
                              if (localSymbol) {
                                if (
                                  getType({ unitSymbol }) === getType({ unitSymbol: localSymbol }) &&
                                  unitSymbol === baseUnitSymbol
                                ) {
                                  if (localPeriodicity === 'daily') {
                                    if (habitLogFilterChecking[startAtDate]) {
                                      logValue = habitLogFilterChecking[startAtDate] + value;
                                    } else {
                                      logValue = value || 0;
                                    }
                                    habitLogFilterChecking[startAtDate] = logValue;
                                    const convertedValue = Math.round(
                                      convert({ source: unitSymbol, target: localSymbol, value: logValue }),
                                    );
                                    if (convertedValue >= localGoalValue) {
                                      allLogValuesComplete.push(startAtDate);
                                    } else {
                                      if (!allLogValuesInProgress.includes(startAtDate)) {
                                        allLogValuesInProgress.push(startAtDate);
                                      }
                                    }
                                    if (isBadHabit) {
                                      if (convertedValue <= localGoalValue) {
                                        if (_dayjs(startAt).isToday()) {
                                          dateToBadHabitCompleteByLogValueMap[startAtDate] = 'in_progress';
                                        } else {
                                          dateToBadHabitCompleteByLogValueMap[startAtDate] = 'completed';
                                        }
                                      } else {
                                        dateToBadHabitCompleteByLogValueMap[startAtDate] = 'failed';
                                      }
                                    }
                                  } else {
                                    allLogValuesComplete.push(startAtDate);
                                    if (isBadHabit) {
                                      const _dateKey = getDateKey({ periodicity: 'daily', date: startAt });
                                      let _logValue = value;
                                      if (badHabitLogValueMap[_dateKey]) {
                                        const badHabitLogValue = badHabitLogValueMap[_dateKey];
                                        _logValue = badHabitLogValue + value;
                                      }
                                      badHabitLogValueMap[_dateKey] = _logValue;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }

                      if (isBadHabit) {
                        const isBeforeChosenDate = date.isBefore(_dayjs(), 'day');
                        if (isBeforeChosenDate) {
                          if (habitCurrentProgress.habitActualLogValue <= goalValue) {
                            habitCurrentProgress.habitProgress = 'completed';
                          } else {
                            habitCurrentProgress.habitProgress = 'failed';
                          }
                        } else {
                          if (habitCurrentProgress.habitActualLogValue <= goalValue) {
                            habitCurrentProgress.habitProgress = 'none';
                          } else {
                            habitCurrentProgress.habitProgress = 'failed';
                          }
                        }
                      } else {
                        if (
                          habitCurrentProgress.habitActualLogValue &&
                          habitCurrentProgress.habitActualLogValue >= goalValue
                        ) {
                          habitCurrentProgress.habitProgress = 'completed';
                        } else {
                          habitCurrentProgress.habitProgress = 'none';
                        }
                      }
                    } else {
                      if (isBadHabit) {
                        const isBeforeChosenDate = date.isBefore(_dayjs(), 'day');
                        if (isBeforeChosenDate) {
                          habitCurrentProgress.habitProgress = 'completed';
                        }
                      }
                    }
                  }
                }
              }
              const filteredCheckinAndLogValues = Array.from(new Set([...allCheckins, ...allLogValuesComplete]));
              filteredCheckinAndLogValues.sort(
                (a: string, b: string) =>
                  +new Date(_dayjs(a, 'DDMMYYYY').format('YYYY-MM-DD')) -
                  +new Date(_dayjs(b, 'DDMMYYYY').format('YYYY-MM-DD')),
              );

              const filteredLogValuesInProgress = Array.from(new Set([...allLogValuesInProgress]));
              filteredCheckinAndLogValues.sort(
                (a: string, b: string) =>
                  +new Date(_dayjs(a, 'DDMMYYYY').format('YYYY-MM-DD')) -
                  +new Date(_dayjs(b, 'DDMMYYYY').format('YYYY-MM-DD')),
              );

              const currentKey = date.format('DDMMYYYY');
              if (filteredCheckinAndLogValues.includes(currentKey)) {
                if (habitCurrentProgress.isNoGoalHabit || habitCurrentProgress.isOneTimePerDayHabit) {
                  const isBeforeToday = date.isBefore(_dayjs(), 'day');
                  if (isBadHabit && habitCurrentProgress.badHabitGoalType === 'no-more-than') {
                    if (isBeforeToday) {
                      habitCurrentProgress.habitProgress = 'completed';
                    } else {
                      const latestHabitGoal = getCurrentHabitGoal({ goals: habitGoalList, date: date, firstDayOfWeek });
                      const goalValue = latestHabitGoal?.value || 0;
                      if (
                        habitCurrentProgress.habitActualLogValue &&
                        habitCurrentProgress.habitActualLogValue > goalValue
                      ) {
                        habitCurrentProgress.habitProgress = 'failed';
                      } else {
                        habitCurrentProgress.habitProgress = 'none';
                      }
                    }
                  } else {
                    habitCurrentProgress.habitProgress = 'completed';
                  }
                }
                if (checkins) {
                  if (checkins[currentKey]) {
                    const checkin = checkins[currentKey];
                    if (checkin.value === 'none') {
                      habitCurrentProgress.habitProgress = 'none';
                    }
                    if (checkin.value === 'completed') {
                      if (habitCurrentProgress.isNoGoalHabit) {
                        habitCurrentProgress.habitActualLogValue = 1;
                      }
                      habitCurrentProgress.habitProgress = 'completed';
                    }
                    if (checkin.value === 'skipped') {
                      habitCurrentProgress.habitProgress = 'skipped';
                    }
                    if (checkin.value === 'failed') {
                      habitCurrentProgress.habitProgress = 'failed';
                    }
                  }
                }
              }

              let currentStreak = 0;
              const todayKey = today.format('DDMMYYYY');

              if (regularly) {
                if (filteredCheckinAndLogValues.includes(todayKey)) {
                  if (getHabitRegularly({ regularly, startDate, date: today }) !== '') {
                    if (checkins && checkins[todayKey] && checkins[todayKey].value === 'failed') {
                      resolve(habitCurrentProgress);
                      return;
                    }
                    currentStreak = 1;
                    if (checkins) {
                      if (checkins[todayKey]) {
                        const checkin = checkins[todayKey];
                        if (checkin.value === 'none' || checkin.value === 'skipped') {
                          currentStreak = 0;
                        }
                      }
                    }
                  }
                }

                const shouldBePreviousDate = new Date();
                shouldBePreviousDate.setDate(shouldBePreviousDate.getDate() - 1);
                while (shouldBePreviousDate > new Date(startDateFormatted)) {
                  const streakCheckinKey = _dayjs(shouldBePreviousDate).format('DDMMYYYY');
                  if (getHabitRegularly({ regularly, startDate, date: _dayjs(shouldBePreviousDate) }) !== '') {
                    if (filteredCheckinAndLogValues.includes(streakCheckinKey)) {
                      if (checkins) {
                        if (checkins[streakCheckinKey]) {
                          const checkin = checkins[streakCheckinKey];

                          if (checkin.value === 'failed') {
                            break;
                          }
                          if (checkin.value !== 'skipped' && checkin.value !== 'none') {
                            currentStreak += 1;
                          }
                        } else {
                          currentStreak += 1;
                        }
                      } else {
                        currentStreak += 1;
                      }
                    } else {
                      if (filteredLogValuesInProgress.includes(streakCheckinKey)) {
                        break;
                      }
                      if (dateRangesOffMode) {
                        if (
                          !isDateIsBetWeenOffDateRange(
                            dateRangesOffMode,
                            shouldBePreviousDate,
                            0,
                            dateRangesOffMode.length - 1,
                          )
                        ) {
                          break;
                        }
                      } else break;
                    }
                  }
                  shouldBePreviousDate.setDate(shouldBePreviousDate.getDate() - 1);
                }
              }
              habitCurrentProgress.currentStreak = currentStreak;
              if (isBadHabit) {
                calculatorBadHabitCurrentStreak({
                  habitCurrentProgress,
                  checkins,
                  startDateFormatted,
                  date,
                  startDate,
                  dateToBadHabitCompleteByLogValueMap,
                  goals: habitGoalList,
                  badHabitLogValueMap,
                  firstDayOfWeek,
                  dateRangesOffMode,
                });
              }
            }

            resolve(habitCurrentProgress);
          }
        }
      } catch (e) {
        reject(e);
      }
    });
  },
);

const getHabitCheckins = memoizeOne((checkins: HabitCheckins): string[] => {
  return keys(checkins);
});

const getDateKey = memoizeOne(({ periodicity, date }: { periodicity: GoalPeriodicity; date: string }): string => {
  const _startDate = _dayjs(date);
  if (periodicity === 'weekly') return `${_startDate.week()}-${_startDate.year()}`;
  if (periodicity === 'monthly') return `${_startDate.month()}-${_startDate.year()}`;
  return _startDate.format('DDMMYYYY');
});

const isDateIsBetWeenOffDateRange = (dateRanges: DateRange[], date: Date, start: number, end: number): boolean => {
  if (start > end) return false;
  let mid = Math.floor((start + end) / 2);
  const midDateRange = dateRanges[mid];
  const { startDate, endDate } = midDateRange;
  if (midDateRange.stopDate) {
    if (_dayjs(date).isBetween(startDate, endDate.subtract(1), 'day', '[]')) {
      return true;
    }
  } else if (_dayjs(date).isBetween(startDate, endDate, 'day', '[]')) {
    return true;
  }

  if (_dayjs(date).isAfter(endDate)) {
    return isDateIsBetWeenOffDateRange(dateRanges, date, start, mid - 1);
  } else {
    return isDateIsBetWeenOffDateRange(dateRanges, date, mid + 1, end);
  }
};
