import { Box } from "@chakra-ui/react";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement } from "react";
import Icon from "../Icon";
interface CloseButtonProps {
  onClose: () => void;
}
const CloseButton: React.FC<CloseButtonProps> = ({
  onClose,
}: CloseButtonProps): ReactElement => {
  const { colorScheme } = useThemeData();
  return (
    <Box
      w="32px"
      h="30px"
      border={`1px solid ${colorScheme.other.border}`}
      borderRadius="3px"
      bgColor={colorScheme.platform.control.background}
      display="flex"
      alignItems="center"
      justifyContent="center"
      onClick={onClose}
    >
      <Icon
        name={"ic_close"}
        width={16}
        height={16}
        fill={colorScheme.label.secondary}
      />
    </Box>
  );
};
export default CloseButton;
