import { Grid } from '@chakra-ui/layout';
import { Box } from '@chakra-ui/react';
import SEO from 'components/common/SEO';
import Sidebar from 'components/common/sidebar/Sidebar';
import { TimeOfDay } from 'models/common';
import { IAppResize, RESIZE_OPTIONS } from 'models/common/resize';
import { HabitFolders } from 'models/habit-folders';
import { Habits } from 'models/habits';
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { NewRootState } from 'store';
import { getLocalStorage } from 'tools/common/getLocalStorage';
import { RootState } from '__archived__/store/roots';
import getAppTitle from '__archived__/utils/appTitle';
interface Props {
  children?: ReactNode;
}

interface ParamTypes {
  folderId: string;
}

const Main: React.FC<Props> = ({ children }: Props): ReactElement => {
  const location = useLocation();
  const { folderId } = useParams<ParamTypes>();

  const [title, setTitle] = useState<string>('');
  const search = location.search?.split('=');
  const habitId: string = search && search?.length ? search[1] : '';
  const habits = useSelector<NewRootState, Habits>((state) => state.habitsModel.habits);
  const habitName = habits[habitId]?.name || '';

  const habitFolders = useSelector<NewRootState, HabitFolders>((state) => state.habitFoldersModel.habitFolders);
  const isJournalRouter: boolean = location.pathname.includes('journal');
  const chooseDate = useSelector<RootState, string>((state) => state.app.date);
  const timeOfDay = useSelector<NewRootState, TimeOfDay>((state) => state.appModel.timeOfDay);

  const refMainApp = useRef(null);
  // useAppResize(refMainApp.current);

  useEffect(() => {
    const title: string = getAppTitle(
      location.pathname,
      timeOfDay,
      habitFolders[folderId]?.name || '',
      habitName,
      chooseDate,
    );
    setTitle(title);
  }, [chooseDate, habitFolders, habitName, location.pathname, folderId, timeOfDay]);

  const appResize: IAppResize = getLocalStorage('APP_RESIZE');
  const widthJournal = appResize.secondSection || RESIZE_OPTIONS.DEFAULT_WIDTH_JOURNAL;
  const widthSidebar = appResize.sidebar || RESIZE_OPTIONS.DEFAULT_WIDTH_SIDEBAR;
  return (
    <Grid
      ref={refMainApp}
      height="100vh"
      templateColumns={
        !isJournalRouter
          ? '240px 1fr'
          : `${widthSidebar}px minmax(${RESIZE_OPTIONS.MIN_WIDTH_JOURNAL}px, ${widthJournal}px) auto`
      }
      templateAreas={`"sidebar journal single-progress"`}
      overflow="hidden"
      className="main-app"
    >
      <SEO title={title} />
      <Box height="70px" w="full" pos="absolute" top="0" right="0" className="drag-top-area"></Box>
      <Sidebar />
      {children}
    </Grid>
  );
};

export default Main;
