import update from 'immutability-helper';
import { IHabitActionList, IHabitStates } from '__archived__/types/states/habit';
import { AUTHENTICATION_ACTION_TYPES, SignOutType } from '../authentication/auth.type';
import {
  CreateHabitType,
  GetAllHabitActionsTypeAction,
  HABIT_TYPE,
  OpenActionListType,
  SelectSuggestionHabitType,
  SetLatestHabitId
} from './habit.type';

const habitInitialState: IHabitStates = {
  selectedSuggestionHabits: {},
  isNewHabitCreate: false,
  isActionListModalOpen: false,
  latestHabitId: '',
  habitActions: new Map<string, IHabitActionList>(),
  getHabitActions: false,
  getHabitActionsSuccess: false,
  getHabitActionsFailed: false,
};

export type HabitReducerActionType =
  | SelectSuggestionHabitType
  | CreateHabitType
  | OpenActionListType
  | SetLatestHabitId
  | SignOutType
  | GetAllHabitActionsTypeAction;

export default function habitReducer(
  state: IHabitStates = habitInitialState,
  action: HabitReducerActionType,
): IHabitStates {
  switch (action.type) {
    case HABIT_TYPE.SELECT_SUGGESTION_HABIT:
      return {
        ...state,
        selectedSuggestionHabits: action.payload,
      };
    case HABIT_TYPE.CREATE_HABIT:
      return {
        ...state,
        isNewHabitCreate: action.payload,
      };
    case HABIT_TYPE.OPEN_ACTION_LIST_MODAL:
      return {
        ...state,
        isActionListModalOpen: action.payload,
      };
    case HABIT_TYPE.SET_LATEST_HABIT_ID:
      return {
        ...state,
        latestHabitId: action.payload,
      };
    case AUTHENTICATION_ACTION_TYPES.SIGN_OUT:
      return habitInitialState;
    case HABIT_TYPE.GET_HABIT_ACTIONS:
      return {
        ...state,
        getHabitActions: action.payload,
      };
    case HABIT_TYPE.GET_HABIT_ACTIONS_SUCCESS:
      return {
        ...state,
        habitActions: action.payload,
        getHabitActions: false,
        getHabitActionsSuccess: true,
        getHabitActionsFailed: false,
      };
    case HABIT_TYPE.GET_HABIT_ACTIONS_FAILED:
      return {
        ...state,
        getHabitActions: false,
        getHabitActionsSuccess: false,
        getHabitActionsFailed: true,
      };
    case HABIT_TYPE.UPDATE_HABIT_ACTIONS_CHILD_ADDED:
      return update(state, {
        habitActions: {
          $add: [[action.payload.habitId, { ...action.payload.habitAction }]],
        },
      });
    case HABIT_TYPE.UPDATE_HABIT_ACTIONS_CHILD_CHANGE:
      return update(state, {
        habitActions: {
          [action.payload.habitId]: {
            $set: { ...action.payload.habitAction },
          },
        },
      });
    case HABIT_TYPE.UPDATE_HABIT_ACTIONS_CHILD_REMOVE:
      return update(state, {
        habitActions: {
          $remove: [action.payload],
        },
      });
    default:
      return state;
  }
}
