import { Dayjs } from 'dayjs';

class DayUtil {
  private dateObj: Dayjs;

  constructor(date: Dayjs) {
    this.dateObj = date;
  }

  get date(): Dayjs {
    return this.dateObj;
  }

  get firstWeekDayOfMonth(): number {
    return this.dateObj.startOf('M').day();
  }

  get lastWeekDayOfMonth(): number {
    return this.dateObj.endOf('M').day();
  }

  get daysInMonth(): number {
    return this.dateObj.daysInMonth();
  }

  get today(): string {
    return this.dateObj.format('D');
  }
}

export default DayUtil;
