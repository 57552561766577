import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import ActionButton from 'components/authentication/common/action-button.component';
import EmailInput from 'components/authentication/common/email-input.component';
import PasswordInput from 'components/authentication/common/password-input.component';
import Icon from 'components/common/Icon';
import WarningModal from 'components/common/modal/warning-modal/warning-modal.component';
import { EmailAuthProvider, getAuth, linkWithCredential, UserInfo } from 'firebase/auth';
import { useThemeData } from 'hooks/useThemeData';
import { PROVIDER_ID } from 'models/single-progress/profile';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  updateUserInfo: (user: UserInfo) => void;
  closeModal: () => void;
}

const AccountAuthentication: React.FC<Props> = ({ updateUserInfo, closeModal }: Props): ReactElement => {
  const { t } = useTranslation();
  const { colorScheme, typography } = useThemeData();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [messError, setMessError] = useState('');

  const handleOnSubmit = (email: string, password: string) => {
    const credential = EmailAuthProvider.credential(email, password);
    const auth = getAuth();
    if (auth.currentUser) {
      setIsLoading(true);
      linkWithCredential(auth.currentUser, credential)
        .then((usercred) => {
          const user = usercred.user;
          const providerData = user.providerData.find((val) => val.providerId === PROVIDER_ID.EMAIL_PROVIDER_ID);
          providerData && updateUserInfo(providerData);
          setIsLoading(false);
          closeModal();
        })
        .catch((error) => {
          setMessError(t('authentication.accountExist'));
          setIsOpenWarningModal(true);
          setIsLoading(false);
        });
    }
  };

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        position="relative"
        border={`1px solid ${colorScheme.other.border}`}
        background="#313131"
        textAlign="center"
        padding="45px 54px 43px 55px"
        width="401px"
        maxH="352px"
        minH="274px"
        borderRadius="5px"
        bgColor={colorScheme.background.grouped[2]}
      >
        <Box marginTop="10px" id="account-authentication">
          <Flex
            as={'button'}
            position="absolute"
            top="12px"
            left="12px"
            height="30px"
            border={`1px solid ${colorScheme.other.border}`}
            justify="center"
            align="center"
            borderRadius="3px"
            cursor="default"
            p="8px 7.5px"
            onClick={() => closeModal()}
          >
            <Icon name="ic_arrow_left" width={16} height={16} fill={colorScheme.label.secondary} />
            <Text {...typography.emphasized.callout} color={colorScheme.label.primary} lineHeight="30px" ml="6px">
              {t('common.back')}
            </Text>
          </Flex>

          <>
            <Text
              {...typography.emphasized.title[1]}
              color={colorScheme.label.primary}
              mt="19px"
              mb="5px"
              lineHeight="27px"
            >
              {t('authentication.signInTitle')}
            </Text>
            <Text {...typography.normal.body} color={colorScheme.label.secondary} mb="20px" lineHeight="18px">
              {t('authentication.signInMessage')}
            </Text>
            <EmailInput defaultValue={email} handleInput={(e) => setEmail(e.target.value)} />
            <PasswordInput
              defaultValue={password}
              handleInput={(e) => setPassword(e.target.value)}
              handleSubmit={() => () => { }}
            />
            <Flex justify={isLoading ? 'space-between' : 'flex-end'} align="center" w="280px" h="28px" m="0 auto">
              {isLoading && (
                <Text {...typography.emphasized.callout} color={colorScheme.label.secondary}>
                  Registering you in...
                </Text>
              )}

              {isLoading ? (
                <Box h="28px">
                  <Spinner color={colorScheme.label.secondary} w="20px" h="20px" />
                </Box>
              ) : (
                <ActionButton
                  text={t('common.signIn')}
                  handleSubmit={() => handleOnSubmit(email, password)}
                  width="76px"
                  height="28px"
                />
              )}
            </Flex>
          </>
        </Box>
      </Box>
      <WarningModal
        icon="ic-warning-red"
        title={'Confirmation'}
        subTitle={messError}
        labelBtnSecondary={t('common.cancel')}
        open={isOpenWarningModal}
        closeModal={() => setIsOpenWarningModal(false)}
      />
    </Box>
  );
};

export default AccountAuthentication;
