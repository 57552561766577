import { Box, Spinner, useColorModeValue } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { darkMode, lightMode } from '__archived__/utils/colorPreferences';

const Splash: React.FC = (): ReactElement => {
  const accentColor = useColorModeValue(lightMode.ACCENT_COLOR, darkMode.ACCENT_COLOR);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" width="100%" textAlign="center">
      <Box className="pulsing-overlay">
        <Spinner color={accentColor} size="lg" />
      </Box>
    </Box>
  );
};

export default Splash;
