import { ref, update as firebaseUpdate } from '@firebase/database';
import { createModel } from '@rematch/core';
import { database } from 'firebase-v9';
import { onValue, remove } from 'firebase/database';
import { DateRanges, OffMode, OffModes } from 'models/off-mode';
import { _dayjs } from 'tools/extended-dayjs';
import { offModeModelMapper } from 'tools/off-mode/off-mode-model-parser';
import { RootModel } from '../../root.model';

type OffModeModel = {
  offModes: OffModes;
  dateRanges: DateRanges;
  isOffMode: boolean;
};

const initialState: OffModeModel = {
  offModes: {},
  dateRanges: {},
  isOffMode: false,
};

export const offModeModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setOffModes(state, payload: OffModes) {
      return {
        ...state,
        offModes: payload,
      };
    },
    setDateRanges(state, payload: DateRanges) {
      return {
        ...state,
        dateRanges: payload,
      };
    },
    setIsOffMode(state, payload: boolean) {
      return {
        ...state,
        isOffMode: payload,
      };
    },
  },
  effects: (dispatch) => ({
    getAllOffModes({ uid }: { uid: string }) {
      const offModeRef = ref(database, `offModes/${uid}`);
      onValue(offModeRef, (snapshot) => {
        const offModes: OffModes = {};
        snapshot.forEach((childSnapshot) => {
          const offModeId = childSnapshot.key;
          if (offModeId) {
            const offMode = parseOffMode(offModeId, childSnapshot.val());
            if (offMode) {
              offModes[offModeId] = offMode;
            }
          }
        });
        this.setOffModes(offModes);
      });
    },
    getDateRangesOfOffModes({ ranges }: { ranges: DateRanges }) {
      this.setDateRanges(ranges);
    },
    updateAllOffModes(offModes: OffModes) {
      this.setOffModes(offModes);
    },
    deleteOffMode({ uid, offModeId }: { uid: string; offModeId: string }) {
      const offModeRef = ref(database, `offModes/${uid}/${offModeId}`);
      remove(offModeRef);
      this.setIsOffMode(false);
    },
    stopOffMode({ uid, offModeId }: { uid: string; offModeId: string }) {
      const offModeRef = ref(database, `offModes/${uid}/${offModeId}`);
      firebaseUpdate(offModeRef, { stopDate: _dayjs().format('YYYY-MM-DD') });
      this.setIsOffMode(false);
    },
    updateIsOffMode(status: boolean) {
      this.setIsOffMode(status);
    },
    resetOffModes() {
      this.setOffModes({});
    },
  }),
});

function parseOffMode(offModeId: string, rawValue: any): OffMode | undefined {
  return offModeModelMapper({ key: offModeId, rawValue });
}
