export const offModeIconOptions = [
  'a4f5788_becd_11ec_9d64_0242ac120002',
  'a4f6b38_becd_11ec_9d64_0242ac120002',
  'a4f6912_becd_11ec_9d64_0242ac120002',
  'a4f6714_becd_11ec_9d64_0242ac120002',
  'a4f6570_becd_11ec_9d64_0242ac120002',
  'a4f62aa_becd_11ec_9d64_0242ac120002',
  'a4f5c9c_becd_11ec_9d64_0242ac120002',
  'a4f5a76_becd_11ec_9d64_0242ac120002',
];
