import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { database, messaging } from 'firebase-v9';
import { OAuthCredential, Unsubscribe as AuthUnsubscribe } from 'firebase/auth';
import {
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
  Unsubscribe as DatabaseUnsubscribe,
  Unsubscribe,
} from 'firebase/database';
import { getToken } from 'firebase/messaging';
import { useCheckAppleSale } from 'hooks';
import { useCheckPremium } from 'hooks/useCheckPremium';
import ISO6391 from 'iso-639-1';
import { resources } from 'localization/resources';
import values from 'lodash/values';
import { DateRange } from 'models/off-mode';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { NewDispatch, NewRootState } from 'store';
import { updateMigrateVersion } from 'tools/authentication-and-affiliates';
import { updateUserEndpoint, updateUserLastActiveTime } from 'tools/endpoints';
import { isElectronEnv } from 'tools/env';
import Routes from 'views/Routes';
import { _dayjs } from '__archived__/constants/app';
import {
  fetchEvent,
  getMoodLogAction,
  getUsageConfigAction,
  getUserPreferencesAction,
} from '__archived__/store/ducks/app/app.action';
import { RootState } from '__archived__/store/roots';
import { pushAnalyticEvent } from '__archived__/utils/pushAnalyticEvent';

dayjs.extend(localeData);

const App: React.FC = (): ReactElement => {
  const location = useLocation();
  const history = useHistory();

  const uid = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const newDispatch = useDispatch<NewDispatch>();

  const getUserPreferences = useCallback((uid) => dispatch(getUserPreferencesAction(uid)), [dispatch]);
  const getUsageConfig = useCallback(() => dispatch(getUsageConfigAction()), [dispatch]);
  const getEvent = useCallback((uid) => dispatch(fetchEvent(uid)), [dispatch]);
  const getUserMood = useCallback((uid) => dispatch(getMoodLogAction(uid)), [dispatch]);
  const isPremium = useCheckPremium();
  const userInfo = useSelector((state: NewRootState) => state.usersInfoModel.user);
  const pricingInfoApple = useSelector((state: NewRootState) => state.paymentModel.pricingInfoApple);
  const appleSaleCampaign = useSelector((state: NewRootState) => state.paymentModel.remoteAppleSaleCampaign);
  const canMakePayments = useSelector<NewRootState, boolean>((state) => state.appModel.isCanMakeMacAppStorePayments);
  const chooseDate = useSelector<RootState, string>((state) => state.app.date);
  const offModeDateRanges = useSelector((state: NewRootState) => state.offModeModel.dateRanges);

  const syncedHabitsSuccess = useSelector<NewRootState, boolean>((state) => state.habitsModel.syncedHabitsSuccess);
  const syncedHabitLogsSuccess = useSelector<NewRootState, boolean>(
    (state) => state.habitLogsModel.syncedHabitLogsSuccess,
  );
  const isSale = useCheckAppleSale();

  useEffect(() => {
    if (uid) {
      newDispatch.habitsModel.getAllHabits({ uid });
    }
  }, [newDispatch.habitsModel, uid]);

  useEffect(() => {
    if (uid) {
      newDispatch.habitLogsModel.getAllHabitLogs({ uid });
    }
  }, [newDispatch.habitLogsModel, uid]);

  useEffect(() => {
    if (uid) {
      newDispatch.offModeModel.getAllOffModes({ uid });
    }
  }, [newDispatch.offModeModel, uid]);

  const checkDateIsInOffMode = useCallback(
    (offDateRanges: DateRange[], date: Dayjs) => {
      for (let i = 0; i < offDateRanges.length; i++) {
        const range = offDateRanges[i];
        const { startDate, endDate } = range;
        if (range.stopDate) {
          if (date.isBetween(startDate, endDate.subtract(1), 'day', '[]')) {
            newDispatch.offModeModel.updateIsOffMode(false);
            break;
          }
        } else if (date.isBetween(startDate, endDate, 'day', '[]')) {
          newDispatch.offModeModel.updateIsOffMode(false);
          break;
        }
      }
    },
    [newDispatch.offModeModel],
  );

  useEffect(() => {
    let onOffModeChildRemovedUnsubscribe: Unsubscribe;
    if (uid) {
      const offModeRef = ref(database, `offModes/${uid}`);
      const offDateRanges = values(offModeDateRanges);

      onOffModeChildRemovedUnsubscribe = onChildRemoved(offModeRef, (snapshot) => {
        const date = _dayjs(chooseDate);
        checkDateIsInOffMode(offDateRanges, date);
      });
    }

    return () => {
      if (typeof onOffModeChildRemovedUnsubscribe === 'function') {
        onOffModeChildRemovedUnsubscribe();
      }
    };
  }, [checkDateIsInOffMode, chooseDate, offModeDateRanges, uid]);

  useEffect(() => {
    let onOffModeChildChangeUnsubscribe: Unsubscribe;
    if (uid) {
      const offModeRef = ref(database, `offModes/${uid}`);
      const offDateRanges = values(offModeDateRanges);

      onOffModeChildChangeUnsubscribe = onChildChanged(offModeRef, (snapshot) => {
        const date = _dayjs(chooseDate);
        checkDateIsInOffMode(offDateRanges, date);
      });
    }

    return () => {
      if (typeof onOffModeChildChangeUnsubscribe === 'function') {
        onOffModeChildChangeUnsubscribe();
      }
    };
  }, [checkDateIsInOffMode, chooseDate, offModeDateRanges, uid]);

  useEffect(() => {
    let onHabitChildAddedUnsubscribe: Unsubscribe;
    if (syncedHabitsSuccess && uid) {
      const habitRef = ref(database, `habits/${uid}`);
      const offDateRanges = values(offModeDateRanges);
      onHabitChildAddedUnsubscribe = onChildAdded(habitRef, (snapshot) => {
        newDispatch.habitsModel.watchOnChildAdded({ snapshot, chooseDate, offModeDateRanges: offDateRanges });
      });
    }

    return () => {
      if (typeof onHabitChildAddedUnsubscribe === 'function') {
        onHabitChildAddedUnsubscribe();
      }
    };
  }, [chooseDate, newDispatch.habitsModel, offModeDateRanges, syncedHabitsSuccess, uid]);

  useEffect(() => {
    let onHabitChildChangedUnsubscribe: Unsubscribe;
    if (syncedHabitsSuccess && uid) {
      const habitRef = ref(database, `habits/${uid}`);
      const offDateRanges = values(offModeDateRanges);
      onHabitChildChangedUnsubscribe = onChildChanged(habitRef, (snapshot) => {
        newDispatch.habitsModel.watchOnChildChanged({ snapshot, chooseDate, offModeDateRanges: offDateRanges });
      });
    }

    return () => {
      if (typeof onHabitChildChangedUnsubscribe === 'function') {
        onHabitChildChangedUnsubscribe();
      }
    };
  }, [chooseDate, newDispatch.habitsModel, offModeDateRanges, syncedHabitsSuccess, uid]);

  useEffect(() => {
    let onHabitChildRemovedUnsubscribe: Unsubscribe;
    if (syncedHabitsSuccess && uid) {
      const habitRef = ref(database, `habits/${uid}`);
      onHabitChildRemovedUnsubscribe = onChildRemoved(habitRef, (snapshot) => {
        newDispatch.habitsModel.watchOnChildRemoved(snapshot);
      });
    }

    return () => {
      if (typeof onHabitChildRemovedUnsubscribe === 'function') {
        onHabitChildRemovedUnsubscribe();
      }
    };
  }, [newDispatch.habitsModel, syncedHabitsSuccess, uid]);

  useEffect(() => {
    let onHabitLogsChildAddedUnsubscribe: Unsubscribe;
    if (syncedHabitLogsSuccess && uid) {
      const habitLogsRef = ref(database, `habitLogs/${uid}`);
      const offDateRanges = values(offModeDateRanges);
      onHabitLogsChildAddedUnsubscribe = onChildAdded(habitLogsRef, (snapshot) => {
        newDispatch.habitLogsModel.watchOnChildAdded({ snapshot, chooseDate, offModeDateRanges: offDateRanges });
      });
    }

    return () => {
      if (typeof onHabitLogsChildAddedUnsubscribe === 'function') {
        onHabitLogsChildAddedUnsubscribe();
      }
    };
  }, [chooseDate, newDispatch.habitLogsModel, offModeDateRanges, syncedHabitLogsSuccess, uid]);

  useEffect(() => {
    let onHabitLogsChildChangedUnsubscribe: Unsubscribe;
    if (syncedHabitLogsSuccess && uid) {
      const habitLogsRef = ref(database, `habitLogs/${uid}`);
      const offDateRanges = values(offModeDateRanges);

      onHabitLogsChildChangedUnsubscribe = onChildChanged(habitLogsRef, (snapshot) => {
        newDispatch.habitLogsModel.watchOnChildChanged({ snapshot, chooseDate, offModeDateRanges: offDateRanges });
      });
    }

    return () => {
      if (typeof onHabitLogsChildChangedUnsubscribe === 'function') {
        onHabitLogsChildChangedUnsubscribe();
      }
    };
  }, [chooseDate, newDispatch.habitLogsModel, offModeDateRanges, syncedHabitLogsSuccess, uid]);

  useEffect(() => {
    let onHabitLogsChildRemovedUnsubscribe: Unsubscribe;
    if (syncedHabitsSuccess && uid) {
      const habitLogsRef = ref(database, `habitLogs/${uid}`);
      const offDateRanges = values(offModeDateRanges);

      onHabitLogsChildRemovedUnsubscribe = onChildRemoved(habitLogsRef, (snapshot) => {
        newDispatch.habitLogsModel.watchOnChildRemoved({ snapshot, chooseDate, offModeDateRanges: offDateRanges });
      });
    }

    return () => {
      if (typeof onHabitLogsChildRemovedUnsubscribe === 'function') {
        onHabitLogsChildRemovedUnsubscribe();
      }
    };
  }, [chooseDate, newDispatch.habitLogsModel, offModeDateRanges, syncedHabitsSuccess, uid]);

  useEffect(() => {
    let onAuthStateChangedUnsubscribe: AuthUnsubscribe;
    newDispatch.authenticationModel.onRefreshAuthenticationState({
      setUnsubscribe: (unsubscribe) => (onAuthStateChangedUnsubscribe = unsubscribe),
    });

    return () => {
      if (typeof onAuthStateChangedUnsubscribe === 'function') {
        onAuthStateChangedUnsubscribe();
      }
    };
  }, [newDispatch.authenticationModel]);

  useEffect(() => {
    if (uid) {
      if (isElectronEnv() && process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID) {
        const senderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
        window.ElectronBridge.startNotificationService(senderId);
        window.ElectronBridge.getToken((pushToken) => {
          updateUserEndpoint({ uid, pushToken, deviceType: 'Desktop' });
        });
        window.ElectronBridge.notifyPushMessage((habitId: string) => {
          history.push(`${location.pathname}?habitId=${habitId}`);
        });
      } else {
        getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_FCM_KEY })
          .then((pushToken) => {
            if (pushToken) {
              updateUserEndpoint({ uid, pushToken, deviceType: 'Web' });
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch((e) => {
            console.log('An error occurred while retrieving token. ', e);
          });
      }
    }
  }, [history, uid, location]);

  useEffect(() => {
    if (isElectronEnv()) {
      window.ElectronBridge.checkCanMakePayments((canMakePayments) => {
        newDispatch.appModel.setIsCanMakeMacAppStorePayments(canMakePayments);
        if (isElectronEnv() && canMakePayments) {
          newDispatch.paymentModel.getPricingInfoApple();
          newDispatch.paymentModel.getRemoteAppleSaleCampaign();
        }
      });
    }
  }, [newDispatch.appModel, newDispatch.paymentModel]);

  useEffect(() => {
    if (isElectronEnv() && canMakePayments) {
      if (isSale) {
        if (appleSaleCampaign?.availablePlans) {
          const plans = appleSaleCampaign.availablePlans;
          newDispatch.paymentModel.getAppleSaleProducts(plans);
        }
      } else {
        if (pricingInfoApple?.plans) {
          const plans = pricingInfoApple.plans;
          newDispatch.paymentModel.getAppleProducts(plans);
        }
      }
    }
  }, [appleSaleCampaign?.availablePlans, canMakePayments, isSale, newDispatch.paymentModel, pricingInfoApple?.plans]);

  useEffect(() => {
    if (isElectronEnv()) {
      window.ElectronBridge.onAuthenticationUpdate((credentialJSON) => {
        const credential = OAuthCredential.fromJSON(credentialJSON);
        if (credential) {
          newDispatch.authenticationModel.setSignInWithCredential(true);
          newDispatch.authenticationModel.signInWithCredential(credential).then((results) => {
            const user = results.user;
            updateMigrateVersion(user.uid);
            newDispatch.authenticationModel.setSignInWithCredential(false);
          });
        }
      });
    }
  }, [newDispatch.authenticationModel]);

  useEffect(() => {
    if (uid) {
      updateUserLastActiveTime(uid);
    }
  }, [uid]);

  useEffect(() => {
    pushAnalyticEvent('AppSession');
    // pushAnalyticEvent(AppAnalytics.EventName.AppSession);
    sessionStorage.removeItem('state-zoom');
  }, []);

  useEffect(() => {
    if (uid) {
      getUsageConfig();
      getUserPreferences(uid);
      getUserMood(uid);
    }
  }, [getUsageConfig, getUserMood, getUserPreferences, uid]);

  useEffect(() => {
    let watchOnUserInfoValueUnsubscribe: DatabaseUnsubscribe;
    if (uid) {
      newDispatch.usersInfoModel.onValueUser({
        uid,
        setUnsubscribe: (unsubscribe) => {
          watchOnUserInfoValueUnsubscribe = unsubscribe;
        },
      });
    }

    return () => {
      if (typeof watchOnUserInfoValueUnsubscribe === 'function') {
        watchOnUserInfoValueUnsubscribe();
      }
    };
  }, [newDispatch.usersInfoModel, uid]);

  useEffect(() => {
    let watchOnHabitFoldersValueUnsubscribe: DatabaseUnsubscribe;
    if (uid) {
      newDispatch.habitFoldersModel.watchOnHabitFoldersValue({
        uid,
        setUnsubscribe: (unsubscribe) => (watchOnHabitFoldersValueUnsubscribe = unsubscribe),
      });
    }

    return () => {
      if (typeof watchOnHabitFoldersValueUnsubscribe === 'function') {
        watchOnHabitFoldersValueUnsubscribe();
      }
    };
  }, [newDispatch.habitFoldersModel, uid]);

  useEffect(() => {
    if (!isPremium && uid) {
      getEvent(uid);
    }
  }, [getEvent, isPremium, uid]);

  useEffect(() => {
    const lang = userInfo?.language || localStorage.getItem('i18nextLng') || navigator.language || dayjs.locale();
    const _resources = resources as any;
    const isLangSupport = _resources[lang];
    const langCurrent = isLangSupport ? lang : 'en';
    if (langCurrent) {
      i18n.changeLanguage(langCurrent);
      newDispatch.appSettingModel.updateAppLanguage(ISO6391.getNativeName(langCurrent));
      import(`dayjs/locale/${langCurrent}.js`).then((res) => {
        dayjs.locale({
          ...res,
          weekStart: 0,
        });
      });
    }
  }, [i18n, newDispatch.appSettingModel, userInfo?.language]);

  useEffect(() => {
    if (uid) {
      newDispatch.userRemoteConfigModel.getRemoteConfig({ uid });
    }
  }, [newDispatch.userRemoteConfigModel, uid]);

  return <Routes />;
};

export default App;
