import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import { FC, ReactElement, useCallback } from 'react';
import InformationIcon from '../../../assets/icons/ic-warning-blue.svg';
import ErrorIcon from '../../../assets/icons/ic-warning-red.svg';
import WarningIcon from '../../../assets/icons/ic-warning.svg';

interface Props {
  variant?: 'info' | 'warn' | 'error';
  title?: string;
  description?: string;
}

const ToastWrapper: FC<Props> = ({ variant, title, description }: Props): ReactElement => {
  const { colorScheme, typography } = useThemeData();

  const getIconSrc = useCallback((variant: 'info' | 'warn' | 'error' | undefined): string => {
    switch (variant) {
      case 'info':
        return InformationIcon;
      case 'warn':
        return WarningIcon;
      case 'error':
        return ErrorIcon;
      default:
        return InformationIcon;
    }
  }, []);

  return (
    <Box
      width="421px"
      bg={colorScheme.background.primary[1]}
      p="23px"
      borderRadius="6px"
      m="4"
      border={`1px solid ${colorScheme.supporting.seperator}`}
    >
      <Flex justify="flex-start" align="flex-start">
        <Image src={getIconSrc(variant)} width="18px" height="18px" mr="11px" pt="1px" />
        <Box>
          <Text {...typography.normal.title[3]} color={colorScheme.label.primary} mb="6px">
            {title}
          </Text>
          <Text {...typography.normal.body} color={colorScheme.label.secondary}>
            {description}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export default ToastWrapper;
