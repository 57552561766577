import { onValue, ref, remove, update as updateFirebase } from '@firebase/database';
import { getDownloadURL, ref as refStore, uploadBytes } from '@firebase/storage';
import { createModel } from '@rematch/core';
import { database, storage } from 'firebase-v9';
import { getAuth } from 'firebase/auth';
import { Unsubscribe } from 'firebase/database';
import { Habits } from 'models/habits';
import { User } from 'models/user';
import { RootModel } from '../../root.model';

type UsersInfoModel = {
  urlAvatarClient: string | null;
  user: User | null;
  isOpenIntercom: boolean;
};

const initialState: UsersInfoModel = {
  urlAvatarClient: null,
  user: null,
  isOpenIntercom: false,
};

export const usersInfoModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateUserAvatarClient(state, url: string) {
      return {
        ...state,
        urlAvatarClient: url,
      };
    },
    setUser(state, payload: { user: User | null }) {
      return {
        ...state,
        user: payload.user,
      };
    },
    setIsOpenIntercom(state, payload: boolean) {
      return {
        ...state,
        isOpenIntercom: payload,
      };
    },
  },
  effects: (dispatch) => ({
    updateUserAvatar(payload: { uid: string | undefined; files: FileList | null }) {
      const { uid, files } = payload;
      if (uid && files) {
        const file = files[0];
        const avatarRef = refStore(storage, `${uid}/profileImage.jpg`);
        uploadBytes(avatarRef, file)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
              const userRef = ref(database, `users/${uid}`);
              dispatch.usersInfoModel.updateUserAvatarClient(url);
              return updateFirebase(userRef, { profileImage: url });
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    updateFirstLastName(payload: { uid: string | undefined; firstName: string; lastName: string }) {
      const { uid, firstName, lastName } = payload;
      if (uid) {
        const _fistName = !!firstName.trim() ? firstName : null;
        const _lastName = !!lastName.trim() ? lastName : null;
        if (!_fistName && !_lastName) return;
        const userRef = ref(database, `users/${uid}`);
        const fullName =
          _fistName && !_lastName ? _fistName : !_fistName && _lastName ? _lastName : `${_fistName} ${_lastName}`;
        return updateFirebase(userRef, { firstName: _fistName, lastName: _lastName, name: fullName });
      }
    },
    onValueUser({ uid, setUnsubscribe }: { uid: string; setUnsubscribe?: (unsubscribe: Unsubscribe) => void }) {
      const userRef = ref(database, `users/${uid}`);
      const unsubscribe = onValue(userRef, (snapshot) => {
        dispatch.usersInfoModel.setUser({ user: snapshot.val() });
      });
      if (setUnsubscribe) setUnsubscribe(unsubscribe);
    },

    deleteAllData({ uid }: { uid: String | undefined }) {
      if (uid) {
        remove(ref(database, `habits/${uid}`));
        remove(ref(database, `habitLogs/${uid}`));
        remove(ref(database, `notes2/${uid}`));
        remove(ref(database, `habitFolders/${uid}`));
        remove(ref(database, `habitActions/${uid}`));
        remove(ref(database, `events/${uid}`));
        remove(ref(database, `preferences/${uid}`));
        remove(ref(database, `userMood/${uid}`));
      }
    },

    resetData({ uid, habits }: { uid: string | undefined; habits: Habits }) {
      if (uid && Object.keys(habits)) {
        Object.keys(habits).map((habitId) => {
          if (habitId && habits[habitId]?.checkins) {
            remove(ref(database, `habits/${uid}/${habitId}/checkins`));
          }
          return habitId;
        });
        remove(ref(database, `habitLogs/${uid}`));
        remove(ref(database, `notes2/${uid}`));
        remove(ref(database, `habitActions/${uid}`));
      }
    },

    deleteAccount() {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        currentUser.delete();
      }
    },

    updateStatusOpenIntercom(isOpen: boolean) {
      dispatch.usersInfoModel.setIsOpenIntercom(isOpen);
    },
  }),
});
