import memoizeOne from 'memoize-one';
import { Habit } from 'models/habits';
import { removeDiacritics } from '../common/diacritics';

export type SortingOrder = 'asc' | 'desc';

export const sortHabitByAlphabetical = memoizeOne(
  ({ habits, order }: { habits: Habit[]; order: SortingOrder }): Promise<Habit[]> => {
    return new Promise((resolve, reject) => {
      try {
        resolve(habits.sort((a, b) => _sort({ order, nameA: a.name, nameB: b.name })));
      } catch (e) {
        reject(e);
      }
    });
  },
);

const _sort = memoizeOne(
  ({
    order,
    nameA,
    nameB,
  }: {
    order: SortingOrder;
    nameA: string | null | undefined;
    nameB: string | null | undefined;
  }) => {
    const _nameA = nameA ? removeDiacritics(nameA.toLowerCase().trim()) : '';
    const _nameB = nameB ? removeDiacritics(nameB.toLowerCase().trim()) : '';

    let comparison = 0;
    if (_nameA > _nameB) {
      comparison = 1;
    }
    if (_nameA < _nameB) {
      comparison = -1;
    }

    return order === 'desc' ? comparison * -1 : comparison;
  },
);
