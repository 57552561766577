import { get, ref, remove, update as firebaseUpdate } from '@firebase/database';
import { createModel } from '@rematch/core';
import { database } from 'firebase-v9';
import remoteConfig from 'firebase-v9/remote-config';
import { DataSnapshot, push } from 'firebase/database';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import update from 'immutability-helper';
import { Habit, HabitGoal, Habits, NewHabit } from 'models/habits';
import { SuggestionHabitsList } from 'models/habits/habit-suggestion';
import { DateRange } from 'models/off-mode';
import { _dayjs } from 'tools/extended-dayjs';
import { calculateHabitProgress } from 'tools/habit-progress';
import { habitModelMapper } from 'tools/habits';
import { v4 as uuid } from 'uuid';
import { RootModel } from '../../root.model';

type HabitsState = {
  habits: Habits;
  selectedHabit: Habit;
  syncedHabitsSuccess: boolean;
  lastSyncedAction: 'child-added' | 'child-changed' | 'child-removed' | 'get' | 'none';
  isEditHabitModalOpen: boolean;
  habitSuggestions: Map<string, SuggestionHabitsList>;
  badHabitSuggestions: Map<string, SuggestionHabitsList>;
};

const initialState: HabitsState = {
  habits: {},
  selectedHabit: {},
  syncedHabitsSuccess: false,
  lastSyncedAction: 'none',
  isEditHabitModalOpen: false,
  habitSuggestions: new Map(),
  badHabitSuggestions: new Map(),
};

export const habitsModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    onDataSynced(state, payload: Habits) {
      return {
        ...state,
        habits: payload,
        syncedHabitsSuccess: true,
        lastSyncedAction: 'get',
      };
    },
    onAddedChildSynced(state, { habitId, addedHabit }: { habitId: string; addedHabit: Habit }) {
      return update(state, {
        habits: {
          [habitId]: {
            $set: addedHabit,
          },
        },
        lastSyncedAction: { $set: 'child-added' },
      });
    },
    onChangedChildSynced(state, { habitId, changedHabit }: { habitId: string; changedHabit: Habit }) {
      return update(state, {
        habits: {
          [habitId]: {
            $set: changedHabit,
          },
        },
        lastSyncedAction: { $set: 'child-changed' },
      });
    },
    onDeletedChildSynced(state, habitId: string) {
      return update(state, {
        habits: {
          $unset: [habitId],
        },
        lastSyncedAction: {
          $set: 'child-removed',
        },
      });
    },
    resetState() {
      return initialState;
    },
    setSelectedHabit(state, habit: Habit) {
      return {
        ...state,
        selectedHabit: habit,
      };
    },
    setIsEditHabit(state, isEdit: boolean) {
      return {
        ...state,
        isEditHabitModalOpen: isEdit,
      };
    },
    setSuggestionHabitList(state, list: Map<string, SuggestionHabitsList>) {
      return {
        ...state,
        habitSuggestions: list,
      };
    },
    setSuggestionBadHabitList(state, list: Map<string, SuggestionHabitsList>) {
      return {
        ...state,
        badHabitSuggestions: list,
      };
    },
  },
  effects: (dispatch) => ({
    getAllHabits({ uid }: { uid: string }) {
      const habitsRef = ref(database, `habits/${uid}`);
      get(habitsRef).then((snapshot) => {
        const habits: Habits = {};
        snapshot.forEach((childSnapshot) => {
          const habitId = childSnapshot.key;
          if (habitId) {
            const habit = parseHabit(habitId, childSnapshot.val());
            if (habit && habit.name && habit.startDate) {
              habits[habitId] = habit;
            }
          }
        });
        this.onDataSynced(habits);
      });
    },
    async watchOnChildAdded(
      {
        snapshot,
        chooseDate,
        offModeDateRanges,
      }: { snapshot: DataSnapshot; chooseDate: string; offModeDateRanges: DateRange[] },
      currentState,
    ) {
      const habitId = snapshot.key;
      if (habitId) {
        const addedHabit = parseHabit(habitId, snapshot.val());
        const isExisted = currentState.habitsModel.habits[habitId];
        if (!isExisted && addedHabit && addedHabit.name && addedHabit.startDate) {
          this.onAddedChildSynced({
            habitId,
            addedHabit,
          });
          const firstDayOfWeek = currentState.appSettingModel.preferences?.firstWeekDay;
          if (firstDayOfWeek) {
            const habitProgress = await calculateHabitProgress({
              habit: addedHabit,
              habitLogs: [],
              date: _dayjs(chooseDate),
              firstDayOfWeek,
              dateRangesOffMode: offModeDateRanges,
            });
            dispatch.habitProgressModel.addHabitProgress({
              habitId,
              habitProgress,
            });
          }
        }
      }
    },
    async watchOnChildChanged(
      {
        snapshot,
        chooseDate,
        offModeDateRanges,
      }: { snapshot: DataSnapshot; chooseDate: string; offModeDateRanges: DateRange[] },
      currentState,
    ) {
      const habitId = snapshot.key;
      if (habitId) {
        const changedHabit = parseHabit(habitId, snapshot.val());
        if (changedHabit && changedHabit.name && changedHabit.startDate) {
          this.onChangedChildSynced({
            habitId,
            changedHabit,
          });
          const habitLogs = currentState.habitLogsModel.habitLogs[habitId];
          const firstDayOfWeek = currentState.appSettingModel.preferences?.firstWeekDay;
          if (firstDayOfWeek) {
            const habitProgress = await calculateHabitProgress({
              habit: changedHabit,
              habitLogs: habitLogs,
              date: _dayjs(chooseDate),
              firstDayOfWeek,
              dateRangesOffMode: offModeDateRanges,
            });
            dispatch.habitProgressModel.updateHabitProgress({
              habitId,
              habitProgress,
            });
          }
        }
      }
    },
    watchOnChildRemoved(snapshot: DataSnapshot) {
      const habitId = snapshot.key;
      if (habitId) {
        this.onDeletedChildSynced(habitId);
        dispatch.habitProgressModel.removeHabitProgress(habitId);
      }
    },
    archiveHabit({ uid, habitId, isArchived }: { uid: string; habitId: string; isArchived: boolean }) {
      // const habitRef = ref(database, `habits/${uid}/${habitId}`)
      const updatesArchived = {
        [`/habits/${uid}/${habitId}/isArchived`]: isArchived,
      };

      firebaseUpdate(ref(database), updatesArchived);
    },
    updateTimeOfDay({ uid, habitId, timeOfDay }: { uid: string; habitId: string; timeOfDay: number }) {
      const updatesArchived = {
        [`/habits/${uid}/${habitId}/timeOfDay`]: timeOfDay,
      };

      firebaseUpdate(ref(database), updatesArchived);
    },
    updateTargetFolderId({ uid, habitId, folderId }: { uid: string; habitId: string; folderId: string }) {
      const updatesArchived = {
        [`/habits/${uid}/${habitId}/targetFolderId`]: folderId,
      };

      firebaseUpdate(ref(database), updatesArchived);
    },
    deleteFolderId({ uid, habitId }: { uid: string; habitId: string }) {
      const habitTargetFolderRef = ref(database, `habits/${uid}/${habitId}/targetFolderId`);

      remove(habitTargetFolderRef);
    },
    deleteHabit({ uid, habitId }: { uid: string; habitId: string }) {
      const habitRef = ref(database, `habits/${uid}/${habitId}`);
      remove(habitRef);
    },
    selectHabit({ habit }: { habit: Habit }) {
      dispatch.habitsModel.setSelectedHabit(habit);
    },
    openEditHabit(isOpenEdit: boolean) {
      dispatch.habitsModel.setIsEditHabit(isOpenEdit);
    },
    getSuggestionHabits() {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const goodHabits = getValue(remoteConfig, 'habitTemplates_Web');
          const badHabits = getValue(remoteConfig, 'badHabitTemplates_Web');
          const parsedGoodHabits = goodHabits && !!goodHabits.asString() && JSON.parse(goodHabits.asString());
          const parsedBadHabits = badHabits && !!badHabits.asString() && JSON.parse(badHabits.asString());
          dispatch.habitsModel.setSuggestionHabitList(parsedGoodHabits);
          dispatch.habitsModel.setSuggestionBadHabitList(parsedBadHabits);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    createHabit({ uid, habit }: { uid: string; habit: NewHabit }) {
      const habitsRef = ref(database, `habits/${uid}`);
      const id = uuid();
      const newHabit = {
        ...habit,
        goals: {
          [id]: {
            ...habit.goals,
          },
        },
      };
      push(habitsRef, newHabit);
    },
    editHabit({
      uid,
      habitId,
      habit,
      isChangedGoal,
      _newGoalBadHabitByStartDate,
      isChangedGoalBadHabit,
    }: {
      uid: string;
      habitId: string;
      habit: NewHabit;
      isChangedGoal: boolean;
      _newGoalBadHabitByStartDate: HabitGoal | null;
      isChangedGoalBadHabit: boolean;
    }) {
      const habitRef = ref(database, `habits/${uid}/${habitId}`);
      let rest = (({ name, regularly, startDate, timeOfDay, isArchived, remind }) => ({
        name,
        regularly,
        startDate,
        timeOfDay,
        isArchived,
        remind,
      }))(habit);

      if (habit.habitType === 2) {
        delete rest.regularly;
        delete rest.remind;
        delete rest.timeOfDay;
      }
      const { accentColor, iconNamed } = habit;
      if (accentColor) {
        rest = Object.assign(rest, { accentColor });
      }

      if (iconNamed) {
        rest = Object.assign(rest, { iconNamed });
      }
      if (habit.habitType === 2 && _newGoalBadHabitByStartDate) {
        const goalId = uuid();
        const goalUpdates = {
          [`/goals/${goalId}`]: _newGoalBadHabitByStartDate,
        };
        firebaseUpdate(habitRef, goalUpdates);
      }
      if (isChangedGoal) {
        firebaseUpdate(habitRef, rest);
        const goalId = uuid();
        const goal = habit.goals;
        if (goal) {
          if (habit.habitType === 2 && isChangedGoalBadHabit) {
            goal.createdAt = new Date().toISOString();
            // await habitRef.child(`goals/${goalId}`).update(goal);
            firebaseUpdate(habitRef, {
              [`/goals/${goalId}`]: goal,
            });
          }
          if (habit.habitType === 1) {
            goal.createdAt = new Date().toISOString();
            firebaseUpdate(habitRef, {
              [`/goals/${goalId}`]: goal,
            });
          }
        }
      } else {
        firebaseUpdate(habitRef, rest);
      }
    },
    updateHabitDescription({ uid, text, habitId }: { uid: string | undefined; habitId: string; text: string }) {
      if (uid) {
        const refHabit = ref(database, `habits/${uid}/${habitId}`);
        firebaseUpdate(refHabit, { description: !!text ? text : null });
      }
    },
  }),
});

function parseHabit(habitId: string, rawValue: any): Habit | undefined {
  return habitModelMapper({ key: habitId, rawValue });
}
