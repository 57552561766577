import { ISIUnit, SIBaseUnit, UNIT_SYMBOL, UNIT_TYPE } from '__archived__/types/enum/SIBaseUnit';

/**
 * Get Type of SI Unit
 * @param {string} symbol Symbol of the SI Unit.
 * @return {string} Type of the SI Unit.
 */
export function getType(symbol: string): UNIT_TYPE {
  const unit: ISIUnit = SIBaseUnit[symbol];
  return unit.type;
}

/**
 * Get Base Unit of the SI Unit by Type
 * @param {string} type Type of the SI Unit
 * @return {string} Base Unit symbol of the SI Unit
 */
export function getBaseUnitFromType(type: string): UNIT_SYMBOL {
  switch (type) {
    case UNIT_TYPE.LENGTH:
      return UNIT_SYMBOL.M;
    case UNIT_TYPE.VOLUME:
      return UNIT_SYMBOL.L;
    case UNIT_TYPE.DURATION:
      return UNIT_SYMBOL.SEC;
    case UNIT_TYPE.MASS:
      return UNIT_SYMBOL.KG;
    case UNIT_TYPE.ENERGY:
      return UNIT_SYMBOL.J;
    case UNIT_TYPE.SCALAR:
      return UNIT_SYMBOL.REP;
    case UNIT_TYPE.STEP:
      return UNIT_SYMBOL.STEP;
    default:
      return UNIT_SYMBOL.NONE;
  }
}

/**
 * Convert between two unit on a same type
 * @param {string} source Source unit symbol.
 * @param {string} target Target unit symbol.
 * @param {number} value Value need to convert.
 * @return {number} Target value.
 */
export function convert(source: string, target: string, value: number): number {
  const sourceUnit: ISIUnit = SIBaseUnit[source];
  const targetUnit: ISIUnit = SIBaseUnit[target];
  if (sourceUnit.type !== targetUnit.type) {
    throw new Error(`Cannot convert from ${sourceUnit.type} to ${targetUnit.type}`);
  }
  const sourceAlpha = sourceUnit.alpha;
  const targetAlpha = targetUnit.alpha;
  return (sourceAlpha / targetAlpha) * value;
}
