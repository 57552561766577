import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  title: string;
}

const SEO: React.FC<Props> = ({ title }: Props): ReactElement => {
  return (
    <Helmet>
      <title>{title} - Habitify</title>
    </Helmet>
  );
};

export default SEO;
