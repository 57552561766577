import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import IconPicker from 'components/common/icon-picker/new_icon_picker';
import InputField from 'components/common/input-field/input-field.component';
import { Dayjs } from 'dayjs';
import { database } from 'firebase-v9';
import { push, ref } from 'firebase/database';
import { useThemeData } from 'hooks/useThemeData';
import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import { DateRange, OffMode } from 'models/off-mode';
import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewRootState } from 'store';
import { _dayjs } from 'tools/extended-dayjs';
import { offModeIconOptions } from '__archived__/constants/offModeIconOptions';
import OffModeDate from './off-mode-date';
import { updateDateAction, updateDetailHabitAction } from '__archived__/store/ducks/app/app.action';
interface Props {
  isOpenModal: boolean;
  onCloseModal: () => void;
}

export type OffDateType = 'startDate' | 'endDate';

const randomIcon = Math.floor(Math.random() * offModeIconOptions.length);

const CreateOffModeModal: React.FC<Props> = ({ isOpenModal, onCloseModal }): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [reason, setReason] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({
    id: '',
    startDate: _dayjs(),
    endDate: _dayjs().add(3, 'day'),
  });
  const [selectedIcon, setSelectedIcon] = useState('');

  const uid = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const offModeDateRanges = useSelector((state: NewRootState) => state.offModeModel.dateRanges);

  useEffect(() => {
    setSelectedIcon(offModeIconOptions[randomIcon]);
  }, []);

  const handleChangeReason = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  }, []);

  const handleChangeIcon = (icon: string) => {
    setSelectedIcon(icon);
  };

  const selectDateRange = useCallback(
    (type: OffDateType, date: Dayjs) => {
      switch (type) {
        case 'startDate':
          setSelectedDateRange({ ...selectedDateRange, startDate: date, endDate: date.add(3, 'day') });
          break;
        case 'endDate':
          setSelectedDateRange({ ...selectedDateRange, endDate: date });
          break;
        default:
          break;
      }
    },
    [selectedDateRange],
  );

  const closeAndClearData = useCallback(() => {
    onCloseModal();
    setReason('');
    setSelectedDateRange({
      id: '',
      startDate: _dayjs(),
      endDate: _dayjs().add(3, 'day'),
    });
    setSelectedIcon(offModeIconOptions[randomIcon]);
  }, [onCloseModal]);

  const createOffMode = useCallback(() => {
    const offModesRef = ref(database, `offModes/${uid}`);
    const newOffMode: OffMode = {
      reason,
      startDate: selectedDateRange.startDate.format('YYYY-MM-DD'),
      endDate: selectedDateRange.endDate.format('YYYY-MM-DD'),
      iconNamed: selectedIcon,
    };
    push(offModesRef, newOffMode);
    closeAndClearData();
    dispatch(updateDateAction(_dayjs().format('YYYY-MM-DD')));
    dispatch(updateDetailHabitAction(''));
  }, [uid, reason, selectedDateRange.startDate, selectedDateRange.endDate, selectedIcon, closeAndClearData, dispatch]);

  const checkTodayIsOnDateRange = useCallback(() => {
    const dateRanges = values(offModeDateRanges);
    for (let i = 0; i < dateRanges.length; i++) {
      const range = dateRanges[i];
      if (range.stopDate) {
        if (selectedDateRange.startDate.isBetween(range.startDate, range.endDate.subtract(1), 'day', '[]')) {
          return true;
        }
        if (selectedDateRange.endDate.isBetween(range.startDate, range.endDate.subtract(1), 'day', '[]')) {
          return true;
        }
      } else {
        if (selectedDateRange.startDate.isBetween(range.startDate, range.endDate, 'day', '[]')) {
          return true;
        }
        if (selectedDateRange.endDate.isBetween(range.startDate, range.endDate, 'day', '[]')) {
          return true;
        }
      }
    }
    return false;
    // return false;
  }, [offModeDateRanges, selectedDateRange.endDate, selectedDateRange.startDate]);

  const checkDisable = useCallback(() => {
    if (isEmpty(reason)) {
      return true;
    }
    if (checkTodayIsOnDateRange()) {
      return true;
    }
  }, [checkTodayIsOnDateRange, reason]);

  return (
    <Modal
      isOpen={isOpenModal}
      onClose={closeAndClearData}
      scrollBehavior="inside"
      isCentered
      size="sm"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      onEsc={closeAndClearData}
      onOverlayClick={closeAndClearData}
    >
      <ModalOverlay bgColor={colorScheme.platform.backdrop} />
      <ModalContent bg={colorScheme.platform.menu}>
        <ModalHeader {...typography.emphasized.title[2]} padding="14px 16px 14px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Text>{t('offmode.settingNewOffModeTitle')}</Text>
          </Box>
        </ModalHeader>
        <Box mb="20px" mx="16px">
          <FormControl spacing="2">
            <FormLabel
              {...typography.emphasized.caption[2]}
              color={colorScheme.label.secondary}
              pl="6px"
              textTransform="uppercase"
            >
              {t('offmode.reason')}
            </FormLabel>
            <Box display="flex">
              <InputField
                defaultValue={reason}
                inputType="default"
                {...typography.emphasized.callout}
                cursor="default"
                autoComplete="off"
                isRequired
                height="30px"
                padding="0 10px"
                bg={colorScheme.platform.control.background}
                type="text"
                name="habitName"
                border={`1px solid`}
                borderColor={colorScheme.platform.border}
                borderRadius="3px"
                marginTop="unset !important"
                _focus={{ borderColor: `${colorScheme.accent.primary}` }}
                _hover={{ borderColor: `${colorScheme.accent.primary}` }}
                onChange={handleChangeReason}
              />
              <Box ml="8px">
                <Popover placement="bottom-start">
                  <PopoverTrigger>
                    <Button
                      size="sm"
                      h="30px"
                      width="30px"
                      padding="0"
                      bg="transparent"
                      border={`1px solid ${colorScheme.other.border}`}
                      // color={selectedColor}
                      _focus={{ outline: 'none' }}
                      _hover={{ bgColor: 'transparent' }}
                      cursor="default"
                    >
                      <Icon
                        name={selectedIcon}
                        folderName="off-mode"
                        fill={colorScheme.label.secondary}
                        height={20}
                        width={20}
                      />
                    </Button>
                  </PopoverTrigger>
                  <IconPicker
                    type="off-mode"
                    folderIcons="off-mode"
                    defaultIcon={selectedIcon}
                    // updateColor={handleSelectColor}
                    color={colorScheme.background.primary[3]}
                    updateIcon={handleChangeIcon}
                    showColorSwatch={false}
                  />
                </Popover>
              </Box>
            </Box>
          </FormControl>
        </Box>
        <Box mx="16px" mb="20px">
          <FormControl spacing="2" display="flex">
            <Box mr="8px">
              <OffModeDate
                date={selectedDateRange.startDate}
                title={t('common.start')}
                offModeDateType="startDate"
                selectDateRange={selectDateRange}
              />
            </Box>
            <Box ml="8px">
              <OffModeDate
                date={selectedDateRange.endDate}
                title={t('common.end')}
                offModeDateType="endDate"
                selectDateRange={selectDateRange}
                startDate={selectedDateRange.startDate}
              />
            </Box>
          </FormControl>
        </Box>
        <ModalFooter p="10px 12px" bgColor={colorScheme.background.primary[2]} borderBottomRadius="8px">
          <Box ml="auto">
            <Button
              {...typography.emphasized.callout}
              color={colorScheme.label.primary}
              bgColor={colorScheme.platform.control.background}
              // onClick={closeForm}
              height="30px"
              mr="8px"
              borderRadius="3px"
              border={`1px solid ${colorScheme.other.border}`}
              _hover={{ bgColor: colorScheme.platform.control.background }}
              _focus={{ outline: 'none' }}
              cursor="default"
              lineHeight="30px"
              onClick={closeAndClearData}
            >
              {t('common.cancel')}
            </Button>
            <Button
              {...typography.emphasized.callout}
              color={colorScheme.universal.white}
              backgroundColor={colorScheme.accent.primary}
              type="submit"
              height="30px"
              borderRadius="3px"
              onClick={createOffMode}
              _focus={{ outline: 'none' }}
              _hover={{ bgColor: colorScheme.other.pushdownHover }}
              cursor="default"
              lineHeight="30px"
              disabled={checkDisable()}
            >
              {t('common.save')}
            </Button>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default CreateOffModeModal;
