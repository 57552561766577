import { createModel } from "@rematch/core";
import { ref, update } from "@firebase/database";
import { database } from "firebase-v9";
import { RootModel } from "../../root.model";
import { ITimeBound } from "__archived__/types/states";

type UsersInfoModel = {
};

const initialState: UsersInfoModel = {
};

export const preferencesModels = createModel<RootModel>()({
  state: initialState,
//   reducers: {},
  effects: (dispatch) => ({
    updateTimeOfDaySettings(payload: {
      uid: string | undefined;
      timeOfDaySettings: {
        morningMinuteRange: ITimeBound;
        afternoonMinuteRange: ITimeBound;
        eveningMinuteRange: ITimeBound;
      };
    }) {
      const { uid, timeOfDaySettings } = payload;
      if (uid) {
        const timeOfDaySettingsRef = ref(
          database,
          `preferences/${uid}/timeOfDaySettings`
        );
        return update(timeOfDaySettingsRef, timeOfDaySettings);
      }
    },
  }),
});
