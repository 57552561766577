export enum RESIZE_OPTIONS {
  MIN_WIDTH_JOURNAL = 600,
  MIN_WIDTH_SIDEBAR = 200,
  MIN_WIDTH_SINGLE = 350,
  MIN_WIDTH_SINGLE_NOTE = 500,
  MIN_WIDTH_NOTE = 350,
  MIN_WIDTH_MANAGE_SIDEBAR = 200,
  MIN_WIDTH_MANAGE_CONTENT = 400,
  MIN_WIDTH_MANAGE_EMPTY = 350,

  MAX_WIDTH_SIDEBAR = 350,
  MAX_WIDTH_MANAGE_SIDEBAR = 350,

  DEFAULT_WIDTH_SIDEBAR = 240,
  DEFAULT_WIDTH_JOURNAL = 700,
  DEFAULT_WIDTH_NOTE = 413,
  DEFAULT_WIDTH_MANGE_SIDEBAR = 266,
  DEFAULT_WIDTH_MANAGE_CONTENT = 406,
}

export enum SHARE_LINK_SIZE {
  WIDTH_SM = 750,
  STATUS_ON = 1,
  STATUS_OFF = 0,
}

export type IAppResize = {
  sidebar?: number;
  secondSection?: number;
  thirdSection?: number;
};
