export enum HABIT_PROGRESS_FILTER {
  PENDING,
  COMPLETED,
  SKIPPED,
  FAILED,
}

export enum FIRST_DAY_OF_WEEK_IN_APP {
  SUNDAY = 1,
  MONTH = 2,
}
export enum AnalyticType {
  FIREBASE = 'firebase',
  AMPLITUDE = 'amplitude',
  AMAZON_PINPOINT = 'amazon-pinpoint',
}

export enum AppLinkIntegrate {
  PROD = 'https://app.habitify.me',
  BETA = 'https://sso.habitify.club',
  DEV = 'https://sso-dev.habitify.club',
  SITE_FLUTTER_DEV = 'https://habitify-flutter-dev.habitify.club',
  SITE_FLUTTER_BETA = 'https://flutter.habitify.club',
  SITE_FLUTTER_PROD = 'https://flutter.habitify.me',
  SITE_VUE_PROD = 'https://legacy.habitify.me',
  SITE_VUE_BETA = 'https://legacy.habitify.club',
  SITE_VUE_DEV = 'https://legacy-dev.habitify.club',
}

export enum LIMIT_EVENT_TYPE {
  CHECK_IN = 'common.checkIn',
  CREATE_HABIT = 'common.createNewHabit',
  SKIP = 'common.skip',
  FAILED = 'common.failed',
  TIMER = 'common.timer',
  REMINDER = 'edithabit.addReminder',
  ADD_NOTE = 'common.addNote',
  UP_IMAGE = 'common.saveImage',
  MOOD = 'add mood',
}

export enum DETAIL_PAGE_TYPE {
  SINGLE_PROGRESS = 'singleProgress',
  MOOD_DETAIL = 'moodDetail',
}

export enum COOKIES_NAME {
  HABIT_SUGGESTION_TEMPLATE = 'HABIT_SUGGESTION_TEMPLATE',
}

export enum JOURNAL_LAYOUT_TYPE {
  OLD_LAYOUT = 1,
  NEW_LAYOUT = 2,
}

export enum FOLDER_NAME_ICON_COLLECTION {
  HABIT = 'habit-icons',
  AREA = 'area',
  SIDEBAR = 'sidebar',
}

export enum SortJournalType {
  REMIND_TIME = 'remind_asc',
  MY_HABIT_ORDER = 'priority_desc',
  ALPHABETICAL_ASC = 'alphabetical_asc',
  ALPHABETICAL_DESC = 'alphabetical_desc',
}

export enum SELECT_ICON_TYPE {
  HABIT_ICON = 'habitIcon',
  AREA_ICON = 'areaIcon',
}

export enum LOCAL_STORAGE_KEY {
  GENERATE_SESSION_COOKIE = 'generate_session_cookie',
}
