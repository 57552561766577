import toPairs from 'lodash/toPairs';
import { HabitLog, HabitLogType } from 'models/habit-logs';
import { getSIUnitSymbol } from '../si-unit/si-unit-utils';
import memoizeOne from 'memoize-one';

export const habitLogsMapper = memoizeOne(
  ({ habitId, rawValue }: { habitId: string; rawValue: any }): HabitLog[] | undefined => {
    const habitLogs: HabitLog[] = [];
    if (!rawValue) return undefined;
    toPairs(rawValue).forEach(([key, value]) => {
      const anyValue = value as any;
      const singleHabitLogs = singleHabitLogMapper({ habitId: habitId, key, rawValue: anyValue });
      if (singleHabitLogs) {
        habitLogs.push(singleHabitLogs);
      }
    });
    return habitLogs;
  },
);

const singleHabitLogMapper = memoizeOne(
  ({ habitId, key, rawValue }: { habitId: string; key: string; rawValue: any }): HabitLog | undefined => {
    if (!rawValue) return undefined;
    return {
      id: key,
      habitId,
      type: habitLogTypeMapper(rawValue['type']),
      unitSymbol: getSIUnitSymbol({ rawUnitSymbol: rawValue['unitSymbol'] }),
      value: Number(rawValue['value']) || 0,
      startAt: habitLogTimeMapper(rawValue['startAt']),
      endAt: habitLogTimeMapper(rawValue['endAt']),
    };
  },
);

const habitLogTypeMapper = memoizeOne((rawValue: any): HabitLogType | undefined => {
  if (!rawValue) return undefined;
  if (rawValue === 'manual') {
    return 'manual';
  }
  if (rawValue === 'auto') {
    return 'auto';
  }
  return '';
});

const habitLogTimeMapper = memoizeOne((rawValue: any): string | undefined => {
  if (!rawValue) return undefined;
  return rawValue;
});
