import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';
import { _dayjs } from 'tools/extended-dayjs';

export const useCheckAppleSale = () => {
  const [isSale, setIsSale] = useState<boolean>(false);

  const appleSaleCampaign = useSelector((state: NewRootState) => state.paymentModel.remoteAppleSaleCampaign);

  useEffect(() => {
    const startDate = _dayjs(appleSaleCampaign?.start);
    const endDate = _dayjs(appleSaleCampaign?.end);
    const isBetweenSale = _dayjs().isBetween(startDate, endDate);
    setIsSale(isBetweenSale);
  }, [appleSaleCampaign?.end, appleSaleCampaign?.start]);

  return isSale;
};
