import { _dayjs } from '__archived__/constants/app';
import { useEffect, useState } from 'react';

type ReturnType = {
  checkInDateISO: string;
  checkInDateFormatted: string;
};

export const useCheckInDate = (chooseDate: string): ReturnType => {
  const [checkInDateISO, setCheckInDateISO] = useState<string>('');
  const [checkInDateFormatted, setCheckInDateFormatted] = useState<string>('');

  useEffect(() => {
    if (!_dayjs(chooseDate).isToday()) {
      setCheckInDateISO(_dayjs(chooseDate).toISOString());
      setCheckInDateFormatted(_dayjs(chooseDate).format('DDMMYYYY'));
    } else {
      setCheckInDateISO(_dayjs().toISOString());
      setCheckInDateFormatted(_dayjs().format('DDMMYYYY'));
    }
  }, [chooseDate]);

  return {
    checkInDateISO,
    checkInDateFormatted,
  };
};
