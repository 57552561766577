import { database } from 'firebase-v9';
import { ref, remove } from 'firebase/database';
import { deviceUUID } from './device-uuid';

export function removeUserEndpoint(deviceType: 'Web' | 'Desktop', uid: string) {
  let uuidDevice = deviceUUID();
  if (deviceType === 'Desktop') {
    uuidDevice = `desktop-${deviceUUID()}`;
  }
  const userEndpointRef = ref(database, `users/${uid}/endPoints/${uuidDevice}`);
  remove(userEndpointRef);
}
