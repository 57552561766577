import dayjs from "dayjs";
import isSafari from "tools/common/checkSafari";

export const getLocaleString = (number: number | string, unit: string, language: string) => {

    if (isSafari && unit === 'day') {
        return number === 1 ? `${number} day` : `${number} days`
    }

    return Number(number)
        .toLocaleString(language, {
            style: 'unit',
            unit: unit,
            unitDisplay: 'long',
        })
}

export const getDescriptionBadHabitPending = (latestDayFailBadHabit: string | null | undefined, language: string) => {
    if (latestDayFailBadHabit) {
        const diff = dayjs().diff(dayjs(latestDayFailBadHabit), 'day', true);
        const days = Math.floor(diff);
        const hours = Math.floor((diff - days) * 24);
        const timestampsDiff = dayjs().valueOf() - dayjs(latestDayFailBadHabit).valueOf();
        const mins = dayjs(timestampsDiff).format('m');
        // const second = dayjs(timestampsDiff).format('s');
        if (!isSafari) {
            return `${getLocaleString(days, 'day', language)} ${getLocaleString(hours, 'hour', language)}`
        } else return `${days}d ${hours}h ${mins}m`
    }
}