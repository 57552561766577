import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import { models, RootModel } from './root.model';
import { app, auth, habit } from '__archived__/store/ducks';
import thunk from 'redux-thunk';
import { RudderStackEventMiddleware } from './custom-middleware/rudderstack-event';
export const newStore = init<RootModel>({
  redux: {
    devtoolOptions: {
      actionSanitizer: (action) => action,
      disabled: process.env.NODE_ENV === 'production',
    },
    reducers: {
      app,
      auth,
      habit,
    },
    middlewares: [thunk, RudderStackEventMiddleware],
  },
  models,
});

export type NewStore = typeof newStore;
export type NewDispatch = RematchDispatch<RootModel>;
export type NewRootState = RematchRootState<RootModel>;
