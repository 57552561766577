import { UnitSymbol } from 'models/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SIBaseUnit } from '__archived__/types/enum/SIBaseUnit';

export const useUnitDisplay = (unitSymbol: UnitSymbol | null | undefined): string => {
  const { t } = useTranslation();
  const [unitDisplay, setUnitDisplay] = useState<string>('');

  useEffect(() => {
    if (unitSymbol) {
      if (unitSymbol === 'rep') {
        setUnitDisplay(t('measurement.unitTimes'));
      } else if (unitSymbol === 'step') {
        setUnitDisplay(t('measurement.unitSteps'));
      } else {
        setUnitDisplay(SIBaseUnit[unitSymbol].display);
      }
    }
  }, [t, unitSymbol]);
  return unitDisplay;
};
