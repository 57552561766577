import React, { ReactElement, useCallback, useEffect } from 'react';
import MenuItemLayout from 'components/common/menu/MenuItem';
import MenuListLayout from 'components/common/menu/MenuList';
import { useTranslation } from 'react-i18next';
import GroupMenuItems from 'components/common/menu/group-menu-items.component';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';

interface ResourcesProps {
  closeMenu: () => void;
}

type ResourcesLink = {
  title: string;
  path: string;
};

const resourceLinks: ResourcesLink[] = [
  {
    title: 'support',
    path: '/',
  },
  {
    title: 'translate',
    path: 'https://crowdin.com/project/habitify',
  },
  {
    title: 'featureRequests',
    path: 'https://habitify.upvoty.com/b/feature-requests/',
  },
  {
    title: 'followTwitter',
    path: 'https://twitter.com/usehabitify',
  },
];

const Resources: React.FC<ResourcesProps> = ({ closeMenu }): ReactElement => {
  const { t } = useTranslation();
  const userInfo = useSelector((state: NewRootState) => state.usersInfoModel.user);
  const uid = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const isOpenIntercomFromInAppMessage = useSelector((state: NewRootState) => state.usersInfoModel.isOpenIntercom);
  const { boot, show } = useIntercom();

  useEffect(() => {
    if (userInfo && uid) {
      const { name } = userInfo;
      boot({ name, userId: uid, hideDefaultLauncher: true });
    }
  }, [boot, uid, userInfo]);

  useEffect(() => {
    if(isOpenIntercomFromInAppMessage){
      show();
    }
  }, [isOpenIntercomFromInAppMessage, show])

  const onClickLink = useCallback(
    (link: string) => {
      closeMenu();
      window.open(link, '', 'noopener, noreferrer');
    },
    [closeMenu],
  );

  const bootIntercom = useCallback(() => {
    show();
    closeMenu();
  }, [closeMenu, show]);

  return (
    <MenuListLayout style={{ width: 'auto' }}>
      <GroupMenuItems isFirstGroup>
        {resourceLinks.map((link, index) => {
          if (index === 0) {
            return (
              <MenuItemLayout
                key={`${link.title}-${index}`}
                title={t(`settings.${link.title}`)}
                hasIconLeft={false}
                onClick={bootIntercom}
              />
            );
          }
          return (
            <MenuItemLayout
              key={`${link.title}-${index}`}
              title={t(`settings.${link.title}`)}
              hasIconLeft={false}
              onClick={() => onClickLink(link.path)}
            />
          );
        })}
      </GroupMenuItems>
    </MenuListLayout>
  );
};
export default Resources;
