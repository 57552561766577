import { DatabaseReference, onValue, query, Unsubscribe } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';

export const useDatabaseQuery = <T extends Object>(
  ref: DatabaseReference,
  parser: ({ key, rawValue }: { key: string; rawValue: any }) => T | undefined,
) => {
  const [data, setData] = useState<{ [key: string]: T }>({});

  const getDataFromRef = useCallback(
    (setUnsubscribe?: (unsubscibe: Unsubscribe) => void) => {
      const q = query(ref);
      const unsubscribe = onValue(q, (dataSnapshot) => {
        const data: { [key: string]: T } = {};
        dataSnapshot.forEach((childSnapshot) => {
          const key = childSnapshot.key;
          if (key) {
            const rawValue = childSnapshot.val();
            const childData = parser({ key, rawValue });
            if (childData) {
              data[key] = childData;
            }
          }
        });
        setData(data);
      });
      if (setUnsubscribe) setUnsubscribe(unsubscribe);
    },
    [parser, ref],
  );

  useEffect(() => {
    let queryUnsubscribe: Unsubscribe;
    getDataFromRef((unsubscribe) => {
      queryUnsubscribe = unsubscribe;
    });

    return () => {
      if (typeof queryUnsubscribe === 'function') {
        queryUnsubscribe();
      }
    };
  }, [getDataFromRef]);

  return data;
};
