import { Box, BoxProps, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';
interface GroupMenuItemsProps extends BoxProps {
  isFirstGroup?: boolean;
  title?: string;
}

const defaultProps: GroupMenuItemsProps = {
  isFirstGroup: false,
};

const GroupMenuItems: React.FC<GroupMenuItemsProps> = ({
  title,
  children,
  isFirstGroup,
  ...rest
}: GroupMenuItemsProps): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  return (
    <Box
      {...rest}
      p={isFirstGroup ? '6px 0px 4px 0px' : '4px 0px 4px 0px'}
      borderBottom={`1px solid ${colorScheme.other.border}`}
    >
      {title && (
        <Text
          {...typography.emphasized.callout}
          color={colorScheme.other.menuHeading}
          p="4px 10px"
          textTransform="capitalize"
        >
          {title}
        </Text>
      )}
      <Box pt={isFirstGroup ? 'unset' : '2px'}>{children}</Box>
    </Box>
  );
};

GroupMenuItems.defaultProps = defaultProps;

export default GroupMenuItems;
