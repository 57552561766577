import { InputGroup, Popover, PopoverTrigger } from "@chakra-ui/react";
import InputField from "components/common/input-field/input-field.component";
import MenuItemLayout from "components/common/menu/MenuItem";
import MenuListLayout from "components/common/menu/MenuList";
import { useThemeData } from "hooks/useThemeData";
import { NewDispatch, NewRootState } from "store";
import React, { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralSettingItem from "../general-setting-item.component";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { FirstWeekDay } from "../models/app-setting.type";

interface DayInWeek {
    value: number;
    title: string;
    id: string;
}

const weekdays: DayInWeek[] = [
    {
        value: 1,
        id: "sunday",
        title: "Sunday",
    },
    {
        value: 2,
        id: "monday",
        title: "Monday",
    },
];

const FirstDayOfWeek: React.FC = (): ReactElement => {
    const dispatch = useDispatch<NewDispatch>();
    const { t, i18n } = useTranslation();
    const { typography, colorScheme } = useThemeData();
    const isOpen = useSelector(
        (state: NewRootState) => state.appSettingModel.isOpenFirstDayOfWeek
    );
    const uid = useSelector(
        (state: NewRootState) => state.authenticationModel.user?.uid
    );
    const firstDayOfWeek = useSelector(
        (state: NewRootState) => state.appSettingModel.preferences?.firstWeekDay
    );
    const handleOpenMenu = useCallback(() => {
        // setOpenUnit(!openUnit);
        dispatch.appSettingModel.openFirstDayOfWeek(!isOpen);
    }, [dispatch.appSettingModel, isOpen]);

    const closeMenu = () => {
        dispatch.appSettingModel.openFirstDayOfWeek(false);
    };

    const changeFirstDayOfWeek = useCallback(
        (firstWeekDay: number) => {
            dispatch.appSettingModel.openFirstDayOfWeek(!isOpen);
            import(`dayjs/locale/${i18n.language}.js`).then((res) => {
                dayjs.locale({
                    ...res,
                    weekStart: 0,
                });
            });
            if (uid) {
                dispatch.appSettingModel.updateFirstDayOfWeek({
                    uid,
                    firstWeekDay,
                });
                dispatch.habitProgressModel.resetState();
            }
        },
        [
            dispatch.appSettingModel,
            dispatch.habitProgressModel,
            i18n.language,
            isOpen,
            uid,
        ]
    );

    const getTextFDOW = useCallback(
        (firstDayOfWeek: FirstWeekDay | string | undefined) => {
            return firstDayOfWeek === "monday"
                ? dayjs().locale(i18n.language).day(1).format("dddd")
                : dayjs().locale(i18n.language).day(0).format("dddd");
        },
        [i18n.language]
    );

    return (
        <GeneralSettingItem
            title={t("settings.firstDayOfWeek")}
            subTitle={t("settings.firstdayofweekSubtitle")}
        >
            <Popover
                matchWidth
                placement="bottom-start"
                isOpen={isOpen}
                onClose={closeMenu}
                closeOnBlur
            >
                <PopoverTrigger>
                    <InputGroup maxW="109px">
                        <InputField
                            inputType="select"
                            cursor="default"
                            isReadOnly
                            textTransform="capitalize"
                            isOpen={isOpen}
                            {...typography.emphasized.callout}
                            value={getTextFDOW(firstDayOfWeek || "sunday")}
                            h="30px"
                            onClick={handleOpenMenu}
                        />
                    </InputGroup>
                </PopoverTrigger>
                <MenuListLayout style={{ width: "auto" }}>
                    {weekdays.map((day, index) => (
                        <MenuItemLayout
                            title={getTextFDOW(day.id)}
                            hasIconLeft={false}
                            key={`${day.title}-${index}`}
                            bgColor={
                                day.id === firstDayOfWeek
                                    ? colorScheme.accent.primary
                                    : "none"
                            }
                            isSelected={day.id === firstDayOfWeek}
                            borderTopRadius={index === 0 ? "3px" : "unset"}
                            borderBottomRadius={
                                index === weekdays.length - 1 ? "3px" : "unset"
                            }
                            onClick={() => changeFirstDayOfWeek(day.value)}
                        />
                    ))}
                </MenuListLayout>
            </Popover>
        </GeneralSettingItem>
    );
};
export default FirstDayOfWeek;
