import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ChangeEvent, KeyboardEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface IPasswordInputProps {
  defaultValue?: string;
  handleInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
}

const PasswordInput: React.FC<IPasswordInputProps> = ({
  handleInput,
  handleSubmit,
  defaultValue,
}: IPasswordInputProps): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  const { t } = useTranslation();

  const handleSubmitWithEnterKey = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <FormControl id="password" isRequired mb="7px" autoComplete="off">
      <FormLabel
        {...typography.emphasized.caption[2]}
        color={colorScheme.label.secondary}
        p="8px 0px 4px 6px"
        m="0px 0px 3px 6px"
        width="286px"
      >
        {t('common.password').toUpperCase()}
      </FormLabel>
      <Input
        type="password"
        placeholder={t('common.password')}
        name="password"
        borderRadius="3px"
        w="282px"
        height="32px"
        p="7.5px 10px 7.5px 10px"
        {...typography.emphasized.callout}
        border={`1px solid ${colorScheme.supporting.seperator}`}
        onKeyUp={handleSubmitWithEnterKey}
        onChange={handleInput}
        defaultValue={defaultValue}
        required={false}
        autoComplete="off"
        _focus={{ outline: 'none' }}
      />
    </FormControl>
  );
};

export default PasswordInput;
