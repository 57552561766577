import toPairs from 'lodash/toPairs';
import memoizeOne from 'memoize-one';
import { UnitSymbol } from 'models/common';
import { goalPeriodicity, GoalPeriodicity, GoalUnit, HabitGoal, HabitGoals } from 'models/habits';
import { getSIUnitSymbol, getType } from '../si-unit/si-unit-utils';

export const goalSingleModelMapper = memoizeOne(
  ({ rawGoalSingleValue }: { rawGoalSingleValue: any }): HabitGoal | null | undefined => {
    if (!rawGoalSingleValue) {
      return undefined;
    }
    return getGoalFromRawValue(rawGoalSingleValue);
  },
);

export const goalsModelMapper = memoizeOne(
  ({ rawGoalsValue }: { rawGoalsValue: any }): HabitGoals | null | undefined => {
    if (!rawGoalsValue) {
      return undefined;
    }
    const goals: HabitGoals = {};
    for (let [key, value] of toPairs(rawGoalsValue)) {
      goals[key] = getGoalFromRawValue(value);
    }
    return goals;
  },
);

const getGoalFromRawValue = memoizeOne((rawValue: any): HabitGoal => {
  return {
    createdAt: goalCreationTimeMapper(rawValue['createdAt']),
    periodicity: goalPeriodicityMapper(rawValue['periodicity']),
    unit: unitMapper(rawValue['unit']),
    value: rawValue['value'],
  };
});

const unitMapper = memoizeOne((rawValue: any): GoalUnit | undefined => {
  if (!rawValue) return undefined;
  return {
    symbol: goalUnitSymbolMapper(rawValue['symbol']),
    type: goalUnitTypeMapper(rawValue['symbol']),
  };
});

const goalCreationTimeMapper = memoizeOne((rawValue: any): string | undefined => {
  if (!rawValue) return undefined;

  return rawValue;
});

const goalPeriodicityMapper = memoizeOne((rawValue: any): GoalPeriodicity | undefined => {
  if (!rawValue) return undefined;
  return getPeriodicity({ rawPeriodicity: rawValue });
});

const goalUnitSymbolMapper = memoizeOne((rawValue: any): UnitSymbol | undefined => {
  if (!rawValue) return undefined;
  return getSIUnitSymbol({ rawUnitSymbol: rawValue });
});

const goalUnitTypeMapper = memoizeOne((unitSymbol: UnitSymbol): string | undefined => {
  if (!unitSymbol) return undefined;
  return getType({ unitSymbol }).toUpperCase();
});

const isPeriodicity = memoizeOne((rawPeriodicity: any): rawPeriodicity is GoalPeriodicity => {
  return goalPeriodicity.includes(rawPeriodicity);
});

const getPeriodicity = memoizeOne(({ rawPeriodicity }: { rawPeriodicity: any }): GoalPeriodicity => {
  let goalPeriodicity: GoalPeriodicity = '';
  if (isPeriodicity(rawPeriodicity)) {
    goalPeriodicity = rawPeriodicity;
  }
  return goalPeriodicity;
});
