import { TimeOfDay } from 'models/common';
import { _dayjs } from '../extended-dayjs';
import memoizeOne from 'memoize-one';
import { ITimeBound } from '__archived__/types/states';

export const getTimeOfDay = memoizeOne((morningTimeBound: ITimeBound, afternoonTimeBound: ITimeBound): TimeOfDay => {
  const currentHour = _dayjs().hour() * 60 + _dayjs().minute();

  if (morningTimeBound.lowerbound <= currentHour && currentHour <= morningTimeBound.upperbound) {
    return 'morning';
  } else if (afternoonTimeBound.lowerbound <= currentHour && currentHour <= afternoonTimeBound.upperbound) {
    return 'afternoon';
  } else {
    return 'evening';
  }
});
