import {
    Box,
    Editable,
    EditableInput,
    EditablePreview,
    Flex,
    Popover,
    PopoverTrigger,
    Portal,
    Text,
    useOutsideClick,
} from "@chakra-ui/react";
import Icon from "components/common/Icon";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { FOLDER_NAME_ICON_COLLECTION } from "__archived__/types/enum/app";
import IconPicker from "components/common/icon-picker/new_icon_picker";
import { HabitFolders } from "models/habit-folders";
import Lexorank from "__archived__/utils/lexorankUtils";
import { _dayjs } from "tools/extended-dayjs";
import { IHabitFolder } from "__archived__/types/states/habit";
import { NewDispatch, NewRootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import { colorHabitIconOptions as colorOptions } from "__archived__/constants/habit_color_options";
import { useTranslation } from "react-i18next";
import isSafari from "tools/common/checkSafari";

const ICON_FOLDER_DEFAULT = "ic-habit-folder";
const ICON_NEW_FOLDER = "ic-new-area";

const lexorankUtils = new Lexorank();

interface NewAreaProps {
    areasSorted: HabitFolders;
}

const NewArea: React.FC<NewAreaProps> = ({
    areasSorted,
}: NewAreaProps): ReactElement => {
    const { colorScheme, typography } = useThemeData();
    const { t } = useTranslation();
    const dispatch = useDispatch<NewDispatch>();
    const userAuth = useSelector(
        (state: NewRootState) => state.authenticationModel.user
    );

    const [isNewFolder, setIsNewFolder] = useState(false);
    const [isPopoverIconPicker, setIsPopoverIconPicker] = useState(false);
    const [iconSelected, setIconSelected] = useState("");
    const [iconKey, setIconKey] = useState("");
    const [iconColor, setIconColor] = useState("");
    const [folderName, setFolderName] = useState("");

    const borderOrOutline = isSafari ? "border" : "outline";

    const refBoxNewFolder = useRef(null);
    const refEditable = useRef(null);

    useEffect(() => {
        document.addEventListener("contextmenu", () => setIsNewFolder(false));
        return () => {
            document.removeEventListener("contextmenu", () =>
                setIsNewFolder(false)
            );
        };
    }, []);

    useEffect(() => {
      if(!isNewFolder) {
        setIconColor("");
      }
    }, [isNewFolder])

    useEffect(() => {
        if (isNewFolder) {
            handleEditableFocus();
        } else setIconSelected("");
    }, [isNewFolder]);

    useOutsideClick({
        ref: refBoxNewFolder,
        handler: () => {
            // setIsNewFolder(false);
            if(!isPopoverIconPicker) {
              addNewFolder();
            }
        },
    });

    const handleEditableFocus = () => {
        setTimeout(() => {
            const elEditable = refEditable.current as HTMLElement | null;
            const elInput = elEditable?.querySelector("input");
            elInput?.focus();
        }, 0);
    };

    const onMouseEnterIcon = () => {
        isNewFolder && setIsPopoverIconPicker(true);
    };

    const handleSelectIcon = (icon: string) => {
        setIsNewFolder(true);
        // setIsPopoverIconPicker(false);
        setIconSelected(icon);
        setIconKey(icon);
        // setTimeout(() => {
        //     const elEditable = refEditable.current as HTMLElement | null;
        //     const elInput = elEditable?.querySelector("span");
        //     elInput?.focus();
        // }, 0);
    };

    const onFocusEditable = () => {
      const elEditable = refEditable.current as HTMLElement | null;
            const elInput = elEditable?.querySelector("span");
            elInput?.focus();
    }

    const handleSelectColor = (color: string) => {
        setIconColor(color);
    };
    

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLParagraphElement>) => {
        if (e?.which === 13) {
            e.preventDefault();
            addNewFolder();
        }
    };

    const handleOnBlur = () => {
        if (!(isPopoverIconPicker && isNewFolder)) {
            addNewFolder();
        }
    };

    const addNewFolder = () => {
        setIsNewFolder(false);
        if (!!folderName) {
            const nextPriority =
                areasSorted[
                    Object.keys(areasSorted)[
                        Object.keys(areasSorted).length - 1
                    ]
                ]?.priority || "";
            const newFolder: IHabitFolder = {
                createdAt: _dayjs.utc().toISOString(),
                name: folderName,
                priority: lexorankUtils.insert("", nextPriority)[0],
            };

            if (!!iconKey) {
                newFolder.iconKey = iconKey;
            }

            if (!!iconColor) {
                newFolder.color = iconColor.includes("#")
                    ? iconColor.replace("#", "").trim()
                    : iconColor;
            }

              dispatch.habitFoldersModel.addNewFolder({
                uid: userAuth?.uid,
                newFolder,
            });

            setFolderName("");
            setIconKey("");
            setIconColor("");
        }
    };

    return (
        <Box
            ref={refBoxNewFolder}
            height="36px"
            onMouseLeave={() => {setIsPopoverIconPicker(false)}}
            onClick={(e) => {!isNewFolder && setIsNewFolder(true);}}
        >
            <Flex
                p="4.3px 0 4.3px 8px"
                height="100%"
                alignItems="center"
                cursor="default"
            >
                <Box display="flex" w="20px" mr="7px" pos="relative">
                    <Popover
                        placement="bottom-end"
                        offset={[-8, 3]}
                        strategy="fixed"
                        isOpen={isPopoverIconPicker && isNewFolder}
                    >
                        <PopoverTrigger>
                            <Box
                            minH="28px"
                            display="flex"
                            alignItems="center"
                                className={
                                    isNewFolder ? "new-folder-wrapper-icon" : ""
                                }
                                onMouseEnter={(e) => {
                                    e.preventDefault();
                                    onMouseEnterIcon();
                                }}
                            >
                                <Icon
                                    folderName={
                                        isNewFolder
                                            ? FOLDER_NAME_ICON_COLLECTION.AREA
                                            : FOLDER_NAME_ICON_COLLECTION.SIDEBAR
                                    }
                                    name={
                                        iconSelected
                                            ? iconSelected
                                            : isNewFolder
                                            ? ICON_FOLDER_DEFAULT
                                            : ICON_NEW_FOLDER
                                    }
                                    fill={"#7B7C7C"}
                                />
                            </Box>
                        </PopoverTrigger>
                        <Portal>
                            <IconPicker
                                type="area"
                                folderIcons="area"
                                defaultIcon={"#7b7c7c"}
                                color={
                                    !!iconColor
                                        ? iconColor
                                        : colorOptions[0].hex
                                }
                                updateIcon={handleSelectIcon}
                                updateColor={handleSelectColor}
                            ></IconPicker>
                        </Portal>
                    </Popover>
                </Box>

                {isNewFolder ? (
                    <Box width="100%" onClick={() => onFocusEditable()}>
                      <Editable
                        ref={refEditable}
                        display="flex"
                        alignItems="center  "
                        width="calc(100%)"
                        height="28px"
                        p="6px 7px"
                        borderRadius="5px"
                        zIndex="9"
                        {...typography.normal.title[4]}
                        color={colorScheme.label.secondary}
                        outline={
                            !isSafari
                                ? `1.5px solid ${
                                      !!iconColor
                                          ? iconColor
                                          : colorScheme.sidebar.item
                                                .borderColorFocus
                                  }`
                                : "none"
                        }
                        border={
                            isSafari
                                ? `1.5px solid ${
                                      !!iconColor
                                          ? iconColor
                                          : colorScheme.sidebar.item
                                                .borderColorFocus
                                  }`
                                : "none"
                        }
                        background={colorScheme.sidebar.item.backgroundFocus}
                        className="sidebar-editable"
                        selectAllOnFocus={false}
                        startWithEditView={true}
                        value={folderName}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsPopoverIconPicker(false);
                            
                        }}
                        onChange={(val) => setFolderName(val)}
                        onBlur={() => handleOnBlur()}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                    >
                        <EditablePreview width="100%" />
                        <EditableInput
                            outline="none"
                            outlineOffset="unset"
                            color={colorScheme.label.primary}
                            _focus={{ boxShadow: "unset" }}
                        />
                    </Editable>
                    </Box>
                ) : (
                    <Text
                        width="100%"
                        p="6px 7px"
                        {...typography.normal.title[4]}
                        color={colorScheme.label.secondary}
                        _focusVisible={{
                            [borderOrOutline]: "1.5px solid #2A67F4",
                            borderRadius: "5px",
                            caretColor: "#2A67F4",
                        }}
                    >
                        {t("area.newFolder")}
                    </Text>
                )}
            </Flex>
        </Box>
    );
};

export default React.memo(NewArea);
