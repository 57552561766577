import { Box, Spinner, Stack, Text } from '@chakra-ui/react';
import RadioButton from 'components/common/button/radio-button.component';
import Icon from 'components/common/Icon';
import { database } from 'firebase-v9';
import { push, ref, update } from 'firebase/database';
import { useCheckAppleSale } from 'hooks';
import { useThemeData } from 'hooks/useThemeData';
import { AppleProduct } from 'models/payment/apple-product';
import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';
import { isElectronEnv } from 'tools/env';

const ApplePricePlan: FC = (): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  const { t } = useTranslation();

  const [selectedPlan, setSelectedPlan] = useState<AppleProduct>({});
  const [transactionState, setTransactionState] = useState<any>({});

  const isSale = useCheckAppleSale();
  const uid = useSelector<NewRootState, string | undefined>((state) => state.authenticationModel.user?.uid);
  const pricingInfoApple = useSelector((state: NewRootState) => state.paymentModel.pricingInfoApple);
  const appleSaleCampaign = useSelector((state: NewRootState) => state.paymentModel.remoteAppleSaleCampaign);
  const appleProducts = useSelector((state: NewRootState) => state.paymentModel.appleProducts);
  const canMakePayments = useSelector<NewRootState, boolean>((state) => state.appModel.isCanMakeMacAppStorePayments);

  useEffect(() => {
    if (isSale) {
      if (appleSaleCampaign?.preSelectedProductId) {
        const preSelectedProductId = appleSaleCampaign.preSelectedProductId;
        if (appleProducts) {
          const product = appleProducts.find((product) => product.productIdentifier === preSelectedProductId);
          if (product) {
            setSelectedPlan(product);
          }
        }
      }
    } else {
      if (pricingInfoApple?.preSelectedProductId) {
        const preSelectedProductId = pricingInfoApple.preSelectedProductId;
        if (appleProducts) {
          const product = appleProducts.find((product) => product.productIdentifier === preSelectedProductId);
          if (product) {
            setSelectedPlan(product);
          }
        }
      }
    }
  }, [appleProducts, appleSaleCampaign?.preSelectedProductId, isSale, pricingInfoApple?.preSelectedProductId]);

  const handleClickPlan = useCallback(
    (planId: string) => {
      if (appleProducts) {
        const product = appleProducts.find((product) => product.productIdentifier === planId);
        if (product) {
          setSelectedPlan(product);
        }
      }
    },
    [appleProducts],
  );

  const getCurrency = (price: number, currency: string) => {
    return Number(price).toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
    });
  };

  const getPrice = useCallback(
    (productId: string) => {
      if (appleProducts && appleProducts.length > 0) {
        const product = appleProducts.find((product) => product.productIdentifier === productId);
        let price: number = 0;
        if (product) {
          const priceFromApple = product.price;
          const currency = product.currencyCode;
          if (priceFromApple && currency) {
            switch (productId) {
              case 'co.unstatic.premium.habitify.onemonth':
                price = Math.round(priceFromApple * 100) / 100;
                return t('upgrade.monthlyPriceWith', { args1: getCurrency(price, currency) });
              case 'co.unstatic.premium.habitify.oneyear.alt.1':
                price = Math.round((priceFromApple / 12) * 100) / 100;
                return t('upgrade.monthlyPriceWith', { args1: getCurrency(price, currency) });
              case 'co.unstatic.premium.habitify.oneyear':
                price = Math.round((priceFromApple / 12) * 100) / 100;
                return t('upgrade.monthlyPriceWith', { args1: getCurrency(price, currency) });
              case 'new.high.tier.habitify':
                return getCurrency(priceFromApple, currency);
              case 'habitify.premium':
                return getCurrency(priceFromApple, currency);
              default:
                return t('upgrade.monthlyPriceWith', { args1: getCurrency(priceFromApple, currency) });
            }
          }
        }
      }
    },
    [appleProducts, t],
  );

  const renderSubtitle = useCallback(
    (productId: string) => {
      if (appleProducts && appleProducts.length > 0) {
        const productFromApple = appleProducts.find((product) => product.productIdentifier === productId);
        if (productFromApple) {
          const priceFromApple = productFromApple.formattedPrice;
          switch (productId) {
            case 'co.unstatic.premium.habitify.oneyear':
              return (
                <Text {...typography.normal.footnote} color={colorScheme.label.secondary}>
                  {priceFromApple} billed yearly
                </Text>
              );
            case 'co.unstatic.premium.habitify.oneyear.alt.1':
              return (
                <Text {...typography.normal.footnote} color={colorScheme.label.secondary}>
                  {priceFromApple} billed yearly
                </Text>
              );
            case 'habitify.premium':
              return (
                <Text {...typography.normal.footnote} color={colorScheme.label.secondary}>
                  Billed Once
                </Text>
              );
            case 'new.high.tier.habitify':
              return (
                <Text {...typography.normal.footnote} color={colorScheme.label.secondary}>
                  Billed Once
                </Text>
              );
            default:
              return '';
          }
        }
      }
    },
    [appleProducts, colorScheme.label.secondary, typography.normal.footnote],
  );

  const handleCheckout = useCallback(
    (productIdentifier: string | undefined, currencyCode: string | undefined, price: number | undefined) => {
      if (isElectronEnv() && canMakePayments) {
        if (productIdentifier && currencyCode && price) {
          window.ElectronBridge.purchase(productIdentifier, currencyCode, price);
        }
      }
    },
    [canMakePayments],
  );

  useEffect(() => {
    window.ElectronBridge.onTransactionUpdate((state) => {
      setTransactionState(state);
      if (uid) {
        let receiptRef = ref(database, `receipts/apple/ios/${uid}`);
        const newReceiptKey = push(receiptRef).key;

        if (state && state.encryptedPurchase && state.receipt) {
          const encryptPurchase = state.encryptedPurchase;
          const receipt = state.receipt;
          const newReceipt = {
            encryptPurchase,
            receipt,
          };
          receiptRef = ref(database, `receipts/apple/ios/${uid}/${newReceiptKey}`);
          update(receiptRef, newReceipt);
        }
      }
    });
  }, [uid]);

  return (
    <>
      <Box m="11px 0 16px 0">
        <Stack>
          {isSale
            ? appleSaleCampaign &&
              appleSaleCampaign.availablePlans &&
              appleSaleCampaign.availablePlans.map((plan) => (
                <RadioButton
                  key={plan.targetProductId}
                  value={plan.targetProductId || ''}
                  selected={selectedPlan?.productIdentifier || ''}
                  p="10px"
                  bgColor={plan.targetProductId === selectedPlan ? colorScheme.background.primary[1] : 'unset'}
                  borderRadius="5px"
                  boxShadow={plan.targetProductId === selectedPlan ? `0 0 6px rgba(0, 0, 0, 0.08)` : 'none'}
                  onClick={() => handleClickPlan(plan.targetProductId || '')}
                >
                  <Box display="flex" alignItems="center">
                    <Text {...typography.emphasized.title[4]}>
                      {t(`newupgrade.${plan.desktopNameLocalizationKey}`)} -
                    </Text>
                    &nbsp;
                    <Text {...typography.normal.callout}>{getPrice(plan.targetProductId || '')}</Text>
                  </Box>
                  {renderSubtitle(plan.targetProductId || '')}
                </RadioButton>
              ))
            : pricingInfoApple &&
              pricingInfoApple.plans &&
              pricingInfoApple.plans.map((plan) => (
                <RadioButton
                  key={plan.productId}
                  value={plan.productId || ''}
                  selected={selectedPlan?.productIdentifier || ''}
                  p="10px"
                  bgColor={plan.productId === selectedPlan ? colorScheme.background.primary[1] : 'unset'}
                  borderRadius="5px"
                  boxShadow={plan.productId === selectedPlan ? `0 0 6px rgba(0, 0, 0, 0.08)` : 'none'}
                  onClick={() => handleClickPlan(plan.productId || '')}
                >
                  <Box display="flex" alignItems="center">
                    <Text {...typography.emphasized.title[4]}>
                      {t(`newupgrade.${plan.desktopNameLocalizationKey}`)} -
                    </Text>
                    &nbsp;
                    <Text {...typography.normal.callout}>{getPrice(plan.productId || '')}</Text>
                  </Box>
                  {renderSubtitle(plan.productId || '')}
                </RadioButton>
              ))}
        </Stack>
      </Box>
      <Box p="0 16px 0 17px">
        <Box
          w="100%"
          h="30px"
          bgColor={colorScheme.accent.primary}
          borderRadius="3px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() =>
            handleCheckout(selectedPlan?.productIdentifier, selectedPlan?.currencyCode, selectedPlan?.price)
          }
        >
          {transactionState.state === 'purchasing' && <Spinner w="10px" h="10px" color="white" />}
          {transactionState.state === 'purchased' && (
            <Icon name="ic_completed" width={10} height={10} fill={colorScheme.universal.white} />
          )}
          {transactionState.state !== 'purchasing' && transactionState.state !== 'purchased' && (
            <Text m="auto" {...typography.emphasized.callout} color={colorScheme.universal.white}>
              Continue to Payment
            </Text>
          )}
        </Box>
        {!canMakePayments && !isElectronEnv() && (
          <Box display="flex" alignItems="center" justifyContent="center" mt="10px">
            <Icon name="ic_lock" fill={colorScheme.label.secondary} />
            <Text {...typography.normal.footnote} color={colorScheme.label.secondary} ml="7.6px">
              Secured payment with Paddle
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ApplePricePlan;
