import { useEffect } from 'react';

export const useEscKeyboardEvent = (handle: () => void) => {
  useEffect(() => {
    const onKeyup = (event: KeyboardEvent) => {
      event.preventDefault();
      if (event.which === 27 || event.keyCode === 27 || event.code === 'Escape' || event.key === 'Escape') {
        handle();
      }
    };

    document.addEventListener('keyup', onKeyup);

    return () => {
      document.removeEventListener('keyup', onKeyup);
    };
  }, [handle]);
};
