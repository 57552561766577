import { Box, Button, ModalContent, Spinner, Text } from '@chakra-ui/react';
import { useCheckPremium } from 'hooks';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';
import { ReactComponent as MoodGood } from 'assets/icons/mood/ic-mood-good.svg';
import { useTranslation } from 'react-i18next';

interface ProcessingProps {
  closeModal: () => void;
}

const Processing: React.FC<ProcessingProps> = ({ closeModal }): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  const { t } = useTranslation();
  const isPremium = useCheckPremium();
  return (
    <ModalContent bgColor={colorScheme.background.primary[1]} height="480px">
      {isPremium ? (
        <Box my="auto" ml="208px" mr="207px" textAlign="center">
          <Box display="flex" justifyContent="center">
            <MoodGood width={30} height={30} />
          </Box>
          <Text {...typography.emphasized.title[4]} color={colorScheme.label.primary} mt="13px">
            {t('upgrade.paymentCompletedTitle')}
          </Text>
          <Text {...typography.normal.body} color={colorScheme.label.secondary} mt="6px" mb="20px" lineHeight="18px">
            {t('upgrade.paymentCompletedSubtitle')}
          </Text>
          <Button
            {...typography.emphasized.callout}
            p="7.5px 27.5px"
            cursor="default"
            height="30px"
            bgColor={colorScheme.platform.control.background}
            border={`1px solid ${colorScheme.other.border}`}
            _hover={{ bgColor: `${colorScheme.platform.control.background}` }}
            _focus={{ bgColor: `${colorScheme.platform.control.background}` }}
            onClick={closeModal}
          >
            {t('common.close')}
          </Button>
        </Box>
      ) : (
        <Box m="auto" textAlign="center">
          <Box boxSize="20px" m="auto">
            <Spinner speed="0.65s" size="sm" color={colorScheme.label.secondary} />
          </Box>
          <Text {...typography.emphasized.callout} color={colorScheme.label.secondary} mt="10px">
            {t('upgrade.paymentLoading')}
          </Text>
        </Box>
      )}
    </ModalContent>
  );
};
export default Processing;
