export type AuthenticationMethodState = {
  loading: boolean;
  success: boolean;
  failed: boolean;
  error: ErrorPayload | null;
};

export type AuthenticationMethodReducers = {
  onLoading: (state: AuthenticationMethodState) => AuthenticationMethodState;
  onSuccess: (state: AuthenticationMethodState) => AuthenticationMethodState;
  onFailed: (
    state: AuthenticationMethodState,
    payload: ErrorPayload
  ) => AuthenticationMethodState;
  onSignOut: () => AuthenticationMethodState;
};

export type ErrorPayload = {
  code: any;
  message: any;
  email: any;
};

export const initialState: AuthenticationMethodState = {
  loading: false,
  success: false,
  failed: false,
  error: null,
};

export const authenticationMethodReducers: AuthenticationMethodReducers = {
  onLoading(state: AuthenticationMethodState) {
    return {
      ...state,
      loading: true,
    };
  },
  onSuccess(state: AuthenticationMethodState) {
    return {
      ...state,
      loading: false,
      success: true,
    };
  },
  onFailed(state: AuthenticationMethodState, payload: ErrorPayload) {
    return {
      ...state,
      loading: false,
      failed: true,
      error: payload,
    };
  },
  onSignOut() {
    return initialState;
  },
};
