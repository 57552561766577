import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';
import { _dayjs } from '__archived__/constants/app';

export const isOnThisWeek = (date: string, newDay = false, firstDayOfWeek: FirstWeekDay): boolean => {
  let startOfWeek = _dayjs().startOf('week').format('YYYY-MM-DD');
  let endOfWeek = _dayjs().endOf('week').format('YYYY-MM-DD');
  if (firstDayOfWeek === 'monday') {
    startOfWeek = _dayjs().add(-1, 'day').startOf('week').add(1, 'day').format('YYYY-MM-DD');
    endOfWeek = _dayjs().add(-1, 'day').endOf('week').add(1, 'day').format('YYYY-MM-DD');
  }
  if (newDay) {
    startOfWeek = _dayjs().add(1, 'day').startOf('week').format('YYYY-MM-DD');
    endOfWeek = _dayjs().add(1, 'day').endOf('week').format('YYYY-MM-DD');
    return _dayjs(_dayjs(date).format('YYYY-MM-DD')).isBetween(startOfWeek, endOfWeek, null, '[]');
  }
  const day = _dayjs(date);
  return _dayjs(day.format('YYYY-MM-DD')).isBetween(startOfWeek, endOfWeek, null, '[]');
};

export const isOnThisDay = (date: string, newDay = false): boolean => {
  const convertedDate = _dayjs(date).format('YYYY-MM-DD');
  let today = _dayjs().format('YYYY-MM-DD');
  const day = _dayjs(convertedDate);
  if (newDay) {
    today = _dayjs().add(1, 'day').format('YYYY-MM-DD');
    return day.isSame(_dayjs(today));
  }
  return day.isSame(_dayjs(today));
};
