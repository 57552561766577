import { Box, Grid } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import AreasSections from './area-section/areas.sidebar';
import HabitMenuSection from './habit-section/habit_menu.sidebar';
import PreferencesSections from './preferences-section/preferences.sidebar';
import UserInfo from './user_info.sidebar';
import WrapperSections from './wrapper_sections.sidebar';

const Sidebar: React.FC = (): ReactElement => {
  const { colorScheme } = useThemeData();
  const { t } = useTranslation();

  return (
    <Grid
      gridArea="sidebar"
      gridTemplateAreas={`"user-menu" "box-sections"`}
      gridAutoRows="min-content"
      position="relative"
      bg={colorScheme.sidebar.background}
      overflowY="auto"
      overflowX="hidden"
      className="sidebar"
      p="39px 13px 12px 12px"
      borderRight={`1px solid ${colorScheme.other.border}`}
    >
      <UserInfo />
      <Box gridArea="box-sections" display="grid" gridAutoRows="min-content" gridTemplateColumns="1fr" mt="12px">
        <WrapperSections>
          <HabitMenuSection></HabitMenuSection>
        </WrapperSections>

        <WrapperSections title={t('common.areas')}>
          <AreasSections></AreasSections>
        </WrapperSections>

        <WrapperSections title={t('common.preferences')}>
          <PreferencesSections></PreferencesSections>
        </WrapperSections>
      </Box>
    </Grid>
  );
};

export default React.memo(Sidebar);
