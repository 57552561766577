import { Box, Flex, Image, Popover, PopoverTrigger, Text } from '@chakra-ui/react';
import DefaultAvatarPath2 from 'assets/icons/default-avatar2.svg';
import MenuItemLayout from 'components/common/menu/MenuItem';
import MenuListLayout from 'components/common/menu/MenuList';
import { useOnClickOutside } from 'hooks';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewDispatch, NewRootState } from 'store';
import { removeUserEndpoint } from 'tools/endpoints';
import { resetStateAction } from '__archived__/store/ducks/app/app.action';
import WarningModal from 'components/common/modal/warning-modal/warning-modal.component';

const UserInfo: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const profileRef = useRef(null);
  const dispatch = useDispatch<NewDispatch>();
  const oldDispatch = useDispatch();
  const { colorScheme, typography } = useThemeData();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState<boolean>(false);

  const uid = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const userInfo = useSelector((state: NewRootState) => state.usersInfoModel.user);
  const userAvatar: string | null | undefined = useSelector(
    (state: NewRootState) => state.authenticationModel.user?.photoURL,
  );
  const userAuthName = useSelector((state: NewRootState) => state.authenticationModel.user?.displayName);

  const truncated = (name: string): string => {
    const MAX_LENTH = 25;
    return name.length > MAX_LENTH ? name.substring(0, MAX_LENTH) + '...' : name;
  };

  const getUserName = () => {
    return !!userInfo?.name?.trim()
      ? truncated(userInfo.name)
      : userAuthName
      ? truncated(userAuthName)
      : t('common.guest');
  };

  const getUrlAvatar = () => {
    return userInfo?.profileImage ? userInfo?.profileImage : userAvatar ? userAvatar : DefaultAvatarPath2;
  };

  const handleOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleClose = () => setIsOpen(false);

  useOnClickOutside(profileRef, () => handleClose());

  const handleSignOut = useCallback(() => {
    setIsOpenWarningModal(false);
    if (uid) {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf(' electron/') > -1) {
        removeUserEndpoint('Desktop', uid);
      } else {
        removeUserEndpoint('Web', uid);
      }
    }
    dispatch.authenticationModel.signOut();
    dispatch.appModel.resetAppState();
    dispatch.usersInfoModel.setUser({ user: null });
    dispatch.habitFoldersModel.resetHabitFolder({});
    dispatch.offModeModel.getDateRangesOfOffModes({ ranges: {} });
    dispatch.offModeModel.resetOffModes();
    oldDispatch(resetStateAction());
    window.localStorage.removeItem('i18nextLng');
  }, [dispatch, oldDispatch, uid]);

  const onClickSignOut = () => {
    setIsOpenWarningModal(true);
  };

  const openProfile = useCallback(() => {
    dispatch.appSettingModel.openAppSetting(true);
    dispatch.appSettingModel.changeTab('profile');
    handleClose();
  }, [dispatch.appSettingModel]);

  return (
    <Box ref={profileRef}>
      <Popover matchWidth isOpen={isOpen}>
        <PopoverTrigger>
          <Flex
            p="7px"
            alignItems="center"
            gridArea="user-menu"
            border="1px"
            borderColor={colorScheme.other.border}
            borderRadius="5px"
            background={colorScheme.sidebar.backgroundAvatar}
            onSelect={(e) => {
              e.preventDefault();
            }}
            onClick={handleOpen}
          >
            <Image
              w="26px"
              h="26px"
              borderRadius="100%"
              objectFit="cover"
              src={getUrlAvatar()}
              fallbackSrc={DefaultAvatarPath2}
            />
            <Text ml="10px" {...typography.normal.title[4]} color={colorScheme.label.primary}>
              {getUserName()}
            </Text>
          </Flex>
        </PopoverTrigger>
        <MenuListLayout style={{ width: 'auto' }}>
          <MenuItemLayout title={t('common.profile')} hasIconLeft={false} borderTopRadius="5px" onClick={openProfile} />
          <MenuItemLayout
            title={t('settings.signOut')}
            hasIconLeft={false}
            borderBottomRadius="5px"
            onClick={onClickSignOut}
          />
        </MenuListLayout>
      </Popover>
      <WarningModal
        title={t('edithabit.deleteConfirmTitle')}
        subTitle={t('settings.signOutConfirmation')}
        icon="ic-warning-yellow"
        labelBtnPrimary={t('common.ok')}
        labelBtnSecondary={t('common.cancel')}
        isNotCentered={true}
        open={isOpenWarningModal}
        closeModal={() => setIsOpenWarningModal(false)}
        onClick={handleSignOut}
      ></WarningModal>
    </Box>
  );
};

export default React.memo(UserInfo);
