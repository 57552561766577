import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewDispatch, NewRootState } from 'store';
import { _dayjs } from 'tools/extended-dayjs';

export const useCheckPremium = (): boolean => {
  const userInfo = useSelector((state: NewRootState) => state.usersInfoModel.user);
  const [isPremium, setIsPremium] = useState(false);
  const newDispatch = useDispatch<NewDispatch>();

  useEffect(() => {
    if (userInfo) {
      if (userInfo.premiumExpireDate || userInfo.premiumStatus || userInfo.premiumStatusAndroid) {
        const { premiumStatusAndroid, premiumStatus, premiumExpireDate } = userInfo;
        if (
          (premiumStatus && premiumStatus > 0) ||
          (premiumStatusAndroid && premiumStatusAndroid > 0) ||
          _dayjs(premiumExpireDate).isAfter(_dayjs())
        ) {
          setIsPremium(true);
        } else {
          setIsPremium(false);
        }
      }
    }
  }, [newDispatch.usersInfoModel, setIsPremium, userInfo]);

  return isPremium;
};
