import { Box, Flex, Text } from "@chakra-ui/react";
import { useThemeData } from "hooks/useThemeData";
import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NewDispatch, NewRootState } from "store";
import WarningModal from "components/common/modal/warning-modal/warning-modal.component";

const CautionZone: FC = (): ReactElement => {
    const { typography, colorScheme } = useThemeData();
    const newDispatch = useDispatch<NewDispatch>();

    const { t } = useTranslation();

    const habits = useSelector(
        (state: NewRootState) => state.habitsModel.habits
    );
    const uid = useSelector(
        (state: NewRootState) => state.authenticationModel.user?.uid
    );

    const [subTitle, setSubTitle] = useState("");
    const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
    const [actionCurrent, setActionCurrent] = useState<
        "REST_DATA" | "DELETE_ALL_DATA" | "DELETE_ACCOUNT" | ""
    >("");

    const handleResetData = () => {
        setSubTitle(t("settings.resetHabitConfirmation"));
        setIsOpenWarningModal(true);
        setActionCurrent("REST_DATA");
    };

    const handleDeleteAllData = () => {
        setSubTitle(t("settings.deleteAllHabitConfirmation"));
        setIsOpenWarningModal(true);
        setActionCurrent("DELETE_ALL_DATA");
    };

    const handleDeleteAccount = () => {
        setSubTitle(t("settings.deleteAccountConfirmation"));
        setIsOpenWarningModal(true);
        setActionCurrent("DELETE_ACCOUNT");
    };

    const handleOnClick = (
        action: "REST_DATA" | "DELETE_ALL_DATA" | "DELETE_ACCOUNT" | ""
    ) => {
        if (action === "REST_DATA")
            newDispatch.usersInfoModel.resetData({ uid, habits });
        if (action === "DELETE_ALL_DATA")
            newDispatch.usersInfoModel.deleteAllData({ uid });
        if (action === "DELETE_ACCOUNT")
            newDispatch.usersInfoModel.deleteAccount();
        setIsOpenWarningModal(false);
    };

    return (
        <Box mb="15px">
            <Text
                mt="18px"
                textTransform="uppercase"
                {...typography.emphasized.caption[2]}
                color={colorScheme.label.secondary}
                mb="12px"
            >
                {t("settings.cautionZone")}
            </Text>
            <Box
                border={`1px solid ${colorScheme.platform.border}`}
                borderRadius="5px"
                mr="16px"
            >
                <Flex
                    justify="flex-start"
                    align="center"
                    p="10px 0 10px 16px"
                    borderRadius="10px"
                >
                    <Flex
                        justify="space-between"
                        align="center"
                        w="100%"
                        pb="10px"
                        borderBottom={`1px solid ${colorScheme.platform.border}`}
                    >
                        <Box w="80%">
                            <Text
                                {...typography.normal.title[4]}
                                color={colorScheme.label.primary}
                                lineHeight="15.73px"
                                mb="4px"
                            >
                                {t('settings.deleteAccount')}
                            </Text>
                            <Text
                                {...typography.normal.callout}
                                color={colorScheme.label.secondary}
                                lineHeight="14.52px"
                            >
                                {t('settings.deleteAllHabitCompleted')}
                            </Text>
                        </Box>
                        <Flex
                            as="button"
                            p="8px"
                            mr="16px"
                            borderRadius="3px"
                            border={`1px solid ${colorScheme.platform.border}`}
                            onClick={() => handleDeleteAccount()}
                        >
                            <Text
                                {...typography.emphasized.callout}
                                color={colorScheme.supporting.error}
                                lineHeight="14.52px"
                            >
                                {t('common.delete')}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    justify="flex-start"
                    align="center"
                    p="10px 0 10px 16px"
                    borderRadius="10px"
                >
                    <Flex
                        justify="space-between"
                        align="center"
                        w="100%"
                        pb="10px"
                        borderBottom={`1px solid ${colorScheme.supporting.seperator}`}
                    >
                        <Box w="80%">
                            <Text
                                {...typography.normal.title[4]}
                                color={colorScheme.label.primary}
                                lineHeight="15.73px"
                                mb="4px"
                            >
                                {t("settings.deleteAllHabitData")}
                            </Text>
                            <Text
                                {...typography.normal.callout}
                                color={colorScheme.label.secondary}
                                lineHeight="14.52px"
                            >
                                {t('settings.deleteAllHabitDataDescription')}
                            </Text>
                        </Box>
                        <Flex
                            as="button"
                            p="8px"
                            mr="16px"
                            borderRadius="3px"
                            border={`1px solid ${colorScheme.platform.border}`}
                            cursor="default"
                            onClick={() => handleDeleteAllData()}
                        >
                            <Text
                                {...typography.emphasized.callout}
                                color={colorScheme.supporting.error}
                                lineHeight="14.52px"
                            >
                                {t('common.delete')}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    justify="flex-start"
                    align="center"
                    p="10px 0 10px 16px"
                    borderRadius="10px"
                >
                    <Flex justify="space-between" align="center" w="100%">
                        <Box w="60%">
                            <Text
                                {...typography.normal.title[4]}
                                color={colorScheme.label.primary}
                                lineHeight="15.73px"
                                mb="4px"
                            >
                                {t('settings.resetHabitData')}
                            </Text>
                            <Text
                                {...typography.normal.callout}
                                color={colorScheme.label.secondary}
                                lineHeight="14.52px"
                            >
                                {t('settings.resetHabitDataDescription')}
                            </Text>
                        </Box>
                        <Flex
                            as="button"
                            p="8px"
                            mr="16px"
                            borderRadius="3px"
                            border={`1px solid ${colorScheme.platform.border}`}
                            onClick={() => handleResetData()}
                            cursor="default"
                        >
                            <Text
                                {...typography.emphasized.callout}
                                color={colorScheme.supporting.error}
                                lineHeight="14.52px"
                            >
                                {t('settings.resetHabitData')}
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
            <WarningModal
                icon="ic-warning-red"
                title={t("edithabit.deleteConfirmTitle")}
                subTitle={subTitle}
                labelBtnPrimary={t("common.ok").toUpperCase()}
                labelBtnSecondary={t("common.cancel")}
                open={isOpenWarningModal}
                onClick={() => handleOnClick(actionCurrent)}
                closeModal={() => setIsOpenWarningModal(false)}
            />
        </Box>
    );
};

export default CautionZone;
