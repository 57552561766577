import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { Box, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';
import Icon from '../../Icon';

type IconWarning = 'ic-warning-blue' | 'ic-warning-yellow' | 'ic-warning-red';

interface LimitModalProps {
  title: string;
  subTitle?: string;
  labelBtnPrimary?: string;
  labelBtnSecondary?: string;
  open: boolean;
  icon?: IconWarning;
  isNotCentered?: boolean;
  setOpenModalProps?: (open: boolean) => void;
  onClick?: () => void;
  closeModal: () => void;
}

const defaultProps: LimitModalProps = {
  title: 'Are you sure',
  icon: 'ic-warning-blue',
  open: false,
  closeModal: () => {},
};

const WarningModal: React.FC<LimitModalProps> = ({
  title,
  subTitle,
  labelBtnPrimary,
  labelBtnSecondary,
  open,
  icon,
  isNotCentered,
  closeModal,
  onClick,
}: LimitModalProps): ReactElement => {
  const { colorScheme, typography } = useThemeData();

  return (
    <Modal size="lg" isOpen={open} onClose={closeModal} isCentered={isNotCentered ? false : true}>
      <ModalOverlay bgColor={colorScheme.platform.backdrop} />
      <ModalContent maxW="421px" p="20px 20px 20px 23px" bgColor={colorScheme.platform.menu}>
        <ModalBody p="0">
          <Box display="flex">
            <Box mr="11px">
              <Icon name={icon} width={18} height={18} />
            </Box>
            <Box>
              <Text {...typography.normal.title[3]} pb="6px">
                {title}
              </Text>
              <Text {...typography.normal.body} color="#7B7C7C" mb="18px">
                {subTitle}
              </Text>
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            {!!labelBtnSecondary && (
              <Box
                display="flex"
                justifyContent="space-between"
                h="30px"
                mr="8px"
                borderRadius="5px"
                lineHeight="44px"
                bgColor={colorScheme.platform.control.background}
                border={`1px solid ${colorScheme.other.border}`}
                onClick={closeModal}
              >
                <Text
                  {...typography.emphasized.callout}
                  width="100%"
                  textAlign="center"
                  px="8px"
                  color={colorScheme.label.primary}
                  m="auto"
                >
                  {labelBtnSecondary}
                </Text>
              </Box>
            )}
            {!!labelBtnPrimary && (
              <Box
                {...typography.emphasized.callout}
                backgroundColor={colorScheme.accent.primary}
                color={colorScheme.universal.white}
                textAlign="center"
                h="30px"
                borderRadius="5px"
                lineHeight="44px"
                px="8px"
                display="flex"
                alignItems="center"
                onClick={onClick}
              >
                {labelBtnPrimary}
              </Box>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

WarningModal.defaultProps = defaultProps;

export default WarningModal;
