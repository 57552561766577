import { Box, Flex, Image, Text } from '@chakra-ui/react';
import GoogleColorLogo from 'assets/images/google-color.svg';
import { getAuth, GoogleAuthProvider, linkWithPopup, unlink, UserInfo } from 'firebase/auth';
import { useThemeData } from 'hooks/useThemeData';
import { PROVIDER_ID } from 'models/single-progress/profile';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';
import { useTranslation } from 'react-i18next';

interface SignInGoogleProps {
  setOpenWarning: () => void;
  setSignInApple: (status: boolean) => void;
}

const SigInGoogleAccount: React.FC<SignInGoogleProps> = ({ setOpenWarning, setSignInApple }): ReactElement => {
  const { t } = useTranslation();
  const { typography, colorScheme } = useThemeData();
  const auth = getAuth();
  const [userInfoGoogle, setUserInfoGoogle] = useState<UserInfo>();
  const [userInfoNormal, setUserInfoNormal] = useState<UserInfo>();
  const [isGoogleLinked, setIsGoogleLinked] = useState(false);
  const [isHiddenGoogleOptions, setIsShowGoogleOptions] = useState<boolean | undefined>(false);
  const userInfoFirebase = useSelector((state: NewRootState) => state.usersInfoModel.user);

  useEffect(() => {
    if (auth.currentUser) {
      const userNormal = auth.currentUser.providerData.find((val) => val.providerId === PROVIDER_ID.EMAIL_PROVIDER_ID);
      const userGoogle = auth.currentUser.providerData.find((val) => val.providerId === PROVIDER_ID.GOOGLE_PROVIDER_ID);
      const userApple = auth.currentUser.providerData.find((val) => val.providerId === PROVIDER_ID.APPLE_PROVIDER_ID);
      const isAppleProvider = typeof userApple === 'object' || (!auth.currentUser.providerData.length && !auth.currentUser.isAnonymous);
      // const userFb = auth.currentUser.providerData.find((val) => val.providerId === PROVIDER_ID.FACEBOOK_PROVIDER_ID);
      
      setUserInfoNormal(userNormal);
      setUserInfoGoogle(userGoogle);
      setSignInApple(isAppleProvider);
      setIsGoogleLinked(userGoogle ? true : false)
      setIsShowGoogleOptions(userGoogle && !isAppleProvider && !userNormal)
    }
  }, [auth.currentUser, setSignInApple, userInfoFirebase?.email, userInfoNormal?.email]);

  const handleLink = () => {
    const provider = new GoogleAuthProvider();
    if (auth.currentUser) {
      linkWithPopup(auth.currentUser, provider)
        .then((result) => {
          GoogleAuthProvider.credentialFromResult(result);
          const user = result.user;
          const providerData = user.providerData.find((val) => val.providerId === PROVIDER_ID.GOOGLE_PROVIDER_ID);
          setUserInfoGoogle(providerData);
          setIsGoogleLinked(true)
        })
        .catch((error) => {
          console.error(error.code);
          setOpenWarning();
        });
    }
  };

  const handleUnLink = (providerId: string) => {
    if (auth.currentUser && !!providerId) {
      unlink(auth.currentUser, providerId)
        .then((val) => {
          setUserInfoGoogle(undefined);
          setIsGoogleLinked(false)
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Flex justify="flex-start" align="center" p="10px 0 10px 16px" borderRadius="10px">
      <Image src={GoogleColorLogo} w="24px" h="24px" mr="12px" mb="10px" />
      <Flex
        justify="space-between"
        align="center"
        w="100%"
        pb="10px"
        borderBottom={`1px solid ${colorScheme.platform.border}`}
      >
        <Box>
          <Text {...typography.normal.title[4]} color={colorScheme.label.primary} lineHeight="15.73px">
            {t('challenge.signinmethodGoogle')}
          </Text>
          {userInfoGoogle?.email && (
            <Text {...typography.normal.callout} color={colorScheme.label.secondary} lineHeight="14.52px" mt="4px">
              {userInfoGoogle.email}
            </Text>
          )}
        </Box>
        
        {!isHiddenGoogleOptions && 
          <>
            {isGoogleLinked ? (
              <Flex
                as="button"
                p="8px"
                mr="16px"
                borderRadius="3px"
                border={`1px solid ${colorScheme.platform.border}`}
                onClick={() => handleUnLink(userInfoGoogle?.providerId || '')}
              >
                <Text {...typography.emphasized.callout} color={colorScheme.supporting.error} lineHeight="14.52px">
                  {t('settings.signInMethodUnlink')}
                </Text>
              </Flex>
            ) : (
              <Flex
                as="button"
                p="8px"
                mr="16px"
                borderRadius="3px"
                border={`1px solid ${colorScheme.platform.border}`}
                onClick={handleLink}
              >
                <Text {...typography.emphasized.callout} color={colorScheme.label.primary} lineHeight="14.52px">
                  {t('settings.signinMethodLink')}
                </Text>
              </Flex>
            )}
          </>
        }
      </Flex>
    </Flex>
  );
};

export default SigInGoogleAccount;
