import { Box, Text, TextProps, useColorModeValue } from '@chakra-ui/react';
import { darkMode, lightMode } from '__archived__/utils/colorPreferences';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useThemeData } from 'hooks/useThemeData';
import { useState } from 'react';
import Icon from '../Icon';

interface Props {
  title?: string;
  bgColor?: string;
  color?: string;
  iconColor?: string;
  hasIconLeft?: boolean;
  iconLeftName?: string;
  folderName?: string;
  borderBottomRadius?: string;
  borderTopRadius?: string;
  command?: string;
  subMenu?: any;
  type?: string;
  titleStyle?: TextProps;
  isSelected?: boolean;
  onClick?: () => void;
}

const defaultProps: Props = {
  hasIconLeft: true,
  iconColor: '#7B7C7C',
};

const MenuItemLayout: React.FC<Props> = ({
  title,
  bgColor,
  color,
  hasIconLeft,
  iconLeftName,
  borderTopRadius,
  borderBottomRadius,
  folderName,
  command,
  subMenu,
  onClick,
  type,
  isSelected,
}: Props): ReactElement => {
  const [isHoveringItem, setIsHoveringItem] = useState(false);
  const commandBgColor = useColorModeValue(lightMode.BACKGROUND_LEVEL_2, darkMode.LABEL_SECONDARY);
  const commandTextColor = useColorModeValue(lightMode.LABEL_SECONDARY, darkMode.TEXT_COLOR);
  const { colorScheme, typography } = useThemeData();

  const handleMouseEnter = () => {
    setIsHoveringItem(true);
  };

  const handleMouseLeave = () => {
    setIsHoveringItem(false);
  };

  const renderIconLeft = useMemo(() => {
    return (
      <Icon
        name={iconLeftName}
        folderName={folderName}
        fill={isHoveringItem ? colorScheme.universal.white : colorScheme.other.menuItem}
        // stroke={
        //   isHoveringItem
        //     ? colorScheme.universal.white
        //     : colorScheme.label.primary
        // }
        // opacity={isHoveringItem ? "unset" : 0.7}
        width={16}
        height={16}
      />
    );
  }, [colorScheme.other.menuItem, colorScheme.universal.white, folderName, iconLeftName, isHoveringItem]);

  const renderIconByType = useCallback(() => {
    switch (type) {
      case 'select':
        if (iconLeftName) {
          return (
            <Box marginY="auto" mr="10.5px">
              {renderIconLeft}
            </Box>
          );
        }
        break;
      default:
        if (iconLeftName) {
          return (
            <Box marginY="auto" mr="10.5px">
              {renderIconLeft}
            </Box>
          );
        }
    }
  }, [iconLeftName, renderIconLeft, type]);

  return (
    <Box
      className={isSelected ? 'menu-item-selected' : 'menu-item-title'}
      display="flex"
      cursor="default"
      height="30px"
      lineHeight="30px"
      textTransform="capitalize"
      p="0 6px 0 10px"
      bgColor={bgColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      color={color}
      borderTopRadius={borderTopRadius}
      borderBottomRadius={borderBottomRadius}
      _hover={{
        bg: colorScheme.accent.primary,
        color: colorScheme.universal.white,
      }}
      _focus={{ bg: '' }}
      as={!command ? subMenu : null}
      onClick={onClick}
    >
      {hasIconLeft && (
        <Box width="25.5px" height="30px" display="flex" justifyContent="center" alignItems="center">
          {renderIconByType()}
        </Box>
      )}
      <Box marginY="auto" display="inline-block" overflow="hidden" whiteSpace="nowrap">
        <Text {...typography.normal.callout} overflow="hidden" textOverflow="ellipsis" lineHeight="30px">
          {title}
        </Text>
        {command && (
          <Box p="1px 4px" bgColor={commandBgColor} borderRadius="3px">
            <Text {...typography.emphasized.caption[1]} color={commandTextColor}>
              {command}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
};

MenuItemLayout.defaultProps = defaultProps;

export default React.memo(MenuItemLayout);
