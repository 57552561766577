import {
    InputGroup,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@chakra-ui/react";
import InputField from "components/common/input-field/input-field.component";
import MenuItemLayout from "components/common/menu/MenuItem";
import MenuListLayout from "components/common/menu/MenuList";
import { useThemeData } from "hooks/useThemeData";
import ISO6391 from "iso-639-1";
import { NewDispatch, NewRootState } from "store";
import React, { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralSettingItem from "../general-setting-item.component";
import { useTranslation } from "react-i18next";

const codeLangs: string[] = [
    "en",
    "en-Us",
    "vi",
    "vi-VN",
    "ca",
    "ca-ES",
    "cs",
    "cs-CZ",
    "da",
    "da-DK",
    "de",
    "de-AT",
    "de-CH",
    "de-DE",
    "de-LI",
    "de-LU",
    "es",
    "es-AR",
    "es-BO",
    "es-CL",
    "es-CO",
    "es-CR",
    "es-DO",
    "es-EC",
    "es-ES",
    "es-GT",
    "es-HN",
    "es-MX",
    "es-NI",
    "es-PA",
    "es-PE",
    "es-PR",
    "es-PY",
    "es-SV",
    "es-UV",
    "es-VE",
    "fi",
    "fi-FI",
    "fr",
    "fr-BE",
    "fr-CA",
    "fr-CH",
    "fr-FR",
    "fr-LU",
    "fr-MC",
    "hi",
    "hi-HN",
    "hu",
    "hu-HU",
    "it",
    "it-CH",
    "it-IT",
    "ja",
    "ja-JP",
    "ko",
    "ko-KR",
    "nl",
    "nl-BE",
    "nl-NL",
    "pl",
    "pl-PL",
    "pt",
    "pt-br",
    "pt-pt",
    "ro",
    "ro-RO",
    "ru",
    "ru-RU",
    "sk",
    "sk-SK",
    "sv",
    "sv-FI",
    "sv-SE",
    "tr",
    "tr-TR",
    "uk",
    "uk-UA",
    "zh",
    "zh-Hans",
    "zh-CN",
    "zh-HK",
    "zh-MO",
    "zh-SG",
    "zh-TW",
    "zh-Hant",
];

const AppLanguage: React.FC = (): ReactElement => {
    const dispatch = useDispatch<NewDispatch>();
    const { t, i18n } = useTranslation();
    const { colorScheme, typography } = useThemeData();
    const isOpen = useSelector(
        (state: NewRootState) => state.appSettingModel.isOpenAppLanguage
    );
    const appLanguage = useSelector(
        (state: NewRootState) => state.appSettingModel.appLanguage
    );
    const uid = useSelector(
        (state: NewRootState) => state.authenticationModel.user?.uid
    );

    const handleOpenAppLanguage = useCallback(() => {
        dispatch.appSettingModel.openAppLanguage(!isOpen);
    }, [dispatch.appSettingModel, isOpen]);

    const closeAppLanguage = useCallback(() => {
        dispatch.appSettingModel.openAppLanguage(false);
    }, [dispatch.appSettingModel]);

    const getLangs = () => {
        const languages: string[] = [];
        codeLangs.forEach((code) => {
            if (ISO6391.getNativeName(code)) {
                languages.push(ISO6391.getNativeName(code));
            }
        });
        return languages;
    };

    const setLanguage = useCallback(
        (language: string) => {
            const codeLanguage = ISO6391.getCode(language);
            i18n.changeLanguage(ISO6391.getCode(language));
            // dayjs.locale(ISO6391.getCode(language));
            dispatch.appSettingModel.updateAppLanguage(language);
            dispatch.appSettingModel.updateAppLanguageToFirebase({
                uid,
                codeLanguage,
            });
            closeAppLanguage();
        },
        [closeAppLanguage, dispatch.appSettingModel, i18n, uid]
    );

    return (
        <GeneralSettingItem
            title={t("settings.language")}
            subTitle={t('settings.languageSubtitle')}
        >
            <Popover
                matchWidth
                placement="bottom-start"
                isOpen={isOpen}
                onClose={closeAppLanguage}
                closeOnBlur
            >
                <PopoverTrigger>
                    <InputGroup maxW="109px">
                        <InputField
                            isReadOnly
                            cursor="default"
                            inputType="select"
                            textTransform="capitalize"
                            value={appLanguage}
                            isOpen={isOpen}
                            {...typography.emphasized.callout}
                            h="30px"
                            onClick={handleOpenAppLanguage}
                        />
                    </InputGroup>
                </PopoverTrigger>
                <PopoverContent
                    height="389px"
                    overflow="overlay"
                    w="full"
                    border="unset"
                    _focus={{ outline: "none" }}
                >
                    <MenuListLayout style={{ width: "auto" }}>
                        {getLangs().map((lang, index) => (
                            <MenuItemLayout
                                title={lang}
                                hasIconLeft={false}
                                key={`${lang}-${index}`}
                                onClick={() => setLanguage(lang)}
                                bgColor={
                                    appLanguage === lang
                                        ? colorScheme.accent.primary
                                        : "unset"
                                }
                                isSelected={appLanguage === lang}
                                borderTopRadius={index === 0 ? "3px" : "unset"}
                                borderBottomRadius={
                                    index === getLangs().length - 1
                                        ? "3px"
                                        : "unset"
                                }
                            />
                        ))}
                        {/* {getLangs()} */}
                    </MenuListLayout>
                </PopoverContent>
            </Popover>
        </GeneralSettingItem>
    );
};
export default AppLanguage;
