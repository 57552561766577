import memoizeOne from 'memoize-one';
import { OffMode } from 'models/off-mode';
import { _dayjs } from 'tools/extended-dayjs';

type OffModeStatus = 'current' | 'upcoming' | 'past';

export const filterOffModeByDate = memoizeOne(
  ({ offModes, status }: { offModes: OffMode[]; status: OffModeStatus }): Promise<OffMode[]> => {
    return new Promise((resolve, reject) => {
      try {
        const offModeByDate = offModes.filter((offMode) => {
          const { startDate, endDate } = offMode;
          switch (status) {
            case 'current':
              if (offMode.stopDate) {
                return false;
              }
              return _dayjs().isBetween(startDate, endDate, 'day', '[]');
            case 'upcoming':
              return _dayjs().isBefore(startDate);
            case 'past':
              if (offMode.stopDate) {
                return true;
              }
              return _dayjs().isAfter(endDate, 'day');
            default:
              return false;
          }
        });
        resolve(offModeByDate);
      } catch (e) {
        reject(e);
      }
    });
  },
);

export const sortOffModeByDate = memoizeOne((offModes: OffMode[]) => {
  return offModes.sort((a, b) => (_dayjs(a.endDate).isBefore(_dayjs(b.startDate)) ? 1 : -1));
});
