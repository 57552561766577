import { Middleware } from "redux";
import { EventTrackByRedux } from "tools/analytics";
import { pushAnalyticEvent } from "__archived__/utils/pushAnalyticEvent";

export const RudderStackEventMiddleware: Middleware = api => next => action => {
    const type = action?.type
    if(EventTrackByRedux[type]){
        const payload = action?.payload;
        switch (EventTrackByRedux[type]) {
            case 'UpgradeClose':
                payload ? pushAnalyticEvent('UpgradeOpen') : pushAnalyticEvent('UpgradeClose')
                break;
            case 'ViewSingleProgressScreen':
                !!payload && pushAnalyticEvent('ViewSingleProgressScreen');
                break
            case 'StartTimer':
                const habitId = payload?.habitId
                const unitSymbol = payload?.unitSymbol
                !!habitId && !!unitSymbol && pushAnalyticEvent('StartTimer')
                break
            case 'EditHabit':
                payload && pushAnalyticEvent('EditHabit')
                break
            
            default:
                break;
        }
    }

    return next(action);
};