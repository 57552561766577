import { Box, Text } from '@chakra-ui/react';
import WarningModal from 'components/common/modal/warning-modal/warning-modal.component';
import { useThemeData } from 'hooks/useThemeData';
import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SigInEmail from './auth-method/sigin-email.auth-method.profile';
import SigInGoogle from './auth-method/sigin-google.auth-method.profile';
import SignInAppleAccount from './auth-method/signin-apple.auth-method.profile';

const SignInMethod: FC = (): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSignInApple, setIsSignInApple] = useState(false);

  const openWarningModal = () => setIsOpenModal(true);

  const closeWarningModal = () => setIsOpenModal(false);

  return (
    <Box>
      <Text
        mt="28px"
        textTransform="uppercase"
        mb="12px"
        {...typography.emphasized.caption[2]}
        color={colorScheme.label.secondary}
      >
        {t('settings.signinMethod')}
      </Text>
      <Box border={`1px solid ${colorScheme.platform.border}`} borderRadius="5px" mr="16px">
        <SigInGoogle setOpenWarning={openWarningModal} setSignInApple={setIsSignInApple} />
        <SigInEmail />
        {isSignInApple && <SignInAppleAccount />}
        <WarningModal
          icon="ic-warning-yellow"
          title={t('edithabit.deleteConfirmTitle')}
          subTitle={'This google account has already in use'}
          // labelBtnPrimary={t("newupgrade.seeAvailableOptions")}
          labelBtnSecondary={t('common.ok')}
          open={isOpenModal}
          // onClick={goToUpgrade}
          closeModal={closeWarningModal}
        />
      </Box>
    </Box>
  );
};

export default SignInMethod;
