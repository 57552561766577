import { Box, Text } from '@chakra-ui/react';
import { Dayjs } from 'dayjs';
import { useThemeData } from 'hooks/useThemeData';
import { SaleCampaignApple } from 'models/payment';
import { FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';
import { _dayjs } from 'tools/extended-dayjs';

const AppleSaleCampaign: FC = (): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  const [isSale, setIsSale] = useState(false);
  const [countdown, setCountDown] = useState('');

  const saleCampaign = useSelector<NewRootState, SaleCampaignApple | null>(
    (state) => state.paymentModel.remoteAppleSaleCampaign,
  );

  const startTimer = useCallback(() => {
    let endDate: Dayjs = _dayjs(saleCampaign?.end);

    setInterval(() => {
      const duration = _dayjs.duration(endDate.diff(_dayjs()));
      const numOfDayLeft = endDate.diff(_dayjs(), 'days');

      const hourLeft = duration.hours();
      const minutesLeft = duration.minutes();
      const secondsLeft = duration.seconds();
      if (numOfDayLeft <= 0 && hourLeft <= 0 && minutesLeft <= 0 && secondsLeft === 0) {
        setIsSale(false);
        clearInterval();
      }
      if (numOfDayLeft || hourLeft || minutesLeft || secondsLeft) {
        setCountDown(`${numOfDayLeft}d ${hourLeft}h ${minutesLeft}m ${secondsLeft}s`);
      }
    }, 1000);
  }, [saleCampaign?.end]);

  useEffect(() => {
    const startDate = _dayjs(saleCampaign?.start);
    const endDate = _dayjs(saleCampaign?.end);
    const isBetweenSale = _dayjs().isBetween(startDate, endDate);
    if (isBetweenSale) {
      setIsSale(true);
      startTimer();
    } else {
      setIsSale(false);
    }
  }, [saleCampaign?.end, saleCampaign?.start, startTimer]);

  return (
    <>
      {isSale && (
        <Box display="flex" p="14px" bgColor={saleCampaign?.backgroundColorHex} borderTopRightRadius="10px">
          <Box w="212px" mr="16px">
            <Text {...typography.normal.title[4]} color={colorScheme.universal.white} mb="4px">
              {saleCampaign?.title}
            </Text>
            <Text {...typography.normal.callout} color={colorScheme.universal.white} opacity="0.5">
              {saleCampaign?.subtitle}
            </Text>
          </Box>
          <Box display="flex" alignItems="center">
            <Box>
              <Text
                {...typography.emphasized.caption[1]}
                textTransform="uppercase"
                color={colorScheme.universal.white}
                opacity="0.5"
                textAlign="right"
              >
                valid til
              </Text>
              <Text {...typography.emphasized.callout} color={colorScheme.universal.white} whiteSpace="pre">
                {countdown}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AppleSaleCampaign;
