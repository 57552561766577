import {
  Box,
  Grid,
  GridItem,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { iconOptions } from '__archived__/constants/iconOptions';
import Icon from '../Icon';
import { useThemeData } from 'hooks/useThemeData';
import ColorSwatch from '../color-swatch/color_swatch';
import { habitIconOptions } from '__archived__/constants/habitIconOptions';
import { useTranslation } from 'react-i18next';
import { offModeIconOptions } from '__archived__/constants/offModeIconOptions';

type IconType = 'habit' | 'area' | 'off-mode';

interface Props extends PopoverContentProps {
  type?: IconType;
  folderIcons?: string;
  defaultIcon?: string;
  color?: string;
  showColorSwatch?: boolean;
  updateIcon?: (icon: string) => void;
  updateColor?: (color: string) => void;
}

const defaultProps: Props = {
  showColorSwatch: true,
};

export default function IconPicker({
  defaultIcon,
  updateIcon,
  color,
  updateColor,
  folderIcons,
  type,
  showColorSwatch,
}: Props) {
  const { t } = useTranslation();
  const { colorScheme, typography } = useThemeData();
  const [iconName, setIconName] = useState('');
  const [icons, setIcons] = useState<string[]>([]);
  const [selectedColor, setSelectedColor] = useState('#2AA8D0');
  const hoverColor = useColorModeValue(colorScheme.background.primary[2], colorScheme.background.primary[3]);

  useEffect(() => {
    if (color) {
      setSelectedColor(color);
    }
  }, [color]);

  useEffect(() => {
    let t: NodeJS.Timeout;
    let options: string[] = iconOptions;
    switch (type) {
      case 'habit':
        options = habitIconOptions;
        break;
      case 'area':
        options = iconOptions;
        break;
      case 'off-mode':
        options = offModeIconOptions;
        break;
      default:
        break;
    }
    if (iconName) {
      t = setTimeout(() => {
        const icons = options.filter((icon) => icon.toLocaleLowerCase().includes(iconName.toLocaleLowerCase()));
        setIcons(icons);
      }, 300);
    } else setIcons(options);
    return () => {
      t && clearTimeout(t);
    };
  }, [iconName, type]);

  const selectColor = useCallback(
    (newColor: string) => {
      setSelectedColor(newColor);
      if (updateColor) {
        updateColor(newColor);
      }
    },
    [updateColor],
  );

  return (
    <PopoverContent
      _focus={{ outline: 'none' }}
      padding="8px"
      border="1px solid #E5E4E5"
      bgColor={colorScheme.platform.menu}
      h="258px"
      overflowY="scroll"
      w="100%"
      zIndex="99"
    >
      <PopoverBody padding="0">
        <Box mb="10px">
          <Box mb="8px" display="flex" justifyContent="space-between" alignItems="center">
            <Text {...typography.emphasized.title[3]} color={colorScheme.label.primary}>
              {t('area.selectAreaIcon')}
            </Text>
            {showColorSwatch && <ColorSwatch defaultColor={selectedColor} updateColor={selectColor} />}
          </Box>
          <InputGroup height="30px">
            <InputLeftElement
              height="30px"
              left="0"
              pointerEvents="none"
              children={
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M5.40974 8.62309C5.83173 8.62309 6.24958 8.53997 6.63944 8.37849C7.0293 8.217 7.38354 7.98031 7.68193 7.68192C7.98032 7.38353 8.21701 7.0293 8.3785 6.63944C8.53998 6.24957 8.6231 5.83172 8.6231 5.40974C8.6231 4.98776 8.53998 4.5699 8.3785 4.18004C8.21701 3.79018 7.98032 3.43594 7.68193 3.13756C7.38354 2.83917 7.0293 2.60247 6.63944 2.44099C6.24958 2.2795 5.83173 2.19639 5.40974 2.19639C4.55751 2.19639 3.74018 2.53494 3.13756 3.13756C2.53494 3.74018 2.19639 4.5575 2.19639 5.40974C2.19639 6.26197 2.53494 7.0793 3.13756 7.68192C3.74018 8.28454 4.55751 8.62309 5.40974 8.62309ZM8.79448 8.03719L10.7118 9.95449C10.7629 10.0039 10.8036 10.0631 10.8317 10.1284C10.8597 10.1938 10.8744 10.2641 10.875 10.3352C10.8755 10.4063 10.8619 10.4768 10.835 10.5426C10.808 10.6084 10.7682 10.6682 10.7179 10.7184C10.6676 10.7687 10.6077 10.8084 10.5419 10.8353C10.4761 10.8621 10.4055 10.8756 10.3344 10.875C10.2633 10.8743 10.193 10.8595 10.1277 10.8314C10.0624 10.8033 10.0033 10.7624 9.95396 10.7112L8.03666 8.79393C7.17573 9.46223 6.09247 9.77731 5.00741 9.67503C3.92234 9.57276 2.91704 9.06081 2.19615 8.24341C1.47526 7.42601 1.09297 6.36461 1.1271 5.27527C1.16124 4.18593 1.60924 3.15055 2.3799 2.37989C3.15055 1.60924 4.18594 1.16124 5.27528 1.1271C6.36462 1.09297 7.42602 1.47526 8.24342 2.19615C9.06082 2.91704 9.57276 3.92234 9.67504 5.0074C9.77732 6.09247 9.46224 7.17572 8.79394 8.03665L8.79448 8.03719Z"
                    fill="#7B7C7C"
                  />
                </svg>
              }
            />
            <Input
              placeholder={t('common.search')}
              height="30px"
              padding="7.5px 10px 7.5px 32px"
              border={`1px solid ${colorScheme.other.border}`}
              borderRadius="3px"
              color={colorScheme.label.primary}
              fontSize="12px"
              fontWeight="500"
              outline="none"
              background={colorScheme.platform.control.background}
              _focus={{ boxShadow: 'unset' }}
              defaultValue={iconName}
              onChange={(e) => setIconName(e.target.value)}
              isInvalid={false}
              // _invalid={{ borderColor: colorScheme.other.border }}
            />
          </InputGroup>
        </Box>
        <Grid gridTemplateColumns="repeat(9, 1fr)">
          {icons.map((icon, i) => (
            <GridItem
              key={`icon-${i}`}
              m="4px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="5px"
              onClick={
                updateIcon &&
                ((e) => {
                  e.preventDefault();
                  updateIcon(icon);
                })
              }
            >
              <Box
                width="30px"
                height="32px"
                borderRadius="5px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                color={color}
                bgColor={defaultIcon === icon ? selectedColor : 'unset'}
                _hover={{
                  bgColor: defaultIcon === icon && selectedColor ? selectedColor : hoverColor,
                  borderRadius: '5px',
                }}
              >
                <Icon
                  name={icon}
                  folderName={folderIcons}
                  fill={defaultIcon === icon ? colorScheme.universal.white : colorScheme.label.secondary}
                  width={folderIcons === 'habit-icons' ? 30 : 20}
                  height={folderIcons === 'habit-icons' ? 30 : 20}
                />
              </Box>
            </GridItem>
          ))}
        </Grid>
      </PopoverBody>
    </PopoverContent>
  );
}

IconPicker.defaultProps = defaultProps;
