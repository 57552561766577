import { Dayjs } from 'dayjs';
import { _dayjs } from '../extended-dayjs';
import { REGULARLY_INDICATOR } from './types';
import memoizeOne from 'memoize-one';

export type HabitRegularly = 'daily' | 'weekDays' | 'monthDays' | 'intervals' | '';

export const getHabitRegularly = memoizeOne(
  ({ regularly, startDate, date }: { regularly: string; startDate: number; date: Dayjs }): HabitRegularly => {
    if (isDailyHabit({ regularly })) {
      return 'daily';
    }

    if (isWeekDaysHabit({ regularly, date })) {
      return 'weekDays';
    }

    if (isMonthDaysHabit({ regularly, date })) {
      return 'monthDays';
    }

    if (isIntervalsHabit({ regularly, date, startDate })) {
      return 'intervals';
    }

    return '';
  },
);

const isDailyHabit = memoizeOne(({ regularly }: { regularly: string }): boolean => {
  return regularly.includes(REGULARLY_INDICATOR.DAILY);
});

export const isWeekDaysHabit = memoizeOne(({ date, regularly }: { regularly: string; date: Dayjs }): boolean => {
  const weekday = date.locale('en').format('ddd').toLowerCase();
  let regularlyValue = '';
  let regularlyArray: string[] = [];
  if (regularly.includes(REGULARLY_INDICATOR.WEEKDAYS)) {
    regularlyValue = regularly.replace(REGULARLY_INDICATOR.WEEKDAYS, '');
    regularlyArray = regularlyValue.trim().split(',');
    return regularlyArray.indexOf(weekday) > -1;
  }
  return false;
});

const isMonthDaysHabit = memoizeOne(({ date, regularly }: { regularly: string; date: Dayjs }): boolean => {
  const monthday = date.format('D');

  let regularlyValue = '';
  let regularlyArray: string[] = [];
  if (regularly.includes(REGULARLY_INDICATOR.MONTHDAYS)) {
    regularlyValue = regularly.replace(REGULARLY_INDICATOR.MONTHDAYS, '');
    regularlyArray = regularlyValue.trim().split(',');
    return regularlyArray.indexOf(monthday) > -1;
  }
  return false;
});

const isIntervalsHabit = memoizeOne(
  ({ date, regularly, startDate }: { regularly: string; startDate: number; date: Dayjs }): boolean => {
    const startDateFormatted = _dayjs(startDate).format('YYYY-MM-DD');
    let regularlyValue = '';
    if (regularly.includes(REGULARLY_INDICATOR.INTERVAL)) {
      regularlyValue = regularly.replace(REGULARLY_INDICATOR.INTERVAL, '');
      const interval = Number(regularlyValue.trim());
      const diffDays = date.diff(_dayjs(startDateFormatted), 'days');
      return diffDays % interval === 0;
    }

    return false;
  },
);
