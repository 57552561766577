import { useEffect, useState } from 'react';
import { StartWeekDay } from './date-picker.type';

export const useStartWith = (startWith: number) => {
  const [startWithValue, setStartWithValue] = useState<StartWeekDay>('sun');

  useEffect(() => {
    switch (startWith) {
      case 1:
        setStartWithValue('sun');
        break;
      case 2:
        setStartWithValue('mon');
        break;
      default:
        setStartWithValue('sun');
        break;
    }
  }, [startWith]);

  return {
    startWithValue
  }
};
