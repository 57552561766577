import { Box, Button, Grid, GridItem, Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { colorHabitIconOptions as colorOptions } from '__archived__/constants/habit_color_options';
import { useThemeData } from 'hooks/useThemeData';
import Icon from '../Icon';
interface Props {
  defaultColor?: string;
  updateColor?: (color: string) => void;
}

export default function ColorSwatch({ defaultColor, updateColor }: Props) {
  const { colorScheme } = useThemeData();
  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button
          size="sm"
          h="30px"
          width="30px"
          padding="0"
          bg="transparent"
          border={`1px solid ${colorScheme.other.border}`}
          _focus={{ outline: 'none' }}
          cursor="default"
        >
          <Box width="16px" height="16px" bg={defaultColor} borderRadius="50%"></Box>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        _focus={{ outline: 'none' }}
        width="176px"
        padding="8px"
        border="1px solid #E5E4E5"
        bgColor={colorScheme.platform.control.background}
      >
        <PopoverBody padding="0">
          <Grid gridTemplateColumns="repeat(5, 1fr)">
            {colorOptions.map((color) => (
              <GridItem
                key={color.hex}
                m="4px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                onClick={updateColor && (() => updateColor(color.hex))}
              >
                <Box
                  bg={color.hex}
                  width="24px"
                  height="24px"
                  borderRadius="50%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {defaultColor === color.hex && (
                    <Icon name="ic_completed" width={12} height={12} fill={colorScheme.universal.white} />
                  )}
                </Box>
              </GridItem>
            ))}
          </Grid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
