import { combineReducers } from '@reduxjs/toolkit';
import * as reducers from '__archived__/store/ducks';

const rootReducer = combineReducers({
  ...reducers,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
