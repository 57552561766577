import { Box, Text } from "@chakra-ui/react";
import Icon from "components/common/Icon";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement } from "react";
interface Props {
  iconName: string;
  folderName?: string;
  color: string;
  title: string;
  subTitle: string;
}
const BenefitItem: React.FC<Props> = ({
  iconName,
  folderName,
  color,
  title,
  subTitle,
}: Props): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  return (
    <Box mt="23px" display="flex">
      <Box
        minW="28px"
        h="28px"
        bgColor={color}
        borderRadius="5px"
        display="flex"
        justifyContent="center"
      >
        <Icon
          name={iconName}
          folderName={folderName}
          width={15}
          height={15}
          style={{ margin: "auto" }}
          fill={colorScheme.universal.white}
        />
      </Box>
      <Box pl="12px">
        <Text {...typography.emphasized.title[4]} mb="5px">
          {title}
        </Text>
        <Text
          {...typography.normal.callout}
          color={colorScheme.label.secondary}
        >
          {subTitle}
        </Text>
      </Box>
    </Box>
  );
};
export default BenefitItem;
