import { get, ref } from '@firebase/database';
import { createModel } from '@rematch/core';
import { database } from 'firebase-v9';
import { IUsageLimit } from 'models/common';
import { SuggestionHabitsList } from 'models/habits/habit-suggestion';
import { Pricing, SaleCampaign } from 'models/payment';
import { IMoodReasonItem } from '__archived__/types/states/app';
import { RootModel } from '../../root.model';

type UserRemoteConfig = {
  remoteSaleCampaign: SaleCampaign | null;
  pricingInfo: Pricing | null;
  habitSuggestions: Map<string, SuggestionHabitsList>;
  badHabitSuggestions: Map<string, SuggestionHabitsList>;
  usageConfig: IUsageLimit | null;
  numberOfFreeHabitAllowed: number;
  reasonsCategories: IMoodReasonItem[];
};

const initialState: UserRemoteConfig = {
  remoteSaleCampaign: null,
  pricingInfo: null,
  habitSuggestions: new Map(),
  badHabitSuggestions: new Map(),
  usageConfig: null,
  numberOfFreeHabitAllowed: 0,
  reasonsCategories: [],
};

export const userRemoteConfigModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setRemoteCampaignSale(state, saleCampaign: SaleCampaign) {
      return {
        ...state,
        remoteSaleCampaign: saleCampaign,
      };
    },
    setPricingInfo(state, pricing: Pricing) {
      return {
        ...state,
        pricingInfo: pricing,
      };
    },
    setHabitSuggestion(state, habitSuggestions: Map<string, SuggestionHabitsList>) {
      return {
        ...state,
        habitSuggestions,
      };
    },
    setBadHabitSuggestion(state, badHabitSuggestions: Map<string, SuggestionHabitsList>) {
      return {
        ...state,
        badHabitSuggestions,
      };
    },
    setUsageConfig(state, usageConfig: IUsageLimit) {
      return {
        ...state,
        usageConfig,
      };
    },
    setNumberHabitAllowed(state, numberHabit: number) {
      return {
        ...state,
        numberOfFreeHabitAllowed: numberHabit,
      };
    },
    setReasons(state, reasons: IMoodReasonItem[]) {
      return {
        ...state,
        reasonsCategories: reasons,
      };
    },
  },
  effects: (dispatch) => ({
    getRemoteConfig({ uid }: { uid: string }) {
      if (uid) {
        const remoteConfigRef = ref(database, `remoteConfig/${uid}`);
        get(remoteConfigRef).then((snapshot) => {
          snapshot.forEach((childSnapshot) => {
            switch (childSnapshot.key) {
              case 'activePromotionalCampaign_Web':
                dispatch.userRemoteConfigModel.setRemoteCampaignSale(childSnapshot.val());
                break;
              case 'pricingInfoWeb':
                dispatch.userRemoteConfigModel.setPricingInfo(childSnapshot.val());
                break;
              case 'habitTemplates_Web':
                dispatch.userRemoteConfigModel.setHabitSuggestion(childSnapshot.val());
                break;
              case 'badHabitTemplates_Web':
                dispatch.userRemoteConfigModel.setBadHabitSuggestion(childSnapshot.val());
                break;
              case 'usageLimit':
                dispatch.userRemoteConfigModel.setUsageConfig(childSnapshot.val());
                break;
              case 'numberOfFreeHabitAllowed':
                dispatch.userRemoteConfigModel.setNumberHabitAllowed(childSnapshot.val());
                break;
              case 'moodLogConfiguration':
                dispatch.userRemoteConfigModel.setReasons(childSnapshot.val()['reasonCategories']);
                break;
              default:
                break;
            }
          });
        });
      }
    },
  }),
});
