import InputField from "components/common/input-field/input-field.component";
import { useDebounce } from "hooks/useDebounce";
import { useThemeData } from "hooks/useThemeData";
import { NewDispatch, NewRootState } from "store";
import React, {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralSettingItem from "../general-setting-item.component";
import { useTranslation } from "react-i18next";

const JournalName: React.FC = (): ReactElement => {
  const {t} = useTranslation()
  const dispatch = useDispatch<NewDispatch>();
  const { typography } = useThemeData();
  const [name, setName] = useState("");
  const uid = useSelector(
    (state: NewRootState) => state.authenticationModel.user?.uid
  );
  const journalTitle = useSelector(
    (state: NewRootState) => state.appSettingModel.preferences?.journalTitle
  );

  const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);

  const debounceName = useDebounce(name, 1000);

  useEffect(() => {
    if (journalTitle) {
      setName(journalTitle);
    }
  }, [journalTitle]);

  useEffect(() => {
    if (uid && debounceName) {
      dispatch.appSettingModel.updateJournalTitle({
        uid,
        journalTitle: debounceName,
      });
    }
  }, [debounceName, dispatch.appSettingModel, uid]);

  return (
    <GeneralSettingItem
      title={t('journal.screenName')}
      subTitle={t('settings.journalSubtitle')}
    >
      <InputField
        cursor="default"
        inputType="default"
        defaultValue={journalTitle}
        {...typography.emphasized.callout}
        textTransform="capitalize"
        h="30px"
        maxW="160px"
        onChange={handleChangeName}
      />
    </GeneralSettingItem>
  );
};
export default JournalName;
