import { database } from 'firebase-v9';
import { ref, update } from 'firebase/database';
import { _dayjs } from 'tools/extended-dayjs';

export function updateUserLastActiveTime(uid: string) {
  const userRef = ref(database, `users/${uid}`);
  update(userRef, {
    lastActiveTime: _dayjs().toISOString(),
    lastActiveTimeZone: _dayjs.tz.guess(),
  });
}
