import {
    Box,
    Text,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
} from "@chakra-ui/react";
import { useThemeData } from "hooks/useThemeData";
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NewDispatch, NewRootState } from "store";
import { getFormatTime } from "tools/profile-settings/format-time";
import { UserPreferences } from "__archived__/store/ducks/app/app.type";
import { ITimeBound } from "__archived__/types/states";

interface TimeOfDaySettingProps {
    timeOfDayOption: number;
    userPreferences: UserPreferences;
}

type SliderDirection = "LEFT" | "RIGHT";
type StatusTimeRangeActive = "MORNING" | "AFTERNOON" | "EVENING";

const SUM_TIME = 1440; //unit: minute
const LIMIT_TIME_RANGE = 300; //unit: minute <-> 5 hour

const TimeOfDaySettings: React.FC<TimeOfDaySettingProps> = ({
    timeOfDayOption,
    userPreferences,
}: TimeOfDaySettingProps): ReactElement => {
    const { t } = useTranslation();
    const { colorScheme, typography } = useThemeData();
    const newDispatch = useDispatch<NewDispatch>();
    const uid = useSelector(
        (state: NewRootState) => state.authenticationModel.user?.uid
    );

    // timeOfDay setting
    const [timeStart, setTimeStart] = useState(0); // morningLowerbound
    const [timeStartAfternoon, setTimeStartAfternoon] = useState(0); // afternoonLowerbound
    const [timeEndAfternoon, setTimeEndAfternoon] = useState(0); // afternoonUpperbound
    const [timeEnd, setTimeEnd] = useState(0); //eveningUpperbound
    // const [mornignTimeRange, setMorningTimeRange] = useState<number>(0);
    // const [afternoonTimeRange, setAfternoonTimeRange] = useState<number>(0);
    // const [eveningTimeRange, setEveningTimeRange] = useState<number>(0);
    const [valSlider, setValSlider] = useState<number[]>([0, 0]);
    const [
        afternoonMinuteRangeLowerboundClient,
        setAfternoonMinuteRangeLowerboundClient,
    ] = useState(0);
    const [
        afternoonMinuteRangeUpperboundClient,
        setAfternoonMinuteRangeUpperboundClient,
    ] = useState(0);
    const [defaultValueSlider, setDefaultValueSlider] = useState<number[]>([
        0, 0,
    ]);
    const [sliderDirection, setDirectionSlider] =
        useState<SliderDirection>("LEFT");
    const [isReadOnlySlider, setIsReadOnlySlider] = useState<boolean>(true);
    const [timeRangeActive, setTimeRangeAvtive] =
        useState<StatusTimeRangeActive>("AFTERNOON");
    const [isShowTimeOfDayClient, setIsShowTimeOfDayClient] =
        useState<boolean>(false);

    // refs
    const timeOfDayWrapperRef = useRef(null);
    const refRangeSliderFilledTrack = useRef(null);
    const refLabelTimeRangeMorning = useRef(null);
    const refLabelTimeRangeAfternoon = useRef(null);
    const refLabelTimeRangeEvening = useRef(null);

    // css
    const cssLabelTimeRange: React.CSSProperties = {
        position: "absolute",
        transform: "translateY(-50%)",
        color: colorScheme.label.secondary,
        ...typography.other.title[6],
    };

    // handle timeOfDaysSettings from firebase
    useEffect(() => {
        const _timeOfDayWrapper =
            timeOfDayWrapperRef.current as HTMLElement | null;
        const {
            timeOfDaySettings: {
                morningMinuteRange,
                afternoonMinuteRange,
                eveningMinuteRange,
            },
        } = userPreferences;
        if (_timeOfDayWrapper) {
            _timeOfDayWrapper.style.maxWidth =
                _timeOfDayWrapper?.offsetWidth + "px";
        }
        setTimeStart(morningMinuteRange.lowerbound);
        setTimeStartAfternoon(afternoonMinuteRange.lowerbound);
        setTimeEndAfternoon(afternoonMinuteRange.upperbound);
        setAfternoonMinuteRangeLowerboundClient(
            afternoonMinuteRange.lowerbound
        );
        setAfternoonMinuteRangeUpperboundClient(
            afternoonMinuteRange.upperbound
        );
        setTimeEnd(eveningMinuteRange.upperbound);
    }, [userPreferences]);

    const handleFormatTimeOfDaySetting = useCallback(
        (timeOfDay: number) => {
            const rangeTimeStart = timeOfDay;
            const numberMinuteReset = 8 * 60; //minute
            const rangeTimeEnd = rangeTimeStart;
            const rangeTimeStartAfternoon = calculatorTimeRangeTimeOfDayChanged(
                rangeTimeStart + numberMinuteReset
            );
            const rangeTimeEndAfternoon = calculatorTimeRangeTimeOfDayChanged(
                rangeTimeStartAfternoon + numberMinuteReset
            );
            const morningMinuteRange: ITimeBound = {
                lowerbound: rangeTimeStart,
                upperbound: rangeTimeStartAfternoon,
            };
            const afternoonMinuteRange: ITimeBound = {
                lowerbound: rangeTimeStartAfternoon,
                upperbound: rangeTimeEndAfternoon,
            };
            const eveningMinuteRange: ITimeBound = {
                lowerbound: rangeTimeEndAfternoon,
                upperbound: rangeTimeEnd,
            };
            const timeOfDaySettings = {
                morningMinuteRange,
                afternoonMinuteRange,
                eveningMinuteRange,
            };
            setTimeStart(rangeTimeStart);
            setTimeStartAfternoon(rangeTimeStartAfternoon);
            setTimeEndAfternoon(rangeTimeEndAfternoon);
            setAfternoonMinuteRangeLowerboundClient(rangeTimeStartAfternoon);
            setAfternoonMinuteRangeUpperboundClient(rangeTimeEndAfternoon);
            setTimeEnd(rangeTimeEnd);
            newDispatch.preferencesModels.updateTimeOfDaySettings({
                uid,
                timeOfDaySettings,
            });
        },
        [newDispatch.preferencesModels, uid]
    );

    useEffect(() => {
        if (timeOfDayOption >= 0) {
            handleFormatTimeOfDaySetting(timeOfDayOption);
        }
    }, [handleFormatTimeOfDaySetting, timeOfDayOption]);

    useLayoutEffect(() => {
        // get time range
        const numberMinuteOfMorning = getMinuteTimeRange(
            timeStart,
            timeStartAfternoon
        );
        const numberMinuteOfAfternoon = getMinuteTimeRange(
            timeStartAfternoon,
            timeEndAfternoon
        );
        // const numberMinuteOfEvening = getMinuteTimeRange(
        //     timeEndAfternoon,
        //     timeEnd
        // );

        const valSlider1 = numberMinuteOfMorning;
        const valSlider2 = numberMinuteOfMorning + numberMinuteOfAfternoon;

        // update state
        // setMorningTimeRange(numberMinuteOfMorning);
        // setAfternoonTimeRange(numberMinuteOfAfternoon);
        // setEveningTimeRange(numberMinuteOfEvening);
        setValSlider([valSlider1, valSlider2]);
        setDefaultValueSlider([valSlider1, valSlider2]);

        setTimeout(() => {
            setStyleLabelTimeRange();
        }, 0);
    }, [timeStart, timeStartAfternoon, timeEndAfternoon, timeEnd]);

    const calculatorTimeRangeTimeOfDayChanged = (time: number) => {
        return time > SUM_TIME ? time - SUM_TIME : time;
    };

    const getMinuteTimeRange = (timeStart: number, timeEnd: number) => {
        return timeEnd > timeStart
            ? timeEnd - timeStart
            : SUM_TIME - timeStart + timeEnd;
    };

    const getMinuteTimeRangeSliderValueChanged = () => {
        const defaultVal1 = defaultValueSlider[0];
        const defaultVal2 = defaultValueSlider[1];
        const val1 = valSlider[0];
        const val2 = valSlider[1];
        const defaultVal =
            sliderDirection === "LEFT" ? defaultVal1 : defaultVal2;
        const val = sliderDirection === "LEFT" ? val1 : val2;
        const timeline =
            sliderDirection === "LEFT" ? timeStartAfternoon : timeEndAfternoon;
        let result: number = 0;
        result = timeline + (val - defaultVal);

        if (result < 0) result = SUM_TIME + result;
        if (result > SUM_TIME) result = result - SUM_TIME;

        return result;
    };

    const handleOnChange = (values: number[]) => {
        setValSlider(values);
        const val1 = values[0];
        const val2 = values[1];

        if (sliderDirection === "LEFT") {
            if (val1 <= LIMIT_TIME_RANGE) handleTimeStartLimited("MORNING");
            if (val2 - val1 <= LIMIT_TIME_RANGE)
                handleTimeStartLimited("AFTERNOON");

            const _afternoonLowerbound = getMinuteTimeRangeSliderValueChanged();
            setAfternoonMinuteRangeLowerboundClient(_afternoonLowerbound);
        }

        if (sliderDirection === "RIGHT") {
            if (val2 >= SUM_TIME - LIMIT_TIME_RANGE)
                handleTimeEndLimited("EVENING");
            if (val2 - val1 <= LIMIT_TIME_RANGE)
                handleTimeEndLimited("AFTERNOON");

            const _afternoonUpperbound = getMinuteTimeRangeSliderValueChanged();
            setAfternoonMinuteRangeUpperboundClient(_afternoonUpperbound);
        }

        setStyleLabelTimeRange();
    };

    const setStyleLabelTimeRange = () => {
        const elRangeSliderFilledTrack =
            refRangeSliderFilledTrack.current as HTMLElement | null;
        const elLabelTimeRangeMorning =
            refLabelTimeRangeMorning.current as HTMLElement | null;
        const elLabelTimeRangeAfternoon =
            refLabelTimeRangeAfternoon.current as HTMLElement | null;
        const elLabelTimeRangeEvening =
            refLabelTimeRangeEvening.current as HTMLElement | null;
        elLabelTimeRangeMorning?.setAttribute(
            "style",
            `width: ${elRangeSliderFilledTrack?.style.left}`
        );
        elLabelTimeRangeAfternoon?.setAttribute(
            "style",
            `width: ${elRangeSliderFilledTrack?.style.width}; left:${elRangeSliderFilledTrack?.style.left} `
        );
        elLabelTimeRangeEvening?.setAttribute(
            "style",
            `width: calc(100% - ${elRangeSliderFilledTrack?.style.left} -  ${elRangeSliderFilledTrack?.style.width})`
        );
    };

    const handleTimeStartLimited = (type: string) => {
        const _startAfternoon =
            timeStart + LIMIT_TIME_RANGE > SUM_TIME
                ? timeStart + LIMIT_TIME_RANGE - SUM_TIME
                : timeStart + LIMIT_TIME_RANGE;
        const val2 = valSlider[1];
        const val1 =
            type === "MORNING" ? LIMIT_TIME_RANGE : val2 - LIMIT_TIME_RANGE;
        setValSlider([val1, val2]);
        setAfternoonMinuteRangeLowerboundClient(_startAfternoon);
    };

    const handleTimeEndLimited = (type: string) => {
        const _afternoonUpperbound = getMinuteTimeRangeSliderValueChanged();
        const val1 = valSlider[0];
        const val2 =
            type === "EVENING"
                ? SUM_TIME - LIMIT_TIME_RANGE
                : val1 + LIMIT_TIME_RANGE;
        setValSlider([val1, val2]);
        setAfternoonMinuteRangeUpperboundClient(_afternoonUpperbound);
    };

    const handleOnchangeEnd = () => {
        if (sliderDirection === "LEFT") {
            const _afternoonLowerbound = getMinuteTimeRangeSliderValueChanged();
            setDefaultValueSlider(valSlider);
            setTimeStartAfternoon(_afternoonLowerbound);
            setAfternoonMinuteRangeLowerboundClient(_afternoonLowerbound);
            updateTimeOfDaySettings(_afternoonLowerbound, "lowerbound");
        } else {
            const _afternoonUpperbound = getMinuteTimeRangeSliderValueChanged();
            setDefaultValueSlider(valSlider);
            setTimeEndAfternoon(_afternoonUpperbound);
            setAfternoonMinuteRangeUpperboundClient(_afternoonUpperbound);
            updateTimeOfDaySettings(_afternoonUpperbound, "upperbound");
        }
    };

    const updateTimeOfDaySettings = (
        timeline: number,
        typeUpdate: "lowerbound" | "upperbound"
    ) => {
        const timeOfDaySettings: {
            morningMinuteRange: ITimeBound;
            afternoonMinuteRange: ITimeBound;
            eveningMinuteRange: ITimeBound;
        } = {
            morningMinuteRange: {
                lowerbound: timeStart,
                upperbound:
                    typeUpdate === "lowerbound" ? timeline : timeStartAfternoon,
            },
            afternoonMinuteRange: {
                lowerbound:
                    typeUpdate === "lowerbound" ? timeline : timeStartAfternoon,
                upperbound:
                    typeUpdate === "upperbound" ? timeline : timeEndAfternoon,
            },
            eveningMinuteRange: {
                lowerbound:
                    typeUpdate === "upperbound" ? timeline : timeEndAfternoon,
                upperbound: timeEnd,
            },
        };
        newDispatch.preferencesModels.updateTimeOfDaySettings({
            uid,
            timeOfDaySettings,
        });
    };

    return (
        <Box
            ref={timeOfDayWrapperRef}
            pr="16px"
            onMouseEnter={() => setIsShowTimeOfDayClient(true)}
        >
            <Box mb="12px">
                <Text
                    textTransform="capitalize"
                    color={colorScheme.label.primary}
                    {...typography.emphasized.title[4]}
                >
                    {t("settings.timeofdayConfiguration")}
                </Text>
            </Box>
            <Box position="relative" height="37px" borderRadius="5px">
                <RangeSlider
                    // aria-label={["min", "max"]}
                    colorScheme="pink"
                    height="100%"
                    isReadOnly={isReadOnlySlider}
                    min={0}
                    max={SUM_TIME}
                    defaultValue={defaultValueSlider}
                    value={valSlider}
                    onChange={(val) => handleOnChange(val)}
                    onChangeEnd={() => handleOnchangeEnd()}
                    background={colorScheme.background.primary[2]}
                    borderRadius="5px"
                >
                    <RangeSliderTrack height="100%" background="transparent">
                        <RangeSliderFilledTrack
                            ref={refRangeSliderFilledTrack}
                            height="100%"
                            background="transparent"
                        >
                            <Box
                                height={"100%"}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                backgroundColor={
                                    timeRangeActive === "AFTERNOON"
                                        ? colorScheme.accent.primary
                                        : colorScheme.background.primary[2]
                                }
                                color={
                                    timeRangeActive === "AFTERNOON"
                                        ? "white"
                                        : colorScheme.label.secondary
                                }
                                {...typography.emphasized.callout}
                                onClick={() => setTimeRangeAvtive("AFTERNOON")}
                            >
                                {t("common.afternoon")}
                            </Box>
                        </RangeSliderFilledTrack>
                    </RangeSliderTrack>
                    <RangeSliderThumb
                        width="12px"
                        height="26px"
                        borderRadius="unset"
                        backgroundColor="transparent"
                        boxShadow="unset"
                        zIndex={timeRangeActive === "EVENING" ? "-1" : 1}
                        _focus={{ boxShadow: "unset" }}
                        onMouseEnter={() => {
                            setDirectionSlider("LEFT");
                            setIsReadOnlySlider(false);
                        }}
                        index={0}
                    >
                        <Box
                            position="absolute"
                            width="2px"
                            height="16px"
                            backgroundColor="white"
                            right={timeRangeActive === "MORNING" ? "100%" : 0}
                            borderRadius="2px"
                            opacity="0.5"
                        ></Box>
                    </RangeSliderThumb>
                    <RangeSliderThumb
                        width="12px"
                        height="26px"
                        borderRadius="unset"
                        backgroundColor="transparent"
                        boxShadow="unset"
                        zIndex={timeRangeActive === "MORNING" ? "-1" : 1}
                        _focus={{ boxShadow: "unset" }}
                        onMouseEnter={() => {
                            setDirectionSlider("RIGHT");
                            setIsReadOnlySlider(false);
                        }}
                        index={1}
                    >
                        <Box
                            position="absolute"
                            width="2px"
                            height="16px"
                            backgroundColor="white"
                            left={timeRangeActive === "EVENING" ? "100%" : 0}
                            borderRadius="2px"
                            opacity="0.5"
                        ></Box>
                    </RangeSliderThumb>
                </RangeSlider>
                <Box
                    width="100%"
                    height="100%"
                    position="absolute"
                    top="0"
                    left="0"
                    background="transparent"
                    borderRadius="5px"
                    border={`1px solid ${colorScheme.other.border}`}
                >
                    <Box
                        ref={refLabelTimeRangeMorning}
                        position="absolute"
                        top={timeRangeActive === "MORNING" ? "-1px" : 0}
                        height={timeRangeActive === "MORNING" ? "37px" : "100%"}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderTopLeftRadius="5px"
                        borderBottomLeftRadius="5px"
                        backgroundColor={
                            !isShowTimeOfDayClient
                                ? "transparent"
                                : timeRangeActive === "MORNING"
                                ? colorScheme.accent.primary
                                : colorScheme.background.primary[2]
                        }
                        color={
                            timeRangeActive === "MORNING"
                                ? "white"
                                : colorScheme.label.secondary
                        }
                        {...typography.emphasized.callout}
                        onClick={() => setTimeRangeAvtive("MORNING")}
                    >
                        {t('common.morning')}
                        <Text
                            position="absolute"
                            left="0"
                            style={cssLabelTimeRange}
                            bottom={
                                timeRangeActive === "MORNING"
                                    ? "-22px"
                                    : "-23px"
                            }
                        >
                            {getFormatTime(timeStart)}
                        </Text>
                        <Text
                            position="absolute"
                            left="100%"
                            whiteSpace="nowrap"
                            style={cssLabelTimeRange}
                            bottom={
                                timeRangeActive === "MORNING"
                                    ? "-22px"
                                    : "-23px"
                            }
                        >
                            {getFormatTime(
                                afternoonMinuteRangeLowerboundClient
                            )}
                        </Text>
                    </Box>
                    <Box
                        ref={refLabelTimeRangeAfternoon}
                        position="absolute"
                        top={timeRangeActive === "AFTERNOON" ? "-1px" : 0}
                        height={
                            timeRangeActive === "AFTERNOON" ? "37px" : "100%"
                        }
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        backgroundColor={
                            !isShowTimeOfDayClient
                                ? "transparent"
                                : timeRangeActive === "AFTERNOON"
                                ? colorScheme.accent.primary
                                : colorScheme.background.primary[2]
                        }
                        color={
                            !isShowTimeOfDayClient
                                ? "transparent"
                                : timeRangeActive === "AFTERNOON"
                                ? "white"
                                : colorScheme.label.secondary
                        }
                        {...typography.emphasized.callout}
                        onClick={() => setTimeRangeAvtive("AFTERNOON")}
                    >
                        {t('common.afternoon')}
                    </Box>
                    <Box
                        ref={refLabelTimeRangeEvening}
                        position="absolute"
                        right="0"
                        top={timeRangeActive === "EVENING" ? "-1px" : 0}
                        height={timeRangeActive === "EVENING" ? "37px" : "100%"}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        borderTopRightRadius="5px"
                        borderBottomRightRadius="5px"
                        backgroundColor={
                            !isShowTimeOfDayClient
                                ? "transparent"
                                : timeRangeActive === "EVENING"
                                ? colorScheme.accent.primary
                                : colorScheme.background.primary[2]
                        }
                        color={
                            timeRangeActive === "EVENING"
                                ? "white"
                                : colorScheme.label.secondary
                        }
                        {...typography.emphasized.callout}
                        onClick={() => setTimeRangeAvtive("EVENING")}
                    >
                        {t('common.evening')}
                        <Text
                            position="absolute"
                            right="100%"
                            width="25%"
                            whiteSpace="nowrap"
                            style={cssLabelTimeRange}
                            bottom={
                                timeRangeActive === "EVENING"
                                    ? "-22px"
                                    : "-23px"
                            }
                        >
                            {getFormatTime(
                                afternoonMinuteRangeUpperboundClient
                            )}
                        </Text>
                        <Text
                            position="absolute"
                            right="0"
                            style={cssLabelTimeRange}
                            bottom={
                                timeRangeActive === "EVENING"
                                    ? "-22px"
                                    : "-23px"
                            }
                        >
                            {getFormatTime(timeEnd)}
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
export default TimeOfDaySettings;
