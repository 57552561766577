import { createModel } from '@rematch/core';
import remoteConfig from 'firebase-v9/remote-config';
import { RootModel } from '../../root.model';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import jsonp from 'jsonp';
import {
  ApplePlan,
  PaddleProduct,
  Plan,
  Pricing,
  PricingApple,
  SaleApplePlan,
  SaleCampaign,
  SaleCampaignApple,
} from 'models/payment';
import { appleProductParser, appleSaleCampaignParser, pricingInfoAppleParser } from 'tools/pricing-info';
import { AppleProduct } from 'models/payment/apple-product';

type PaymentState = {
  pricingInfo: Pricing | null;
  pricingInfoApple: PricingApple | null;
  paddleProducts: PaddleProduct[] | null;
  appleProducts: AppleProduct[] | null;
  isOpenPaymentModal: boolean;
  remoteSaleCampaign: SaleCampaign | null;
  remoteAppleSaleCampaign: SaleCampaignApple | null;
  isOpenProcessing: boolean;
};

const initialState: PaymentState = {
  pricingInfo: null,
  pricingInfoApple: null,
  paddleProducts: [],
  appleProducts: [],
  isOpenPaymentModal: false,
  remoteSaleCampaign: null,
  remoteAppleSaleCampaign: null,
  isOpenProcessing: false,
};

export const paymentModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setPayment(state, payload: { pricingInfo: Pricing }) {
      return {
        ...state,
        pricingInfo: payload.pricingInfo,
      };
    },
    setPricingInfoApple(state, payload: PricingApple) {
      return {
        ...state,
        pricingInfoApple: payload,
      };
    },
    setPaddleProduct(state, payload: { products: any }) {
      return {
        ...state,
        paddleProducts: payload.products,
      };
    },
    setIsOpenPaymentModal(state, payload: { open: boolean }) {
      return {
        ...state,
        isOpenPaymentModal: payload.open,
      };
    },
    setRemoteSaleCampaign(state, payload: { campaign: SaleCampaign }) {
      return {
        ...state,
        remoteSaleCampaign: payload.campaign,
      };
    },
    setRemoteAppleSaleCampaign(state, payload: SaleCampaignApple) {
      return {
        ...state,
        remoteAppleSaleCampaign: payload,
      };
    },
    setIsOpenProcessing(state, payload: { open: boolean }) {
      return {
        ...state,
        isOpenProcessing: payload.open,
      };
    },
    setAppleProducts(state, payload: AppleProduct[]) {
      return {
        ...state,
        appleProducts: payload,
      };
    },
  },
  effects: (dispatch) => ({
    getPlansFromRemoteConfig() {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const pricingConfig = getValue(remoteConfig, 'pricingInfoWeb');
          const parsedPricing = pricingConfig && !!pricingConfig.asString() && JSON.parse(pricingConfig.asString());
          dispatch.paymentModel.setPayment({ pricingInfo: parsedPricing });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getPricingInfoApple() {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const pricingConfig = getValue(remoteConfig, 'pricingInfoApple');
          const pricingConfigRawValue =
            pricingConfig && pricingConfig.asString() && JSON.parse(pricingConfig.asString());
          const pricingInfoApple = pricingInfoAppleParser(pricingConfigRawValue);
          if (pricingInfoApple) {
            dispatch.paymentModel.setPricingInfoApple(pricingInfoApple);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async getPaddleProducts(plans: Plan[]) {
      const productIds = plans.map((plan) => {
        return plan.productId;
      });
      let isBlockApi = false;
      const paddleLinkToProduct = `https://checkout.paddle.com/api/2.0/prices?product_ids=${productIds.join(',')}`;

      jsonp(paddleLinkToProduct, {}, (err: any, data: any) => {
        if (err) {
          console.error(err.message);
        } else if (data.response.products) {
          const paddleProducts = data.response.products.map((product: any) => {
            const convertedProduct: PaddleProduct = {
              productId: product.product_id,
              productTitle: product.product_title,
              currency: product.currency,
              priceIncludeTax: product.vendor_set_prices_included_tax,
              price: product.price,
              listPrice: product.list_price,
            };
            return convertedProduct;
          });
          dispatch.paymentModel.setPaddleProduct({
            products: paddleProducts,
          });
          isBlockApi = true;
        }
      });
      if (isBlockApi) {
        const ipInfoToken = 'cff5fcb37abf60';
        const ipInfoResponse = await fetch(`https://ipinfo.io/json?token=${ipInfoToken}`);
        console.log(ipInfoResponse.json());
      }
    },
    getRemoteSaleCampaign() {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const promoCampaign = getValue(remoteConfig, 'activePromotionalCampaign_Web');
          const parsedPromo = promoCampaign && !!promoCampaign.asString() && JSON.parse(promoCampaign.asString());
          dispatch.paymentModel.setRemoteSaleCampaign({
            campaign: parsedPromo,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getRemoteAppleSaleCampaign() {
      fetchAndActivate(remoteConfig)
        .then(() => {
          const promoCampaign = getValue(remoteConfig, 'activePromotionalCampaign');
          const promoCampaignRawValue =
            promoCampaign && promoCampaign.asString() && JSON.parse(promoCampaign.asString());
          const remoteAppleSaleCampaign = appleSaleCampaignParser(promoCampaignRawValue);
          if (remoteAppleSaleCampaign) {
            dispatch.paymentModel.setRemoteAppleSaleCampaign(remoteAppleSaleCampaign);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getAppleProducts(plans: ApplePlan[]) {
      const productIds = plans.map((plan) => {
        if (plan.productId) {
          const productId = plan.productId;
          return productId;
        }
        return '';
      });
      window.ElectronBridge.getProducts(productIds, (products: any) => {
        if (Array.isArray(products) && products.length) {
          const appleProducts: AppleProduct[] = [];
          products.forEach((product) => {
            const appleProduct = appleProductParser(product);
            if (appleProduct) {
              appleProducts.push(appleProduct);
            }
          });
          dispatch.paymentModel.setAppleProducts(appleProducts);
        }
      });
    },
    getAppleSaleProducts(plans: SaleApplePlan[]) {
      const productIds = plans.map((plan) => {
        if (plan.targetProductId) {
          const productId = plan.targetProductId;
          return productId;
        }
        return '';
      });
      window.ElectronBridge.getProducts(productIds, (products: any) => {
        if (Array.isArray(products) && products.length) {
          const appleProducts: AppleProduct[] = [];
          products.forEach((product) => {
            const appleProduct = appleProductParser(product);
            if (appleProduct) {
              appleProducts.push(appleProduct);
            }
          });
          dispatch.paymentModel.setAppleProducts(appleProducts);
        }
      });
    },
    openPaymentModal(open: boolean) {
      dispatch.paymentModel.setIsOpenPaymentModal({ open });
    },
    openProcessingModal(open: boolean) {
      dispatch.paymentModel.setIsOpenProcessing({ open });
    },
  }),
});
