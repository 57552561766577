import Splash from 'components/common/Splash';
import AuthenticationLayout from 'components/layouts/Authentication';
import MainLayout from 'components/layouts/main.layout';
import MoodDetailLayout from 'components/layouts/MoodDetail';
import { Location } from 'history';
import React, { ReactElement, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { NewRootState } from 'store';
import NotFound from './not-found/not-found.view';

const AuthenticationView = React.lazy(() => import('views/authentication/authentication.view'));
const DesktopEntry = React.lazy(() => import('views/authentication/desktop-entry.view'));
const MASEntry = React.lazy(() => import('views/authentication/mas-entry.view'));
const MainView = React.lazy(() => import('views/main/main.views'));
const TimerView = React.lazy(() => import('views/timer/timer.views'));
const MoodDetailView = React.lazy(() => import('views/mood-detail/MoodDetail'));
const ManageHabitsView = React.lazy(() => import('views/manage/manage-habits-main.view'));

interface LocationState {
  background: Location;
}

const Routes: React.FC = (): ReactElement => {
  const isLoading = useSelector<NewRootState, boolean>((state) => state.authenticationModel.isLoading);
  const location = useLocation<LocationState>();

  if (isLoading) {
    return <Splash />;
  }

  return (
    <>
      <Switch location={location}>
        <Route exact path="/">
          {!!window.location.search ? (
            <Redirect
              to={{
                pathname: '/signin',
                search: window.location.search,
              }}
            />
          ) : (
            <Redirect to="/signin" />
          )}
        </Route>
        <Route exact path="/signin">
          <Suspense fallback={<AuthenticationLayout />}>
            <AuthenticationView />
          </Suspense>
        </Route>
        <Route exact path="/signup">
          <Suspense fallback={<AuthenticationLayout />}>
            <AuthenticationView />
          </Suspense>
        </Route>
        <Route exact path="/signin-desktop">
          <Suspense fallback={<AuthenticationLayout isRemoteAuthentication={true} />}>
            <AuthenticationView isRemoteAuthentication={true} />
          </Suspense>
        </Route>
        <Route exact path="/desktop-entry">
          <Suspense fallback={<AuthenticationLayout />}>
            <DesktopEntry />
          </Suspense>
        </Route>
        <Route exact path="/mas-entry">
          <Suspense fallback={<AuthenticationLayout />}>
            <MASEntry />
          </Suspense>
        </Route>
        <Route exact path="/journal">
          <Redirect to="/journal/time-of-day" />
        </Route>
        <Route exact path="/journal/all-habits">
          <Suspense fallback={<MainLayout />}>
            <MainView />
          </Suspense>
        </Route>
        <Route exact path="/journal/time-of-day">
          <Suspense fallback={<MainLayout />}>
            <MainView />
          </Suspense>
        </Route>
        <Route exact path="/journal/:folderId">
          <Suspense fallback={<MainLayout />}>
            <MainView />
          </Suspense>
        </Route>
        <Route exact path="/timer/:id">
          <Suspense fallback={<MainLayout />}>
            <TimerView />
          </Suspense>
        </Route>
        <Route exact path="/mood/:id">
          <Suspense fallback={<MoodDetailLayout />}>
            <MoodDetailView />
          </Suspense>
        </Route>
        <Route exact path="/manage-habits">
          <Suspense fallback={<MainLayout />}>
            <ManageHabitsView />
          </Suspense>
        </Route>
        {/* <Route
          path={[`${location.pathname}`, '/']}
          render={() => {
            return <AppSettingModal />;
          }}
        ></Route> */}
        <Route path="*" component={NotFound} />
      </Switch>
      {/* {background && (
        <Route
          path={[`${location.pathname}`, '/']}
          render={() => {
            return <AppSettingModal />;
          }}
        ></Route>
      )} */}
    </>
  );
};

export default Routes;
