import isLeapYear from 'dayjs/plugin/isLeapYear';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import weekday from 'dayjs/plugin/weekday';
import objectSupport from 'dayjs/plugin/objectSupport';
import isoWeek from 'dayjs/plugin/isoWeek';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(weekday);
dayjs.extend(objectSupport);
dayjs.extend(isLeapYear);
dayjs.extend(isoWeek);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime)
dayjs.tz.setDefault(dayjs.tz.guess());
dayjs().format();

export const _dayjs = dayjs;

export const microValueDivider = 1000000;

export const today = _dayjs();
export const tomorrow = today.add(1, 'days');
export const yesterday = today.subtract(1, 'days');
export const thisWeek = today.week();
export const thisMonth = today.month();
export const thisYear = today.year();

export const todayUTC = _dayjs.utc();
export const thisWeekUTC = todayUTC.week();
export const thisMonthUTC = todayUTC.month();
export const thisYearUTC = todayUTC.year();

export const creationTimeThreshold = _dayjs('2020-08-20');

export const MAX_DOUBLE = Math.pow(2.0, 100);
export const MIN_DOUBLE = -1 * MAX_DOUBLE;
