import { Box } from '@chakra-ui/react';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '__archived__/store/roots';
import { TIME_OF_DAY_BITWISE } from '__archived__/types/enum';
import { FOLDER_NAME_ICON_COLLECTION } from '__archived__/types/enum/app';
import { ITimeBound, ITimeOfDay } from '__archived__/types/states';
import { getTimeOfDay, getTimeOfDayByBitwise } from '__archived__/utils/getTimeOfDay';
import NavItem from './habit_menu_item.sidebar';

interface IHabitMenu {
  timeOfDayBitwise: number;
  title: string;
  iconName: string;
  iconFolder: string;
  link: { pathName: string; pageName: 'allHabit' | 'timeOfDay' };
}

const HabitMenu: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const morningTimeBound: ITimeBound = useSelector((state: RootState) => state.app.morningMinuteRange);
  const afternoonTimeBound: ITimeBound = useSelector((state: RootState) => state.app.afternoonMinuteRange);
  const { timeOfDayBitwise }: ITimeOfDay = getTimeOfDay(morningTimeBound, afternoonTimeBound);

  const textTimeOfDayLocal = useCallback(
    (timeOfDay: number) => {
      if (timeOfDay === TIME_OF_DAY_BITWISE.MORNING) return t('common.morning');
      if (timeOfDay === TIME_OF_DAY_BITWISE.AFTERNOON) return t('common.afternoon');
      if (timeOfDay === TIME_OF_DAY_BITWISE.EVENING) return t('common.evening');
      return getTimeOfDayByBitwise(timeOfDayBitwise);
    },
    [t, timeOfDayBitwise],
  );

  const data:IHabitMenu[] = useMemo(() => {
    return [
      {
        timeOfDayBitwise: TIME_OF_DAY_BITWISE.ALL,
        title: t('common.allHabit'),
        iconName: 'ic-all-habits',
        iconFolder: FOLDER_NAME_ICON_COLLECTION.SIDEBAR,
        link: {
          pathName: '/journal/all-habits',
          pageName: 'allHabit',
        },
      },
      {
        timeOfDayBitwise,
        title: textTimeOfDayLocal(timeOfDayBitwise),
        iconName: 'ic-morning',
        iconFolder: FOLDER_NAME_ICON_COLLECTION.SIDEBAR,
        link: {
          pathName: `/journal/time-of-day`,
          pageName: 'timeOfDay',
        },
      },
    ];
  }, [t, textTimeOfDayLocal, timeOfDayBitwise]);

  return (
    <Box>
      {data.map((item: IHabitMenu) => {
        const { timeOfDayBitwise, title, iconName, iconFolder, link } = item;
        return <NavItem key={title} payload={{ title, iconName, iconFolder, link, timeOfDayBitwise }}></NavItem>;
      })}
    </Box>
  );
};

export default HabitMenu;
