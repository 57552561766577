import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';
import { DeviceUUID } from 'device-uuid';
import { Dispatch } from 'redux';
import { _dayjs } from '__archived__/constants/app';
import firebase from '__archived__/firebase-compat/index';
import remoteConfig from '__archived__/firebase-compat/remoteConfig';
import store from '__archived__/store/index';
import { HABIT_PROGRESS_FILTER } from '__archived__/types/enum/app';
import {
  IActionModal,
  IContextMenu,
  IEventCount,
  IEvents,
  IMood,
  IMoodContextMenu,
  IMoodSelected,
  IRemoteConfig,
  ITimerOverlay,
} from '__archived__/types/states/app';
import { IUserInfo } from '__archived__/types/states/habit';
import { isOnThisDay, isOnThisWeek } from '__archived__/utils/dateTimeUtils';
import { filterMoodByDate } from '__archived__/utils/emotionPreferences';
import { eventType } from '__archived__/utils/eventType';
import {
  AddEventCount,
  AddEventType,
  APP_TYPES,
  EventType,
  FilteredMood,
  FilteredMoodFailed,
  FilteredMoodSuccess,
  FilteredMoodType,
  FilteredMoodWithDate,
  GetAllMoodType,
  GetAllUserInfoType,
  GetEventType,
  GetMoodFailedType,
  GetMoodSuccessType,
  GetMoodType,
  GetUsageConfigType,
  GetUserInfoFailedType,
  GetUserInfoSuccessType,
  GetUserInfoType,
  GetUserPreferences,
  GetUserPreferencesAction,
  GetUserPreferencesFailed,
  GetUserPreferencesSuccess,
  MoodLogWithId,
  OpenLimitModalType,
  OpenMoodNoteModalType,
  PreferencesLoaded,
  ResetDataType,
  SelectMoodType,
  ShowJournalAnnouncementType,
  SortJournalType,
  UpdateContextMenuProps,
  UpdateContextMenuPropsSingleProgress,
  UpdateDate,
  UpdateDatePickerModalState,
  UpdateDateSingleProgress,
  UpdateDetailHabit,
  UpdateEventChildRemoveType,
  UpdateEventType,
  UpdateFirstWeekDayChildAdded,
  UpdateFirstWeekDayChildChanged,
  UpdateFirstWeekDayChildRemoved,
  UpdateHabitFolderFilter,
  UpdateHabitProgressFilter,
  UpdateJournalLayoutTypeChildAdded,
  UpdateJournalLayoutTypeChildChanged,
  UpdateJournalLayoutTypeChildRemoved,
  UpdateLimitTypeCurrent,
  UpdateLogActionModal,
  UpdateManualLogModalProps,
  UpdateMoodContextMenuProps,
  UpdateMoodIdType,
  UpdateMoodLogChildAddedType,
  UpdateMoodLogChildChangedType,
  UpdateMoodLogChildRemoveType,
  UpdateOnUserPreferencesChildAdded,
  UpdateOnUserPreferencesChildChanged,
  UpdateOnUserPreferencesChildRemoved,
  UpdateSignOutAlertState,
  UpdateTimeOfDayType,
  UpdateTimerModalProps,
  UpdateTimerOverlayProps,
  UpdateUserInfoChildAddedType,
  UpdateUserInfoChildChangedType,
  UpdateUserInfoChildRemoveType,
  UserPreferences,
} from './app.type';

const db = firebase.database();

export function updateTimeOfDay(timeOfDay: number): UpdateTimeOfDayType {
  return { type: APP_TYPES.UPDATE_TIME_OF_DAY, payload: timeOfDay };
}

export function updateHabitProgressFilter(filter: HABIT_PROGRESS_FILTER): UpdateHabitProgressFilter {
  return { type: APP_TYPES.UPDATE_HABIT_PROGRESS_FILTER, payload: filter };
}

export function updateContextMenuPropsAction(
  habitId: string,
  xPos: number,
  yPos: number,
  showMenu: boolean,
): UpdateContextMenuProps {
  const contextMenuProps: IContextMenu = {
    habitId,
    xPos,
    yPos,
    showMenu,
  };
  return { type: APP_TYPES.UPDATE_CONTEXT_MENU_PROPS, payload: contextMenuProps };
}

export function updateContextMenuPropsActionSingleProgress(
  habitId: string,
  xPos: number,
  yPos: number,
  showMenu: boolean,
): UpdateContextMenuPropsSingleProgress {
  const contextMenuProps: IContextMenu = {
    habitId,
    xPos,
    yPos,
    showMenu,
  };
  return { type: APP_TYPES.UPDATE_CONTEXT_MENU_PROPS_SINGLE_PROGRESS, payload: contextMenuProps };
}

export function updateLogActionModalAction(habitId: string, openState: boolean): UpdateLogActionModal {
  const logActionModalProps: IActionModal = {
    habitId,
    isOpen: openState,
  };
  return { type: APP_TYPES.UPDATE_LOG_ACTION_MODAL_PROPS, payload: logActionModalProps };
}

export function updateDatePickerModalStateAction(state: boolean): UpdateDatePickerModalState {
  return { type: APP_TYPES.UPDATE_DATE_PICKER_MODAL_STATE, payload: state };
}

export function updateDateAction(date: string): UpdateDate {
  return { type: APP_TYPES.UPDATE_DATE, payload: date };
}

export function updateDateActionSingleProgress(date: string): UpdateDateSingleProgress {
  return { type: APP_TYPES.UPDATE_DATE_SINGLE_PROGRESS, payload: date };
}

export function updateManualLogModalPropsAction(habitId: string, openState: boolean): UpdateManualLogModalProps {
  const manualLogModalProps: IActionModal = {
    habitId,
    isOpen: openState,
  };
  return { type: APP_TYPES.UPDATE_MANUAL_LOG_MODAL_PROPS, payload: manualLogModalProps };
}

export function updateHabitFolderFilterAction(folderId: string): UpdateHabitFolderFilter {
  return { type: APP_TYPES.UPDATE_HABIT_FOLDER_FILTER, payload: folderId };
}

export function updateTimeOfDayAction(timeOfDay: number) {
  return (dispatch: Dispatch<UpdateTimeOfDayType>): void => {
    dispatch(updateTimeOfDay(timeOfDay));
  };
}

export function updateTimerModalPropsAction(habitId: string, openState: boolean): UpdateTimerModalProps {
  const timerModalProps: IActionModal = {
    habitId,
    isOpen: openState,
  };
  return { type: APP_TYPES.UPDATE_TIMER_MODAL_PROPS, payload: timerModalProps };
}

export function updateTimerOverlayPropsAction(
  habitId: string,
  timerOverlayState: boolean,
  timeValue: number,
): UpdateTimerOverlayProps {
  const timerOverlayProps: ITimerOverlay = {
    habitId,
    timerOverlayState,
    timeValue,
  };
  return { type: APP_TYPES.UPDATE_TIMER_OVERLAY_PROPS, payload: timerOverlayProps };
}

export function getUserInfo(isPending: boolean): GetUserInfoType {
  return { type: APP_TYPES.GET_USER_INFO, payload: isPending };
}

export function getUserInfoSuccess(userInfo: IUserInfo): GetUserInfoSuccessType {
  return { type: APP_TYPES.GET_USER_INFO_SUCCESS, payload: userInfo };
}

export function getUserInfoFailed(): GetUserInfoFailedType {
  return { type: APP_TYPES.GET_USER_INFO_FAILED };
}

export function updateUserInfoChildAdded(key: string, value: string | number): UpdateUserInfoChildAddedType {
  return { type: APP_TYPES.UPDATE_USER_INFO_CHILD_ADDED, payload: { key, value } };
}

export function updateUserInfoChildChanged(key: string, value: string | number): UpdateUserInfoChildChangedType {
  return { type: APP_TYPES.UPDATE_USER_INFO_CHILD_CHANGED, payload: { key, value } };
}

export function updateUserInfoChildRemove(key: string, value: string | number): UpdateUserInfoChildRemoveType {
  return { type: APP_TYPES.UPDATE_USER_INFO_CHILD_REMOVED, payload: { key, value } };
}

export function getUserInfoAction(uid: string) {
  return async (dispatch: Dispatch<GetAllUserInfoType>): Promise<void | firebase.database.DataSnapshot> => {
    dispatch(getUserInfo(true));
    const usersRef = db.ref(`users/${uid}`);
    return usersRef
      .once('value', (dataSnapshot) => {
        let userInfo: IUserInfo = {
          name: '',
          email: '',
          premiumStatusAndroid: 0,
          premiumExpireDate: 0,
          premiumStatus: 0,
        };
        if (dataSnapshot.exists()) {
          userInfo = dataSnapshot.val();
        }
        dispatch(getUserInfoSuccess(userInfo));

        usersRef.on('child_added', (childSnapshot) => {
          if (childSnapshot.key) {
            if (!Object.keys(userInfo).includes(childSnapshot.key)) {
              dispatch(updateUserInfoChildAdded(childSnapshot.key, childSnapshot.val()));
            }
          }
        });

        usersRef.on('child_changed', (childSnapshot) => {
          if (childSnapshot.key) {
            dispatch(updateUserInfoChildChanged(childSnapshot.key, childSnapshot.val()));
          }
        });

        usersRef.on('child_removed', (childSnapshot) => {
          if (childSnapshot.key) {
            dispatch(updateUserInfoChildRemove(childSnapshot.key, ''));
          }
        });
      })
      .catch(() => {
        dispatch(getUserInfoFailed());
      });
  };
}

export function getUsageConfig(usageConfig: IRemoteConfig): GetUsageConfigType {
  return { type: APP_TYPES.GET_USAGE_CONFIG, payload: usageConfig };
}

export function getUsageConfigAction() {
  return async (dispatch: Dispatch<GetUsageConfigType>): Promise<void> => {
    try {
      remoteConfig
        .fetchAndActivate()
        .then(() => {
          const usageConfigResponse = remoteConfig.getValue('usageLimit');
          const freeHabitResponse = remoteConfig.getValue('numberOfFreeHabitAllowed');
          const moodOptions = remoteConfig.getValue('moodLogConfiguration');
          const parsedUsage =
            usageConfigResponse && !!usageConfigResponse.asString() && JSON.parse(usageConfigResponse.asString());
          const parsedFreeHabit =
            !!freeHabitResponse && !!freeHabitResponse.asString() && JSON.parse(freeHabitResponse.asString());
          const parsedMoodOptions = !!moodOptions && !!moodOptions.asString() && JSON.parse(moodOptions.asString());
          const usageConfig: IRemoteConfig = {
            usageConfig: parsedUsage,
            numberOfFreeHabitAllowed: parsedFreeHabit,
            reasonsCategories: parsedMoodOptions.reasonCategories,
          };
          dispatch(getUsageConfig(usageConfig));
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {
      console.error(e);
    }
  };
}

export function openLimitModalAction(status: boolean): OpenLimitModalType {
  return { type: APP_TYPES.OPEN_LIMIT_MODAL, payload: status };
}

export function openMoodNotesModalAction(status: boolean): OpenMoodNoteModalType {
  return { type: APP_TYPES.OPEN_MOOD_NOTES_MODAL, payload: status };
}

export function getEventAction(events: Map<string, IEvents>): GetEventType {
  return { type: APP_TYPES.GET_EVENT, payload: events };
}

export function filterEvents(events: Map<string, IEvents>, newDay = false, firstDayOfWeek: FirstWeekDay) {
  return async (dispatch: Dispatch<EventType>): Promise<void> => {
    const listEvent = Array.from(events.values());

    const filteredEvent = listEvent.filter((e: IEvents) => {
      if (isOnThisWeek(e.created, newDay, firstDayOfWeek)) {
        return true;
      }
      return false;
    });
    const counts: IEventCount = {};
    for (let i = 0; i < filteredEvent.length; i++) {
      const e = filteredEvent[i].event;
      if (e !== eventType.TIMER && e !== eventType.MOOD) {
        counts[e] = counts[e] ? counts[e] + 1 : 1;
        continue;
      }
      if ((e === eventType.TIMER || e === eventType.MOOD) && isOnThisDay(filteredEvent[i].created, newDay)) {
        counts[e] = counts[e] ? counts[e] + 1 : 1;
        continue;
      }
    }
    dispatch(addEventCount(counts));
  };
}

export function fetchEvent(uid: string) {
  return async (dispatch: Dispatch<EventType>): Promise<void | firebase.database.DataSnapshot> => {
    const eventRef = db.ref(`events/${uid}`);
    return eventRef.once('value', (dataSnapshot) => {
      let eventsMap: Map<string, IEvents> = new Map<string, IEvents>();
      if (dataSnapshot.exists()) {
        eventsMap = new Map<string, IEvents>(Object.entries(dataSnapshot.val()));
      }
      dispatch(getEventAction(eventsMap));

      eventRef.on('child_added', (childSnapshot) => {
        if (childSnapshot.key) {
          if (!eventsMap.has(childSnapshot.key)) {
            dispatch(addEvent(childSnapshot.key, childSnapshot.val()));
          }
        }
      });

      eventRef.on('child_removed', (childSnapshot) => {
        if (childSnapshot.key) {
          dispatch(updateEventChildRemove(childSnapshot.key));
        }
      });
    });
  };
}

export function addEvent(eventId: string, event: IEvents): AddEventType {
  return { type: APP_TYPES.ADD_EVENT, payload: { id: eventId, event } };
}

export function updateEventChildRemove(eventId: string): UpdateEventChildRemoveType {
  return { type: APP_TYPES.UPDATE_EVENT_CHILD_REMOVED, payload: eventId };
}

export function addEventCount(count: IEventCount): AddEventCount {
  return { type: APP_TYPES.ADD_EVENT_COUNT, payload: count };
}

export function createNewEventAction(eventType: string, uid: string) {
  return async (dispatch: Dispatch<AddEventType>): Promise<void> => {
    const eventRef = db.ref(`events/${uid}`);
    const event: IEvents = {
      event: eventType,
      created: new Date().toISOString(),
    };
    await eventRef.push(event).then((snap) => {
      if (snap && snap.key) {
        dispatch(addEvent(snap.key, event));
      }
    });
  };
}

export function updateEventTypeAction(timerType: string): UpdateEventType {
  return { type: APP_TYPES.UPDATE_EVENT_TYPE, payload: timerType };
}

export function updateLimitTypeCurrent(timerType: string): UpdateLimitTypeCurrent {
  return { type: APP_TYPES.UPDATE_LIMIT_TYPE_CURRENT, payload: timerType };
}

export function getUserPreferences(isPending: boolean): GetUserPreferences {
  return { type: APP_TYPES.GET_USER_PREFERENCES, payload: isPending };
}

export function getUserPreferencesSuccess(userPreferences: UserPreferences): GetUserPreferencesSuccess {
  return { type: APP_TYPES.GET_USER_PREFERENCES_SUCCESS, payload: userPreferences };
}

export function getUserPreferencesFailed(): GetUserPreferencesFailed {
  return { type: APP_TYPES.GET_USER_PREFERENCES_FAILED };
}

export function updateOnUserPreferencesChildAdded(userPreferences: UserPreferences): UpdateOnUserPreferencesChildAdded {
  return { type: APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_ADDED, payload: userPreferences };
}

export function updateSortJournalType(sortType: string): SortJournalType {
  return { type: APP_TYPES.SORT_JOURNAL_TYPE, payload: sortType };
}

export function updatePreferencesLoaded(stateLoad: boolean): PreferencesLoaded {
  return { type: APP_TYPES.PREFERENCES_LOADED, payload: stateLoad };
}

export function updateOnUserPreferencesChildChanged(
  userPreferences: UserPreferences,
): UpdateOnUserPreferencesChildChanged {
  return { type: APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_CHANGED, payload: userPreferences };
}

export function updateOnUserPreferencesChildRemoved(): UpdateOnUserPreferencesChildRemoved {
  return { type: APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_REMOVED };
}

export function updateFirstWeekDayChildAdded(firstWeekDay: number): UpdateFirstWeekDayChildAdded {
  return { type: APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_ADDED, payload: firstWeekDay };
}

export function updateFirstWeekDayChildChanged(firstWeekDay: number): UpdateFirstWeekDayChildChanged {
  return { type: APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_CHANGED, payload: firstWeekDay };
}

export function updateFirstWeekDayChildRemoved(): UpdateFirstWeekDayChildRemoved {
  return { type: APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_REMOVED };
}

export function updateJournalLayoutTypeChildAdded(type: number): UpdateJournalLayoutTypeChildAdded {
  return { type: APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_ADDED, payload: type };
}

export function updateJournalLayoutTypeChildChanged(firstWeekDay: number): UpdateJournalLayoutTypeChildChanged {
  return { type: APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_CHANGED, payload: firstWeekDay };
}

export function updateJournalLayoutTypeChildRemoved(): UpdateJournalLayoutTypeChildRemoved {
  return { type: APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_REMOVED };
}

function createUserPreferencesPayload(dataSnapshot: firebase.database.DataSnapshot): UserPreferences {
  const userPreferences: UserPreferences = {
    timeOfDaySettings: {
      morningMinuteRange: {
        upperbound: 720,
        lowerbound: 0,
      },
      afternoonMinuteRange: {
        upperbound: 1080,
        lowerbound: 720,
      },
      eveningMinuteRange: {
        upperbound: 0,
        lowerbound: 1080,
      },
    },
  };
  const preferencesSnapshot = { ...dataSnapshot.val() };
  if (preferencesSnapshot) {
    const timeOfDaySettings = preferencesSnapshot?.timeOfDaySettings || preferencesSnapshot;
    if (timeOfDaySettings?.morningMinuteRange) {
      userPreferences.timeOfDaySettings.morningMinuteRange = timeOfDaySettings.morningMinuteRange;
    }
    if (timeOfDaySettings?.afternoonMinuteRange) {
      userPreferences.timeOfDaySettings.afternoonMinuteRange = timeOfDaySettings.afternoonMinuteRange;
    }
    if (timeOfDaySettings?.eveningMinuteRange) {
      userPreferences.timeOfDaySettings.eveningMinuteRange = timeOfDaySettings.eveningMinuteRange;
    }
  }
  return userPreferences;
}

export function getUserPreferencesAction(uid: string) {
  return async (dispatch: Dispatch<GetUserPreferencesAction>): Promise<void | firebase.database.DataSnapshot> => {
    dispatch(getUserPreferences(true));
    const preferencesRef = db.ref(`preferences/${uid}`);
    return preferencesRef
      .once('value', (dataSnapshot) => {
        const snapshotMap = new Map();
        if (dataSnapshot.exists()) {
          const userPreferences = createUserPreferencesPayload(dataSnapshot) as UserPreferences;
          const sortJournalType = getDataSnapshotPreferencesChild(dataSnapshot, 'sortJournalType');
          const journalLayoutType = getDataSnapshotPreferencesChild(dataSnapshot, 'journalLayoutType');
          const firstWeekDay = getDataSnapshotPreferencesChild(dataSnapshot, 'firstWeekday');
          const _userPreferences = userPreferences as UserPreferences;
          dispatch(getUserPreferencesSuccess(_userPreferences));

          if (sortJournalType) {
            snapshotMap.set('sortJournalType', sortJournalType);
            dispatch(updateSortJournalType(sortJournalType));
          }

          if (journalLayoutType) {
            snapshotMap.set('journalLayoutType', journalLayoutType);
            dispatch(updateJournalLayoutTypeChildAdded(Number(journalLayoutType)));
          }

          if (firstWeekDay) {
            snapshotMap.set('firstWeekDay', firstWeekDay);
            dispatch(updateFirstWeekDayChildAdded(Number(firstWeekDay)));
          }
        }

        preferencesRef.on('child_added', (childSnapshot) => {
          if (childSnapshot.key) {
            const userPreferences = createUserPreferencesPayload(childSnapshot);
            if (!Object.keys(userPreferences).includes('timeOfDaySettings')) {
              dispatch(updateOnUserPreferencesChildAdded(userPreferences));
            }

            if (childSnapshot.key === 'firstWeekday' && !snapshotMap.has('firstWeekDay')) {
              const firstWeekDay: number = Number(childSnapshot.val()) || 0;
              dispatch(updateFirstWeekDayChildAdded(firstWeekDay));
            }

            if (childSnapshot.key === 'journalLayoutType' && !snapshotMap.has('journalLayoutType')) {
              const type: number = Number(childSnapshot.val()) || 0;
              dispatch(updateJournalLayoutTypeChildAdded(type));
            }

            if (childSnapshot.key === 'sortJournalType' && !snapshotMap.has('sortJournalType')) {
              dispatch(updateSortJournalType(childSnapshot.val() || ''));
            }
          }
        });

        preferencesRef.on('child_changed', (childSnapshot) => {
          if (childSnapshot.key) {
            if (childSnapshot.key === 'firstWeekday') {
              const firstWeekDay: number = Number(childSnapshot.val()) || 0;
              dispatch(updateFirstWeekDayChildChanged(firstWeekDay));
            } else if (childSnapshot.key === 'journalLayoutType') {
              const type: number = Number(childSnapshot.val()) || 0;
              dispatch(updateJournalLayoutTypeChildChanged(type));
            } else if (childSnapshot.key === 'sortJournalType') {
              const sortJournalType = childSnapshot.val() || '';
              dispatch(updateSortJournalType(sortJournalType));
            }else {
              const userPreferences = createUserPreferencesPayload(childSnapshot);
              dispatch(updateOnUserPreferencesChildChanged(userPreferences));
            }
          }
        });

        preferencesRef.on('child_removed', (childSnapshot) => {
          if (childSnapshot.key) {
            dispatch(updateOnUserPreferencesChildRemoved());
            if (childSnapshot.key === 'firstWeekday') {
              dispatch(updateFirstWeekDayChildRemoved());
            }
            if (childSnapshot.key === 'journalLayoutType') {
              dispatch(updateJournalLayoutTypeChildRemoved());
            }
            if (childSnapshot.key === 'sortJournalType') {
              snapshotMap.delete('sortJournalType');
              dispatch(updateSortJournalType(''));
            }
          }
        });
      })
      .finally(() => {
        dispatch(updatePreferencesLoaded(true));
      })
      .catch(() => {
        dispatch(getUserPreferencesFailed());
      });
  };
}

export function updateSignOutAlertStateAction(state: boolean): UpdateSignOutAlertState {
  return { type: APP_TYPES.UPDATE_SIGN_OUT_ALERT_STATE, payload: state };
}

export function selectMoodAction(mood: IMoodSelected): SelectMoodType {
  return { type: APP_TYPES.SELECT_MOOD, payload: mood };
}

export function submitMoodAction(mood: IMood, uid: string) {
  return async (): Promise<void | firebase.database.DataSnapshot> => {
    const moodRef = db.ref(`userMood/${uid}`);
    const { selectedCategories, ...rest } = mood;
    await moodRef.push(rest).then((snap) => {
      if (snap && snap.key) {
        moodRef.child(snap.key).child('selectedCategories').set(selectedCategories);
      }
    });
  };
}

export function updateMoodNoteAction(uid: string, moodId: string, notes: string) {
  return async (): Promise<void | firebase.database.DataSnapshot> => {
    const moodRef = db.ref(`userMood/${uid}/${moodId}`);
    await moodRef.child('notes').set(notes);
  };
}

export function getAllMood(isPending: boolean): GetMoodType {
  return { type: APP_TYPES.GET_MOOD, payload: isPending };
}

export function getAllMoodSuccess(moods: Map<string, IMood>): GetMoodSuccessType {
  return { type: APP_TYPES.GET_MOOD_SUCCESS, payload: moods };
}

export function getAllMoodFailed(): GetMoodFailedType {
  return { type: APP_TYPES.GET_MOOD_FAILED };
}

export function updateOnMoodChildAdded(moodId: string, mood: IMood, chooseDate: string): UpdateMoodLogChildAddedType {
  const newMoodWithId: MoodLogWithId = { moodId, mood, chooseDate };
  return { type: APP_TYPES.UPDATE_MOOD_LOG_CHILD_ADDED, payload: newMoodWithId };
}

export function updateOnMoodChildChanged(
  moodId: string,
  mood: IMood,
  chooseDate: string,
): UpdateMoodLogChildChangedType {
  const newMoodWithId: MoodLogWithId = { moodId, mood, chooseDate };
  return { type: APP_TYPES.UPDATE_MOOD_LOG_CHILD_CHANGED, payload: newMoodWithId };
}

export function updateOnMoodChildRemove(moodId: string): UpdateMoodLogChildRemoveType {
  return { type: APP_TYPES.UPDATE_MOOD_LOG_CHILD_REMOVE, payload: moodId };
}

export function getMoodLogAction(uid: string) {
  return async (dispatch: Dispatch<GetAllMoodType>): Promise<void | firebase.database.DataSnapshot> => {
    const moodRef = db.ref(`userMood/${uid}`);
    return moodRef.once('value', (dataSnapshot) => {
      let moodsMap: Map<string, IMood> = new Map<string, IMood>();
      if (dataSnapshot.exists()) {
        moodsMap = new Map<string, IMood>(Object.entries(dataSnapshot.val()));
      }
      dispatch(getAllMoodSuccess(moodsMap));
      moodRef.on('child_added', (childSnapshot) => {
        if (childSnapshot.key) {
          if (!moodsMap.has(childSnapshot.key)) {
            const moodId = childSnapshot.key;
            const chooseDate = store.getState().app.date;
            dispatch(updateOnMoodChildAdded(moodId, childSnapshot.val(), chooseDate));
          }
        }
      });

      moodRef.on('child_changed', (childSnapshot) => {
        if (childSnapshot.key) {
          const moodId = childSnapshot.key;
          const chooseDate = store.getState().app.date;
          dispatch(updateOnMoodChildChanged(moodId, childSnapshot.val(), chooseDate));
        }
      });

      moodRef.on('child_removed', (childSnapshot) => {
        const moodId = childSnapshot.key;
        if (moodId) {
          dispatch(updateOnMoodChildRemove(moodId));
          dispatch(updateDetailMoodIdAction(''));
        }
      });
    });
  };
}

export function filteringMood(isPending: boolean): FilteredMood {
  return { type: APP_TYPES.FILTERING_MOOD, payload: isPending };
}

export function filteringMoodSuccess(filteredMood: Map<string, IMood>, chooseDate: string): FilteredMoodSuccess {
  const filteredMoodWithDate: FilteredMoodWithDate = {
    filteredMood,
    chooseDate,
  };
  return { type: APP_TYPES.FILTERING_MOOD_SUCCESS, payload: filteredMoodWithDate };
}

export function filteringMoodFailed(): FilteredMoodFailed {
  return { type: APP_TYPES.FILTERING_MOOD_FAILED };
}

export function filterMoodByDateAction(moods: Map<string, IMood>, chooseDate: string) {
  return async (dispatch: Dispatch<FilteredMoodType>): Promise<void> => {
    dispatch(filteringMood(true));
    return new Promise<Map<string, IMood>>((resolve, reject) => {
      setTimeout(() => {
        try {
          const filteredMood: Map<string, IMood> = new Map<string, IMood>();
          for (const [moodId, mood] of Array.from(moods.entries())) {
            if (filterMoodByDate(mood, chooseDate)) {
              filteredMood.set(moodId, mood);
            }
          }
          resolve(filteredMood);
        } catch (e) {
          reject(e);
        }
      }, 0);
    })
      .then((filteredHabits) => {
        if (filteredHabits) {
          dispatch(filteringMoodSuccess(filteredHabits, chooseDate));
        }
      })
      .catch(() => {
        dispatch(filteringMoodFailed());
      });
  };
}

export function updateMoodContextMenuPropsAction(
  moodId: string,
  xPos: number,
  yPos: number,
  showMenu: boolean,
): UpdateMoodContextMenuProps {
  const moodContextMenuProps: IMoodContextMenu = {
    moodId,
    xPos,
    yPos,
    showMenu,
  };
  return { type: APP_TYPES.UPDATE_MOOD_CONTEXT_MENU_PROPS, payload: moodContextMenuProps };
}

export function deleteMood(uid: string, moodId: string) {
  return async (): Promise<void | firebase.database.DataSnapshot> => {
    const moodRef = db.ref(`userMood/${uid}/${moodId}`);
    await moodRef.remove();
  };
}
export function updateDetailHabitAction(habitId: string): UpdateDetailHabit {
  return { type: APP_TYPES.UPDATE_DETAIL_HABIT, payload: habitId };
}

export function updateDetailMoodIdAction(moodId: string): UpdateMoodIdType {
  return { type: APP_TYPES.UPDATE_DETAIL_MOOD_ID, payload: moodId };
}

export function updateUserEndpoint(token: string, uid: string) {
  return async (): Promise<void> => {
    const uuidDevice = deviceUUID();
    if (!!token && !!uid && !!uuidDevice) {
      try {
        const timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const deviceType = 'Web';
        const pushToken = token;
        const endPoints = {
          [uuidDevice]: {
            deviceType,
            timeZone,
            pushToken,
          },
        };
        return db.ref(`users/${uid}/endPoints`).update(endPoints);
      } catch (error) {
        console.error(error);
      }
    }
  };
}

export function removeUserEndpoint(uid: string) {
  return async (): Promise<void> => {
    const uuidDevice = deviceUUID();
    if (!!uid && !!uuidDevice) {
      db.ref(`users/${uid}/endPoints/${uuidDevice}`).remove();
    }
  };
}

export function updateUserLastActiveTime(uid: string) {
  return async (): Promise<void> => {
    if (!!uid) {
      return db.ref(`users/${uid}`).update({
        lastActiveTime: _dayjs().toISOString(),
      });
    }
  };
}

export function showJournalAnnouncement(status: boolean): ShowJournalAnnouncementType {
  return { type: APP_TYPES.SHOW_JOURNAL_ANNOUNCEMENT, payload: status };
}

export function changeJournalLayoutType(uid: string, type: number) {
  return async (): Promise<void | firebase.database.DataSnapshot> => {
    const moodRef = db.ref(`preferences/${uid}`);
    await moodRef.update({ journalLayoutType: type });
  };
}

export function updateSortJournalTypeToFb(uid: string, sortType: string) {
  return async (): Promise<void | firebase.database.DataSnapshot> => {
    await db.ref(`preferences/${uid}`).update({ sortJournalType: sortType });
  };
}

const getDataSnapshotPreferencesChild = (
  dataSnapshot: firebase.database.DataSnapshot,
  childSnapshotKey: string,
): string => {
  const dataSnapshotVal = dataSnapshot.val();
  return dataSnapshotVal[childSnapshotKey];
};

const deviceUUID = (): string => {
  const du = new DeviceUUID().parse();
  const dua = [
    du.browser,
    du.platform,
    du.os,
    du.cpuCores,
    du.isAuthoritative,
    du.silkAccelerated,
    du.isKindleFire,
    du.isDesktop,
    du.isMobile,
    du.isTablet,
    du.isWindows,
    du.isLinux,
    du.isLinux64,
    du.isMac,
    du.isiPad,
    du.isiPhone,
    du.isiPod,
    du.isSmartTV,
    du.pixelDepth,
    du.isTouchScreen,
  ];
  return du.hashMD5(dua.join(':'));
};

export function resetStateAction(): ResetDataType {
  return { type: APP_TYPES.RESET_DATA };
}
