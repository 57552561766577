import { InputGroup, Popover, PopoverTrigger } from "@chakra-ui/react";
import InputField from "components/common/input-field/input-field.component";
import { useThemeData } from "hooks/useThemeData";
import { NewDispatch, NewRootState } from "store";
import React, { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import GeneralSettingItem from "../general-setting-item.component";
import { useTranslation } from "react-i18next";

const JournalLayout: React.FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch<NewDispatch>();
    const { typography } = useThemeData();
    const isOpen = useSelector(
        (state: NewRootState) => state.appSettingModel.isOpenJournalLayout
    );
    const textWithIcon = t('settings.withIcon')

    const handleOpenMenu = useCallback(() => {
        dispatch.appSettingModel.openJournalLayout(!isOpen);
    }, [dispatch.appSettingModel, isOpen]);

    const closeMenu = useCallback(() => {
        dispatch.appSettingModel.openJournalLayout(false);
    }, [dispatch.appSettingModel]);

    return (
        <GeneralSettingItem
            title={t("settings.layout")}
            subTitle={t("settings.journallayoutSubtitle")}
        >
            <Popover
                matchWidth
                placement="bottom-start"
                isOpen={isOpen}
                onClose={closeMenu}
                closeOnBlur
            >
                <PopoverTrigger>
                    <InputGroup maxW="109px">
                        <InputField
                            cursor="default"
                            inputType="default"
                            isReadOnly
                            value={ textWithIcon }
                            isDisabled={true}
                            isStateDisabled={true}
                            {...typography.emphasized.callout}
                            textTransform="capitalize"
                            isOpen={isOpen}
                            h="30px"
                            onClick={handleOpenMenu}
                        />
                    </InputGroup>
                </PopoverTrigger>
            </Popover>
        </GeneralSettingItem>
    );
};
export default JournalLayout;
