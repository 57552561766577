import { createStandaloneToast } from '@chakra-ui/react';
import { createModel } from '@rematch/core';
import { auth } from 'firebase-v9';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { RootModel } from '../../root.model';
import {
  AuthenticationMethodReducers,
  AuthenticationMethodState,
  ErrorPayload,
  initialState,
} from './authentication.type';

const initState: AuthenticationMethodState = {
  loading: false,
  success: false,
  failed: false,
  error: null,
};

const googleAuthenticationReducers: AuthenticationMethodReducers = {
  onLoading(state) {
    return {
      ...state,
      loading: true,
    };
  },
  onSuccess(state) {
    return {
      ...state,
      loading: false,
      success: true,
    };
  },
  onFailed(state, payload: ErrorPayload) {
    return {
      ...state,
      loading: false,
      failed: true,
      error: payload,
    };
  },
  onSignOut() {
    return initialState;
  },
};

const toast = createStandaloneToast();
const provider = new GoogleAuthProvider();

export const showPopupSignInFail = (reason: ErrorPayload) => {
  if (reason.code !== 'popup_closed_by_user' && reason.code !== 'auth/popup-closed-by-user') {
    toast({
      position: 'top-right',
      title: 'Sign in with Google Account failed.',
      description: reason.message,
      duration: 3000,
      status: 'error',
    });
  }
};

export const googleAuthenticationModel = createModel<RootModel>()({
  state: initState,
  reducers: googleAuthenticationReducers,
  effects: (dispatch) => ({
    signInWithGoogle() {
      return signInWithPopup(auth, provider);
    },
  }),
});
