import { AUTHENTICATION_ACTION_TYPES, SignOutActionType } from './auth.type';

/**
 * Initial States
 */

/**
 * Reducer
 * @param {IAuthState} state @default authInitialState The previous states of the store.
 * @param {IAction} action The dispatched action. Contain a type and a payload.
 * @returns {IAuthState} Return the next state after the mutation.
 */

export type AuthReducerActionType = SignOutActionType;

const authReducer = (state = {}, action: AuthReducerActionType): {} => {
  switch (action.type) {
    case AUTHENTICATION_ACTION_TYPES.SIGN_OUT:
      return {};
    case AUTHENTICATION_ACTION_TYPES.SIGN_OUT_FAILED:
      return {
        ...state,
        signOutFailed: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
