import { database } from 'firebase-v9';
import { ref, update } from 'firebase/database';
import { Habit } from 'models/habits';
import { Lexorank } from './lexorank';

export const MAX_DOUBLE = Math.pow(2.0, 100);
export const MIN_DOUBLE = -1 * MAX_DOUBLE;

function updateHabitPriority(uid: string, habitId: string, newPriority: number): void {
  const habitRef = ref(database, `habits/${uid}/${habitId}`);
  update(habitRef, {
    priority: newPriority,
  });
}

function updateHabitPriorityByArea(uid: string, habitId: string, newPriority: string): void {
  const habitRef = ref(database, `habits/${uid}/${habitId}`);
  update(habitRef, {
    priorityByArea: newPriority,
  });
}

export function updateMissingPriorityList(uid: string | null | undefined, habits: Habit[]): Habit[] {
  let previous = 0;
  return habits.map((habit, i) => {
    let newPriority = 0;
    const habitId = habit.id;
    const current = habit.priority;
    if (habitId) {
      if (current === undefined || current === null) {
        if (i === 0) {
          newPriority = (MIN_DOUBLE + MAX_DOUBLE) / 2;
          previous = newPriority;
        } else {
          if (previous === 0) {
            newPriority = MIN_DOUBLE / 2;
          }
          if (previous) {
            newPriority = (MIN_DOUBLE + previous) / 2;
          }
          previous = newPriority;
        }
        if (uid) {
          updateHabitPriority(uid, habitId, newPriority);
        }
      } else {
        previous = current;
      }
    }
    return habit;
  });
}

export function updateMissingPriority(uid: string, sortedHabits: Habit[]): void {
  if (sortedHabits.length) {
    const arrayLength = sortedHabits.length;
    let previous = 0;
    for (let i = 0; i < arrayLength; i += 1) {
      let newPriority = 0;
      const habitId = sortedHabits[i].id;
      const current = sortedHabits[i].priority;
      if (habitId) {
        if (!current) {
          if (i === 0) {
            newPriority = (MIN_DOUBLE + MAX_DOUBLE) / 2;
            previous = newPriority;
          } else {
            if (previous === 0) {
              newPriority = MIN_DOUBLE / 2;
            }
            if (previous) {
              newPriority = (MIN_DOUBLE + previous) / 2;
            }
            previous = newPriority;
          }
          if (uid) {
            updateHabitPriority(uid, habitId, newPriority);
          }
        }
      }
    }
  }
}

export function updateMissingHabitInAreaPriority(uid: string, sortedHabits: Habit[]): void {
  if (sortedHabits.length) {
    const arrayLength = sortedHabits.length;
    let previous = '';
    for (let i = 0; i < arrayLength; i += 1) {
      let newPriority = '';
      const habitId = sortedHabits[i].id;
      const current = sortedHabits[i].priorityByArea;
      if (habitId) {
        if (!current) {
          if (i === 0) {
            newPriority = Lexorank.insert('', '')[0];
            previous = newPriority;
          } else {
            if (previous === '') {
              newPriority = Lexorank.insert('', '')[0];
            }
            if (previous) {
              newPriority = Lexorank.insert(previous, '')[0];
            }
            previous = newPriority;
          }
          if (uid) {
            updateHabitPriorityByArea(uid, habitId, newPriority);
          }
        }
      }
    }
  }
}

export function rearrangementHabit({
  uid,
  habitId,
  nextPriority,
  previousPriority,
}: {
  uid: string;
  habitId: string;
  previousPriority: number | null | undefined;
  nextPriority: number | null | undefined;
}) {
  let newPriority = 0;
  if (
    previousPriority !== undefined &&
    previousPriority !== null &&
    nextPriority !== undefined &&
    nextPriority !== null
  ) {
    newPriority = (previousPriority + nextPriority) / 2;
  } else if (
    (previousPriority === undefined || previousPriority === null) &&
    nextPriority !== undefined &&
    nextPriority !== null
  ) {
    newPriority = (MIN_DOUBLE + nextPriority) / 2;
  } else if (
    previousPriority !== undefined &&
    previousPriority !== null &&
    (nextPriority === undefined || nextPriority === null)
  ) {
    newPriority = (previousPriority + MAX_DOUBLE) / 2;
  } else {
    newPriority = (MIN_DOUBLE + MAX_DOUBLE) / 2;
  }

  updateHabitPriority(uid, habitId, newPriority);
}

export function rearrangementHabitInHabitFolder({
  uid,
  habitId,
  previousPriority,
  nextPriority,
}: {
  uid: string;
  habitId: string;
  previousPriority: string | null | undefined;
  nextPriority: string | null | undefined;
}) {
  let newPriority = '';
  if (
    previousPriority !== undefined &&
    previousPriority !== null &&
    nextPriority !== undefined &&
    nextPriority !== null
  ) {
    newPriority = Lexorank.insert(previousPriority, nextPriority)[0];
  } else if (
    (previousPriority === undefined || previousPriority === null) &&
    nextPriority !== undefined &&
    nextPriority !== null
  ) {
    newPriority = Lexorank.insert('', nextPriority)[0];
  } else if (
    previousPriority !== undefined &&
    previousPriority !== null &&
    (nextPriority === undefined || nextPriority === null)
  ) {
    newPriority = Lexorank.insert(previousPriority, '')[0];
  } else {
    newPriority = Lexorank.insert('', '')[0];
  }

  updateHabitPriorityByArea(uid, habitId, newPriority);
}

export function sortByPriorityUtil(priorityA: number | null | undefined, priorityB: number | null | undefined): number {
  if (priorityA && priorityB) {
    return priorityB - priorityA;
  } else if (priorityA !== undefined && priorityB === undefined) {
    return -1;
  } else if (priorityA === undefined && priorityB !== undefined) {
    return 1;
  }
  return 1;
}

export function sortByAreaPriorityUtil(
  priorityA: number | null | undefined,
  priorityB: number | null | undefined,
): number {
  if (priorityA && priorityB) {
    return Number(priorityA < priorityB) - Number(priorityA > priorityB);
  } else if (priorityA && !priorityB) {
    return -1;
  } else if (!priorityA && priorityB) {
    return 1;
  }
  return 1;
}
