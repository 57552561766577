import {
  BAD_HABIT_GOAL_TYPE,
  BAD_HABIT_GOAL_VALUE,
  HABIT_PROGRESS,
  HABIT_PROGRESS_PRIORITY,
  HABIT_TYPE,
  REGULARLY,
  TIME_OF_DAY_BITWISE,
} from '__archived__/types/enum/habit';
import { IHabit, IHabitCheckins, IHabitCurrentStreak, IHabitGoal, IHabitLogs } from '__archived__/types/states';
import { convert, getBaseUnitFromType, getType } from './SIUnitUtils';
import { combineTimeOfDay } from './combineTimeOfDay';
import { HABIT_GOAL_PERIODICITY } from '__archived__/types/enum';
import { UNIT_SYMBOL } from '__archived__/types/enum/SIBaseUnit';
import { HABIT_PROGRESS_FILTER } from '__archived__/types/enum/app';
import { _dayjs, MAX_DOUBLE, MIN_DOUBLE, today } from '__archived__/constants/app';
import { IHabitNewGoal, IHabitRemind } from '__archived__/types/states/habit';
import firebase from '__archived__/firebase-compat/index';
import { habitGoalCurrent, listHabitGoal } from '__archived__/utils/goalUtils';
import dayjs from 'dayjs';
import removeDiacritics from './removeDiacritics';
import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';

const db = firebase.database();

const getHabitCheckins = (checkins: IHabitCheckins | undefined): string[] => {
  if (checkins) {
    return Object.keys(checkins);
  }
  return [];
};

const isGoalHabit = (goal: IHabitGoal | string | undefined): goal is IHabitGoal => {
  if (goal) {
    return (goal as IHabitGoal).periodicity !== undefined;
  }
  return false;
};

const isOneTimePerDayHabit = (periodicity: string, symbol: string, value: number): boolean => {
  return periodicity === HABIT_GOAL_PERIODICITY.DAILY && value === 1 && symbol === UNIT_SYMBOL.REP;
};

const isDailyHabit = (regularly: string): boolean => {
  return regularly.includes(REGULARLY.DAILY);
};

const isWeekDaysHabit = (regularly: string, chooseDate: string): boolean => {
  const weekday = _dayjs(chooseDate, 'DDMMYYYY').format('ddd').toLowerCase();
  let regularlyValue = '';
  let regularlyArray: string[] = [];
  if (regularly.includes(REGULARLY.WEEKDAYS)) {
    regularlyValue = regularly.replace(REGULARLY.WEEKDAYS, '');
    regularlyArray = regularlyValue.trim().split(',');
    return regularlyArray.indexOf(weekday) > -1;
  }
  return false;
};

const isMonthDaysHabit = (regularly: string, chooseDate: string): boolean => {
  const monthday = _dayjs(chooseDate, 'DDMMYYYY').format('D');

  let regularlyValue = '';
  let regularlyArray: string[] = [];
  if (regularly.includes(REGULARLY.MONTHDAYS)) {
    regularlyValue = regularly.replace(REGULARLY.MONTHDAYS, '');
    regularlyArray = regularlyValue.trim().split(',');
    return regularlyArray.indexOf(monthday) > -1;
  }
  return false;
};

const isIntervalsHabit = (regularly: string, startDate: number, chooseDate: string): boolean => {
  const chooseDateConverted = _dayjs(chooseDate, 'DDMMYYYY');
  const startDateFormatted = _dayjs(startDate * 1000).format('YYYY-MM-DD');
  let regularlyValue = '';
  if (regularly.includes(REGULARLY.INTERVAL)) {
    regularlyValue = regularly.replace(REGULARLY.INTERVAL, '');
    const interval = Number(regularlyValue.trim());
    const diffDays = chooseDateConverted.diff(_dayjs(startDateFormatted), 'days');
    return diffDays % interval === 0;
  }

  return false;
};

export function getDaysByRangeDate(
  rangeDate: string,
  dateSelected: dayjs.Dayjs,
  firstDayOfWeek: FirstWeekDay,
  habit?: any,
): Days {
  let days: Days = {};
  let start: dayjs.Dayjs;
  let end: dayjs.Dayjs;
  let day: dayjs.Dayjs;
  switch (rangeDate) {
    case HABIT_GOAL_PERIODICITY.WEEKLY:
      start = dateSelected.startOf('week');
      end = dateSelected.endOf('week');
      if (firstDayOfWeek === 'monday') {
        start = dateSelected.add(-1, 'day').startOf('week').add(1, 'day');
        end = dateSelected.add(-1, 'day').endOf('week').add(1, 'day')
      }
      day = start;
      while (day <= end) {
        Object.assign(days, {
          [`${_dayjs(day.toDate()).format('DDMMYYYY')}`]: _dayjs(day.toDate()).format('DDMMYYYY'),
        });
        day = day.clone().add(1, 'day');
      }

      break;
    case HABIT_GOAL_PERIODICITY.MONTHLY:
      start = dateSelected.startOf('month');
      end = dateSelected.endOf('month');

      day = start;
      while (day <= end) {
        Object.assign(days, {
          [`${_dayjs(day.toDate()).format('DDMMYYYY')}`]: _dayjs(day.toDate()).format('DDMMYYYY'),
        });
        day = day.clone().add(1, 'day');
      }
      break;
    case HABIT_GOAL_PERIODICITY.DAILY:
      days = {
        [`${_dayjs(dateSelected).format('DDMMYYYY')}`]: _dayjs(dateSelected).format('DDMMYYYY'),
      };
      break;
  }
  return days;
}

function computeProgressHabitLog(
  goalValue: number,
  periodicity: string,
  symbol: string,
  habitLog: IHabitLogs,
  dateSelected: string,
  timesTampsHabitStartDate: number,
  isBadHabit: boolean | number | undefined,
  habit: any,
): number {
  const days = getDaysByRangeDate(periodicity, _dayjs(dateSelected), habit);
  const goalType = getType(symbol);
  const _habitLog = Array.from(Object.values(habitLog)).filter((item) => {
    const startAtFormatted = _dayjs(item.startAt).format('DDMMYYYY');
    const isPassDateRange: boolean = dayjs(item.startAt).valueOf() >= timesTampsHabitStartDate;
    return startAtFormatted === days[startAtFormatted] && goalType === getType(item.unitSymbol) && isPassDateRange;
  });
  const baseUnitSymbol = getBaseUnitFromType(goalType);
  const convertedGoalValue = convert(symbol, baseUnitSymbol, goalValue);
  if (_habitLog && Object.keys(_habitLog).length > 0) {
    let logValue = 0;
    for (let i = 0; i < _habitLog.length; i++) {
      if (_habitLog[i].unitSymbol === baseUnitSymbol) {
        const timestampOfStartAtLog = _dayjs(_habitLog[i].startAt).valueOf();
        const timestampEndDayOfDateSelected = _dayjs(dateSelected)
          .add(23, 'hour')
          .add(59, 'minute')
          .add(59, 'second')
          .valueOf();
        if (isBadHabit && timestampOfStartAtLog <= timestampEndDayOfDateSelected) {
          logValue += typeof _habitLog[i].value === 'number' ? _habitLog[i].value : 0;
        }
        if (!isBadHabit) {
          logValue += typeof _habitLog[i].value === 'number' ? _habitLog[i].value : 0;
        }
      }
    }
    let progress = 0;
    if (convertedGoalValue !== 0) {
      progress = (logValue / convertedGoalValue) * 100;
    }
    if (!convertedGoalValue && logValue > 0) {
      progress = 100;
    }

    return progress;
  } else {
    return 0;
  }
}

type Days = {
  [key: string]: string;
};

export function calculateHabitProgress(
  habit: IHabit | undefined,
  habitLog: IHabitLogs | undefined,
  chooseDate: string,
  firstDayOfWeek: FirstWeekDay,
): IHabitCurrentStreak {
  const habitCurrentProgress: IHabitCurrentStreak = {
    isNoGoalHabit: false,
    isOneTimePerDayHabit: false,
    habitActualLogValue: 0,
    habitGoalValue: 0,
    currentStreak: 0,
    habitGoalPeriodicity: '',
    habitGoalSymbol: '',
    habitProgress: HABIT_PROGRESS.NONE,
    habitProgressPriority: HABIT_PROGRESS_PRIORITY.NONE,
  };
  if (habit) {
    const { goal, goals, checkins, regularly, startDate, habitType } = habit;
    const startDateFormatted = _dayjs(startDate * 1000).format('YYYY-MM-DD');

    const habitGoalList = listHabitGoal(goal, goals, startDate, firstDayOfWeek);
    const allCheckins = getHabitCheckins(checkins);
    const allLogValues: string[] = [];
    const dateToBadHabitCompleteByLogValueMap: Map<string, number> = new Map<string, number>();
    const isBadHabit = habitType && habitType === HABIT_TYPE.BAD;
    const badHabitLogValueMap: Map<string, number> = new Map<string, number>();

    if (!isGoalHabit(goal) && !goals) {
      habitCurrentProgress.isNoGoalHabit = true;
      habitCurrentProgress.habitGoalPeriodicity = HABIT_GOAL_PERIODICITY.DAILY;
      habitCurrentProgress.habitGoalSymbol = UNIT_SYMBOL.REP;
      habitCurrentProgress.habitGoalValue = 1;
      if (habit.habitType === HABIT_TYPE.BAD) habitCurrentProgress.badHabitGoalType = BAD_HABIT_GOAL_TYPE.QUIT;
    }
    if (isGoalHabit(goal) || goals) {
      const latestHabitGoal: IHabitNewGoal | null | undefined = habitGoalCurrent(habitGoalList, chooseDate);
      if (!latestHabitGoal) {
        habitCurrentProgress.isNoGoalHabit = true;
        habitCurrentProgress.habitGoalPeriodicity = HABIT_GOAL_PERIODICITY.DAILY;
        habitCurrentProgress.habitGoalSymbol = UNIT_SYMBOL.REP;
        habitCurrentProgress.habitGoalValue = 1;
        if (habit.habitType === HABIT_TYPE.BAD) habitCurrentProgress.badHabitGoalType = BAD_HABIT_GOAL_TYPE.QUIT;
      }
      if (latestHabitGoal && latestHabitGoal.periodicity && latestHabitGoal.unit) {
        const goalValue = latestHabitGoal.value || 0;
        const periodicity = latestHabitGoal.periodicity;
        const symbol = latestHabitGoal.unit.symbol;
        habitCurrentProgress.habitGoalValue = goalValue;
        habitCurrentProgress.habitGoalPeriodicity = periodicity;
        habitCurrentProgress.habitGoalSymbol = symbol;
        if (habit.habitType === HABIT_TYPE.BAD) {
          habitCurrentProgress.badHabitGoalType =
            goalValue < BAD_HABIT_GOAL_VALUE.NO_MORE_THAN ? BAD_HABIT_GOAL_TYPE.QUIT : BAD_HABIT_GOAL_TYPE.NO_MORE_THAN;
        }

        if (isOneTimePerDayHabit(periodicity, symbol, goalValue)) habitCurrentProgress.isOneTimePerDayHabit = true;
        if (habitLog) {
          const timesTampsHabitStartDate: number = dayjs(dayjs(habit.startDate * 1000).format('YYYY-MM-DD')).valueOf();
          const progressCurrent = computeProgressHabitLog(
            goalValue,
            periodicity,
            symbol,
            habitLog,
            chooseDate,
            timesTampsHabitStartDate,
            isBadHabit,
            habit,
          );

          const getValueCurrent =
            isBadHabit && progressCurrent && !goalValue ? 100 : (progressCurrent * goalValue) / 100; // add case bad habit had goal is no more than

          habitCurrentProgress.habitActualLogValue = Math.round(getValueCurrent * 100) / 100;
          let logValue = 0;
          const habitLogFilterChecking = new Map();
          let startAtFormatted = '';

          for (const [, log] of Object.entries(habitLog)) {
            const { startAt, value, unitSymbol } = log;
            const timesTampsHabitLog: number = dayjs(startAt).valueOf();
            if (timesTampsHabitLog < timesTampsHabitStartDate) {
              continue;
            }
            startAtFormatted = _dayjs(startAt).format('YYYY-MM-DD');
            if (_dayjs(startAtFormatted).valueOf() >= _dayjs(startDateFormatted).valueOf()) {
              const startAtDate = _dayjs(startAt).format('DDMMYYYY');
              const habitGoal = habitGoalCurrent(habitGoalList, _dayjs(startAt).format('YYYY-MM-DD'));
              if (habitGoal && habitGoal.periodicity && habitGoal.unit) {
                const localGoalValue = habitGoal.value || 0;
                const localPeriodicity = habitGoal.periodicity;
                const localSymbol = habitGoal.unit.symbol;
                const baseUnitSymbol = getBaseUnitFromType(getType(unitSymbol));
                if (
                  getType(unitSymbol) === getType(localSymbol) &&
                  unitSymbol === baseUnitSymbol &&
                  typeof value === 'number'
                ) {
                  if (localPeriodicity === HABIT_GOAL_PERIODICITY.DAILY) {
                    if (habitLogFilterChecking.get(startAtDate)) {
                      logValue = habitLogFilterChecking.get(startAtDate) + value;
                    } else {
                      logValue = value || 0;
                    }
                    habitLogFilterChecking.set(startAtDate, logValue);
                    const convertedValue = Math.round(convert(unitSymbol, localSymbol, logValue));
                    if (convertedValue >= localGoalValue) {
                      allLogValues.push(startAtDate);
                    }
                    if (habit.habitType === HABIT_TYPE.BAD) {
                      convertedValue <= localGoalValue
                        ? dateToBadHabitCompleteByLogValueMap.set(startAtDate, HABIT_PROGRESS.COMPLETE)
                        : dateToBadHabitCompleteByLogValueMap.set(startAtDate, HABIT_PROGRESS.FAILED);
                    }
                  } else {
                    allLogValues.push(startAtDate);
                    if (habit.habitType === HABIT_TYPE.BAD) {
                      const _dateKey = getDateKey(HABIT_GOAL_PERIODICITY.DAILY, startAt);
                      const _logValue = badHabitLogValueMap.has(_dateKey)
                        ? Number(badHabitLogValueMap.get(_dateKey)) + value
                        : value;
                      badHabitLogValueMap.set(_dateKey, _logValue);
                    }
                  }
                }
              }
            }
          }
          if (isBadHabit) {
            const isBeforeChosenDate = _dayjs(chooseDate).isBefore(_dayjs(), 'day');
            if (isBeforeChosenDate) {
              if (habitCurrentProgress.habitActualLogValue <= goalValue) {
                habitCurrentProgress.habitProgress = HABIT_PROGRESS.COMPLETE;
                habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.COMPLETE;
              } else {
                habitCurrentProgress.habitProgress = HABIT_PROGRESS.FAILED;
                habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.FAILED;
              }
            } else {
              if (habitCurrentProgress.habitActualLogValue <= goalValue) {
                habitCurrentProgress.habitProgress = HABIT_PROGRESS.NONE;
                habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.NONE;
              } else {
                habitCurrentProgress.habitProgress = HABIT_PROGRESS.FAILED;
                habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.FAILED;
              }
            }
          } else {
            if (habitCurrentProgress.habitActualLogValue && habitCurrentProgress.habitActualLogValue >= goalValue) {
              habitCurrentProgress.habitProgress = HABIT_PROGRESS.COMPLETE;
              habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.COMPLETE;
            } else {
              habitCurrentProgress.habitProgress = HABIT_PROGRESS.NONE;
              habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.NONE;
            }
          }
        } else {
          if (habitType && habitType === HABIT_TYPE.BAD) {
            const isBeforeChosenDate = _dayjs(chooseDate).isBefore(_dayjs(), 'day');
            if (isBeforeChosenDate) {
              habitCurrentProgress.habitProgress = HABIT_PROGRESS.COMPLETE;
              habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.COMPLETE;
            }
          }
        }
      }
    }
    const filteredCheckinAndLogValues = Array.from(new Set([...allCheckins, ...allLogValues]));
    filteredCheckinAndLogValues.sort(
      (a: string, b: string) =>
        +new Date(_dayjs(a, 'DDMMYYYY').format('YYYY-MM-DD')) - +new Date(_dayjs(b, 'DDMMYYYY').format('YYYY-MM-DD')),
    );

    const currentKey = _dayjs(chooseDate).format('DDMMYYYY');
    if (filteredCheckinAndLogValues.includes(currentKey)) {
      if (habitCurrentProgress.isNoGoalHabit || habitCurrentProgress.isOneTimePerDayHabit) {
        if (!isBadHabit) {
          habitCurrentProgress.habitProgress = HABIT_PROGRESS.COMPLETE;
          habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.COMPLETE;
        }
      }
      if (checkins) {
        if (checkins[currentKey] === HABIT_PROGRESS.NONE) {
          habitCurrentProgress.habitProgress = HABIT_PROGRESS.NONE;
          habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.NONE;
        }
        if (checkins[currentKey] === HABIT_PROGRESS.COMPLETE) {
          if (habitCurrentProgress.isNoGoalHabit) {
            habitCurrentProgress.habitActualLogValue = 1;
          }
          habitCurrentProgress.habitProgress = HABIT_PROGRESS.COMPLETE;
          habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.COMPLETE;
        }
        if (checkins[currentKey] === HABIT_PROGRESS.SKIP) {
          habitCurrentProgress.habitProgress = HABIT_PROGRESS.SKIP;
          habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.SKIP;
        }
        if (checkins[currentKey] === HABIT_PROGRESS.FAILED) {
          habitCurrentProgress.habitProgress = HABIT_PROGRESS.FAILED;
          habitCurrentProgress.habitProgressPriority = HABIT_PROGRESS_PRIORITY.FAILED;
          // return habitCurrentProgress;
        }
      }
    }

    const todayKey = today.format('DDMMYYYY');
    if (regularly && habitType !== HABIT_TYPE.BAD) {
      if (filteredCheckinAndLogValues.includes(todayKey)) {
        if (
          isDailyHabit(regularly) ||
          isWeekDaysHabit(regularly, todayKey) ||
          isMonthDaysHabit(regularly, todayKey) ||
          isIntervalsHabit(regularly, startDate, todayKey)
        ) {
          if (checkins && checkins[todayKey] && checkins[todayKey] === HABIT_PROGRESS.FAILED) {
            return habitCurrentProgress;
          }
          habitCurrentProgress.currentStreak = 1;
          if (checkins) {
            if (checkins[todayKey] === HABIT_PROGRESS.NONE) {
              habitCurrentProgress.currentStreak = 0;
            }
            if (checkins[todayKey] === HABIT_PROGRESS.SKIP) {
              habitCurrentProgress.currentStreak = 0;
            }
          }
        }
      }

      const shouldBePreviousDate = new Date();
      shouldBePreviousDate.setDate(shouldBePreviousDate.getDate() - 1);
      while (shouldBePreviousDate > new Date(startDateFormatted)) {
        const streakCheckinKey = _dayjs(shouldBePreviousDate).format('DDMMYYYY');
        if (
          isDailyHabit(regularly) ||
          isWeekDaysHabit(regularly, streakCheckinKey) ||
          isMonthDaysHabit(regularly, streakCheckinKey) ||
          isIntervalsHabit(regularly, startDate, streakCheckinKey)
        ) {
          if (filteredCheckinAndLogValues.includes(streakCheckinKey)) {
            if (checkins) {
              if (checkins[streakCheckinKey] === HABIT_PROGRESS.FAILED) {
                break;
              }
              if (
                checkins[streakCheckinKey] !== HABIT_PROGRESS.SKIP &&
                checkins[streakCheckinKey] !== HABIT_PROGRESS.NONE
              ) {
                habitCurrentProgress.currentStreak += 1;
              }
            } else {
              habitCurrentProgress.currentStreak += 1;
            }
          } else {
            break;
          }
        }
        shouldBePreviousDate.setDate(shouldBePreviousDate.getDate() - 1);
      }
    }

    // calculator current streak for bad habit
    if (habitType === HABIT_TYPE.BAD) {
      calculatorBadHabitCurrentStreak({
        habitCurrentProgress,
        checkins,
        startDateFormatted,
        chooseDate,
        startDate,
        dateToBadHabitCompleteByLogValueMap,
        habitGoalList,
        badHabitLogValueMap,
      });
    }
  }
  return habitCurrentProgress;
}

const calculatorBadHabitCurrentStreak = (parameters: {
  habitCurrentProgress: IHabitCurrentStreak;
  checkins: IHabitCheckins | undefined;
  startDateFormatted: string;
  chooseDate: string;
  startDate: number;
  dateToBadHabitCompleteByLogValueMap: Map<string, number>;
  habitGoalList: Map<string, IHabitNewGoal>;
  badHabitLogValueMap: Map<string, number>;
}) => {
  const {
    habitCurrentProgress,
    checkins,
    startDateFormatted,
    chooseDate,
    startDate,
    dateToBadHabitCompleteByLogValueMap,
    habitGoalList,
    badHabitLogValueMap,
  } = parameters;
  const todayKey = _dayjs().format('DDMMYYYY');
  const chooseDateKey = _dayjs(chooseDate).format('DDMMYYYY');
  const currentDate = new Date();
  let numberOfSkip = 0;
  currentDate.setDate(currentDate.getDate() - 1);

  habitCurrentProgress.currentStreak = 0;
  habitCurrentProgress.latestDayFailBadHabit = _dayjs(startDate * 1000);
  let streakComplete = 0;
  let streakFail = 0;
  let isBlockCurrentStreak = false;

  const todayGoal = habitGoalCurrent(habitGoalList, _dayjs().format('YYYY-MM-DD'));
  const badHabitStatusByChooseDate = isValidationStatusBadHabit({
    chooseDate,
    checkins,
    dateToBadHabitCompleteByLogValueMap,
    currentGoal: todayGoal,
    badHabitLogValueMap,
  });
  if (todayKey === chooseDateKey && badHabitStatusByChooseDate === HABIT_PROGRESS.COMPLETE)
    habitCurrentProgress.currentStreak = 1;
  if (badHabitStatusByChooseDate === HABIT_PROGRESS.COMPLETE) streakComplete = 1;
  if (badHabitStatusByChooseDate === HABIT_PROGRESS.FAILED) streakFail = 1;

  while (currentDate > new Date(startDateFormatted)) {
    const dateKey = _dayjs(currentDate).format('DDMMYYYY');
    const timestampsChooseDate = _dayjs(chooseDate).valueOf();
    const timestampsCurrentDate = _dayjs(currentDate).valueOf();
    const isChooseDateStatusInProgressAndSkip =
      badHabitStatusByChooseDate === HABIT_PROGRESS.IN_PROGRESS ||
      badHabitStatusByChooseDate === HABIT_PROGRESS.SKIP ||
      habitCurrentProgress.badHabitGoalType === BAD_HABIT_GOAL_TYPE.NO_MORE_THAN;

    const currentGoal = habitGoalCurrent(habitGoalList, _dayjs(currentDate).format('YYYY-MM-DD'));
    const badHabitStatusByCurrentDate = isValidationStatusBadHabit({
      chooseDate: _dayjs(currentDate).format('YYYY-MM-DD'),
      checkins,
      dateToBadHabitCompleteByLogValueMap,
      currentGoal,
      badHabitLogValueMap,
    });

    // Calculator current streak
    if (badHabitStatusByCurrentDate === HABIT_PROGRESS.FAILED) {
      isBlockCurrentStreak = true;
      habitCurrentProgress.latestDayFailBadHabit = _dayjs(dateKey, 'DDMMYYYY').add(1, 'days').format('YYYY-MM-DD');
      if (isChooseDateStatusInProgressAndSkip) {
        break;
      }
    }

    if (badHabitStatusByCurrentDate === HABIT_PROGRESS.SKIP) {
      if (!isBlockCurrentStreak) {
        numberOfSkip += 1;
      }
    }

    if (
      (badHabitStatusByCurrentDate === HABIT_PROGRESS.COMPLETE ||
        badHabitStatusByCurrentDate === HABIT_PROGRESS.NONE) &&
      !isBlockCurrentStreak
    ) {
      habitCurrentProgress.currentStreak += 1;
    }

    // Calculator description
    if (
      timestampsCurrentDate <= timestampsChooseDate &&
      habitCurrentProgress.badHabitGoalType === BAD_HABIT_GOAL_TYPE.QUIT
    ) {
      if (badHabitStatusByChooseDate === HABIT_PROGRESS.COMPLETE) {
        if (badHabitStatusByCurrentDate === HABIT_PROGRESS.COMPLETE) streakComplete += 1;
        if (badHabitStatusByCurrentDate === HABIT_PROGRESS.FAILED) {
          break;
        }
      }

      if (isChooseDateStatusInProgressAndSkip) {
        streakFail = 0;
        streakComplete = 0;
      }

      if (badHabitStatusByChooseDate === HABIT_PROGRESS.FAILED) {
        if (badHabitStatusByCurrentDate === HABIT_PROGRESS.FAILED) {
          if (streakComplete) {
            break;
          }
          streakFail += 1;
        }
        if (badHabitStatusByCurrentDate === HABIT_PROGRESS.COMPLETE) {
          if (streakFail > 1) {
            break;
          }
          streakComplete += 1;
        }
      }
    }

    currentDate.setDate(currentDate.getDate() - 1);
  }

  if (numberOfSkip) {
    const latestDate = _dayjs(habitCurrentProgress.latestDayFailBadHabit);
    const isSkipInStartDate = checkins && checkins[latestDate.format('DDMMYYYY')] === HABIT_PROGRESS.SKIP;
    const isDurationCurrent = latestDate.hour() || latestDate.minute() || latestDate.second();
    if (isDurationCurrent && isSkipInStartDate) {
      habitCurrentProgress.latestDayFailBadHabit = latestDate
        .add(numberOfSkip - 1, 'days')
        .add(23 - latestDate.hour(), 'hour')
        .add(59 - latestDate.minute(), 'minute')
        .add(60 - latestDate.second(), 'second');
    } else {
      habitCurrentProgress.latestDayFailBadHabit = latestDate.add(numberOfSkip, 'days');
    }
  }

  // Check chooseDate is tomorrow
  if (_dayjs(chooseDate).format('DDMMYYYY') === _dayjs().add(1, 'days').format('DDMMYYYY')) {
    const badHabitStatusByToday = isValidationStatusBadHabit({
      chooseDate: _dayjs().format('YYYY-MM-DD'),
      checkins,
      dateToBadHabitCompleteByLogValueMap,
      currentGoal: todayGoal,
      badHabitLogValueMap,
    });
    if (badHabitStatusByChooseDate === HABIT_PROGRESS.COMPLETE) {
      if (badHabitStatusByToday === HABIT_PROGRESS.IN_PROGRESS || badHabitStatusByToday === HABIT_PROGRESS.FAILED) {
        streakComplete = 1;
        streakFail = 0;
      } else {
        if (badHabitStatusByToday !== HABIT_PROGRESS.SKIP) streakComplete += 1;
      }
    }
    if (badHabitStatusByChooseDate === HABIT_PROGRESS.FAILED) {
      if (badHabitStatusByToday === HABIT_PROGRESS.FAILED) {
        streakFail += 1;
        streakComplete = 0;
      }
      if (badHabitStatusByToday === HABIT_PROGRESS.IN_PROGRESS && !streakComplete) {
        streakFail = 1;
      }
      if (badHabitStatusByToday === HABIT_PROGRESS.COMPLETE) {
        streakComplete += 1;
      }
    }
  }

  habitCurrentProgress.badHabitPastDaysStreak = {
    streakComplete,
    streakFail,
  };
};

const isValidationStatusBadHabit = (parameters: {
  chooseDate: string;
  checkins: IHabitCheckins | undefined;
  dateToBadHabitCompleteByLogValueMap: Map<string, number>;
  currentGoal: IHabitNewGoal | null | undefined;
  badHabitLogValueMap: Map<string, number>;
}) => {
  const { chooseDate, checkins, dateToBadHabitCompleteByLogValueMap, currentGoal, badHabitLogValueMap } = parameters;
  const periodicity = currentGoal?.periodicity;
  const chooseDateKey = _dayjs(chooseDate).format('DDMMYYYY');
  if (checkins) {
    if (checkins[chooseDateKey] === HABIT_PROGRESS.FAILED) return HABIT_PROGRESS.FAILED;
    if (checkins[chooseDateKey] === HABIT_PROGRESS.SKIP) return HABIT_PROGRESS.SKIP;
    if (checkins[chooseDateKey] === HABIT_PROGRESS.COMPLETE) return HABIT_PROGRESS.COMPLETE;
    if (checkins[chooseDateKey] === HABIT_PROGRESS.NONE) return HABIT_PROGRESS.NONE;
  }

  if (periodicity === HABIT_GOAL_PERIODICITY.DAILY) {
    const status = dateToBadHabitCompleteByLogValueMap.get(chooseDateKey);
    if (status === HABIT_PROGRESS.FAILED) return HABIT_PROGRESS.FAILED;
    if (status === HABIT_PROGRESS.COMPLETE) return HABIT_PROGRESS.COMPLETE;
    if (status === HABIT_PROGRESS.NONE) return HABIT_PROGRESS.NONE;
  }

  if (periodicity === HABIT_GOAL_PERIODICITY.WEEKLY || periodicity === HABIT_GOAL_PERIODICITY.MONTHLY) {
    const iso = periodicity === HABIT_GOAL_PERIODICITY.WEEKLY ? 'week' : 'month';
    const status = getHabitStatusByPerWeekAndMonth({
      startDateOfPer: _dayjs(chooseDate).startOf(iso),
      chooseDate: _dayjs(chooseDate),
      badHabitLogValueMap,
      currentGoal,
    });
    if (status === HABIT_PROGRESS.FAILED) return HABIT_PROGRESS.FAILED;
    if (status === HABIT_PROGRESS.COMPLETE) return HABIT_PROGRESS.COMPLETE;
    if (status === HABIT_PROGRESS.NONE) return HABIT_PROGRESS.NONE;
  }
  return chooseDateKey === _dayjs().format('DDMMYYYY') ? HABIT_PROGRESS.IN_PROGRESS : HABIT_PROGRESS.COMPLETE;
};

const getHabitStatusByPerWeekAndMonth = (parameters: {
  startDateOfPer: dayjs.Dayjs;
  chooseDate: dayjs.Dayjs;
  badHabitLogValueMap: Map<string, number>;
  currentGoal: IHabitNewGoal | null | undefined;
}) => {
  const { startDateOfPer, chooseDate, badHabitLogValueMap, currentGoal } = parameters;
  let currentDate = chooseDate;
  let logValue = 0;
  let habitStatus = HABIT_PROGRESS.COMPLETE;
  while (currentDate.valueOf() >= startDateOfPer.valueOf()) {
    const goalValue = currentGoal?.value || 0;
    logValue += badHabitLogValueMap.get(currentDate.format('DDMMYYYY')) || 0;
    if (logValue > goalValue) {
      habitStatus = HABIT_PROGRESS.FAILED;
      break;
    }
    currentDate = currentDate.subtract(1, 'days');
  }
  return habitStatus;
};

const getDateKey = (periodicity: string, startDate: string): string => {
  const _startDate = _dayjs(startDate);
  if (periodicity === HABIT_GOAL_PERIODICITY.WEEKLY) return `${_startDate.week()}-${_startDate.year()}`;
  // return `${_startDate.week()}-${_startDate.month()}-${_startDate.year()}`;
  if (periodicity === HABIT_GOAL_PERIODICITY.MONTHLY) return `${_startDate.month()}-${_startDate.year()}`;
  return _startDate.format('DDMMYYYY');
};

export function filterHabitByDate(habit: IHabit | undefined, chooseDate: string): boolean {
  const chooseDateConverted = _dayjs(chooseDate);
  const weekday = _dayjs(chooseDate).locale('en').format('ddd').toLowerCase();
  const monthday = _dayjs(chooseDate).locale('en').format('D').toString();
  if (habit) {
    const { isArchived, regularly, startDate, habitType } = habit;
    const startDateFormatted = _dayjs(startDate * 1000).format('YYYY-MM-DD');
    if (!isArchived) {
      if (chooseDateConverted.diff(startDateFormatted, 'day') < 0) {
        return false;
      } else {
        if (habitType && habitType === HABIT_TYPE.BAD) {
          return true;
        }
        let regularlyValue = '';
        let regularlyArray: string[] = [];
        if (regularly) {
          switch (true) {
            case regularly.includes(REGULARLY.DAILY):
              return true;
            case regularly.includes(REGULARLY.WEEKDAYS):
              regularlyValue = regularly.replace(REGULARLY.WEEKDAYS, '');
              regularlyArray = regularlyValue.trim().split(',');
              return regularlyArray.indexOf(weekday) > -1;
            case regularly.includes(REGULARLY.MONTHDAYS):
              regularlyValue = regularly.replace(REGULARLY.MONTHDAYS, '');
              regularlyArray = regularlyValue.trim().split(',');
              return regularlyArray.indexOf(monthday) > -1;
            case regularly.includes(REGULARLY.INTERVAL):
              regularlyValue = regularly.replace(REGULARLY.INTERVAL, '');
              const interval = Number(regularlyValue.trim());
              const diffDays = chooseDateConverted.diff(_dayjs(startDateFormatted), 'days');
              return diffDays % interval === 0;
            default:
              return true;
          }
        }
      }
    }
  }
  return false;
}

export function filterHabitByTimeOfDay(
  habits: Map<string, IHabit>,
  timeOfDayState: number,
): Promise<Map<string, IHabit>> {
  return new Promise<Map<string, IHabit>>((resolve, reject) => {
    const habitNewMap = new Map<string, IHabit>();
    try {
      habits.forEach((habit, habitId) => {
        const { timeOfDay } = habit;
        const timeOfDayCombineList = combineTimeOfDay(timeOfDay);
        if (
          timeOfDayState === TIME_OF_DAY_BITWISE.ALL ||
          timeOfDayCombineList.includes(timeOfDayState) ||
          timeOfDayCombineList.includes(TIME_OF_DAY_BITWISE.ALL)
        ) {
          habitNewMap.set(habitId, habit);
        }
      });
      resolve(habitNewMap);
    } catch (e) {
      reject(e);
    }
  });
}

export function filterHabitByFolderId(
  habits: Map<string, IHabit>,
  selectedFolderId: string,
): Promise<Map<string, IHabit>> {
  return new Promise<Map<string, IHabit>>((resolve, reject) => {
    const habitNewMap = new Map<string, IHabit>();
    try {
      habits.forEach((habit, habitId) => {
        if (habit?.targetFolderId === selectedFolderId || habit?.folderId === selectedFolderId) {
          habitNewMap.set(habitId, habit);
        }
      });
      resolve(habitNewMap);
    } catch (e) {
      reject(e);
    }
  });
}

function isValidByHabitProgressFilter(
  habitProgress: HABIT_PROGRESS | undefined,
  habitProgressFilter: HABIT_PROGRESS_FILTER,
) {
  switch (habitProgressFilter) {
    case HABIT_PROGRESS_FILTER.PENDING:
      return habitProgress === HABIT_PROGRESS.NONE;
    case HABIT_PROGRESS_FILTER.COMPLETED:
      return habitProgress === HABIT_PROGRESS.COMPLETE;
    case HABIT_PROGRESS_FILTER.SKIPPED:
      return habitProgress === HABIT_PROGRESS.SKIP;
    case HABIT_PROGRESS_FILTER.FAILED:
      return habitProgress === HABIT_PROGRESS.FAILED;
    default:
      return false;
  }
}

function isValidByHabitPeriodicityFilter(
  habitPeriodicity: string | undefined,
  habitPeriodicityFilter: HABIT_GOAL_PERIODICITY,
) {
  switch (habitPeriodicityFilter) {
    case HABIT_GOAL_PERIODICITY.DAILY:
      return habitPeriodicity === HABIT_GOAL_PERIODICITY.DAILY;
    case HABIT_GOAL_PERIODICITY.WEEKLY:
      return habitPeriodicity === HABIT_GOAL_PERIODICITY.WEEKLY;
    case HABIT_GOAL_PERIODICITY.MONTHLY:
      return habitPeriodicity === HABIT_GOAL_PERIODICITY.MONTHLY;
    default:
      return false;
  }
}

function isValidByHabitTypeFilter(habitType: number | undefined, habitTypeFilter: HABIT_TYPE) {
  if (!habitType) {
    habitType = HABIT_TYPE.GOOD;
  }
  switch (habitTypeFilter) {
    case HABIT_TYPE.BAD:
      return habitType === HABIT_TYPE.BAD;
    case HABIT_TYPE.GOOD:
      return habitType === HABIT_TYPE.GOOD;
    default:
      return false;
  }
}

type FilterHabitByProgressReturnType = {
  habitNewMap: Map<string, IHabit>;
  completeCount: number;
  skipCount: number;
  failCount: number;
};

export function filterHabitByProgress(
  habits: Map<string, IHabit>,
  habitCurrentStreaks: Map<string, IHabitCurrentStreak>,
  habitProgressFilter: HABIT_PROGRESS_FILTER,
): Promise<FilterHabitByProgressReturnType> {
  return new Promise<FilterHabitByProgressReturnType>((resolve, reject) => {
    const habitNewMap = new Map<string, IHabit>();
    let completeCount = 0;
    let skipCount = 0;
    let failCount = 0;
    try {
      habits.forEach((habit, habitId) => {
        const habitProgress = habitCurrentStreaks.get(habitId)?.habitProgress;
        if (habitProgress === HABIT_PROGRESS.COMPLETE) {
          completeCount += 1;
        }
        if (habitProgress === HABIT_PROGRESS.SKIP) {
          skipCount += 1;
        }
        if (habitProgress === HABIT_PROGRESS.FAILED) {
          failCount += 1;
        }
        if (isValidByHabitProgressFilter(habitProgress, habitProgressFilter)) {
          habitNewMap.set(habitId, habit);
        }
      });
      resolve({ habitNewMap, completeCount, skipCount, failCount });
    } catch (e) {
      reject(e);
    }
  });
}

export function filterHabitByPeriodicity(
  habits: Map<string, IHabit>,
  habitCurrentStreaks: Map<string, IHabitCurrentStreak>,
  habitPeriodicityFilter: HABIT_GOAL_PERIODICITY,
): Promise<Map<string, IHabit>> {
  return new Promise<Map<string, IHabit>>((resolve, reject) => {
    const habitNewMap = new Map<string, IHabit>();
    try {
      habits.forEach((habit, habitId) => {
        const habitPeriodicity = habitCurrentStreaks.get(habitId)?.habitGoalPeriodicity;
        if (isValidByHabitPeriodicityFilter(habitPeriodicity, habitPeriodicityFilter)) {
          habitNewMap.set(habitId, habit);
        }
      });
      resolve(habitNewMap);
    } catch (e) {
      reject(e);
    }
  });
}

export function filterHabitByType(
  habits: Map<string, IHabit>,
  habitTypeFilter: HABIT_TYPE,
): Promise<Map<string, IHabit>> {
  return new Promise<Map<string, IHabit>>((resolve, reject) => {
    const habitNewMap = new Map<string, IHabit>();
    try {
      habits.forEach((habit, habitId) => {
        const habitType = habit.habitType;
        if (isValidByHabitTypeFilter(habitType, habitTypeFilter)) {
          habitNewMap.set(habitId, habit);
        }
      });
      resolve(habitNewMap);
    } catch (e) {
      reject(e);
    }
  });
}

const updateHabitPriority = (uid: string, habitId: string, newPriority: number): void => {
  const habitRef = db.ref(`habits/${uid}/${habitId}`);
  habitRef.update({
    priority: newPriority,
  });
};

export function updateMissingPriority(uid: string, sortedHabits: Map<string, IHabit>): void {
  const sortedHabitsValueArray = Array.from(sortedHabits.entries());
  if (sortedHabitsValueArray.length) {
    const arrayLength = sortedHabitsValueArray.length;
    let previous = 0;
    for (let i = 0; i < arrayLength; i += 1) {
      let newPriority = 0;
      const habitId = sortedHabitsValueArray[i][0];
      const current = sortedHabitsValueArray[i][1].priority;
      if (current === undefined) {
        if (i === 0) {
          newPriority = (MIN_DOUBLE + MAX_DOUBLE) / 2;
          previous = newPriority;
        } else {
          if (previous === 0) {
            newPriority = MIN_DOUBLE / 2;
          }
          if (previous) {
            newPriority = (MIN_DOUBLE + previous) / 2;
          }
          previous = newPriority;
        }
        if (uid) {
          updateHabitPriority(uid, habitId, newPriority);
        }
      }
    }
  }
}

function sortByPriorityUtil(priorityA: number | undefined, priorityB: number | undefined): number {
  if (priorityA !== undefined && priorityB !== undefined) {
    return priorityB - priorityA;
  } else if (priorityA !== undefined && priorityB === undefined) {
    return -1;
  } else if (priorityA === undefined && priorityB !== undefined) {
    return 1;
  }
  return 1;
}

export function sortHabitByPriority(habits: Map<string, IHabit>): Map<string, IHabit> {
  return new Map<string, IHabit>(Array.from(habits).sort((a, b) => sortByPriorityUtil(a[1].priority, b[1].priority)));
}

function sortByAreaPriorityUtil(priorityA: string | undefined, priorityB: string | undefined): number {
  if (priorityA && priorityB) {
    return Number(priorityA < priorityB) - Number(priorityA > priorityB);
  } else if (priorityA && !priorityB) {
    return -1;
  } else if (!priorityA && priorityB) {
    return 1;
  }
  return 1;
}

export function sortHabitByAreaPriority(habits: Map<string, IHabit>): Map<string, IHabit> {
  return new Map<string, IHabit>(
    Array.from(habits).sort((a, b) => {
      return sortByAreaPriorityUtil(a[1].priorityByArea, b[1].priorityByArea);
    }),
  );
}

function sortByProgressPriorityUtil(priorityA: number | undefined, priorityB: number | undefined): number {
  if (priorityA !== undefined && priorityB !== undefined) {
    return priorityB - priorityA;
  } else if (priorityA !== undefined && priorityB === undefined) {
    return -1;
  } else if (priorityA === undefined && priorityB !== undefined) {
    return 1;
  }
  return 1;
}

function numberOfMinuteConvert(times: string) {
  const arr = times.split(':');
  const hour = Number(arr[0]);
  const minute = Number(arr[1]);
  return hour * 60 + minute;
}

function numberOfMinute(remind: IHabitRemind | undefined): number {
  const timeTriggers = remind?.timeTriggers as unknown as { [key: string]: boolean };
  const MAX_TIME = 1500; // max time of remind
  let timestampsInDay = MAX_TIME;
  if (timeTriggers) {
    if (Object.keys(timeTriggers).length > 1) {
      const times = Object.keys(timeTriggers).sort((time1, time2) => {
        const num1 = timeTriggers[time1] ? numberOfMinuteConvert(time1) : MAX_TIME;
        const num2 = timeTriggers[time2] ? numberOfMinuteConvert(time2) : MAX_TIME;
        return num1 - num2;
      });
      timestampsInDay = timeTriggers[times[0]] ? numberOfMinuteConvert(times[0]) : MAX_TIME;
    } else {
      timestampsInDay = timeTriggers[Object.keys(timeTriggers)[0]]
        ? numberOfMinuteConvert(Object.keys(timeTriggers)[0])
        : MAX_TIME;
    }
  }
  return timestampsInDay;
}

function sortByRemindTime(remind1: IHabitRemind | undefined, remind2: IHabitRemind | undefined) {
  const num1 = numberOfMinute(remind1);
  const num2 = numberOfMinute(remind2);
  if (num1 === num2) {
    return 1;
  }
  return num1 - num2;
}
export function sortHabitByRemindFromJournal(habits: Map<string, IHabit>): Map<string, IHabit> {
  return new Map<string, IHabit>(
    Array.from(habits).sort((a, b) => {
      return sortByRemindTime(a[1]?.remind, b[1]?.remind);
    }),
  );
}

function sortByAlphabetical(order: string, habitName1: string | undefined, habitName2: string | undefined) {
  const _habitName1 = habitName1 ? removeDiacritics(habitName1.toLowerCase().trim()) : '';
  const _habitName2 = habitName2 ? removeDiacritics(habitName2.toLowerCase().trim()) : '';

  let comparison = 0;
  if (_habitName1 > _habitName2) {
    comparison = 1;
  }
  if (_habitName1 < _habitName2) {
    comparison = -1;
  }

  return order === 'desc' ? comparison * -1 : comparison;
}

export function sortHabitByProgressPriority(
  habits: Map<string, IHabit>,
  habitCurrentStreaks: Map<string, IHabitCurrentStreak>,
): Map<string, IHabit> {
  return new Map<string, IHabit>(
    Array.from(habits).sort((a, b) => {
      return sortByProgressPriorityUtil(
        habitCurrentStreaks.get(a[0])?.habitProgressPriority,
        habitCurrentStreaks.get(b[0])?.habitProgressPriority,
      );
    }),
  );
}

export function sortHabitByAlphabeticalFromJournal(habits: Map<string, IHabit>, sortType: string): Map<string, IHabit> {
  return new Map<string, IHabit>(
    Array.from(habits).sort((a, b) => {
      return sortByAlphabetical(sortType, a[1]?.name, b[1]?.name);
    }),
  );
}

export const checkLimitUsage = (type: string, count: number, limit: number): boolean => {
  return count >= limit;
};
