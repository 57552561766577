import { useLayoutEffect } from 'react';

export const useSetWidthSingle = (el: Element | null) => {
    useLayoutEffect(() => {
        if(!el) return
        const elSidebar = document.querySelector(".sidebar") as HTMLElement;
        const elMainJournal = document.querySelector(
            ".main-journal"
        ) as HTMLElement;
        const elSingleProgress = el as HTMLElement | null;
        elSingleProgress &&
            elSingleProgress.setAttribute(
                "style",
                `width: calc(100% - ${elSidebar?.offsetWidth}px - ${elMainJournal?.offsetWidth}px)`
            );
    }, [el]);

};
