import { IAppResize, RESIZE_OPTIONS } from 'models/common/resize';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NewDispatch } from 'store';

export const useSidebarResize = (elReSize: Element | null): void => {
  const location = useLocation();
  const isScreenManageHabit = location.pathname.includes('manage-habits');
  const dispatch = useDispatch<NewDispatch>();

  useEffect(() => {
    const __elResize = elReSize ? elReSize : document.querySelector('.resize-sidebar');
    if (!__elResize) {
      return;
    }
    const elSidebar = document.querySelector('.sidebar');
    const elJournal = document.querySelector('.section-2');
    const elMainApp = document.querySelector('.main-app');
    let thirdSection = document.querySelector('.section-3');

    let clientX = 0;
    let widthSidebar = RESIZE_OPTIONS.DEFAULT_WIDTH_SIDEBAR;
    let widthSecond = RESIZE_OPTIONS.DEFAULT_WIDTH_JOURNAL;
    let widthThird = 0;

    const handleMouseMove = (e: MouseEvent) => {
      e.preventDefault();
      const deltaX = e.clientX - clientX;
      const actualWidthSidebar = widthSidebar + deltaX;
      const actualWidthSecondSection = widthSecond - deltaX;
      const stateZoom = Number(sessionStorage.getItem('state-zoom'));

      const isResizeFullScreenHabitDetail = stateZoom;
      const resize: IAppResize = { sidebar: actualWidthSidebar };

      // RESIZE SIDEBAR - MANAGE HABIT
      if (isScreenManageHabit) {
        elMainApp?.setAttribute(
          'style',
          `grid-template-columns: ${actualWidthSidebar}px ${actualWidthSecondSection}px auto`,
        );

        resize.secondSection = widthSecond - deltaX;
        localStorage.setItem('APP_RESIZE', JSON.stringify(resize));
      }

      // RESIZE SIDEBAR DEFAULT
      if (!isScreenManageHabit) {
        elMainApp?.setAttribute(
          'style',
          `grid-template-columns: ${actualWidthSidebar}px minmax(${RESIZE_OPTIONS.MIN_WIDTH_JOURNAL}px, ${
            widthSecond - deltaX
          }px) auto`,
        );

        resize.secondSection = widthSecond - deltaX;
        localStorage.setItem('APP_RESIZE', JSON.stringify(resize));
      }

      if (actualWidthSidebar < RESIZE_OPTIONS.MIN_WIDTH_SIDEBAR) {
        // CASE JOURNAL AND FULL SCREEN HABIT DETAIL
        if (isResizeFullScreenHabitDetail) {
          thirdSection?.setAttribute('style', `width: calc(100%  - ${RESIZE_OPTIONS.MIN_WIDTH_SIDEBAR}px)`);
        }
        elMainApp?.setAttribute(
          'style',
          `grid-template-columns:  ${RESIZE_OPTIONS.MIN_WIDTH_SIDEBAR}px minmax(${RESIZE_OPTIONS.MIN_WIDTH_JOURNAL}px, calc(100% - ${RESIZE_OPTIONS.MIN_WIDTH_SIDEBAR}px - ${widthThird}px)) auto`,
        );
        resize.sidebar = RESIZE_OPTIONS.MIN_WIDTH_SIDEBAR;
        resize.secondSection = window.innerWidth - RESIZE_OPTIONS.MIN_WIDTH_SIDEBAR - widthThird;
        localStorage.setItem('APP_RESIZE', JSON.stringify(resize));
        return;
      }

      // BLOCK WIDTH SIDEBAR === MAX
      if (actualWidthSidebar > RESIZE_OPTIONS.MAX_WIDTH_SIDEBAR) {
        // CASE JOURNAL AND FULL SCREEN HABIT DETAIL
        if (stateZoom) {
          thirdSection?.setAttribute('style', `width: calc(100%  - ${RESIZE_OPTIONS.MAX_WIDTH_SIDEBAR}px)`);
        }
        elMainApp?.setAttribute(
          'style',
          `grid-template-columns:  ${RESIZE_OPTIONS.MAX_WIDTH_SIDEBAR}px minmax(${RESIZE_OPTIONS.MIN_WIDTH_JOURNAL}px, calc(100% - ${RESIZE_OPTIONS.MAX_WIDTH_SIDEBAR}px - ${widthThird}px)) auto`,
        );
        resize.sidebar = RESIZE_OPTIONS.MAX_WIDTH_SIDEBAR;
        resize.secondSection = window.innerWidth - RESIZE_OPTIONS.MAX_WIDTH_SIDEBAR - widthThird;
        localStorage.setItem('APP_RESIZE', JSON.stringify(resize));
        return;
      }
    };

    __elResize?.addEventListener('mousedown', (event) => {
      const _event = event as MouseEvent;
      const _elSection3 = document.querySelector('.section-3');
      thirdSection = _elSection3;
      clientX = _event.clientX || _event.pageX;
      widthSidebar = elSidebar?.getBoundingClientRect().width || 0;
      widthSecond = elJournal?.getBoundingClientRect().width || 0;
      widthThird = thirdSection?.getBoundingClientRect().width || 0;
      document.addEventListener('mousemove', handleMouseMove);
    });

    document?.addEventListener('mouseup', (event) => {
      const _event = event as MouseEvent;
      clientX = _event.clientX || _event.pageX;
      document.removeEventListener('mousemove', handleMouseMove);
    });

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [dispatch.appModel, elReSize, isScreenManageHabit]);
};
