import { Grid } from '@chakra-ui/react';
import WarningModal from 'components/common/modal/warning-modal/warning-modal.component';
import { useThemeData } from 'hooks/useThemeData';
import unset from 'lodash/unset';
import { OffMode, OffModeSection } from 'models/off-mode';
import React, { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewDispatch, NewRootState } from 'store';
import { _dayjs } from 'tools/extended-dayjs';
import OffModeItem from './off-mode-item.component';

interface Props {
  section: OffModeSection;
  offModes: OffMode[];
}
const OffModeListItem: React.FC<Props> = ({ section, offModes }): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch<NewDispatch>();
  const { colorScheme } = useThemeData();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentOffMode, setCurrenOffMode] = useState<OffMode>();

  const uid = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const dateRanges = useSelector((state: NewRootState) => state.offModeModel.dateRanges);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const handleStopAndDeleteOffMode = useCallback(
    (offMode: OffMode) => {
      if (uid) {
        switch (section) {
          case 'upcoming':
            if (offMode.id) {
              dispatch.offModeModel.deleteOffMode({ uid, offModeId: offMode.id });
              const ranges = { ...dateRanges };
              unset(ranges, offMode.id);
              dispatch.offModeModel.getDateRangesOfOffModes({ ranges });
            }
            break;
          case 'current':
            if (offMode.id) {
              if (_dayjs(offMode.startDate).isToday()) {
                dispatch.offModeModel.deleteOffMode({ uid, offModeId: offMode.id });
                const ranges = { ...dateRanges };
                unset(ranges, offMode.id);
                dispatch.offModeModel.getDateRangesOfOffModes({ ranges });
              } else {
                setCurrenOffMode(offMode);
                openModal();
              }
            }
            break;
          default:
            break;
        }
      }
    },
    [dateRanges, dispatch.offModeModel, section, uid],
  );

  const handleStopOffMode = useCallback(() => {
    if (uid && currentOffMode?.id) {
      if (_dayjs(currentOffMode.startDate).isToday()) {
        dispatch.offModeModel.deleteOffMode({ uid, offModeId: currentOffMode.id });
      } else {
        dispatch.offModeModel.stopOffMode({ uid, offModeId: currentOffMode.id });
      }
    }
  }, [currentOffMode, dispatch.offModeModel, uid]);

  const renderOffModeItem = useCallback(() => {
    return (
      <>
        {offModes.map((offMode) => (
          <OffModeItem
            key={offMode.id}
            offMode={offMode}
            section={section}
            onClick={() => handleStopAndDeleteOffMode(offMode)}
          />
        ))}
      </>
    );
  }, [handleStopAndDeleteOffMode, offModes, section]);

  return (
    <>
      <Grid border={`1px solid ${colorScheme.other.border}`} borderRadius="5px" p="10px 12.5px" gridRowGap="20px">
        {renderOffModeItem()}
      </Grid>
      <WarningModal
        title={t('edithabit.deleteConfirmTitle')}
        subTitle={t('offmode.stopAlert')}
        labelBtnPrimary={t('common.ok')}
        labelBtnSecondary={t('common.close')}
        open={isOpenModal}
        closeModal={closeModal}
        onClick={handleStopOffMode}
        icon="ic-warning-yellow"
      />
    </>
  );
};
export default OffModeListItem;
