import { Box, ModalContent, Text, useColorModeValue } from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import TimeOfDay from 'components/common/modal/preferences-modal-layout/app-setting/time-of-day/time_of_day.app_settings.modal';
import { useCheckPremium } from 'hooks';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NewDispatch, NewRootState } from 'store';
import { pushAnalyticEvent } from '__archived__/utils/pushAnalyticEvent';
import PreferenceModalLayout from '../preferences-modal-layout.component';
import GeneralSetting from './general-setting.component';
import OffModeSetting from './off-mode-setting/off-mode-setting.component';
import ProfileSetting from './profile/profile.component';

const AppSettingModal: React.FC = (): ReactElement => {
  const dispatch = useDispatch<NewDispatch>();
  const { t } = useTranslation();
  const { colorScheme, typography } = useThemeData();
  const isPremium = useCheckPremium();
  const nonSelectedHoverColor = useColorModeValue(colorScheme.background.primary[3], colorScheme.background.primary[2]);
  const currentTab = useSelector((state: NewRootState) => state.appSettingModel.currentTab);
  const isOpenAppSettingModal = useSelector((state: NewRootState) => state.appSettingModel.isOpenAppSetting);

  const appSettingMenu = [
    {
      sectionTitle: t('settings.accountSettingsTitle'),
      menu: [
        {
          id: 'profile',
          title: t('common.profile'),
          iconName: 'ic_human',
        },
        {
          id: 'payment',
          title: t('settings.payment'),
          iconName: 'ic_wallet',
        },
      ],
    },
    {
      sectionTitle: t('settings.appSettings'),
      menu: [
        {
          id: 'general',
          title: t('common.general'),
          iconName: 'ic-app-settings',
          folderName: 'sidebar',
        },
        {
          id: 'timeOfDay',
          title: t('edithabit.timeOfDay'),
          iconName: 'ic_sun',
        },
        {
          id: 'offMode',
          title: t('offmode.settingOffModeTitle'),
          iconName: 'ic_coconut_tree',
          folderName: 'off-mode',
        },
      ],
    },
  ];

  useEffect(() => {
    if (currentTab === 'payment') {
      dispatch.paymentModel.openPaymentModal(true);
      dispatch.appSettingModel.openAppSetting(false);
    }
  }, [currentTab, dispatch.appSettingModel, dispatch.paymentModel]);

  const onCloseSetting = useCallback(() => {
    dispatch.appSettingModel.openAppSetting(false);
    dispatch.appSettingModel.changeTab('profile');
  }, [dispatch.appSettingModel]);

  const handleChangeTab = useCallback(
    (tab) => {
      // if (currentTab === tab) {
      //     dispatch.appSettingModel.changeTab("general");
      // } else {
      //     dispatch.appSettingModel.changeTab(tab);
      // }
      if (currentTab !== tab) {
        dispatch.appSettingModel.changeTab(tab);
      }
      if (tab === 'payment') {
        pushAnalyticEvent('UpgradeOpen');
      }
    },
    [currentTab, dispatch.appSettingModel],
  );

  const renderSettingSection = useCallback(() => {
    switch (currentTab) {
      case 'profile':
        return <ProfileSetting closeSetting={onCloseSetting} />;
      case 'timeOfDay':
        return <TimeOfDay closeSetting={onCloseSetting}></TimeOfDay>;
      case 'general':
        return <GeneralSetting closeSetting={onCloseSetting} />;
      case 'offMode':
        return <OffModeSetting closeSetting={onCloseSetting} />;
      default:
        break;
    }
  }, [currentTab, onCloseSetting]);

  return (
    <PreferenceModalLayout
      isCentered
      onClose={onCloseSetting}
      isOpen={isOpenAppSettingModal}
      motionPreset="slideInBottom"
    >
      <ModalContent borderRadius="9px">
        <Box display="flex" minH="482px" className="22323">
          <Box w="230px" bgColor={colorScheme.other.bgProfileNav} p="17px 13px 17px 12px" borderLeftRadius="8px">
            <Box>
              {appSettingMenu.map((section, index) => (
                <Box key={`${section.sectionTitle}-${index}`} mt={index !== 0 ? '20px' : '0'}>
                  <Text
                    {...typography.emphasized.caption[2]}
                    color={colorScheme.label.secondary}
                    mb="6px"
                    textTransform="uppercase"
                  >
                    {section.sectionTitle}
                  </Text>
                  {section.menu.map((item, index) => {
                    if (isPremium && item.id === 'payment') {
                      return null;
                    }
                    return (
                      <Box
                        key={`${item.iconName}-${index}`}
                        className="sidebar-item"
                        {...typography.normal.title[4]}
                        display="flex"
                        // alignItems="center"
                        alignItems="center"
                        p="8px"
                        height="36px"
                        role="group"
                        _hover={{
                          background: item.id === currentTab ? colorScheme.accent.primary : nonSelectedHoverColor,
                        }}
                        bgColor={item.id === currentTab ? colorScheme.accent.primary : 'none'}
                        borderRadius="5px"
                        onClick={() => {
                          handleChangeTab(item.id);
                        }}
                      >
                        <Box width="20px" height="20px">
                          <Icon
                            name={item.iconName}
                            folderName={item.folderName}
                            width={20}
                            height={20}
                            className={item.id === currentTab ? '' : 'sidebar-item-icon'}
                            fill={item.id === currentTab ? colorScheme.universal.white : colorScheme.label.secondary}
                          />
                        </Box>
                        <Text
                          {...typography.normal.title[4]}
                          color={item.id === currentTab ? colorScheme.universal.white : colorScheme.label.secondary}
                          _groupHover={{
                            color: item.id === currentTab ? colorScheme.universal.white : colorScheme.label.primary,
                          }}
                          ml="12px"
                        >
                          {item.title}
                        </Text>
                      </Box>
                    );
                  })}
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            flex="1"
            bgColor={colorScheme.other.bgProfileContent}
            p="10px 0px 0px 16px"
            borderRightRadius="8px"
            borderLeft={`1px solid ${colorScheme.platform.border}`}
          >
            {renderSettingSection()}
          </Box>
        </Box>
      </ModalContent>
    </PreferenceModalLayout>
  );
};
export default AppSettingModal;
