import { AnalyticsEventName, AnalyticsEventSource } from 'models/analytics';
import * as rudderanalytics from 'rudder-sdk-js';

const pushAnalyticEvent = (eventName: AnalyticsEventName, attributes?: {Source?: AnalyticsEventSource, ProductID?: string | number, LoginType?: string, templateName?: string} | null) => {
  setTimeout(() => {
    attributes ? rudderanalytics.track(eventName, attributes) : rudderanalytics.track(eventName);
  }, 0);
};

export { pushAnalyticEvent };
