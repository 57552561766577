import { database } from 'firebase-v9';
import { ref, update } from 'firebase/database';

export const updateMigrateVersion = (uid: string) => {
  if (uid) {
    const userRef = ref(database, `users/${uid}`);
    update(userRef, {
      migrateVersion: 1,
    });
  }
};
