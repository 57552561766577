import dayjs from 'dayjs';
import { OffModes } from 'models/off-mode';
import { _dayjs } from '__archived__/constants/app';
import { FIRST_DAY_OF_WEEK_IN_APP } from '__archived__/types/enum/app';
import { HABIT_PROGRESS } from '__archived__/types/enum/habit';
import { CalendarClass, FilterCalendarOptionType } from '__archived__/types/enum/singleProgress';
import { DateToCalendarInfo, HabitProgressMapInfo } from '__archived__/types/states/singleProgress';

const getPrevDate = (date: Date): dayjs.Dayjs => {
  return _dayjs(date).add(-1, 'days');
};

const getNextDate = (date: Date): dayjs.Dayjs => {
  return _dayjs(date).add(1, 'days');
};

const getTimesTamps = (date: Date | number, format: string): number => {
  return _dayjs(_dayjs(date).format(format)).valueOf();
};

const getDataCalendarInfo = (
  date: Date,
  habitProgressInfo: HabitProgressMapInfo,
  offModes: OffModes,
): DateToCalendarInfo | undefined => {
  const {
    habitStartDate,
    dateToActualGoalValueMap,
    dateToCheckInStatusMap,
    dateToGoalValueMap,
    dateToHabitGoalCurrentMap,
    dateToOffModeId,
  } = habitProgressInfo;
  if (habitStartDate) {
    const dateFormat = 'YYYY-MM-DD';
    const dateId: string = _dayjs(date).format(dateFormat);
    const isDayBlock: boolean =
      getTimesTamps(date, dateFormat) >= getTimesTamps(habitStartDate, dateFormat) &&
      getTimesTamps(date, dateFormat) <= getTimesTamps(new Date(), dateFormat);
    let classNow = '';
    let iconOffMode = '';
    if (!isDayBlock) {
      classNow = CalendarClass.BLOCK;
    } else {
      const prevDate: dayjs.Dayjs = getPrevDate(date);
      const nextDate: dayjs.Dayjs = getNextDate(date);
      const habitProgress: number | null = dateToCheckInStatusMap?.get(dateId) || null;
      const prevHabitProgress: number | null = dateToCheckInStatusMap?.get(_dayjs(prevDate).format(dateFormat)) || null;
      const nextHabitProgress: number | null = dateToCheckInStatusMap?.get(_dayjs(nextDate).format(dateFormat)) || null;
      const offModeId: string | null = dateToOffModeId?.get(dateId) || null;
      if (offModeId && offModes[offModeId]) {
        iconOffMode = offModes[offModeId].iconNamed || '';
      }

      classNow = getClassOfDay(habitProgress);
      if (classNow === CalendarClass.COMPLETED) {
        const prevClass: string = getClassOfDay(prevHabitProgress);
        const nextClass: string = getClassOfDay(nextHabitProgress);
        classNow = getClassCompleted(prevClass, nextClass, classNow);
      }
    }
    const goal = dateToHabitGoalCurrentMap?.get(dateId) || null;
    const inProgress: number =
      ((dateToActualGoalValueMap?.get(dateId) || 0) / (dateToGoalValueMap?.get(dateId) || 0)) * 100 || 0;

    const result: DateToCalendarInfo = {
      class: classNow,
      goal,
      inProgress,
      icon: iconOffMode,
    };
    return result;
  }
};

const setDaysOfMonth = (
  month: number,
  year: number,
  habitProgressInfo: HabitProgressMapInfo,
  dateToCalendarInfoMap: Map<string, DateToCalendarInfo>,
  offModes: OffModes,
): void => {
  const { habitStartDate } = habitProgressInfo;
  if (habitStartDate) {
    const monthIndex: number = month;
    const date: Date = new Date(year, monthIndex, 1);
    while (date.getMonth() === monthIndex) {
      const dateFormat = 'YYYY-MM-DD';
      const dateId: string = _dayjs(date).format(dateFormat);
      const result: DateToCalendarInfo | undefined = getDataCalendarInfo(date, habitProgressInfo, offModes);
      if (result) dateToCalendarInfoMap.set(dateId, result);
      date.setDate(date.getDate() + 1);
    }
  }
};

const setDaysOfWeek = (
  date: Date,
  habitProgressInfo: HabitProgressMapInfo,
  dateToCalendarInfoMap: Map<string, DateToCalendarInfo>,
  firstDayOfWeek: number,
  type: string,
  offModes: OffModes,
): void => {
  if (habitProgressInfo?.habitStartDate) {
    // first day of week
    const _date = new Date(date.getFullYear(), date.getMonth(), 1);
    firstDayOfWeek === FIRST_DAY_OF_WEEK_IN_APP.MONTH
      ? date.setDate(date.getDate() - date.getDay() + 1)
      : date.setDate(date.getDate() - date.getDay());

    if (type === 'fistDayOfMonth') {
      if (date.getTime() > _date.getTime()) {
        firstDayOfWeek === FIRST_DAY_OF_WEEK_IN_APP.MONTH
          ? date.setDate(date.getDate() - date.getDay() - 6)
          : date.setDate(date.getDate() - date.getDay() - 7);
      }
    }

    for (let i = 0; i < 7; i++) {
      const dateId: string = _dayjs(date).format('YYYY-MM-DD');
      if (dateToCalendarInfoMap.has(dateId)) {
        date.setDate(date.getDate() + 1);
        continue;
      }
      const result = getDataCalendarInfo(date, habitProgressInfo, offModes);
      if (result) {
        dateToCalendarInfoMap.set(dateId, result);
      }
      date.setDate(date.getDate() + 1);
    }
  }
};

const getClassOfDay = (habitProgress: number | null | undefined) => {
  if (!habitProgress) {
    return CalendarClass.NONE;
  }

  if (habitProgress === HABIT_PROGRESS.NONE) {
    return CalendarClass.NONE;
  }

  if (habitProgress === HABIT_PROGRESS.SKIP) {
    return CalendarClass.SKIP;
  }

  if (habitProgress === HABIT_PROGRESS.FAILED) {
    return CalendarClass.FAIL;
  }

  if (habitProgress === HABIT_PROGRESS.IN_PROGRESS) {
    return CalendarClass.IN_PROGRESS;
  }

  if (habitProgress === HABIT_PROGRESS.OFF) {
    return CalendarClass.OFF;
  }

  return CalendarClass.COMPLETED;
};

const getClassCompleted = (prevClass: string, nextClass: string, currentClass: string): string => {
  if (currentClass !== CalendarClass.COMPLETED) {
    return currentClass;
  }
  if (prevClass !== CalendarClass.COMPLETED && nextClass !== CalendarClass.COMPLETED) {
    return CalendarClass.SINGLE_COMPLETED;
  }

  if (prevClass !== CalendarClass.COMPLETED && nextClass === CalendarClass.COMPLETED) {
    return CalendarClass.PREV_COMPLETED;
  }

  if (nextClass !== CalendarClass.COMPLETED && prevClass === CalendarClass.COMPLETED) {
    return CalendarClass.NEXT_COMPLETED;
  }

  if (prevClass === CalendarClass.COMPLETED && nextClass === CalendarClass.COMPLETED) {
    return CalendarClass.MIDDLE_COMPLETED;
  }

  return CalendarClass.COMPLETED;
};

export const getCalendar = (
  month: number,
  year: number,
  type: string,
  habitProgressInfo: HabitProgressMapInfo,
  firstDayOfWeek: number,
  offModes: OffModes,
): { dateToCalendarInfoMap: Map<string, DateToCalendarInfo> } => {
  const dateToCalendarInfoMap = new Map<string, DateToCalendarInfo>();

  if (type === FilterCalendarOptionType.MONTH) {
    setDaysOfWeek(
      new Date(year, month, 1),
      habitProgressInfo,
      dateToCalendarInfoMap,
      firstDayOfWeek,
      'fistDayOfMonth',
      offModes,
    );
  }
  setDaysOfMonth(month, year, habitProgressInfo, dateToCalendarInfoMap, offModes);
  if (type === FilterCalendarOptionType.MONTH) {
    setDaysOfWeek(
      new Date(year, month + 1, 0),
      habitProgressInfo,
      dateToCalendarInfoMap,
      firstDayOfWeek,
      'lastDayOfMonth',
      offModes,
    );
  }

  return { dateToCalendarInfoMap };
};
