import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import rootReducer from '__archived__/store/roots';


const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
      return getDefaultMiddleware({
        serializableCheck: false,
      }).concat(logger);
    }
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
  devTools: false,
});

export default store;
