import { Box, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';

interface IActionButtonProps {
  text: string;
  handleSubmit: () => void;
  isLoading?: boolean;
  width?: string;
  height?: string;
}

const ActionButton: React.FC<IActionButtonProps> = ({
  text,
  handleSubmit,
  isLoading,
  width,
  height,
}: IActionButtonProps): ReactElement => {
  const { colorScheme, typography } = useThemeData();
  return (
    <Box
      cursor="default"
      as="button"
      background={colorScheme.accent.primary}
      color="white"
      _focus={{ outline: 'none' }}
      _hover={{ background: colorScheme.accent.primary }}
      _active={{ background: colorScheme.accent.primary }}
      onClick={() => handleSubmit()}
      borderRadius="3px"
      width={width}
      height={height}
    >
      <Text {...typography.emphasized.callout} color={colorScheme.universal.white}>
        {text}
      </Text>
    </Box>
  );
};

export default ActionButton;
