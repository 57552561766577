import { SaleApplePlan, SaleCampaignApple } from 'models/payment';

export const appleSaleCampaignParser = (rawValue: any): SaleCampaignApple | undefined => {
  if (!rawValue) return undefined;
  return {
    id: rawValue['id'],
    start: rawValue['start'],
    end: rawValue['end'],
    title: rawValue['title'],
    subtitle: rawValue['subtitle'],
    backgroundColorHex: rawValue['backgroundColorHex'],
    foregroundColorHex: rawValue['foregroundColorHex'],
    preSelectedProductId: rawValue['preSelectedProductId'],
    availablePlans: availablePlansParser(rawValue['availablePlans']),
  };
};

const availablePlansParser = (listPlansRawValue: any): SaleApplePlan[] | undefined => {
  if (!listPlansRawValue) return undefined;
  if (Array.isArray(listPlansRawValue) && listPlansRawValue.length) {
    return listPlansRawValue.map((rawValue) => {
      return {
        planType: rawValue['planType'],
        targetProductId: rawValue['targetProductId'],
        desktopNameLocalizationKey: rawValue['desktopNameLocalizationKey'],
      };
    });
  }
  return undefined;
};
