import { ITimeBound, ITimeOfDay } from '__archived__/types/states';
import { TIME_OF_DAY, TIME_OF_DAY_BITWISE } from '__archived__/types/enum';
import { _dayjs } from '__archived__/constants/app';

export const getTimeOfDay = (morningTimeBound: ITimeBound, afternoonTimeBound: ITimeBound): ITimeOfDay => {
  const currentHour: number = _dayjs().hour() * 60 + _dayjs().minute();
  let timeOfDay;
  let timeOfDayText;
  let timeOfDayBitwise;
  if (morningTimeBound.lowerbound <= currentHour && currentHour <= morningTimeBound.upperbound) {
    timeOfDay = TIME_OF_DAY.MORNING;
    timeOfDayText = TIME_OF_DAY.MORNING_TEXT;
    timeOfDayBitwise = TIME_OF_DAY_BITWISE.MORNING;
  } else if (afternoonTimeBound.lowerbound <= currentHour && currentHour <= afternoonTimeBound.upperbound) {
    timeOfDay = TIME_OF_DAY.AFTERNOON;
    timeOfDayText = TIME_OF_DAY.AFTERNOON_TEXT;
    timeOfDayBitwise = TIME_OF_DAY_BITWISE.AFTERNOON;
  } else {
    timeOfDay = TIME_OF_DAY.EVENING;
    timeOfDayText = TIME_OF_DAY.EVENING_TEXT;
    timeOfDayBitwise = TIME_OF_DAY_BITWISE.EVENING;
  }

  return {
    timeOfDay,
    timeOfDayText,
    timeOfDayBitwise,
  };
};

export const getTimeOfDayTextByBitwise = (timeOfDay: TIME_OF_DAY_BITWISE): string => {
  switch (timeOfDay) {
    case TIME_OF_DAY_BITWISE.MORNING:
      return TIME_OF_DAY.MORNING_TEXT;
    case TIME_OF_DAY_BITWISE.AFTERNOON:
      return TIME_OF_DAY.AFTERNOON_TEXT;
    case TIME_OF_DAY_BITWISE.EVENING:
      return TIME_OF_DAY.EVENING_TEXT;
    case TIME_OF_DAY_BITWISE.ALL:
      return TIME_OF_DAY.ALL_TEXT;
    default:
      return TIME_OF_DAY.ALL_TEXT;
  }
};

export const getTimeOfDayTextByString = (timeOfDay: string): string => {
  switch (timeOfDay) {
    case TIME_OF_DAY.MORNING:
      return TIME_OF_DAY.MORNING_TEXT;
    case TIME_OF_DAY.AFTERNOON:
      return TIME_OF_DAY.AFTERNOON_TEXT;
    case TIME_OF_DAY.EVENING:
      return TIME_OF_DAY.EVENING_TEXT;
    case TIME_OF_DAY.ALL:
      return TIME_OF_DAY.ALL_TEXT;
    default:
      return timeOfDay;
  }
};

export const getTimeOfDayByBitwise = (timeOfDay: TIME_OF_DAY_BITWISE): string => {
  switch (timeOfDay) {
    case TIME_OF_DAY_BITWISE.MORNING:
      return TIME_OF_DAY.MORNING;
    case TIME_OF_DAY_BITWISE.AFTERNOON:
      return TIME_OF_DAY.AFTERNOON;
    case TIME_OF_DAY_BITWISE.EVENING:
      return TIME_OF_DAY.EVENING;
    case TIME_OF_DAY_BITWISE.ALL:
      return TIME_OF_DAY.ALL;
    default:
      return TIME_OF_DAY.ALL;
  }
};
