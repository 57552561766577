import { Box, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';

const NotFound: React.FC = (): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  const history = useHistory();
  return (
    <Box textAlign="center">
      <Text {...typography.emphasized.title.large}>404 Not Found</Text>
      <Text {...typography.emphasized.title[3]} color={colorScheme.accent.primary} onClick={() => history.push('/')}>
        Back
      </Text>
    </Box>
  );
};
export default NotFound;
