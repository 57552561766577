import { Box, Text } from "@chakra-ui/react";
import { useThemeData } from "hooks/useThemeData";
import React, { ReactElement, ReactNode } from "react";

interface GeneralSettingProps {
  children: ReactNode;
  title: string;
  subTitle: string;
}

const GeneralSettingItem: React.FC<GeneralSettingProps> = ({
  children,
  title,
  subTitle,
}: GeneralSettingProps): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  return (
    <Box
      p="14px 16px 14px 0"
      display="grid"
      gridTemplateColumns="4fr 2.5fr"
      borderBottom={`1px solid ${colorScheme.platform.border}`}
    >
      <Box maxW="230px">
        <Text
          mb="6px"
          {...typography.emphasized.title[4]}
          color={colorScheme.label.primary}
          lineHeight="16px"
        >
          {title}
        </Text>
        <Text
          {...typography.normal.callout}
          color={colorScheme.label.secondary}
          lineHeight="15px"
        >
          {subTitle}
        </Text>
      </Box>
      <Box display="flex" alignItems="center" justifySelf="flex-end">
        {children}
      </Box>
    </Box>
  );
};
export default GeneralSettingItem;
