import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { ReactComponent as AppleLogo } from 'assets/images/apple_logo.svg';
import { useThemeData } from 'hooks/useThemeData';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';

const SignInAppleAccount: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const { typography, colorScheme } = useThemeData();
  const appleLogoColor = useColorModeValue(colorScheme.universal.black, colorScheme.universal.white);
  const userInfoFirebase = useSelector((state: NewRootState) => state.usersInfoModel.user);

  return (
    <>
      <Flex justify="flex-start" align="center" p="0 0 10px 16px" borderRadius="10px">
        <Box mr="12px" mt="10px">
          <AppleLogo width="24px" height="24px" fill={appleLogoColor} />
        </Box>
        <Flex
          justify="space-between"
          align="center"
          w="100%"
          pt="10px"
          borderTop={`1px solid ${colorScheme.platform.border}`}
        >
          <Box>
            <Text {...typography.normal.title[4]} color={colorScheme.label.primary} lineHeight="15.73px">
              {t('challenge.siginmethodAppleid')}
            </Text>
            {userInfoFirebase?.email && (
              <Text {...typography.normal.callout} color={colorScheme.label.secondary} lineHeight="14.52px" mt="4px">
                {userInfoFirebase.email}
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default SignInAppleAccount;
