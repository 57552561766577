import { _dayjs } from '__archived__/constants/app';
import { HABIT_PROGRESS_FILTER } from '__archived__/types/enum/app';
import { IAppState } from '__archived__/types/states';
import {
  APP_TYPES,
  EventType,
  FilteredMoodType,
  GetAllMoodType,
  GetAllUserInfoType,
  GetUsageConfigType,
  GetUserPreferencesAction,
  OpenLimitModalType,
  UpdateContextMenuProps,
  UpdateContextMenuPropsSingleProgress,
  UpdateDate,
  UpdateDateSingleProgress,
  UpdateDatePickerModalState,
  UpdateDetailHabit,
  UpdateHabitFolderFilter,
  UpdateHabitProgressFilter,
  UpdateLogActionModal,
  UpdateManualLogModalProps,
  UpdateMoodContextMenuProps,
  UpdateSignOutAlertState,
  UpdateTimeOfDayType,
  UpdateTimerModalProps,
  UpdateTimerOverlayProps,
  UpdateLimitTypeCurrent,
  OpenMoodNoteModalType,
  SelectMoodType,
  UpdateMoodIdType,
  ShowJournalAnnouncementType,
  SortJournalType,
  PreferencesLoaded,
  ResetDataType,
} from './app.type';
import update from 'immutability-helper';
import { IEvents, IMood } from '__archived__/types/states/app';
import { AUTHENTICATION_ACTION_TYPES, SignOutType } from '../authentication/auth.type';
import { filterMoodByDate } from '__archived__/utils/emotionPreferences';

const appInitialState: IAppState = {
  timeOfDay: 0,
  firstWeekDay: 0,
  journalLayoutType: 0,
  habitProgressFilter: HABIT_PROGRESS_FILTER.PENDING,
  habitFolderId: '',
  getUserPreferences: false,
  getUserPreferencesSuccess: false,
  getUserPreferencesFailed: false,
  morningMinuteRange: {
    lowerbound: 0,
    upperbound: 720,
  },
  afternoonMinuteRange: {
    lowerbound: 720,
    upperbound: 1080,
  },
  eveningMinuteRange: {
    lowerbound: 1080,
    upperbound: 0,
  },
  contextMenu: {
    xPos: 0,
    yPos: 0,
    showMenu: false,
    habitId: '',
  },
  moodContextMenu: {
    xPos: 0,
    yPos: 0,
    showMenu: false,
    moodId: '',
  },
  contextMenuSingleProgress: {
    xPos: 0,
    yPos: 0,
    showMenu: false,
    habitId: '',
  },
  logActionModal: {
    isOpen: false,
    habitId: '',
  },
  manualLogModal: {
    isOpen: false,
    habitId: '',
  },
  timerModal: {
    isOpen: false,
    habitId: '',
  },
  datePickerModalState: false,
  date: _dayjs().format('YYYY-MM-DD'),
  dateCalendar: _dayjs().format('YYYY-MM-DD'),
  timerOverlay: {
    habitId: '',
    timeValue: 0,
    timerOverlayState: false,
  },
  userInfo: {
    name: '',
    email: '',
    premiumStatus: 0,
    premiumExpireDate: 0,
    premiumStatusAndroid: 0,
  },
  getUserInfo: false,
  getUserInfoSuccess: false,
  getUserInfoFailed: false,
  remoteConfig: {
    usageConfig: {},
    numberOfFreeHabitAllowed: 0,
    reasonsCategories: [],
  },
  openLimitModal: false,
  events: new Map<string, IEvents>(),
  eventCounts: {},
  timerType: '',
  signOutAlert: false,
  moodLog: new Map<string, IMood>(),
  getMood: false,
  getMoodSuccess: false,
  getMoodFailed: false,
  filteredMood: new Map<string, IMood>(),
  filteringMood: false,
  filteringMoodSuccess: false,
  filteringMoodFailed: false,
  detailHabit: '',
  limitTypeCurrent: '',
  openMoodNotesModal: false,
  selectedMood: {
    moodAnimation: '',
    moodType: '',
    mood: {
      value: 0,
      createdAt: '',
      notes: '',
      selectedCategories: [],
    },
    date: '',
    time: '',
  },
  detailMoodId: '',
  isOpenJournalAnnounce: false,
  sortJournalType: '',
  preferencesLoaded: false,
};

type AppActionType =
  | UpdateTimeOfDayType
  | UpdateHabitProgressFilter
  | UpdateContextMenuProps
  | UpdateContextMenuPropsSingleProgress
  | UpdateLogActionModal
  | UpdateDatePickerModalState
  | UpdateDate
  | UpdateDateSingleProgress
  | UpdateManualLogModalProps
  | UpdateHabitFolderFilter
  | UpdateTimerModalProps
  | UpdateTimerOverlayProps
  | GetAllUserInfoType
  | GetUsageConfigType
  | OpenLimitModalType
  | EventType
  | GetUserPreferencesAction
  | SignOutType
  | UpdateSignOutAlertState
  | GetAllMoodType
  | FilteredMoodType
  | UpdateMoodContextMenuProps
  | UpdateDetailHabit
  | UpdateLimitTypeCurrent
  | OpenMoodNoteModalType
  | SelectMoodType
  | UpdateMoodIdType
  | ShowJournalAnnouncementType
  | SortJournalType
  | PreferencesLoaded
  | ResetDataType;

export default function appReducer(state: IAppState = appInitialState, action: AppActionType): IAppState {
  switch (action.type) {
    case APP_TYPES.UPDATE_TIME_OF_DAY:
      return {
        ...state,
        timeOfDay: action.payload,
        habitFolderId: '',
      };
    case APP_TYPES.UPDATE_HABIT_PROGRESS_FILTER:
      return {
        ...state,
        habitProgressFilter: action.payload,
      };
    case APP_TYPES.UPDATE_CONTEXT_MENU_PROPS:
      return {
        ...state,
        contextMenu: action.payload,
      };
    case APP_TYPES.UPDATE_CONTEXT_MENU_PROPS_SINGLE_PROGRESS:
      return {
        ...state,
        contextMenuSingleProgress: action.payload,
      };
    case APP_TYPES.UPDATE_LOG_ACTION_MODAL_PROPS:
      return {
        ...state,
        logActionModal: action.payload,
      };
    case APP_TYPES.UPDATE_DATE_PICKER_MODAL_STATE:
      return {
        ...state,
        datePickerModalState: action.payload,
      };
    case APP_TYPES.UPDATE_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case APP_TYPES.UPDATE_DATE_SINGLE_PROGRESS:
      return {
        ...state,
        dateCalendar: action.payload,
      };
    case APP_TYPES.UPDATE_MANUAL_LOG_MODAL_PROPS:
      return {
        ...state,
        manualLogModal: action.payload,
      };
    case APP_TYPES.UPDATE_HABIT_FOLDER_FILTER:
      return {
        ...state,
        habitFolderId: action.payload,
      };
    case APP_TYPES.UPDATE_TIMER_MODAL_PROPS:
      return {
        ...state,
        timerModal: action.payload,
      };
    case APP_TYPES.UPDATE_TIMER_OVERLAY_PROPS:
      return {
        ...state,
        timerOverlay: action.payload,
      };
    case APP_TYPES.GET_USER_INFO:
      return {
        ...state,
        getUserInfo: action.payload,
      };
    case APP_TYPES.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        getUserInfo: false,
        getUserInfoSuccess: true,
        userInfo: action.payload,
        getUserInfoFailed: false,
      };
    case APP_TYPES.GET_USER_INFO_FAILED:
      return {
        ...state,
        getUserInfo: false,
        getUserInfoSuccess: false,
        getUserInfoFailed: true,
      };
    case APP_TYPES.UPDATE_USER_INFO_CHILD_ADDED:
      return update(state, {
        userInfo: {
          [action.payload.key]: {
            $set: action.payload.value,
          },
        },
      });
    case APP_TYPES.UPDATE_USER_INFO_CHILD_CHANGED:
      return update(state, {
        userInfo: {
          [action.payload.key]: {
            $set: action.payload.value,
          },
        },
      });
    case APP_TYPES.UPDATE_USER_INFO_CHILD_REMOVED:
      return update(state, {
        userInfo: {
          [action.payload.key]: {
            $set: action.payload.value,
          },
        },
      });
    case APP_TYPES.GET_USAGE_CONFIG:
      return {
        ...state,
        remoteConfig: action.payload,
      };
    case APP_TYPES.OPEN_LIMIT_MODAL:
      return {
        ...state,
        openLimitModal: action.payload,
      };
    case APP_TYPES.GET_EVENT:
      return {
        ...state,
        events: action.payload,
      };
    case APP_TYPES.ADD_EVENT:
      return update(state, {
        events: {
          $add: [[action.payload.id, { ...action.payload.event }]],
        },
      });
    case APP_TYPES.UPDATE_EVENT_CHILD_REMOVED:
      return update(state, {
        events: {
          $remove: [action.payload],
        },
      });
    case APP_TYPES.ADD_EVENT_COUNT:
      return {
        ...state,
        eventCounts: action.payload,
      };
    case APP_TYPES.UPDATE_EVENT_TYPE:
      return {
        ...state,
        timerType: action.payload,
      };
    case APP_TYPES.UPDATE_LIMIT_TYPE_CURRENT:
      return {
        ...state,
        limitTypeCurrent: action.payload,
      };
    case APP_TYPES.GET_USER_PREFERENCES:
      return {
        ...state,
        getUserPreferences: action.payload,
      };
    case APP_TYPES.GET_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        getUserPreferences: false,
        getUserPreferencesSuccess: true,
        getUserPreferencesFailed: false,
        morningMinuteRange: action.payload.timeOfDaySettings.morningMinuteRange,
        afternoonMinuteRange: action.payload.timeOfDaySettings.afternoonMinuteRange,
        eveningMinuteRange: action.payload.timeOfDaySettings.eveningMinuteRange,
      };
    case APP_TYPES.GET_USER_PREFERENCES_FAILED:
      return {
        ...state,
        getUserPreferences: false,
        getUserPreferencesSuccess: false,
        getUserPreferencesFailed: true,
      };
    case APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_ADDED:
      return {
        ...state,
        morningMinuteRange: action.payload.timeOfDaySettings.morningMinuteRange,
        afternoonMinuteRange: action.payload.timeOfDaySettings.afternoonMinuteRange,
        eveningMinuteRange: action.payload.timeOfDaySettings.eveningMinuteRange,
      };
    case APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_CHANGED:
      return {
        ...state,
        morningMinuteRange: action.payload.timeOfDaySettings.morningMinuteRange,
        afternoonMinuteRange: action.payload.timeOfDaySettings.afternoonMinuteRange,
        eveningMinuteRange: action.payload.timeOfDaySettings.eveningMinuteRange,
      };
    case APP_TYPES.UPDATE_ON_USER_PREFERENCES_CHILD_REMOVED:
      return {
        ...state,
        morningMinuteRange: {
          lowerbound: 0,
          upperbound: 720,
        },
        afternoonMinuteRange: {
          lowerbound: 720,
          upperbound: 1080,
        },
        eveningMinuteRange: {
          lowerbound: 1080,
          upperbound: 0,
        },
        firstWeekDay: 0,
      };
    case APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_ADDED:
      return {
        ...state,
        firstWeekDay: action.payload,
      };
    case APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_CHANGED:
      return {
        ...state,
        firstWeekDay: action.payload,
      };
    case APP_TYPES.UPDATE_FIST_WEEK_DAY_CHILD_REMOVED:
      return {
        ...state,
        firstWeekDay: 0,
      };
    case APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_ADDED:
      return {
        ...state,
        journalLayoutType: action.payload,
      };
    case APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_CHANGED:
      return {
        ...state,
        journalLayoutType: action.payload,
      };
    case APP_TYPES.UPDATE_JOURNAL_LAYOUT_TYPE_CHILD_REMOVED:
      return {
        ...state,
        journalLayoutType: 0,
      };
    case APP_TYPES.UPDATE_SIGN_OUT_ALERT_STATE:
      return {
        ...state,
        signOutAlert: action.payload,
      };
    case APP_TYPES.UPDATE_DETAIL_HABIT:
      return {
        ...state,
        detailHabit: action.payload,
      };
    case AUTHENTICATION_ACTION_TYPES.SIGN_OUT:
      return appInitialState;
    case APP_TYPES.GET_MOOD:
      return {
        ...state,
        getMood: action.payload,
      };
    case APP_TYPES.GET_MOOD_SUCCESS:
      return {
        ...state,
        getMood: false,
        getMoodSuccess: true,
        moodLog: action.payload,
        getMoodFailed: false,
      };
    case APP_TYPES.GET_MOOD_FAILED:
      return {
        ...state,
        getMood: false,
        getMoodSuccess: false,
        getMoodFailed: true,
      };
    case APP_TYPES.UPDATE_MOOD_LOG_CHILD_ADDED:
      if (filterMoodByDate(action.payload.mood, _dayjs(action.payload.chooseDate).format('YYYY-MM-DD'))) {
        return update(state, {
          moodLog: {
            $add: [[action.payload.moodId, { ...action.payload.mood }]],
          },
          filteredMood: {
            $add: [[action.payload.moodId, { ...action.payload.mood }]],
          },
        });
      }
      return update(state, {
        moodLog: {
          $add: [[action.payload.moodId, { ...action.payload.mood }]],
        },
      });
    case APP_TYPES.UPDATE_MOOD_LOG_CHILD_CHANGED:
      if (filterMoodByDate(action.payload.mood, _dayjs(action.payload.chooseDate).format('YYYY-MM-DD'))) {
        return update(state, {
          moodLog: {
            [action.payload.moodId]: {
              $set: { ...action.payload.mood },
            },
          },
          filteredMood: {
            [action.payload.moodId]: {
              $set: { ...action.payload.mood },
            },
          },
        });
      }
      return update(state, {
        moodLog: {
          [action.payload.moodId]: {
            $set: { ...action.payload.mood },
          },
        },
        filteredMood: {
          $remove: [action.payload.moodId],
        },
      });
    case APP_TYPES.UPDATE_MOOD_LOG_CHILD_REMOVE:
      return update(state, {
        moodLog: {
          $remove: [action.payload],
        },
        filteredMood: {
          $remove: [action.payload],
        },
      });
    case APP_TYPES.FILTERING_MOOD:
      return {
        ...state,
        filteringMood: action.payload,
        filteringMoodSuccess: false,
      };
    case APP_TYPES.FILTERING_MOOD_SUCCESS:
      return {
        ...state,
        filteringMood: false,
        filteringMoodSuccess: true,
        filteredMood: action.payload.filteredMood,
        filteringMoodFailed: false,
      };
    case APP_TYPES.FILTERING_MOOD_FAILED:
      return {
        ...state,
        filteringMood: false,
        filteringMoodFailed: true,
        filteringMoodSuccess: false,
      };
    case APP_TYPES.UPDATE_MOOD_CONTEXT_MENU_PROPS:
      return {
        ...state,
        moodContextMenu: action.payload,
      };
    case APP_TYPES.OPEN_MOOD_NOTES_MODAL:
      return {
        ...state,
        openMoodNotesModal: action.payload,
      };
    case APP_TYPES.SELECT_MOOD:
      return {
        ...state,
        selectedMood: action.payload,
      };
    case APP_TYPES.UPDATE_DETAIL_MOOD_ID:
      return {
        ...state,
        detailMoodId: action.payload,
      };
    case APP_TYPES.SHOW_JOURNAL_ANNOUNCEMENT:
      return {
        ...state,
        isOpenJournalAnnounce: action.payload,
      };
    case APP_TYPES.SORT_JOURNAL_TYPE:
      return {
        ...state,
        sortJournalType: action.payload,
      };
    case APP_TYPES.PREFERENCES_LOADED:
      return {
        ...state,
        preferencesLoaded: action.payload,
      };
    case APP_TYPES.RESET_DATA:
      return {
        timeOfDay: 0,
        firstWeekDay: 0,
        journalLayoutType: 0,
        habitProgressFilter: HABIT_PROGRESS_FILTER.PENDING,
        habitFolderId: '',
        getUserPreferences: false,
        getUserPreferencesSuccess: false,
        getUserPreferencesFailed: false,
        morningMinuteRange: {
          lowerbound: 0,
          upperbound: 720,
        },
        afternoonMinuteRange: {
          lowerbound: 720,
          upperbound: 1080,
        },
        eveningMinuteRange: {
          lowerbound: 1080,
          upperbound: 0,
        },
        contextMenu: {
          xPos: 0,
          yPos: 0,
          showMenu: false,
          habitId: '',
        },
        moodContextMenu: {
          xPos: 0,
          yPos: 0,
          showMenu: false,
          moodId: '',
        },
        contextMenuSingleProgress: {
          xPos: 0,
          yPos: 0,
          showMenu: false,
          habitId: '',
        },
        logActionModal: {
          isOpen: false,
          habitId: '',
        },
        manualLogModal: {
          isOpen: false,
          habitId: '',
        },
        timerModal: {
          isOpen: false,
          habitId: '',
        },
        datePickerModalState: false,
        date: _dayjs().format('YYYY-MM-DD'),
        dateCalendar: _dayjs().format('YYYY-MM-DD'),
        timerOverlay: {
          habitId: '',
          timeValue: 0,
          timerOverlayState: false,
        },
        userInfo: {
          name: '',
          email: '',
          premiumStatus: 0,
          premiumExpireDate: 0,
          premiumStatusAndroid: 0,
        },
        getUserInfo: false,
        getUserInfoSuccess: false,
        getUserInfoFailed: false,
        remoteConfig: {
          usageConfig: {},
          numberOfFreeHabitAllowed: 0,
          reasonsCategories: [],
        },
        openLimitModal: false,
        events: new Map<string, IEvents>(),
        eventCounts: {},
        timerType: '',
        signOutAlert: false,
        moodLog: new Map<string, IMood>(),
        getMood: false,
        getMoodSuccess: false,
        getMoodFailed: false,
        filteredMood: new Map<string, IMood>(),
        filteringMood: false,
        filteringMoodSuccess: false,
        filteringMoodFailed: false,
        detailHabit: '',
        limitTypeCurrent: '',
        openMoodNotesModal: false,
        selectedMood: {
          moodAnimation: '',
          moodType: '',
          mood: {
            value: 0,
            createdAt: '',
            notes: '',
            selectedCategories: [],
          },
          date: '',
          time: '',
        },
        detailMoodId: '',
        isOpenJournalAnnounce: false,
        sortJournalType: '',
        preferencesLoaded: false,
      };
    default:
      return state;
  }
}
