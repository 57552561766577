import { createModel } from '@rematch/core';
import { v4 as uuid } from 'uuid';
import { RootModel } from '../../root.model';

type PersistanceModel = {
  _version: number;
  _indexedInstance: string;
  isRehydrated: boolean;
};

const initialState: PersistanceModel = {
  _version: 1,
  _indexedInstance: '',
  isRehydrated: false,
};

export const persistanceModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    updateVersion(state, payload: number) {
      return {
        ...state,
        _version: payload,
      };
    },
    updateIndexedInstance(state, payload: string) {
      return {
        ...state,
        _indexedInstance: payload,
      };
    },
  },
  effects: (dispatch) => ({
    createDbInstance() {
      const newDbName = `habitify_${uuid()}`;
      dispatch.persistanceModel.updateIndexedInstance(newDbName);
      dispatch.persistanceModel.updateVersion(1);
    },
    updateInstanceVersion({ version }: { version: number }) {
      dispatch.persistanceModel.updateVersion(version);
    },
    updateIndexedDBInstance({ indexedInstance }: { indexedInstance: string }, currentState) {
      dispatch.persistanceModel.updateIndexedInstance(indexedInstance);
    },
  }),
});
