import {
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import CustomDatePicker from 'components/common/custom-date-picker/date-picker.component';
import Icon from 'components/common/Icon';
import { Dayjs } from 'dayjs';
import { useThemeData } from 'hooks/useThemeData';
import React, { memo, ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { _dayjs } from 'tools/extended-dayjs';
import { RootState } from '__archived__/store/roots';
import { OffDateType } from './create-off-mode.modal';

interface Props {
  date: Dayjs;
  title: string;
  offModeDateType: OffDateType;
  selectDateRange: (type: OffDateType, date: Dayjs) => void;
  startDate?: Dayjs;
}
const OffModeDate: React.FC<Props> = ({ date, title, offModeDateType, selectDateRange, startDate }): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  const { t } = useTranslation();
  const [chooseDate, setChooseDate] = useState<number>();
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const firstWeekDay = useSelector((state: RootState) => state.app.firstWeekDay);

  useEffect(() => {
    if (date) {
      setChooseDate(_dayjs(date).valueOf());
    }
  }, [date]);

  const toggleDropdown = useCallback(() => {
    setShowDropDown(!showDropDown);
  }, [showDropDown]);

  const selectDate = (date: Dayjs) => {
    setChooseDate(date.valueOf());
    selectDateRange(offModeDateType, date);
    // handleInput(date.valueOf());
  };

  const renderDateTitle = useCallback(() => {
    if (_dayjs(chooseDate).isToday()) {
      return t(`common.today`);
    }

    if (_dayjs(chooseDate).isTomorrow()) {
      return t(`common.tomorrow`);
    }

    if (_dayjs(chooseDate).isYesterday()) {
      return t(`common.yesterday`);
    }
    return _dayjs(chooseDate).format('MMM DD, YYYY');
  }, [chooseDate, t]);

  const getDisableDays = useCallback(() => {
    switch (offModeDateType) {
      case 'startDate':
        return _dayjs().subtract(1, 'day');
      case 'endDate':
        return startDate;
      default:
        break;
    }
  }, [offModeDateType, startDate]);

  return (
    <>
      <FormLabel
        {...typography.emphasized.caption[2]}
        color={colorScheme.label.secondary}
        pl="6px"
        textTransform="uppercase"
      >
        {title}
      </FormLabel>
      <Popover placement="bottom-start">
        <PopoverTrigger>
          <InputGroup onClick={toggleDropdown}>
            <Input
              readOnly
              value={renderDateTitle()}
              {...typography.emphasized.callout}
              bg={colorScheme.platform.control.background}
              //   isRequired
              height="30px"
              type="text"
              px="10px"
              name="startDate"
              //   onChange={handleInput}
              border={`1px solid`}
              borderColor={colorScheme.platform.border}
              borderRadius="3px"
              marginTop="unset !important"
              _hover={{
                border: '1px solid',
                borderColor: `${colorScheme.accent.primary}`,
              }}
            />
            <InputRightElement
              height="30px"
              children={<Icon name="ic-calendar" fill={colorScheme.label.secondary} />}
            />
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent bg={colorScheme.platform.menu} _focus={{ outline: 'none' }} border="none" width="231px">
          <PopoverBody padding="4px 6px 12px 6px">
            <CustomDatePicker
              defaultSelectedDate={date}
              startWith={firstWeekDay}
              onChange={selectDate}
              type="create"
              disableDays={{ before: getDisableDays() }}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
export default memo(OffModeDate);
