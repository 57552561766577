import { useEffect, useState } from 'react';
import { UnitSymbol } from 'models/common';
import { convert, getBaseUnitFromType, getType } from 'tools/si-unit/si-unit-utils';

type ConvertedUnit = {
  convertedValue: number;
  baseUnitSymbol: UnitSymbol;
};

export const useConvertUnit = (unitSymbol: UnitSymbol | null | undefined, value?: number): ConvertedUnit => {
  const [convertedValue, setConvertedValue] = useState<number>(0);
  const [baseUnitSymbol, setBaseUnitSymbol] = useState<UnitSymbol>('rep');

  useEffect(() => {
    if (unitSymbol) {
      const baseType = getType({ unitSymbol });
      const baseUnitSymbol = getBaseUnitFromType({ type: baseType });
      setBaseUnitSymbol(baseUnitSymbol);
      if (value) {
        const actualValue = convert({ source: unitSymbol, target: baseUnitSymbol, value });
        setConvertedValue(actualValue);
      }
    }
  }, [unitSymbol, value]);

  return {
    convertedValue,
    baseUnitSymbol,
  };
};
