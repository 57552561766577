import { Box, Image, Input, Spinner } from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import WarningModal from 'components/common/modal/warning-modal/warning-modal.component';
import { useThemeData } from 'hooks/useThemeData';
import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewDispatch, NewRootState } from 'store';

const BasicInformation: FC = (): ReactElement => {
  const { typography, colorScheme } = useThemeData();

  const newDispatch = useDispatch<NewDispatch>();
  const userAuth = useSelector((state: NewRootState) => state.authenticationModel.user);
  const userInfo = useSelector((state: NewRootState) => state.usersInfoModel.user);
  const userAvatar: string | null | undefined = useSelector(
    (state: NewRootState) => state.usersInfoModel.user?.profileImage,
  );

  const [isIpReadOnly, setIsIpReadOnly] = useState(true);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isUpLoading, setIsUpLoading] = useState(false);
  const [fileClient, setFileClient] = useState<File>();

  const srcAvatar = userInfo?.profileImage ? userInfo?.profileImage : userAvatar;

  const refInputUploadFile = useRef(null);
  const refFirstName = useRef(null);
  const refLastName = useRef(null);

  useEffect(() => {
    if (userInfo) {
      setFirstName(userInfo.firstName || '');
      setLastName(userInfo.lastName || '');
    }
  }, [userInfo]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isUpLoading) {
      timeout = setTimeout(() => {
        setIsUpLoading(false);
      }, 1300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isUpLoading]);

  const handleUploadFile = () => {
    const elInputUploadFile = refInputUploadFile.current as HTMLElement | null;
    elInputUploadFile?.click();
  };

  const onChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const isPassType = hasExtension(e.target);
    const isPassSize = passImgSize(files);
    if (isPassSize && isPassType && files) {
      const file = files[0];
      const { name, size, lastModified } = file;
      if (name === fileClient?.name && size === fileClient.size && lastModified === fileClient.lastModified) {
        return false;
      }
      setIsUpLoading(true);
      newDispatch.usersInfoModel.updateUserAvatar({
        uid: userAuth?.uid,
        files: files,
      });
      setFileClient(file);
    } else {
      setIsOpenWarningModal(true);
    }
  };

  const passImgSize = (files: FileList | null) => {
    const MAX_SIZE = 2 * 1024 * 1024; // bytes
    return !files || (files && files[0]?.size > MAX_SIZE) ? false : true;
  };

  const hasExtension = (el: HTMLInputElement) => {
    const exts = ['.jpg', '.jpeg'];
    const fileName = el.value;
    return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(fileName);
  };

  const handleUpdateFirstLastName = () => {
    newDispatch.usersInfoModel.updateFirstLastName({
      uid: userAuth?.uid,
      firstName,
      lastName,
    });
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleUpdateFirstLastName();
    }
  };
  return (
    <>
      <Box display="grid" gridTemplateColumns="auto 1fr" gridColumnGap="12px" alignItems="center" mt="16.5px">
        <Box
          position="relative"
          width="61px"
          height="61px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={srcAvatar && !isUpLoading ? 'none' : `2px dashed ${colorScheme.platform.border}`}
          borderRadius="50%"
          className="profile-avatar"
          onClick={handleUploadFile}
        >
          {srcAvatar && !isUpLoading && (
            <Image
              src={srcAvatar}
              width="100%"
              height="100%"
              borderRadius="50%"
              objectFit="cover"
              fallbackSrc={srcAvatar}
            ></Image>
          )}

          {!srcAvatar && !isUpLoading && <Icon name={'default-avatar-profile'} fill={colorScheme.platform.border} />}
          {isUpLoading && (
            <Box position="absolute" width="20px" height="20px">
              <Spinner width="100%" height="100%" color={colorScheme.other.backgroundSpinner} />
            </Box>
          )}
          <input
            ref={refInputUploadFile}
            name="ip-upload-avatar"
            style={{
              position: 'absolute',
              opacity: 0,
              visibility: 'hidden',
              zIndex: -1,
            }}
            type="file"
            accept="image/jpeg"
            value=""
            onChange={(val) => onChangeAvatar(val)}
          />
        </Box>
        <Box display="grid" gridTemplateColumns="1fr" gridRowGap="6px" onClick={() => setIsIpReadOnly(false)}>
          <Input
            ref={refFirstName}
            {...typography.normal.callout}
            placeholder="First Name"
            maxW="246px"
            height="30px"
            border={`1px solid ${colorScheme.platform.border}`}
            borderColor={colorScheme.platform.border}
            borderRadius="3px"
            isReadOnly={isIpReadOnly}
            _focus={{ boxShadow: 'unset', border: `1.5px solid ${colorScheme.accent.primary}` }}
            _hover={{ border: `1px solid ${colorScheme.platform.border}` }}
            _placeholder={{ color: colorScheme.other.placeholder }}
            value={firstName}
            onChange={(val) => setFirstName(val.target.value)}
            onBlur={handleUpdateFirstLastName}
            onKeyDown={(e) => handleOnKeyDown(e)}
            autoFocus={false}
          />
          <Input
            ref={refLastName}
            {...typography.normal.callout}
            placeholder="Last Name"
            maxW="246px"
            height="30px"
            border={`1px solid ${colorScheme.platform.border}`}
            borderColor={colorScheme.platform.border}
            borderRadius="3px"
            _focus={{ boxShadow: 'unset', border: `1.5px solid ${colorScheme.accent.primary}` }}
            _hover={{ border: `1px solid ${colorScheme.platform.border}` }}
            _placeholder={{ color: colorScheme.other.placeholder }}
            isReadOnly={isIpReadOnly}
            value={lastName}
            onChange={(val) => setLastName(val.target.value)}
            onBlur={handleUpdateFirstLastName}
            onKeyDown={(e) => handleOnKeyDown(e)}
            autoFocus={false}
          />
        </Box>
      </Box>{' '}
      <WarningModal
        title="Up load Limit"
        subTitle="You can upload .jpg image files. Max size of 2MB"
        icon="ic-warning-red"
        labelBtnPrimary=""
        labelBtnSecondary="close"
        isNotCentered={true}
        open={isOpenWarningModal}
        closeModal={() => setIsOpenWarningModal(false)}
      ></WarningModal>
    </>
  );
};

export default BasicInformation;
