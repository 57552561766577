import { UnitSymbol, USAGE_LIMIT } from 'models/common';
import { HabitCurrentProgress, HabitProgress } from 'models/habit-progress';
import { Habit } from 'models/habits';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewDispatch, NewRootState } from 'store';
import {
  createNewEventAction,
  updateEventTypeAction,
  updateLimitTypeCurrent,
  updateLogActionModalAction,
} from '__archived__/store/ducks/app/app.action';
import { UpdateLogActionModal } from '__archived__/store/ducks/app/app.type';
import { RootState } from '__archived__/store/roots';
import { LIMIT_EVENT_TYPE } from '__archived__/types/enum/app';
import { HABIT_LOG_TYPE } from '__archived__/types/enum/habit';
import { eventType } from '__archived__/utils/eventType';
import { checkLimitUsage } from '__archived__/utils/habitUtils';
import { checkinNoGoalHabit, createNewHabitLog, removeCheckinHabit } from '__archived__/utils/logProgressUtils';
import { useCheckInDate } from './useCheckInDate';
import { useConvertUnit } from './useConvertUnit';
import { useGetUsageLimit } from './useGetUsageLimit';
import { useLogInfoType } from './useLogInfoType';

type Return = {
  unitSymbol: UnitSymbol | null | undefined;
  isOneTimePerDayHabit: boolean | null | undefined;
  isNoGoalHabit: boolean | null | undefined;
  habitProgress: HabitProgress | null | undefined;
  habitGoalValue: number | null | undefined;
  habitActualLogValue: number | null | undefined;
  valueProgress: number;
  isBadHabit: boolean;
  handleCheckinHabit: () => void;
  handleLogValueForScalarTypeHabit: () => void;
  handleUndoCheckinNoGoalHabit: () => void;
  handleUndoCheckInGoalHabit: () => void;
  handleUndoSkipAndFailed: () => void;
  updateLogActionModal: (habitId: string, openState: boolean) => UpdateLogActionModal;
  handleCheckinBadHabit: () => void;
};

export const useProgressAction = (
  habitId: string,
  habit: Habit,
  habitCurrentProgress: HabitCurrentProgress | undefined,
  isPremium?: boolean,
): Return => {
  const [valueProgress, setValueProgress] = useState<number>(0);
  const [isBadHabit, setIsBadHabit] = useState(false);
  const uid = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const chooseDate = useSelector((state: RootState) => state.app.date);
  const usageConfig = useSelector((state: RootState) => state.app.remoteConfig.usageConfig);
  const userUsageConfig = useSelector((state: NewRootState) => state.userRemoteConfigModel.usageConfig);
  const eventsCount = useSelector((state: RootState) => state.app.eventCounts);

  const dispatch = useDispatch();
  const newDispatch = useDispatch<NewDispatch>();

  const usageLimit = useGetUsageLimit(userUsageConfig, usageConfig);

  const updateLogActionModal = useCallback(
    (habitId: string, openState: boolean) => dispatch(updateLogActionModalAction(habitId, openState)),
    [dispatch],
  );

  const habitLogInfo = habit?.logInfo;
  const habitProgress = habitCurrentProgress?.habitProgress;
  const isOneTimePerDayHabit = habitCurrentProgress?.isOneTimePerDayHabit;
  const habitGoalValue = habitCurrentProgress?.habitGoalValue;
  const habitActualLogValue = habitCurrentProgress?.habitActualLogValue;
  const isNoGoalHabit = habitCurrentProgress?.isNoGoalHabit;
  const unitSymbol = habitCurrentProgress?.habitGoalSymbol;
  const { type } = useLogInfoType(habitLogInfo);
  const { baseUnitSymbol } = useConvertUnit(unitSymbol);

  const { checkInDateISO, checkInDateFormatted } = useCheckInDate(chooseDate);

  const logHabitValue = () => {
    if (uid) {
      createNewHabitLog(uid, habitId, checkInDateISO, baseUnitSymbol, 1);
    }
  };

  const handleCheckinHabit = () => {
    if (type === HABIT_LOG_TYPE.AUTO) {
      updateLogActionModal(habitId, true);
    } else {
      if (uid) {
        if (isPremium) {
          if (isNoGoalHabit) {
            checkinNoGoalHabit(uid, habitId, checkInDateFormatted);
          }
          if (isOneTimePerDayHabit) {
            logHabitValue();
          }
          dispatch(createNewEventAction(eventType.CHECK_IN, uid));
        } else {
          const { checkin } = eventsCount;
          const limit = usageLimit[eventType.CHECK_IN].value || USAGE_LIMIT.CHECK_INS;
          const isLimit = checkLimitUsage(eventType.CHECK_IN, checkin, limit);
          if (isLimit) {
            newDispatch.appModel.setLimitType('check-ins');
            newDispatch.appModel.openLimitModal(true);
            dispatch(updateEventTypeAction(usageConfig[eventType.CHECK_IN]?.periodicity));
            dispatch(updateLimitTypeCurrent(LIMIT_EVENT_TYPE.CHECK_IN));
          } else {
            if (isNoGoalHabit) {
              checkinNoGoalHabit(uid, habitId, checkInDateFormatted);
            }
            if (isOneTimePerDayHabit) {
              logHabitValue();
            }
            dispatch(createNewEventAction(eventType.CHECK_IN, uid));
          }
        }
      }
    }
  };

  const handleLogValueForScalarTypeHabit = () => {
    const { checkin } = eventsCount;
    const limit = usageLimit[eventType.CHECK_IN].value || USAGE_LIMIT.CHECK_INS;
    const isLimit = checkLimitUsage(eventType.CHECK_IN, checkin, limit);
    if (isPremium) {
      logHabitValue();
    } else {
      if (isLimit) {
        newDispatch.appModel.setLimitType('check-ins');
        newDispatch.appModel.openLimitModal(true);
        dispatch(updateEventTypeAction(usageConfig[eventType.CHECK_IN]?.periodicity));
        dispatch(updateLimitTypeCurrent(LIMIT_EVENT_TYPE.CHECK_IN));
      } else {
        logHabitValue();
      }
    }
    if (uid && !isLimit) {
      dispatch(createNewEventAction(eventType.CHECK_IN, uid));
    }
  };

  const handleUndoCheckinNoGoalHabit = () => {
    if (uid) {
      removeCheckinHabit(uid, habitId, checkInDateFormatted);
    }
  };

  const handleUndoCheckInGoalHabit = () => {
    updateLogActionModal(habitId, true);
  };

  const handleUndoSkipAndFailed = () => {
    if (uid) {
      removeCheckinHabit(uid, habitId, checkInDateFormatted);
    }
  };

  const handleCheckinBadHabit = () => {
    if (uid) {
      if (isPremium) {
        checkinNoGoalHabit(uid, habitId, checkInDateFormatted);
        dispatch(createNewEventAction(eventType.CHECK_IN, uid));
      } else {
        const { checkin } = eventsCount;
        const limit = usageLimit[eventType.CHECK_IN].value || USAGE_LIMIT.CHECK_INS;
        const isLimit = checkLimitUsage(eventType.CHECK_IN, checkin, limit);
        if (isLimit) {
          newDispatch.appModel.setLimitType('check-ins');
          newDispatch.appModel.openLimitModal(true);
          dispatch(updateEventTypeAction(usageConfig[eventType.CHECK_IN]?.periodicity));
          dispatch(updateLimitTypeCurrent(LIMIT_EVENT_TYPE.CHECK_IN));
        } else {
          checkinNoGoalHabit(uid, habitId, checkInDateFormatted);
          dispatch(createNewEventAction(eventType.CHECK_IN, uid));
        }
      }
    }
  };

  useEffect(() => {
    if (habitActualLogValue && habitGoalValue) {
      const progress = (habitActualLogValue / habitGoalValue) * 100;
      if (progress < 1) {
        setValueProgress(1);
      } else {
        setValueProgress(progress);
      }
    } else setValueProgress(0);
  }, [habitActualLogValue, habitGoalValue]);

  useEffect(() => {
    if (habit.habitType) {
      const habitType = habit.habitType;
      switch (habitType.habitType) {
        case 'bad':
          setIsBadHabit(true);
          break;
        case 'good':
          setIsBadHabit(false);
          break;
        default:
          break;
      }
    } else {
      setIsBadHabit(false);
    }
  }, [habit.habitType]);

  return {
    unitSymbol,
    isOneTimePerDayHabit,
    isNoGoalHabit,
    habitProgress,
    habitGoalValue,
    habitActualLogValue,
    valueProgress,
    isBadHabit,
    handleCheckinHabit,
    handleLogValueForScalarTypeHabit,
    handleUndoCheckinNoGoalHabit,
    handleUndoCheckInGoalHabit,
    handleUndoSkipAndFailed,
    updateLogActionModal,
    handleCheckinBadHabit,
  };
};
