import { AddIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/react';
import CloseButton from 'components/common/button/close-button.component';
import PullDownButton from 'components/common/button/pushdown-button.component';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateOffModeModal from './create-off-mode/create-off-mode.modal';
import OffModeList from './off-mode-list.component';
interface GeneralSettingProps {
  closeSetting: () => void;
}
const OffModeSetting: React.FC<GeneralSettingProps> = ({ closeSetting }: GeneralSettingProps): ReactElement => {
  const { t } = useTranslation();
  const { typography, colorScheme } = useThemeData();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pr="8px"
        pb="9.5px"
        borderBottom={`1px solid ${colorScheme.other.border}`}
      >
        <Text {...typography.emphasized.title[2]} color={colorScheme.label.primary}>
          {t('offmode.settingOffModeTitle')}
        </Text>
        <CloseButton onClose={closeSetting} />
      </Box>
      <OffModeList />
      <Box display="flex" flexDir="column" pt="8px" pb="2px" borderTop={`1px solid ${colorScheme.other.border}`}>
        <Box ml="auto">
          <PullDownButton
            mt="auto"
            className="add-off-mode"
            leftIcon={<AddIcon color={colorScheme.label.secondary} />}
            title={t('offmode.planNewTimeOff')}
            bgColor={colorScheme.platform.control.background}
            border={`1px solid ${colorScheme.other.border}`}
            mr="8px"
            onClick={openModal}
            _hover={{ bgColor: colorScheme.platform.control.background }}
          />
        </Box>
      </Box>
      <CreateOffModeModal isOpenModal={isOpenModal} onCloseModal={closeModal} />
    </Box>
  );
};
export default React.memo(OffModeSetting);
