import { Box, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import { OffMode } from 'models/off-mode';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';
import { filterOffModeByDate, sortOffModeByDate } from 'tools/off-mode/off-mode-filter-by-date';
import OffModeListItem from './off-mode-list-item.component';
interface Props {}
const OffModeList: React.FC<Props> = (): ReactElement => {
  const { t } = useTranslation();
  const { typography, colorScheme } = useThemeData();
  const [rawOffModes, setRawOffModes] = useState<OffMode[]>([]);
  const [currentOffModes, setCurrentOffMode] = useState<OffMode[]>([]);
  const [upcomingOffModes, setUpcomingOffMode] = useState<OffMode[]>([]);
  const [pastOffModes, setPastOffMode] = useState<OffMode[]>([]);
  const offModes = useSelector((state: NewRootState) => state.offModeModel.offModes);

  useEffect(() => {
    if (!isEmpty(offModes)) {
      const offModeList = values(offModes);
      setRawOffModes(offModeList);
    } else {
      setRawOffModes([]);
    }
  }, [offModes]);

  useEffect(() => {
    filterOffModeByDate({ offModes: rawOffModes, status: 'current' }).then((offModesCurrent) => {
      setCurrentOffMode(offModesCurrent);
    });
    filterOffModeByDate({ offModes: rawOffModes, status: 'upcoming' }).then((offModesUpcoming) => {
      setUpcomingOffMode(offModesUpcoming);
    });
    filterOffModeByDate({ offModes: rawOffModes, status: 'past' }).then((offModesPast) => {
      const sortedOffModePast = sortOffModeByDate(offModesPast);
      setPastOffMode(sortedOffModePast);
    });
  }, [rawOffModes, rawOffModes.length]);

  return (
    <Box mr="16px" h="387px" overflow="auto">
      {currentOffModes.length > 0 && (
        <>
          <Text
            {...typography.emphasized.caption[2]}
            color={colorScheme.label.secondary}
            textTransform="uppercase"
            my="12px"
          >
            {t('common.current')}
          </Text>
          <OffModeListItem section="current" offModes={currentOffModes} />
        </>
      )}
      {upcomingOffModes.length > 0 && (
        <>
          <Text
            {...typography.emphasized.caption[2]}
            color={colorScheme.label.secondary}
            textTransform="uppercase"
            mb="12px"
            mt="18px"
          >
            {t('common.upcoming')}
          </Text>
          <OffModeListItem section="upcoming" offModes={upcomingOffModes} />
        </>
      )}
      {pastOffModes.length > 0 && (
        <>
          <Text
            {...typography.emphasized.caption[2]}
            color={colorScheme.label.secondary}
            textTransform="uppercase"
            mb="12px"
            mt="18px"
          >
            {t('common.past')}
          </Text>
          <OffModeListItem section="past" offModes={pastOffModes} />
        </>
      )}
    </Box>
  );
};
export default OffModeList;
