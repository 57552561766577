import { IHabitActionList, ISuggestionHabitItem } from "__archived__/types/states/habit";

export enum HABIT_TYPE {
  SELECT_SUGGESTION_HABIT = '@@habit/selectSuggestionHabit',
  CREATE_HABIT = '@@habit/createHabit',
  OPEN_ACTION_LIST_MODAL = '@@habit/openActionList',
  SET_LATEST_HABIT_ID = '@@habit/latestHabitId',
  GET_HABIT_ACTIONS = '@@habit/getHabitActions',
  GET_HABIT_ACTIONS_SUCCESS = '@@habit/getHabitActionsSuccess',
  GET_HABIT_ACTIONS_FAILED = '@@habit/getHabitActionsFailed',
  UPDATE_HABIT_ACTIONS_CHILD_ADDED = '@@habit/updateHabitActionsChildAdded',
  UPDATE_HABIT_ACTIONS_CHILD_CHANGE = '@@habit/updateHabitActionsChildChange',
  UPDATE_HABIT_ACTIONS_CHILD_REMOVE = '@@habit/updateHabitActionChildRemove',
}

export type SelectSuggestionHabitType = {
  readonly type: HABIT_TYPE.SELECT_SUGGESTION_HABIT;
  readonly payload: ISuggestionHabitItem;
};

export type CreateHabitType = {
  readonly type: HABIT_TYPE.CREATE_HABIT;
  readonly payload: boolean;
};

export type OpenActionListType = {
  readonly type: HABIT_TYPE.OPEN_ACTION_LIST_MODAL;
  readonly payload: boolean;
};

export type SetLatestHabitId = {
  readonly type: HABIT_TYPE.SET_LATEST_HABIT_ID;
  readonly payload: string;
};

export type GetHabitActionsType = {
  readonly type: HABIT_TYPE.GET_HABIT_ACTIONS;
  readonly payload: boolean;
};

export type GetHabitActionsSuccessType = {
  readonly type: HABIT_TYPE.GET_HABIT_ACTIONS_SUCCESS;
  readonly payload: Map<string, IHabitActionList>;
};

export type GetHabitActionsFailedType = {
  readonly type: HABIT_TYPE.GET_HABIT_ACTIONS_FAILED;
  readonly payload?: boolean;
};

export type HabitActionWithActionId = {
  habitId: string;
  habitAction: IHabitActionList;
};

export type UpdateHabitActionsChildAdded = {
  readonly type: HABIT_TYPE.UPDATE_HABIT_ACTIONS_CHILD_ADDED;
  readonly payload: HabitActionWithActionId;
};

export type UpdateHabitActionsChildChange = {
  readonly type: HABIT_TYPE.UPDATE_HABIT_ACTIONS_CHILD_CHANGE;
  readonly payload: HabitActionWithActionId;
};

export type UpdateHabitActionsChildRemoved = {
  readonly type: HABIT_TYPE.UPDATE_HABIT_ACTIONS_CHILD_REMOVE;
  readonly payload: string;
};

export type GetAllHabitActionsTypeAction =
  | GetHabitActionsType
  | GetHabitActionsSuccessType
  | GetHabitActionsFailedType
  | UpdateHabitActionsChildAdded
  | UpdateHabitActionsChildChange
  | UpdateHabitActionsChildRemoved;
export {}