import { Box, Flex, Text, forwardRef, ComponentWithAs } from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';

interface PreferencesItemProps {
  payload: {
    title: string;
    iconName: string;
    iconFolder: string;
    link?: {
      pathName: string;
      pageName: string;
      param?: string | number;
      open?: boolean;
    };
  };
  handleClick: (item: string) => void;
}

type PreferenceItemType = ComponentWithAs<'div', PreferencesItemProps>;

const PreferencesItem: PreferenceItemType = forwardRef(
  ({ payload, handleClick }: PreferencesItemProps, ref: React.Ref<HTMLDivElement>): ReactElement => {
    const { colorScheme, typography } = useThemeData();

    const handleOnClick = () => {
      // handle onClick
      handleClick(payload.title);
      // history.push({ pathname: payload.link.pathName, state: payload.link.open });
    };

    return (
      <Box
        ref={ref}
        borderRadius="5px"
        onClick={handleOnClick}
        _hover={{ background: colorScheme.sidebar.item.hover }}
        className="sidebar-item"
      >
        <Flex p="4.3px 8px" alignItems="center" cursor="default">
          <Box display="flex" mr="5px" className="sidebar-item-icon--color">
            <Icon folderName={payload.iconFolder} name={payload.iconName} fill={'#7B7C7C'} width={20} height={20} />
          </Box>
          <Text
            width="100%"
            p="6px 7px"
            textTransform="capitalize"
            className="sidebar-item-text--color"
            {...typography.normal.title[4]}
            color={colorScheme.label.secondary}
          >
            {payload.title}
          </Text>
        </Flex>
      </Box>
    );
  },
);

export default PreferencesItem;
