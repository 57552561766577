import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import axios from 'axios';
import ActionButton from 'components/authentication/common/action-button.component';
import EmailInput from 'components/authentication/common/email-input.component';
import Icon from 'components/common/Icon';
import WarningModal from 'components/common/modal/warning-modal/warning-modal.component';
import { getAuth, UserInfo } from 'firebase/auth';
import { useThemeData } from 'hooks/useThemeData';
import { PROVIDER_ID } from 'models/single-progress/profile';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NewDispatch } from 'store';

interface Props {
  updateUserInfo: (user: UserInfo) => void;
  closeModal: () => void;
}

const AccountAuthentication: React.FC<Props> = ({ updateUserInfo, closeModal }: Props): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch<NewDispatch>();
  const { colorScheme, typography } = useThemeData();
  const [email, setEmail] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [messError, setMessError] = useState('');
  const [isChangedEmailSuccess, setChangedEmailSuccess] = useState(false);

  const handleOnSubmit = async (email: string) => {
    const token = await getAuth().currentUser?.getIdToken();
    if (token) {
      setIsLoading(true);
      const providerData = getAuth().currentUser?.providerData.find(
        (val) => val.providerId === PROVIDER_ID.EMAIL_PROVIDER_ID,
      );
      const link = `${process.env.REACT_APP_CHANGE_EMAIL_URL}`;
      if (link) {
        axios
          .put(
            link,
            { email },
            {
              headers: {
                Authorization: token,
              },
            },
          )
          .then((result) => {
            if (result.data.success) {
              setChangedEmailSuccess(true);
            }
            const userInfo = { ...providerData, ...{ email } } as UserInfo;
            updateUserInfo(userInfo);
            setIsLoading(false);
            // closeModal();
          })
          .catch((err) => {
            setMessError('Change mail error :((');
            setIsLoading(false);
            setIsOpenWarningModal(true);
          });
      }
    }
  };

  const closeSuccessModal = () => {
    setChangedEmailSuccess(false);
    dispatch.authenticationModel.signOut();
  };

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        position="relative"
        border={`1px solid ${colorScheme.other.border}`}
        background="#313131"
        textAlign="center"
        padding="45px 54px 43px 55px"
        width="401px"
        maxH="352px"
        minH="210px"
        borderRadius="5px"
        bgColor={colorScheme.background.grouped[2]}
      >
        <Box marginTop="10px" id="account-authentication">
          <Flex
            as={'button'}
            position="absolute"
            top="12px"
            left="12px"
            p="6px 7px"
            height="30px"
            border={`1px solid ${colorScheme.other.border}`}
            justify="center"
            align="center"
            borderRadius="3px"
            cursor="default"
            onClick={() => closeModal()}
          >
            {/* <Icon as={FaChevronLeft} width="10.24px" height="10.24px" color={colorScheme.label.secondary} mr="11px" /> */}
            <Icon name="ic_arrow_left" width={16} height={16} fill={colorScheme.label.secondary} />
            <Text {...typography.emphasized.callout} color={colorScheme.label.primary} lineHeight="30px">
              {t('common.back')}
            </Text>
          </Flex>

          <>
            <Text
              {...typography.emphasized.title[1]}
              color={colorScheme.label.primary}
              mt="19px"
              mb="5px"
              lineHeight="27px"
            >
              Change Email
            </Text>
            <EmailInput defaultValue={email} handleInput={(e) => setEmail(e.target.value)} />
            <Flex justify={isLoading ? 'space-between' : 'flex-end'} align="center" w="280px" h="28px" m="0 auto">
              {isLoading && (
                <Text {...typography.emphasized.callout} color={colorScheme.label.secondary}>
                  Changing you in...
                </Text>
              )}

              {isLoading ? (
                <Box h="28px">
                  <Spinner color={colorScheme.label.secondary} w="20px" h="20px" />
                </Box>
              ) : (
                <Box mt="3px">
                  <ActionButton
                    text={t('common.save')}
                    handleSubmit={() => handleOnSubmit(email)}
                    width="76px"
                    height="28px"
                  />
                </Box>
              )}
            </Flex>
          </>
        </Box>
      </Box>
      <WarningModal
        icon="ic-warning-red"
        title={'Confirmation'}
        subTitle={messError}
        labelBtnSecondary={t('common.cancel')}
        open={isOpenWarningModal}
        closeModal={() => setIsOpenWarningModal(false)}
      />
      <WarningModal
        open={isChangedEmailSuccess}
        icon="ic-warning-blue"
        title="Successful"
        subTitle="Changed email successful, please sign in again"
        labelBtnSecondary="Ok"
        closeModal={closeSuccessModal}
      />
    </Box>
  );
};

export default AccountAuthentication;
