export class Lexorank {
  private static MIN_CHAR: number = Lexorank.byte('0');
  private static MAX_CHAR: number = Lexorank.byte('z');

  static insert(prev: string, next: string): [string, boolean] {
    if (prev === '') {
      prev = this.string(this.MIN_CHAR);
    }
    if (next === '') {
      next = this.string(this.MAX_CHAR);
    }

    let rank = '';
    let i = 0;

    while (true) {
      const prevChar = this.getChar(prev, i, this.MIN_CHAR);
      const nextChar = this.getChar(next, i, this.MAX_CHAR);

      if (prevChar === nextChar) {
        rank += this.string(prevChar);
        i++;
        continue;
      }

      const midChar = this.mid(prevChar, nextChar);
      if (midChar === prevChar || midChar === nextChar) {
        rank += this.string(prevChar);
        i++;
        continue;
      }

      rank += this.string(midChar);
      break;
    }

    if (rank >= next) {
      return [prev, false];
    }
    return [rank, true];
  }

  private static string(byte: number): string {
    return String.fromCharCode(byte);
  }

  private static byte(char: string): number {
    return char.charCodeAt(0);
  }

  private static mid(prev: number, next: number): number {
    // TODO: consider to use 8 steps each jump
    return Math.floor((prev + next) / 2);
  }

  private static getChar(s: string, i: number, defaultChar: number): number {
    if (i >= s.length) {
      return defaultChar;
    }
    return this.byte(s.charAt(i));
  }
}