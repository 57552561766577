import { Models } from '@rematch/core';
import { appSettingModel } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.model';
import { appModel } from './models/app';
import { authenticationModel } from './models/authentication';
import { appleAuthenticationModel } from './models/authentication/apple_authentication.model';
import { googleAuthenticationModel } from './models/authentication/google_authentication.model';
import { habitFoldersModel } from './models/habit-folders';
import { habitLogsModel } from './models/habit-logs';
import { habitProgressModel } from './models/habit-progress';
import { habitsModel } from './models/habits';
import { paymentModel } from './models/payment/payment.model';
import { persistanceModel } from './models/persistance/persistance.model';
import { preferencesModels } from './models/preferences/preferences.models';
import { userRemoteConfigModel } from './models/user-remote-config/user-remote-config.models';
import { usersInfoModel } from './models/users/users.model';
import { inAppMessageModel } from './models/in-app-message';
import { offModeModel } from './models/off-mode/off-mode.model';
export interface RootModel extends Models<RootModel> {
  authenticationModel: typeof authenticationModel;
  habitsModel: typeof habitsModel;
  habitLogsModel: typeof habitLogsModel;
  habitProgressModel: typeof habitProgressModel;
  appModel: typeof appModel;
  habitFoldersModel: typeof habitFoldersModel;
  paymentModel: typeof paymentModel;
  googleAuthenticationModel: typeof googleAuthenticationModel;
  appleAuthenticationModel: typeof appleAuthenticationModel;
  appSettingModel: typeof appSettingModel;
  preferencesModels: typeof preferencesModels;
  usersInfoModel: typeof usersInfoModel;
  inAppMessageModel: typeof inAppMessageModel;
  userRemoteConfigModel: typeof userRemoteConfigModel;
  offModeModel: typeof offModeModel;
}

export const models: RootModel = {
  authenticationModel,
  habitsModel,
  habitLogsModel,
  habitProgressModel,
  persistanceModel,
  appModel,
  habitFoldersModel,
  paymentModel,
  appleAuthenticationModel,
  googleAuthenticationModel,
  appSettingModel,
  preferencesModels,
  usersInfoModel,
  inAppMessageModel,
  userRemoteConfigModel,
  offModeModel,
};
