import { Box, Popover, PopoverTrigger } from '@chakra-ui/react';
import AppSettingModal from 'components/common/modal/preferences-modal-layout/app-setting/app-setting-modal.component';
import PreferenceModalLayout from 'components/common/modal/preferences-modal-layout/preferences-modal-layout.component';
import Processing from 'components/common/modal/preferences-modal-layout/upgrade-modal/processing-payment.component';
import UpgradeModal from 'components/common/modal/preferences-modal-layout/upgrade-modal/upgrade-modal.component';
import { useCheckPremium, useOnClickOutside } from 'hooks';
import { useEscKeyboardEvent } from 'hooks/useEscKeyboardEvent';
import { Plan } from 'models/payment';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { NewDispatch, NewRootState } from 'store';
import { FOLDER_NAME_ICON_COLLECTION } from '__archived__/types/enum/app';
import PreferencesItem from './preferences_item.sidebar';
import Resources from './resources-link/resouces-link.component';

interface IPreferences {
  title: string;
  iconName: string;
  iconFolder: string;
  link?: { pathName: string; pageName: string; state?: { open: boolean } };
}

const preferences: IPreferences[] = [
  {
    title: 'settings.reorderHabit',
    iconName: 'ic-habits-manage',
    iconFolder: FOLDER_NAME_ICON_COLLECTION.SIDEBAR,
    link: {
      pathName: '/manage-habits',
      pageName: 'manage',
    },
  },
  {
    title: 'settings.appSettings',
    iconName: 'ic-app-settings',
    iconFolder: FOLDER_NAME_ICON_COLLECTION.SIDEBAR,
  },
  {
    title: 'common.resources',
    iconName: 'ic_link',
    iconFolder: FOLDER_NAME_ICON_COLLECTION.SIDEBAR,
  },
];

const INTERCOM_APP_ID = 'z85a33or';

const PreferencesSidebar: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [plans, setPlans] = useState<Plan[]>([]);

  const resourceRef = useRef(null);
  const newDispatch = useDispatch<NewDispatch>();
  const isPremium = useCheckPremium();
  const isOpenPaymentModal = useSelector((state: NewRootState) => state.paymentModel.isOpenPaymentModal);
  const pricingInfo = useSelector((state: NewRootState) => state.paymentModel.pricingInfo);
  const userPricingInfo = useSelector((state: NewRootState) => state.userRemoteConfigModel.pricingInfo);
  const uid = useSelector((state: NewRootState) => state.authenticationModel.user?.uid);
  const currentTabInSetting = useSelector((state: NewRootState) => state.appSettingModel.currentTab);

  const isOpenProcessing = useSelector((state: NewRootState) => state.paymentModel.isOpenProcessing);

  const [isOpenResources, setIsOpenResources] = useState(false);

  useEffect(() => {
    if (userPricingInfo) {
      setPlans(userPricingInfo.plans);
    } else if (pricingInfo) {
      setPlans(pricingInfo.plans);
    }
  }, [pricingInfo, userPricingInfo]);

  useEffect(() => {
    if (uid) {
      newDispatch.appSettingModel.getPreferences(uid);
    }
  }, [newDispatch.appSettingModel, uid]);

  useEffect(() => {
    if (plans.length > 0) {
      newDispatch.paymentModel.getPaddleProducts(plans);
    }
  }, [newDispatch.paymentModel, plans]);

  useEscKeyboardEvent(() => {
    setIsOpenResources(false);
  });

  const closePayment = useCallback(() => {
    if (isOpenPaymentModal) {
      newDispatch.paymentModel.openPaymentModal(false);
    }
    if (currentTabInSetting === 'payment') {
      newDispatch.appSettingModel.changeTab('general');
    }
  }, [currentTabInSetting, isOpenPaymentModal, newDispatch.appSettingModel, newDispatch.paymentModel]);

  const closeProcessing = useCallback(() => {
    newDispatch.paymentModel.openProcessingModal(false);
  }, [newDispatch.paymentModel]);

  const openResources = () => {
    setIsOpenResources(!isOpenResources);
  };

  const closeResources = () => {
    setIsOpenResources(false);
  };

  useOnClickOutside(resourceRef, () => closeResources());

  useEffect(() => {
    if (isPremium) {
      closePayment();
    }
  }, [closePayment, isPremium]);

  const openAppSetting = () => {
    newDispatch.appSettingModel.openAppSetting(true);
  };

  const closeIntercom = () => {
    newDispatch.usersInfoModel.updateStatusOpenIntercom(false);
  };

  return (
    <Box>
      {preferences.map((item: IPreferences, index) => {
        const { title, iconName, iconFolder, link } = item;
        if (link) {
          return (
            <Link
              key={`${title}-${index}`}
              to={{
                pathname: `${link.pathName}`,
              }}
            >
              <PreferencesItem
                key={title}
                payload={{ title: t(`${title}`), iconName, iconFolder, link }}
                handleClick={(item) => {}}
              />
            </Link>
          );
        }
        if (title === 'settings.appSettings') {
          return (
            <PreferencesItem
              key={title}
              payload={{ title: t(`${title}`), iconName, iconFolder, link }}
              handleClick={openAppSetting}
            />
          );
        }
        return (
          <IntercomProvider
            key={`${title}-${index}`}
            appId={INTERCOM_APP_ID}
            initializeDelay={3000}
            onHide={closeIntercom}
          >
            <Box ref={resourceRef} key={`${title}-${index}`}>
              <Popover
                matchWidth
                isOpen={isOpenResources}
                onOpen={openResources}
                strategy="fixed"
                placement="right-start"
                gutter={14}
              >
                <PopoverTrigger>
                  <PreferencesItem
                    key={title}
                    payload={{ title: t(`${title}`), iconName, iconFolder, link }}
                    handleClick={openResources}
                  />
                </PopoverTrigger>
                <Resources closeMenu={closeResources} />
              </Popover>
            </Box>
          </IntercomProvider>
        );
      })}
      <PreferenceModalLayout isCentered isOpen={isOpenPaymentModal} onClose={closePayment} trapFocus={false}>
        <UpgradeModal />
      </PreferenceModalLayout>
      <PreferenceModalLayout isCentered isOpen={isOpenProcessing} onClose={closeProcessing}>
        <Processing closeModal={closeProcessing} />
      </PreferenceModalLayout>
      <AppSettingModal />
    </Box>
  );
};

export default React.memo(PreferencesSidebar);
