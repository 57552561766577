import { AppleProduct } from 'models/payment/apple-product';

export const appleProductParser = (rawValue: any): AppleProduct | undefined => {
  if (!rawValue) return undefined;
  return {
    contentLengths: rawValue['contentLengths'],
    contentVersion: rawValue['contentVersion'],
    currencyCode: rawValue['currencyCode'],
    formattedPrice: rawValue['formattedPrice'],
    isDownloadable: rawValue['isDownloadable'],
    localizedDescription: rawValue['localizedDescription'],
    localizedTitle: rawValue['localizedTitle'],
    price: rawValue['price'],
    productIdentifier: rawValue['productIdentifier'],
  };
};
