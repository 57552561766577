import { Box } from '@chakra-ui/react';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import { HabitFolders } from 'models/habit-folders';
import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';
import Area from './area.sidebar';
import NewArea from './new_area.sidebar';

const Areas: React.FC = (): ReactElement => {
  const areas = useSelector<NewRootState, HabitFolders>((state) => state.habitFoldersModel.habitFolders);
  const [folderIdPicked, setFolderIdPicked] = useState('');
  const [areasSorted, setAreasSorted] = useState<HabitFolders>({});

  useEffect(() => {
    if (!Object.keys(areas).length) {
      return;
    }
    const areasSorted = fromPairs(
      toPairs(areas).sort((a, b) => {
        const priority1 = a[1].priority || '';
        const priority2 = b[1].priority || '';
        return Number(priority1 < priority2) - Number(priority1 > priority2);
      }),
    );
    setAreasSorted(areasSorted);
  }, [areas]);

  return (
    <Box className="areas-section--width">
      {keys(areasSorted).map((folderId: string, index: number) => {
        return (
          <Area
            key={folderId}
            folderId={folderId}
            folderIdPicked={folderIdPicked}
            updateFolderIdPicked={setFolderIdPicked}
            folderIndex={index + 1}
          ></Area>
        );
      })}
      <NewArea areasSorted={areasSorted}></NewArea>
    </Box>
  );
};

export default React.memo(Areas);
