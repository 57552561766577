export enum CalendarClass {
  COMPLETED = 'completed',
  PREV_COMPLETED = 'pevCompleted',
  NEXT_COMPLETED = 'nextCompleted',
  MIDDLE_COMPLETED = 'middleCompleted',
  SINGLE_COMPLETED = 'singleCompleted',
  IN_PROGRESS = 'progress',
  SKIP = 'skip',
  FAIL = 'fail',
  NONE = 'none',
  BLOCK = 'blocked',
  OFF = 'off',
}

export enum FilterCalendarOptionType {
  MONTH = 'month',
  YEAR = 'year',
}

export enum WidthElementNotes {
  LG = 341,
}
