import { SaleCampaign } from 'models/payment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NewRootState } from 'store';

export const useGetPromo = (): SaleCampaign | null => {
  const [promo, setPromo] = useState<SaleCampaign>();
  const remotePromo = useSelector((state: NewRootState) => state.paymentModel.remoteSaleCampaign);
  const userPromo = useSelector((state: NewRootState) => state.userRemoteConfigModel.remoteSaleCampaign);

  useEffect(() => {
    if (userPromo) {
      setPromo(userPromo);
    } else if (remotePromo) {
      setPromo(remotePromo);
    }
  }, [remotePromo, userPromo]);

  if (promo) return promo;
  return null;
};
