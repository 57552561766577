import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  User,
  OAuthCredential,
  sendPasswordResetEmail,
  signInWithCredential,
} from '@firebase/auth';
import { Unsubscribe } from '@firebase/util';
import { createModel } from '@rematch/core';
import { auth } from 'firebase-v9';
import { getCookie, setCookie } from 'tools/cookie-tools';
import { RootModel } from '../../root.model';

type AuthenticationState = {
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
  signInWithEmail: boolean;
  credential: OAuthCredential | null;
  signInWithCredential: boolean;
};

const isAuthenticated = getCookie({ cname: '_is_authenticated' });

let initialAuthenticatedState = false;
let initialUser: User | null = null;
let initialIsLoading: boolean = false;

if (isAuthenticated) {
  initialAuthenticatedState = true;
  const ApplicationUser = localStorage.getItem('ApplicationUser');
  if (ApplicationUser) {
    initialUser = JSON.parse(ApplicationUser).user;
  }
} else {
  initialIsLoading = true;
}

const initialState: AuthenticationState = {
  isAuthenticated: initialAuthenticatedState,
  user: initialUser,
  isLoading: initialIsLoading,
  signInWithEmail: false,
  credential: null,
  signInWithCredential: false,
};

export const authenticationModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setIsAuthenticated(state, payload: boolean) {
      return {
        ...state,
        isAuthenticated: payload,
      };
    },
    setAuthenticationState(state, payload: { isAuthenticated: boolean; user: User; isLoading: boolean }) {
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
        user: payload.user,
        isLoading: payload.isLoading,
      };
    },
    resetAuthenticationState() {
      return {
        isAuthenticated: false,
        user: null,
        isLoading: false,
        signInWithEmail: false,
        credential: null,
        signInWithCredential: false,
      };
    },
    setSignInWithEmail(state, payload: boolean) {
      return {
        ...state,
        signInWithEmail: payload,
      };
    },
    setCredential(state, payload: OAuthCredential) {
      return {
        ...state,
        credential: payload,
      };
    },
    setSignInWithCredential(state, payload: boolean) {
      return {
        ...state,
        signInWithCredential: payload,
      };
    },
  },
  effects: (dispatch) => ({
    signIn({ email, password }: { email: string; password: string }) {
      return signInWithEmailAndPassword(auth, email, password);
    },
    resetPassword({ email }: { email: string }) {
      return sendPasswordResetEmail(auth, email);
    },
    signInWithCredential(credential: OAuthCredential) {
      return signInWithCredential(auth, credential);
    },
    onRefreshAuthenticationState({ setUnsubscribe }: { setUnsubscribe: (unsubscribe: Unsubscribe) => void }) {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          localStorage.setItem('ApplicationUser', JSON.stringify(currentUser));
          const d = new Date();
          d.setTime(d.getTime() + 3600000);
          setCookie({ cName: '_is_authenticated', cValue: JSON.stringify(true), exTime: d.toUTCString() });
          this.setAuthenticationState({ isAuthenticated: true, user: currentUser, isLoading: false });
        } else {
          localStorage.removeItem('ApplicationUser');
          const d = new Date('1970-01-01');
          setCookie({ cName: '_is_authenticated', cValue: JSON.stringify(true), exTime: d.toUTCString() });
          this.resetAuthenticationState();
        }
      });
      setUnsubscribe(unsubscribe);
    },
    signOut() {
      signOut(auth).then(() => {
        this.resetAuthenticationState();
        dispatch.habitsModel.resetState();
        dispatch.habitLogsModel.resetState();
        dispatch.habitProgressModel.resetState();
        dispatch.offModeModel.updateIsOffMode(false);
      });
    },
  }),
});
