// import { convert, getBaseUnitFromType, getType } from './SIUnitUtils';
import dayjs from 'dayjs';
import { UnitType } from 'models/common';
import { Habit, HabitGoal, HabitGoals } from 'models/habits';
import { HabitLog } from 'models/habit-logs';
import { getCurrentHabitGoal } from 'tools/habit-progress';
import {
  convert,
  getBaseUnitFromType as newGetBaseUnitFromType,
  getType as newGetType,
} from 'tools/si-unit/si-unit-utils';
import { getMonthKey, getWeekExactlyByMonth, getWeekKey } from '.';
import { FirstWeekDay } from 'components/common/modal/preferences-modal-layout/app-setting/models/app-setting.type';

interface IHabitLogFilter {
  habitLog: HabitLog;
  logValue: number;
  isNoGoal?: boolean;
}

const getLogValue = (
  logValueConverted: number,
  habitLogFilter: Map<string, IHabitLogFilter>,
  dateKey: string,
): number => {
  return habitLogFilter.get(dateKey)
    ? Number(habitLogFilter.get(dateKey)?.logValue) + Number(logValueConverted)
    : Number(logValueConverted) || 0;
};

export const getHabitLogs = (
  habit: Habit,
  habitLogs: HabitLog[],
  listHabitGoal: HabitGoals,
  firstDayOfWeek: FirstWeekDay,
): Map<string, IHabitLogFilter> => {
  if (!habitLogs || !Object.keys(listHabitGoal).length) return new Map();
  const habitStartDate: number | null | undefined = habit.startDate;
  const habitLogFilter: Map<string, IHabitLogFilter> = new Map<string, IHabitLogFilter>();
  const logType = (habit.logInfo?.type && habit.logInfo?.type !== 'manual') || habit.logInfo?.links ? 'auto' : 'manual';
  if (habitStartDate) {
    habitLogs.forEach((habitLog) => {
      if (habitLog && habitLog.unitSymbol) {
        const habitGoal: HabitGoal | null | undefined = getCurrentHabitGoal({
          goals: listHabitGoal,
          date: dayjs(habitLog.startAt),
          firstDayOfWeek,
        });
        const timesTampsHabitStartDate: number = dayjs(dayjs(habitStartDate).format('YYYY-MM-DD')).valueOf();
        const timesTampsHabitLog: number = dayjs(habitLog.startAt).valueOf();
        const isConditionGetHabitLog: boolean = timesTampsHabitLog >= timesTampsHabitStartDate;
        const habitLogType: string = habitLog?.type !== 'manual' ? 'auto' : 'manual';
        const habitLogUnitType: UnitType = newGetType({ unitSymbol: habitLog.unitSymbol });
        const baseUnitSymbolOfLog: string = newGetBaseUnitFromType({ type: habitLogUnitType });
        if (isConditionGetHabitLog && logType === habitLogType && habitLog?.unitSymbol === baseUnitSymbolOfLog) {
          const dateKey: string = dayjs(habitLog.startAt).format('DDMMYYYY');
          const weekKey: string = getWeekKey(dayjs(habitLog.startAt), firstDayOfWeek);
          const weekKeyExactlyByMonth = getWeekExactlyByMonth(dayjs(habitLog.startAt));
          const monthKey: string = getMonthKey(dayjs(habitLog.startAt));
          const _habitLog = typeof habitLog.value === 'number' ? habitLog.value : 0;
          const logValueConverted = convert({
            source: habitLog.unitSymbol,
            target: baseUnitSymbolOfLog,
            value: _habitLog,
          });
          const logValueDaily: number = getLogValue(logValueConverted, habitLogFilter, dateKey);
          const logValueWeek: number = getLogValue(logValueConverted, habitLogFilter, weekKey);
          const logValueWeekExactlyByMonth: number = getLogValue(
            logValueConverted,
            habitLogFilter,
            weekKeyExactlyByMonth,
          );
          const logValueMonth: number = getLogValue(logValueConverted, habitLogFilter, monthKey);

          if (habitGoal?.unit?.symbol) {
            const habitSymbol = habitGoal.unit.symbol;
            const isSymbolSame: boolean = newGetType({ unitSymbol: habitSymbol }) === habitLogUnitType;
            if (isSymbolSame) {
              habitLogFilter.set(dayjs(habitLog.startAt).format('DDMMYYYY'), { habitLog, logValue: logValueDaily });
              habitLogFilter.set(weekKey, { habitLog, logValue: logValueWeek });
              habitLogFilter.set(weekKeyExactlyByMonth, { habitLog, logValue: logValueWeekExactlyByMonth });
              habitLogFilter.set(monthKey, { habitLog, logValue: logValueMonth });
            }
          }
          if (!habitGoal) {
            habitLogFilter.set(dayjs(habitLog.startAt).format('DDMMYYYY'), {
              habitLog,
              logValue: logValueDaily,
              isNoGoal: true,
            });
            habitLogFilter.set(weekKey, { habitLog, logValue: logValueWeek, isNoGoal: true });
            habitLogFilter.set(weekKeyExactlyByMonth, {
              habitLog,
              logValue: logValueWeekExactlyByMonth,
              isNoGoal: true,
            });
            habitLogFilter.set(monthKey, { habitLog, logValue: logValueMonth, isNoGoal: true });
          }
        }
      }
    });
  }
  return habitLogFilter;
};
