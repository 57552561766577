import memoizeOne from 'memoize-one';
import { OffMode } from 'models/off-mode';
import { stringValueParser } from 'tools/parser';

export const offModeModelMapper = memoizeOne(
  ({ key, rawValue }: { key: string; rawValue: any }): OffMode | undefined => {
    if (!rawValue) return undefined;
    return {
      id: key,
      reason: reasonMapper(rawValue['reason']),
      iconNamed: stringValueParser(rawValue['iconNamed']),
      startDate: offModeDateMapper(rawValue['startDate']),
      endDate: offModeDateMapper(rawValue['endDate']),
      stopDate: offModeDateMapper(rawValue['stopDate']),
    };
  },
);

const reasonMapper = memoizeOne((rawValue: any): string | undefined => {
  if (!rawValue) {
    return undefined;
  }

  return rawValue;
});

const offModeDateMapper = memoizeOne((rawValue: any): string | undefined => {
  if (!rawValue) {
    return undefined;
  }

  return rawValue;
});
