import { createModel } from '@rematch/core';
import { StartWeekDay } from 'components/common/custom-date-picker/date-picker.type';
import remoteConfig from 'firebase-v9/remote-config';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { TimeOfDay, UnitSymbol } from 'models/common';
import { RootModel } from 'store/root.model';
import { _dayjs } from 'tools/extended-dayjs';
import { getTimeOfDay } from 'tools/time-of-day/time-of-day-utils';

export type SortingType = 'habit-order' | 'reminder-time' | 'alphabetical-asc' | 'alphabetical-desc';

type ContextMenu = {
  xPos: number;
  yPos: number;
  showMenu: boolean;
  habitId: string;
};

export type TimerOverlayType = {
  habitId: string;
  timeValue?: number | null;
  unitSymbol?: UnitSymbol | null;
};

export type LimitType =
  | 'create-habit'
  | 'reminders'
  | 'check-ins'
  | 'mood-logs'
  | 'notes'
  | 'skips'
  | 'timers'
  | 'upload_note_image'
  | '';

type AppState = {
  firstWeekDay: StartWeekDay;
  chooseDate: string;
  timeOfDay: TimeOfDay;
  selectedFolderId: string | null;
  sortingType: SortingType;
  isOpenLimitModal: boolean;
  limitHabit: number;
  contextMenu: ContextMenu;
  isOpenCreateEditHabitModal: boolean;
  isOpenCreateBadHabitModal: boolean;
  isCanMakeMacAppStorePayments: boolean;
  timerOverlay: TimerOverlayType;
  isOpenMoodNoteModal: boolean;
  limitType?: LimitType;
  stateZoom: boolean;
};

const initialState: AppState = {
  firstWeekDay: 'sun',
  chooseDate: _dayjs().format('YYYY-MM-DD'),
  timeOfDay: getTimeOfDay({ lowerbound: 0, upperbound: 720 }, { lowerbound: 720, upperbound: 1080 }),
  selectedFolderId: null,
  sortingType: 'habit-order',
  isOpenLimitModal: false,
  limitHabit: 0,
  contextMenu: {
    xPos: 0,
    yPos: 0,
    showMenu: false,
    habitId: '',
  },
  isOpenCreateEditHabitModal: false,
  isOpenCreateBadHabitModal: false,
  isCanMakeMacAppStorePayments: false,
  timerOverlay: {
    habitId: '',
    timeValue: 0,
    unitSymbol: 'sec',
  },
  isOpenMoodNoteModal: false,
  stateZoom: false,
};

export const appModel = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFirstWeekDay(state, payload: StartWeekDay) {
      return {
        ...state,
        firstWeekDay: payload,
      };
    },
    setChooseDate(state, payload: string) {
      return {
        ...state,
        chooseDate: payload,
      };
    },
    setTimeOfDay(state, payload: TimeOfDay) {
      return {
        ...state,
        timeOfDay: payload,
      };
    },
    setSelectedFolderId(state, payload: string | null) {
      return {
        ...state,
        selectedFolderId: payload,
      };
    },
    setSortingType(state, payload: SortingType) {
      return {
        ...state,
        sortingType: payload,
      };
    },
    setIsOpenLimitModal(state, payload: boolean) {
      return {
        ...state,
        isOpenLimitModal: payload,
      };
    },
    setLimitHabit(state, payload: number) {
      return {
        ...state,
        limitHabit: payload,
      };
    },
    setContextMenu(state, payload: ContextMenu) {
      return {
        ...state,
        contextMenu: payload,
      };
    },
    setOpenCreateEditModal(state, payload: boolean) {
      return {
        ...state,
        isOpenCreateEditHabitModal: payload,
      };
    },
    setOpenBadHabitModal(state, payload: boolean) {
      return {
        ...state,
        isOpenCreateBadHabitModal: payload,
      };
    },
    setIsCanMakeMacAppStorePayments(state, payload: boolean) {
      return {
        ...state,
        isCanMakeMacAppStorePayments: payload,
      };
    },
    setInitialState(_, payload: AppState) {
      return payload;
    },
    setTimerOverlay(state, payload: TimerOverlayType) {
      return {
        ...state,
        timerOverlay: payload,
      };
    },
    setIsOpenMoodNoteModal(state, payload: boolean) {
      return {
        ...state,
        isOpenMoodNoteModal: payload,
      };
    },
    setLimitType(state, payload: LimitType) {
      return {
        ...state,
        limitType: payload,
      };
    },
    setStateZoom(state, payload: boolean) {
      return {
        ...state,
        stateZoom: payload,
      };
    },
  },
  effects: (dispatch) => ({
    onSetNewDate(date: string) {
      dispatch.appModel.setChooseDate(date);
    },
    openLimitModal(open: boolean) {
      dispatch.appModel.setIsOpenLimitModal(open);
    },
    openCreateEditModal(open: boolean) {
      dispatch.appModel.setOpenCreateEditModal(open);
    },
    openBadHabitModal(open: boolean) {
      dispatch.appModel.setOpenBadHabitModal(open);
    },
    getUsageConfigAction() {
      fetchAndActivate(remoteConfig).then(() => {
        const limitHabit = getValue(remoteConfig, 'numberOfFreeHabitAllowed');
        const parsedLimitHabit = limitHabit && !!limitHabit.asString() && JSON.parse(limitHabit.asString());
        dispatch.appModel.setLimitHabit(parseInt(parsedLimitHabit));
      });
    },
    updateContextMenuAction(contextMenu: ContextMenu) {
      dispatch.appModel.setContextMenu(contextMenu);
    },
    updateTimerOverlay(timer: TimerOverlayType) {
      dispatch.appModel.setTimerOverlay(timer);
    },
    openMoodNoteModal(open: boolean) {
      dispatch.appModel.setIsOpenMoodNoteModal(open);
    },
    resetAppState() {
      dispatch.appModel.setInitialState(initialState);
    },
    updateLimitType(type: LimitType) {
      dispatch.appModel.setLimitType(type);
    },
    updateStateZoom(state: boolean) {
      dispatch.appModel.setStateZoom(state);
    },
  }),
});
