const formatHours: { [key: string]: number } = {
  "12": 12,
  "13": 1,
  "14": 2,
  "15": 3,
  "16": 4,
  "17": 5,
  "18": 6,
  "19": 7,
  "20": 8,
  "21": 9,
  "22": 10,
  "23": 11,
  "24": 12,
};
export const getFormatTime = (timeline: number): string => {
  let hours: number | string = Math.floor(timeline / 60);
  let minutes: number | string = Math.floor(timeline % 60);
  let timezone!: string;

  timezone = hours < 12 ? "AM" : "PM";

  if (hours === 0) hours = 12;
  else if (hours === 24) {
    hours = 12;
    minutes = "00";
    timezone = "AM";
  } else if (hours > 12) hours = formatHours[hours];

  if (minutes === 0) minutes = "00";

  return `${hours}:${minutes} ${timezone}`;
};
