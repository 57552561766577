import { Button, useColorModeValue } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';

interface Props {
  date: number;
  isSelected: boolean;
  selectDate: () => void;
  isDisable?: boolean;
}

const DateItem: React.FC<Props> = ({ date, isSelected, selectDate, isDisable }: Props): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  const dateCellHoveredColor = useColorModeValue(
    colorScheme.background.primary[2],
    colorScheme.platform.control.background,
  );
  const weekDaysColor = useColorModeValue('rgb(0,0,0,0.3)', colorScheme.label.secondary);

  return (
    <Button
      size="xs"
      variant="ghost"
      bg={isSelected ? colorScheme.accent.primary : 'transparent'}
      color={isSelected ? colorScheme.universal.white : colorScheme.label.primary}
      {...typography.emphasized.callout}
      _hover={{
        background: isSelected ? colorScheme.accent.primary : dateCellHoveredColor,
      }}
      _active={{
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3) 0 0)',
      }}
      _focus={{ outline: 'none' }}
      width="27px"
      height="27px"
      borderRadius="5px"
      onClick={selectDate}
      cursor="default"
      isDisabled={isDisable}
      _disabled={{ color: weekDaysColor, cursor: 'not-allowed' }}
    >
      {date}
    </Button>
  );
};
export default DateItem;
