import toPairs from 'lodash/toPairs';
import { CheckInStatus, HabitCheckIn, HabitCheckins } from 'models/habits';
import memoizeOne from 'memoize-one';

export const habitCheckinsModelMapper = memoizeOne(({ rawValue }: { rawValue: any }): HabitCheckins => {
  const habitCheckins: HabitCheckins = {};
  if (rawValue) {
    for (let [key, value] of toPairs(rawValue)) {
      const habitCheckin: HabitCheckIn = {
        date: key,
        rawValue: value,
        value: checkinStatusMapper(value),
      };

      habitCheckins[key] = habitCheckin;
    }
  }

  return habitCheckins;
});

const checkinStatusMapper = memoizeOne((rawValue: any): CheckInStatus => {
  if (rawValue === 1) {
    return 'skipped';
  } else if (rawValue === 2) {
    return 'completed';
  } else if (rawValue === 3) {
    return 'failed';
  } else {
    return 'none';
  }
});
