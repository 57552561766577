import { HabitFolder } from 'models/habit-folders';
import { useEffect, useState } from 'react';

export const useFolderColor = (folder: HabitFolder | undefined): string => {
  const [color, setColor] = useState<string>('');

  useEffect(() => {
    if (folder && folder.color) {
      const folderColor = folder.color;
      setColor(folderColor);
    } else {
      setColor('');
    }
  }, [folder]);

  return color;
};
